import { FormInput } from '@/components/common/form';
import {
	Card,
	CardContent,
	CardDescription,
	CardHeader,
	CardTitle,
} from '@/components/base/ui/card';
import { FormField } from '@/components/base/ui/form';
import {
	ButtonAdd,
	ButtonCancel,
	ButtonDelete,
	ButtonEdit,
	ButtonSave,
	ConfirmDialog,
} from '@/components/common';
import { cn } from '@/lib/utils';
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from '@/ui/table';
import { NotifyHelper } from '@/utils/notify-helper';
import { useCallback, useMemo, useState } from 'react';
import { BankInfoOptionObject } from '@/contexts/models/Common';

interface BankInfoCardProps {
	array_string?: string;
	name?: string;
	onValueChange: (value: string) => void;
}

type BankInfoOptionObjectFrom = {
	isShow: boolean;
	isEdit: boolean;
	objectData: BankInfoOptionObject;
};

const BankInfoCard = ({ array_string, onValueChange }: BankInfoCardProps) => {
	const [showForm, setShowForm] = useState<BankInfoOptionObjectFrom>({
		isEdit: false,
		isShow: false,
		objectData: { id: 0, title: '', description: '', sort_order: 0 },
	});
	const [showConfirm, setShowConfirm] = useState({
		isShow: false,
		objectId: 0,
	});

	const dataSource = useMemo(() => {
		if (array_string && array_string.length > 0) {
			const result = JSON.parse(array_string) as BankInfoOptionObject[];
			return result.map((item, index) => ({
				...item,
				sort_order: index + 1,
				id: !item.id ? index + 10 : item.id,
			}));
		}
		return [];
	}, [array_string]);

	const handleSave = useCallback(
		(data: BankInfoOptionObject) => {
			if (data && data.title && data.description) {
				const newList = [...dataSource];
				if (data.id > 0) {
					const index = newList.findIndex((item) => item.id === data.id);
					if (index > -1) {
						newList[index] = data;
					}
				} else {
					newList.push({ ...data, sort_order: newList.length + 1 });
				}
				const reOrders = newList.map((item, index) => ({
					...item,
					sort_order: index + 1,
					id: index + 10,
				}));
				onValueChange(JSON.stringify(reOrders));
			} else {
				NotifyHelper.Error('Vui lòng chọn lại thông tin');
			}
		},
		[dataSource, onValueChange]
	);

	const handleDelete = useCallback(() => {
		const index = dataSource.findIndex(
			(item) => item.id === showConfirm.objectId
		);
		const newList = dataSource;
		if (index > -1) newList.splice(index, 1);

		const reOrders = newList.map((item, index) => ({
			...item,
			sort_order: index + 1,
		}));
		onValueChange(JSON.stringify(reOrders));
	}, [dataSource, onValueChange, showConfirm.objectId]);

	const FormDataEdit = useMemo(() => {
		const editData = showForm.objectData || {
			id: 0,
			title: '',
			description: '',
			sort_order: 0,
		};
		return (
			<>
				<div className='grid md:grid-cols-3 space-x-2'>
					<FormField
						name='title'
						render={() => (
							<FormInput
								isRequired={true}
								name='title'
								value={editData.title}
								onValueChange={(value) =>
									setShowForm((prev) => ({
										...prev,
										objectData: { ...editData, title: value },
									}))
								}
								label='Tên ngân hàng'
							/>
						)}
					/>
					<FormField
						name='description'
						render={() => (
							<FormInput
								isRequired={true}
								name='description'
								value={editData.description}
								onValueChange={(value) =>
									setShowForm((prev) => ({
										...prev,
										objectData: { ...editData, description: value },
									}))
								}
								label='Thông tin thanh toán'
								classForm='md:col-span-2'
							/>
						)}
					/>
				</div>

				<ButtonSave
					className='mr-2'
					title='Lưu'
					onClick={() => {
						handleSave(showForm.objectData);
						setShowForm({
							isShow: false,
							isEdit: false,
							objectData: {
								id: 0,
								title: '',
								description: '',
								sort_order: 0,
							},
						});
					}}
				/>
				<ButtonCancel
					onClick={() => {
						setShowForm({
							isShow: false,
							isEdit: false,
							objectData: {
								id: 0,
								title: '',
								description: '',
								sort_order: 0,
							},
						});
					}}
				/>
			</>
		);
	}, [showForm, setShowForm, handleSave]);

	return (
		<>
			<Card className='mb-2'>
				<CardHeader
					className={cn('p-4', 'flex flex-row items-start bg-muted/50')}
				>
					<div className='grid gap-0.5'>
						<CardTitle className='group flex items-center gap-2 text-md space-x-1'>
							Ngân hàng
						</CardTitle>
						<CardDescription>
							Thông tin về tài khoản ngân hàng của khách hàng
						</CardDescription>
					</div>
				</CardHeader>
				<CardContent className={cn('pt-2')}>
					<div className='rounded-md border'>
						<Table>
							<TableHeader className='text-center'>
								<TableRow className='text-center'>
									<TableHead className='w-[60px] text-center'>STT</TableHead>
									<TableHead className='w-[300px] text-center'>
										Ngân hàng
									</TableHead>
									<TableHead className='w-[60px] text-center'>...</TableHead>
								</TableRow>
							</TableHeader>
							<TableBody>
								{dataSource.map((item, index) => (
									<TableRow key={index}>
										{showForm.isShow && showForm.objectData.id === item.id ? (
											<TableCell
												colSpan={3}
												className={
													showForm.isShow ? 'text-left' : 'text-center'
												}
											>
												{FormDataEdit}
											</TableCell>
										) : (
											<>
												<TableCell className='text-center font-semibold'>
													{index + 1}
												</TableCell>
												<TableCell className='font-semibold'>
													<span className='text-primary'>{item.title}: </span>
													{item.description}
												</TableCell>
												<TableCell className='font-semibold text-center'>
													<ButtonEdit
														variant={'link'}
														onClick={() =>
															setShowForm({
																isShow: true,
																isEdit: true,
																objectData: item,
															})
														}
													/>
													<ButtonDelete
														variant={'link'}
														onClick={() =>
															setShowConfirm({
																isShow: true,
																objectId: item.id,
															})
														}
													/>
												</TableCell>
											</>
										)}
									</TableRow>
								))}
								<TableRow>
									<TableCell
										colSpan={3}
										className={showForm.isShow ? 'text-left' : 'text-center'}
									>
										{showForm.isShow === false && (
											<ButtonAdd
												variant={'outline'}
												className='h-7'
												title='Tạo mới'
												onClick={() =>
													setShowForm({
														isShow: true,
														isEdit: false,
														objectData: {
															id: 0,
															title: '',
															description: '',
															sort_order: 0,
														},
													})
												}
											/>
										)}
										{showForm.isShow === true && showForm.isEdit === false && (
											<>{FormDataEdit}</>
										)}
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</div>
				</CardContent>
			</Card>
			{showConfirm.isShow && (
				<ConfirmDialog
					isOpen={showConfirm.isShow}
					onClose={() => setShowConfirm({ isShow: false, objectId: 0 })}
					onConfirm={() => handleDelete()}
					title='Thông báo'
					description={`Bạn có chắc chắn muốn xóa nội dung này?`}
				/>
			)}
		</>
	);
};

export default BankInfoCard;
