import { Badge } from '@/components/base/ui/badge';
import { useContractStatus } from '@/hooks/catalog/use-contract-status';
import { cn } from '@/lib/utils';
import { DotFilledIcon } from '@radix-ui/react-icons';
import { useMemo } from 'react';

interface IContractStatusBadgeProps {
	value: string;
}

export const ContractStatusBadge = ({ value }: IContractStatusBadgeProps) => {
	const { all_contract_status } = useContractStatus();

	const data = useMemo(() => {
		return (
			all_contract_status?.find((item) => item.id === value) || {
				id: 'NEW',
				name: 'Hóa đơn mới',
				class: 'bg-secondary text-secondary',
			}
		);
	}, [all_contract_status, value]);

	return (
		<>
			<Badge
				variant='outline'
				className={cn('inline-flex', data.class)}
			>
				<DotFilledIcon className={cn('w-3 h-3')} /> 
				<span className='text-[11px]'>{data.name}</span>
			</Badge>
		</>
	);
};
