import { ButtonCustom } from '@/components/common/button';
import { FormSheetDialog } from '@/components/common/form/form-sheet-dialog';
import {
	useContractInfoGetDetailQuery,
	useContractInfoSaveMutation,
	useLazyContractInfoGetWordFileQuery,
} from '@/contexts/api/contract-management/contract/contract-info-api';
import {
	ContractInfoFormDefault,
	ContractInfoFormModel,
	ContractInfoFormValid,
} from '@/contexts/models/contract-management/contract';
import { useBrowserHistory } from '@/hooks/use-browser-history';
import { zodResolver } from '@hookform/resolvers/zod';
import { FileDownIcon } from 'lucide-react';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ContractDetailForm } from './form/contract-detail-form';
import { useLazyContractDeliveryGetAllByFilterQuery } from '@/contexts/api/contract-management/contract/contract-delivery-api';

interface IContractDetailFormSheetProps {
	title: string;
	document_type: string;
	contract_id: number;
	ref_contract_id?: number;
	quotation_id?: number;
	isOpen: boolean;
	onOpenChange: (isOpen: boolean) => void;
}

const ContractDetailFormSheet = ({
	title = 'hợp đồng',
	document_type = '000',
	contract_id,
	ref_contract_id = 0,
	quotation_id = 0,
	isOpen,
	onOpenChange,
}: IContractDetailFormSheetProps) => {
	const history = useBrowserHistory();
	const {
		data: contractInfo,
		isLoading: loading,
		isSuccess: loadOK,
	} = useContractInfoGetDetailQuery(contract_id);

	const [currentDocumentType, setCurrentDocumentType] =
		useState<string>(document_type);

	const [downloadWord] = useLazyContractInfoGetWordFileQuery();
	const [saveAction, { data, isLoading: isSaving, isSuccess: isSaved }] =
		useContractInfoSaveMutation();

	const [
		getDeliveryByContract,
		{ data: contract_deliveries, isSuccess: isDeliveryLoaded },
	] = useLazyContractDeliveryGetAllByFilterQuery();

	const [defaultValues, setDefaultValues] = useState<ContractInfoFormModel>({
		...ContractInfoFormDefault,
		document_type: currentDocumentType,
	});

	const form = useForm<ContractInfoFormModel>({
		resolver: zodResolver(ContractInfoFormValid),
		defaultValues,
	});

	const {
		reset,
		control,
		setValue,
		formState: { isSubmitting },
	} = form;

	const isLoading = loading || isSaving || isSubmitting;

	useEffect(() => {
		if (defaultValues.id > 0) {
			getDeliveryByContract(defaultValues.id);
		}
	}, [defaultValues, getDeliveryByContract]);

	useEffect(() => {
		if (contract_deliveries) {
			setValue('contract_deliveries', contract_deliveries);
		}
	}, [contract_deliveries, setValue]);

	const onSubmit = useCallback(
		async (formState: ContractInfoFormModel) => {
			const modelSave = {
				...formState,
				document_type: currentDocumentType,
				document_code: defaultValues.document_code,
				document_date: defaultValues.document_date,
			};
			await saveAction(modelSave).unwrap();
		},
		[saveAction, defaultValues, currentDocumentType]
	);

	useEffect(() => {
		if (isSaved && data) {
			history.navigate(
				`${history.base_name.replace(':id', data.id.toString())}`,
				{
					replace: true,
					state: { from: history.parent_path },
				}
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, isSaved]);

	useEffect(() => {
		if (loadOK && contractInfo) {
			setCurrentDocumentType(contractInfo.document_type);
			setDefaultValues(contractInfo);
		} else {
			setDefaultValues({
				...ContractInfoFormDefault,
				ref_contract_id,
				quotation_id,
			});
		}
	}, [loadOK, contractInfo, ref_contract_id, quotation_id]);

	useEffect(() => {
		reset(defaultValues);
	}, [reset, defaultValues]);

	return (
		<>
			<FormSheetDialog
				sheetTitle={`${defaultValues.id > 0 ? 'Chỉnh sửa' : 'Tạo mới'} ${title}`}
				isOpen={isOpen}
				form={form}
				onSubmit={onSubmit}
				isDisabled={isSaving}
				onOpenChange={onOpenChange}
				width='w-full'
				hasScroll={true}
				footerBtn={
					<>
						<ButtonCustom
							type='button'
							className='rounded-full'
							disabled={isLoading}
							isLoading={isLoading}
							icon={<FileDownIcon className='w-3.5 h-3.5' />}
							onClick={() => downloadWord(contract_id)}
							title='Xuất file word'
						/>
					</>
				}
			>
				<ContractDetailForm
					document_type={currentDocumentType}
					form={form}
					formData={defaultValues}
					isSaving={isLoading}
				/>
			</FormSheetDialog>
		</>
	);
};

export default ContractDetailFormSheet;
