import { Skeleton } from '@/components/base/ui/skeleton';
import React from 'react';

interface LoadingFormProps {
	blockCount?: number;
}

const LoadingForm = ({ blockCount = 3 }: LoadingFormProps) => {
	return (
		<>
			{blockCount > 0
				? Array.from({ length: blockCount }).map((_, i) => (
						<div
							key={i}
							className='flex w-full flex-col space-y-3 space-x-2 mb-2'
						>
							<div className='space-y-2 space-x-2'>
								<Skeleton className='h-4.5 w-48 mb-4' />
								<Skeleton className='h-4 max-w-[480px] mb-2.5' />
								<Skeleton className='h-2 mb-2.5' />
								<Skeleton className='h-4 max-w-[440px] mb-2.5' />
								<Skeleton className='h-2 max-w-[460px] mb-2.5' />
								<Skeleton className='h-2 max-w-[360px]' />
							</div>
						</div>
					))
				: null}
		</>
	);
};

export default LoadingForm;
