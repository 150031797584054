import { useMemo } from 'react';
import {
	useLocation,
	useMatches,
	useNavigate,
	useParams,
} from 'react-router-dom';

export const useBrowserHistory = () => {
	const matches = useMatches();
	const location = useLocation();
	const navigate = useNavigate();
	const params = useParams();
	const current = useMemo(() => {
		const curentMatch = matches.find(
			(match) => match.pathname === location.pathname
		);
		let baseName = location.pathname;
		for (const [key, value] of Object.entries(params)) {
			baseName = baseName.replace(value || '', `:${key}`);
		}
		let parentMatch;
		if (curentMatch) {
			const targetId = curentMatch.id;
			const similarityScores = matches
				.filter((x) => x.id !== targetId)
				.map((item) => {
					let similarity = 0;
					for (let i = 0; i < Math.min(item.id.length, targetId.length); i++) {
						if (item.id[i] === targetId[i]) {
							similarity++;
						} else {
							break;
						}
					}
					return { item, similarity };
				});
			similarityScores.sort((a, b) => b.similarity - a.similarity);
			parentMatch = similarityScores[0].item;
		}
		return {
			parent_path: parentMatch?.pathname || '/',
			current_path: curentMatch?.pathname || '/',
			base_name: baseName,
		};
	}, [matches, location, params]);
	return { ...current, location, navigate };
};
