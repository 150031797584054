import { Button } from '@/components/base/ui/button';
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from '@/components/base/ui/dropdown-menu';
import { ConfirmDialog } from '@/components/common';
import {
	ButtonAdd,
	ButtonCustom,
	ButtonDelete,
} from '@/components/common/button';
import { SheetDialog } from '@/components/common/sheet-dialog';
import DataTable from '@/components/data-table';
import {
	useContractQuotationDeleteMultipleMutation,
	useContractQuotationGetAllByContractQuery,
} from '@/contexts/api/contract-management/contract/contract-quotation-api';
import { eDocumentType } from '@/contexts/models/contract-management/contract';
import {
	ContractQuotation,
	ContractQuotationFormDefault,
	ContractQuotationFormModel,
} from '@/contexts/models/contract-management/contract/ContractQuotation';
import { contractQuotationActions } from '@/contexts/reducers/contract-management/contract/contract-quotation.slice';
import { AppState, useAppDispatch, useAppSelector } from '@/contexts/store';
import { ColumnDef } from '@tanstack/react-table';
import { formatDate } from 'date-fns';
import { EllipsisIcon, FileArchiveIcon } from 'lucide-react';
import { useCallback, useMemo, useState } from 'react';
import { ContractSalesListByQuotationSheet } from '../contract/contract-list-by-quotation-sheet';
import { QuotationStatusBadge } from './form/quotation-status-badge';
import { ContractQuotationDetailSheet } from './quotation-detail-sheet';

interface ContractQuotationProps {
	contract_id: number;
	contract_code: string;
	isOpen: boolean;
	onOpenChange: (isOpen: boolean) => void;
}

export const ContractQuotationListSheet = ({
	contract_id,
	contract_code,
	isOpen,
	onOpenChange,
}: ContractQuotationProps) => {
	const dispatch = useAppDispatch();
	const title = 'phiếu báo giá chào hàng';

	const { is_open_confirm, is_open_dialog, selected_ids, detail } =
		useAppSelector((state: AppState) => state.Contract.ContractQuotation);

	const [openSaleList, setOpenSaleList] = useState({
		isOpen: false,
		quotation_id: 0,
	});

	const { data, isFetching, isLoading, refetch } =
		useContractQuotationGetAllByContractQuery(contract_id);
	const [deletesAction] = useContractQuotationDeleteMultipleMutation();

	const addEditHandle = (
		isOpen: boolean,
		detail?: ContractQuotationFormModel
	) => {
		dispatch(
			contractQuotationActions.ContractQuotationShowDetail({
				is_open_dialog: isOpen,
				detail: detail,
			})
		);
	};

	const quotationDetail = useMemo(() => {
		if (detail) {
			return detail as ContractQuotationFormModel;
		}
		return {
			...ContractQuotationFormDefault,
			contract_id: contract_id,
			contract_code: contract_code,
		};
	}, [detail, contract_id, contract_code]);

	const deleteMultiHandle = (isOpen: boolean) => {
		dispatch(
			contractQuotationActions.ContractQuotationShowConfirm({
				is_open_confirm: isOpen,
			})
		);
	};

	const selectedIdsChange = (ids: number[]) => {
		dispatch(
			contractQuotationActions.ContractQuotationSelectedIdsChange({
				selected_ids: ids,
			})
		);
	};

	const deleteMultiConfirm = useCallback(async () => {
		if (selected_ids.length > 0) await deletesAction(selected_ids).unwrap();
	}, [deletesAction, selected_ids]);

	const columns: ColumnDef<ContractQuotation>[] = [
		{
			accessorKey: 'quotation_date',
			header: 'Ngày',
			cell: ({ row }) => {
				return (
					<div className='text-center'>
						<div className='text-xxs'>
							{formatDate(row.original.quotation_date, 'dd/MM/yyyy')}
						</div>
						<QuotationStatusBadge value={row.original.quotation_status_id} />
					</div>
				);
			},
		},
		{
			id: 'action_button',
			header: '...',
			cell: ({ row }) => {
				return (
					<div className='text-center'>
						<DropdownMenu>
							<DropdownMenuTrigger asChild>
								<Button size='icon' variant='outline' className='h-5 w-5'>
									<EllipsisIcon className='h-3.5 w-3.5' />
									<span className='sr-only'>More</span>
								</Button>
							</DropdownMenuTrigger>
							<DropdownMenuContent align='start'>
								<DropdownMenuItem>
									<ButtonCustom
										variant={'link'}
										icon={<FileArchiveIcon className='h-3.5 w-3.5' />}
										onClick={() => {
											addEditHandle(
												true,
												row.original as ContractQuotationFormModel
											);
										}}
										className='text-black h-5'
										title='Chỉnh sửa'
									/>
								</DropdownMenuItem>
								<DropdownMenuSeparator />
								<DropdownMenuItem>
									<ButtonCustom
										variant={'link'}
										className='text-black h-5'
										icon={<FileArchiveIcon className='h-3.5 w-3.5' />}
										onClick={() => {
											setOpenSaleList({
												isOpen: true,
												quotation_id: row.original.id,
											});
										}}
										title='HĐ bán hàng'
									/>
								</DropdownMenuItem>
							</DropdownMenuContent>
						</DropdownMenu>
					</div>
				);
			},
		},
		{
			id: 'customer_name',
			header: 'Khách hàng',
			cell: ({ row }) => {
				return (
					<div className='text-left text-xxs'>
						<div className='text-sm'>
							<span
								className='font-semibold text-primary hover:underline hover:text-primary/90 hover:cursor-pointer'
								onClick={() =>
									addEditHandle(
										true,
										row.original as ContractQuotationFormModel
									)
								}
							>
								{row.original.quotation_code || 'N/A'}
							</span>
							<div className='text-xxs'>
								Hàng hóa: <b className='text-xxs'>{row.original.goods_name}</b>{' '}
							</div>
							<div className='text-xxs'>
								Khách hàng: <b>{row.original.customer_name}</b>
							</div>
						</div>
					</div>
				);
			},
		},
		{
			id: 'goods_quantity',
			header: 'Số lượng (Kg)',
			enableSorting: false,
			cell: ({ row }) => {
				return (
					<div className='text-center text-xxs'>
						<div className='text-sm'>
							<code className='text-xs'>
								{row.original.goods_quantity.toLocaleString('vi')}
							</code>
						</div>
					</div>
				);
			},
		},
		{
			id: 'goods_price',
			header: 'Đơn giá (VNĐ)',
			enableSorting: false,
			cell: ({ row }) => {
				return (
					<div className='text-center text-xxs'>
						<div className='text-sm'>
							<code className='text-xs'>
								{row.original.goods_price.toLocaleString('vi')}
							</code>
						</div>
					</div>
				);
			},
		},
		{
			accessorKey: 'goods_sold',
			header: 'Dự kiến giao hàng',
			cell: ({ row }) => {
				return (
					<div className='text-center text-xxs'>
						<div className='text-xxs'>
							<div className='hidden text-muted-foreground md:block'>
								<b>
									{formatDate(
										row.original.quotation_delivery_date_start || '',
										'dd/MM/yyyy'
									)}{' '}
									-{' '}
									{formatDate(
										row.original.quotation_delivery_date_end || '',
										'dd/MM/yyyy'
									)}
								</b>
							</div>
							<div className='hidden text-muted-foreground md:block'>
								<b>{row.original.quotation_delivery_address}</b>
							</div>
						</div>
					</div>
				);
			},
		},
	];

	return (
		<>
			<SheetDialog
				sheetTitle={`Danh sách ${title} của hợp đồng ${contract_code}`}
				isOpen={isOpen}
				onOpenChange={onOpenChange}
				hasScroll={true}
				width='w-full'
			>
				<DataTable
					toolBars={
						<>
							<ButtonAdd onClick={() => addEditHandle(true)} />
							<ButtonDelete
								variant={'destructive'}
								title={`Xóa (${selected_ids.length})`}
								disabled={selected_ids.length <= 0}
								onClick={() => deleteMultiHandle(true)}
							/>
						</>
					}
					isLoading={isFetching || isLoading}
					data={data || []}
					columns={columns}
					keyExpr={'id'}
					searchKey={'customer_name'}
					onReload={refetch}
					selectionRows={{
						selectedRowsKey: selected_ids,
						onSelectedRowsKeyChange: (values) =>
							selectedIdsChange(values as number[]),
					}}
					isScrollable={true}
					height='h-[calc(100vh-16rem)]'
				/>
			</SheetDialog>
			{is_open_dialog && (
				<ContractQuotationDetailSheet
					contract_id={contract_id}
					contract_code={contract_code}
					title={title}
					isOpen={is_open_dialog}
					onOpenChange={() => addEditHandle(false)}
					detail={quotationDetail}
				/>
			)}
			{is_open_confirm && (
				<ConfirmDialog
					isOpen={is_open_confirm}
					onClose={() => deleteMultiHandle(false)}
					onConfirm={() => deleteMultiConfirm()}
					title='Thông báo'
					description={`Bạn có chắc chắn muốn xóa ${selected_ids.length} ${title} này không?`}
				/>
			)}
			{openSaleList.isOpen && (
				<ContractSalesListByQuotationSheet
					isOpen={openSaleList.isOpen}
					quotation_id={openSaleList.quotation_id}
					document_type={eDocumentType.HOP_DONG_BAN_HANG}
					title='hợp đồng bán hàng'
					onOpenChange={() =>
						setOpenSaleList({
							isOpen: false,
							quotation_id: 0,
						})
					}
				/>
			)}
		</>
	);
};
