import { cn } from '@/lib/utils';
import { HTMLInputTypeAttribute, useMemo, useReducer } from 'react';
import {
	FormControl,
	FormDescription,
	FormItem,
	FormLabel,
	FormMessage,
} from '../../base/ui/form';
import { Input } from '../../base/ui/input';

interface FormInputNumberProps
	extends React.InputHTMLAttributes<HTMLInputElement> {
	onValueChange: (value: number) => void;
	name: string;
	label?: string;
	type?: HTMLInputTypeAttribute | undefined;
	isDisabled?: boolean;
	placeholder?: string;
	isRequired?: boolean;
	button?: React.ReactNode;
	helpBlock?: string;
	classForm?: string;
	/* Định dạng số: Tham khảo tại đây https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat */
	numberFormatter?: Intl.NumberFormat;
	/* Đơn vị chia nhỏ số tiền: 1.000, 1.000.000,... */
	unitSlice?: 1 | 1000 | 1000000 | number;
}

const FormInputNumber = ({
	name,
	value,
	onValueChange,
	label,
	isDisabled,
	placeholder,
	isRequired,
	button,
	helpBlock,
	classForm,
	numberFormatter = Intl.NumberFormat('vi-VN', {
		minimumSignificantDigits: 1,
		maximumSignificantDigits: 10,
		minimumFractionDigits: 0,
		maximumFractionDigits: 0,
	}),
	...rest
}: FormInputNumberProps) => {
	const initialValue = value ? numberFormatter.format(Number(value)) : '0';

	// const [currentValue, setCurrentValue] = useReducer((_: any, next: string) => {
	// 	const digits = next.replace(/\D/g, '');
	// 	return numberFormatter.format(Number(digits));
	// }, initialValue);

	function handleChange(formattedValue: string) {
		const digits = formattedValue.replace(/\D/g, '');
		const realValue = Number(digits);
		onValueChange(realValue);
	}
	return (
		<>
			<FormItem className={cn('flex flex-col pb-2', classForm)}>
				{label && (
					<FormLabel htmlFor={name}>
						{label} {isRequired ? <code className='text-red-400'>*</code> : ''}
					</FormLabel>
				)}
				<FormControl>
					<div className='flex w-full'>
						<Input
							id={name}
							name={name}
							type={'text'}
							value={initialValue}
							onChange={(event) => {
								// setCurrentValue(event.target.value || '');
								handleChange(event.target.value || '0');
							}}
							placeholder={placeholder}
							disabled={isDisabled || false}
							{...rest}
						/>
						{button && <>{button}</>}
					</div>
				</FormControl>
				{helpBlock && (
					<FormDescription
						className='text-xs italic'
						dangerouslySetInnerHTML={{ __html: helpBlock }}
					></FormDescription>
				)}
				<FormMessage />
			</FormItem>
		</>
	);
};

export default FormInputNumber;
