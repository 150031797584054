import { FormField } from '@/components/base/ui/form';
import { Cardbox } from '@/components/common';
import { FormCombobox, FormInput } from '@/components/common/form';
import FromDateTimeRangePicker from '@/components/common/form/form-datetime-range-picker';
import FormInputNumber from '@/components/common/form/form-input-number';
import FormTextarea from '@/components/common/form/form-text-area';
import { ContractDispatchFormModel } from '@/contexts/models/contract-management/contract/ContractDispatchModel';
import { useCatalogGoods } from '@/hooks/catalog/use-catalog-goods';
import { useCatalogTransportationCompany } from '@/hooks/catalog/use-transportation-company';
import PackagingRequirementRadio from '@/modules/contract/form/packaging-requirements-radio';
import { formatDate } from 'date-fns';
import { UseFormReturn } from 'react-hook-form';

interface IContractDispatchDetailFormProps {
	isSaving: boolean;
	formData: ContractDispatchFormModel;
	form: UseFormReturn<ContractDispatchFormModel, any, undefined>;
}
export const ContractDispatchDetailForm = ({
	isSaving,
	formData,
	form,
}: IContractDispatchDetailFormProps) => {
	const { reset, control, setValue } = form;
	const { all_catalog_goods } = useCatalogGoods();
	const { all_transportation_companys } = useCatalogTransportationCompany();

	// const watchChangeGoodsAmount = useWatch({
	// 	control,
	// 	name: 'transportation_amount',
	// 	defaultValue: 0,
	// });
	// useEffect(() => {
	// 	const slug = convertNumberToWords(watchChangeGoodsAmount);
	// 	setValue('transportation_amount_words', slug);
	// }, [watchChangeGoodsAmount, setValue]);

	return (
		<>
			<Cardbox title='Thông tin'>
				<FormField
					control={form.control}
					name='goods_id'
					render={({ field }) => (
						<FormCombobox
							isRequired={true}
							name={field.name}
							dataSource={all_catalog_goods}
							defaultValue={0}
							value={field.value}
							onValueChange={(value) => field.onChange(parseInt(value || '0'))}
							onDataChange={(data) => {
								if (data) {
									setValue('goods_code', data.code);
									setValue('goods_name', data.name);
									setValue(
										'code',
										`${data.code}-${formatDate(new Date(), 'yyyyMMdd-HHmm')}`
									);
								}
							}}
							isDisabled={isSaving}
							label='Hàng hóa vận chuyển'
							placeholder='Chọn hàng hóa...'
							displayExpr='name'
							valueExpr='id'
						/>
					)}
				/>
				<FormField
					control={form.control}
					name='goods_code'
					render={({ field }) => (
						<FormInput
							isRequired={true}
							readOnly={true}
							name={field.name}
							value={field.value}
							onValueChange={field.onChange}
							isDisabled={isSaving}
							label='Mã hàng hóa'
						/>
					)}
				/>
				<FormField
					control={form.control}
					name='goods_name'
					render={({ field }) => (
						<FormInput
							isRequired={true}
							readOnly={true}
							name={field.name}
							value={field.value}
							onValueChange={field.onChange}
							isDisabled={isSaving}
							label='Tên hàng hóa'
						/>
					)}
				/>
				<FormField
					control={form.control}
					name={'goods_packaging_requirements'}
					render={({ field }) => (
						<PackagingRequirementRadio
							isRequired={true}
							name={field.name}
							value={field.value}
							defaultValue={'Hàng rời trên xe'}
							onValueChange={field.onChange}
							isDisabled={isSaving}
							label='Hình thức đóng gói'
						/>
					)}
				/>
				<FormField
					control={form.control}
					name='code'
					render={({ field }) => (
						<FormInput
							isRequired={true}
							name={field.name}
							value={field.value}
							onValueChange={field.onChange}
							isDisabled={isSaving}
							label='Mã đợt giao hàng'
						/>
					)}
				/>
				<FormField
					control={form.control}
					name={'begin_date'}
					render={({ field }) => (
						<FromDateTimeRangePicker
							isRequired={true}
							name_from={field.name}
							name_to={'end_date'}
							value_from={field.value}
							value_to={form.getValues('end_date')}
							onValueFromChange={field.onChange}
							onValueToChange={(value) => {
								form.setValue('end_date', value || '');
							}}
							isDisabled={isSaving}
							label='Thời gian giao hàng'
						/>
					)}
				/>

				<FormField
					control={form.control}
					name='address'
					render={({ field }) => (
						<FormTextarea
							isRequired={true}
							name={field.name}
							value={field.value}
							onValueChange={field.onChange}
							isDisabled={isSaving}
							label='Địa chỉ nhận hàng'
						/>
					)}
				/>
				<FormField
					control={form.control}
					name={'goods_quantity'}
					render={({ field }) => (
						<FormInputNumber
							isRequired={true}
							name={field.name}
							value={field.value}
							onValueChange={(value) => {
								field.onChange(value);
							}}
							isDisabled={isSaving}
							classForm='col-span-2'
							label='Trọng lượng hàng hóa (KG)'
						/>
					)}
				/>
			</Cardbox>
		</>
	);
};
