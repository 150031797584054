import { FormField } from '@/components/base/ui/form';
import { Cardbox } from '@/components/common';
import { FormInput } from '@/components/common/form';
import FromDateRangePicker from '@/components/common/form/form-date-range-picker';
import FromDateTimeRangePicker from '@/components/common/form/form-datetime-range-picker';
import FormInputNumber from '@/components/common/form/form-input-number';
import { FormSheetDialog } from '@/components/common/form/form-sheet-dialog';
import FormTextarea from '@/components/common/form/form-text-area';
import { useContractDeliverySaveMutation } from '@/contexts/api/contract-management/contract/contract-delivery-api';
import {
	ContractDeliveryFormModel,
	ContractDeliveryFormValid,
} from '@/contexts/models/contract-management/contract/ContractDeliveryModel';

import { zodResolver } from '@hookform/resolvers/zod';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

interface IContractDeliveryFormProps {
	detail: ContractDeliveryFormModel;
	isOpen: boolean;
	contract_id: number;
	onOpenChange: (isOpen: boolean) => void;
	onSubmitted: (data: ContractDeliveryFormModel) => void;
}
export const ContractDeliveryForm = ({
	detail,
	contract_id,
	isOpen,
	onOpenChange,
	onSubmitted,
}: IContractDeliveryFormProps) => {
	const [defaultValues, setDefaultValues] =
		useState<ContractDeliveryFormModel>(detail);
	const form = useForm<ContractDeliveryFormModel>({
		resolver: zodResolver(ContractDeliveryFormValid),
		defaultValues,
	});

	const [saveDeliveryAction, { isLoading: isSaving, isSuccess: isSaved }] =
		useContractDeliverySaveMutation();

	const {
		reset,
		control,
		setValue,
		formState: { isSubmitting },
	} = form;

	const isLoading = isSaving || isSubmitting;

	const onSubmit = useCallback(
		async (formState: ContractDeliveryFormModel) => {
			onOpenChange(false);
			if (contract_id > 0) {
				await saveDeliveryAction({
					...formState,
					contract_id: contract_id,
				}).unwrap();
			}
			onSubmitted(formState);
		},
		[onOpenChange, onSubmitted, saveDeliveryAction, contract_id]
	);

	useEffect(() => {
		if (defaultValues) {
			reset(defaultValues);
		}
	}, [defaultValues, reset]);

	useEffect(() => {
		if (detail) {
			setDefaultValues(detail);
		}
	}, [detail]);

	return (
		<>
			<FormSheetDialog
				sheetTitle={`${detail.id > 0 ? 'Chỉnh sửa' : 'Tạo mới'} Thông tin giao/nhận hàng`}
				isOpen={isOpen}
				onOpenChange={onOpenChange}
				form={form}
				onSubmit={onSubmit}
				isDisabled={isLoading}
				hasScroll={true}
				width='w-1/3'
			>
				<Cardbox title='Thông tin'>
					<FormField
						control={form.control}
						name='begin_date'
						render={({ field }) => (
							<FromDateTimeRangePicker
								isRequired={true}
								name_from={'from_date'}
								name_to={'to_date'}
								value_from={field.value}
								isDisabled={isLoading}
								value_to={form.getValues('end_date')}
								onValueFromChange={(value) => {
									field.onChange(value);
								}}
								onValueToChange={(value) => {
									form.setValue('end_date', value || '');
								}}
								label='Ngày giao/nhận hàng'
							/>
						)}
					/>
					<FormField
						control={form.control}
						name={'title'}
						render={({ field }) => (
							<FormInput
								isRequired={true}
								name={field.name}
								value={field.value}
								isDisabled={isLoading}
								onValueChange={field.onChange}
								label='Tiêu đề'
							/>
						)}
					/>
					<FormField
						control={form.control}
						name='address'
						render={({ field }) => (
							<FormTextarea
								isRequired={true}
								name={field.name}
								value={field.value}
								isDisabled={isLoading}
								onValueChange={field.onChange}
								label='Địa chỉ giao/nhận hàng'
								classForm='md:col-span-2'
							/>
						)}
					/>
					<div className='sm:grid lg:grid-cols-2 lg:space-x-2'>
						<FormField
							control={form.control}
							name={'quantity'}
							render={({ field }) => (
								<FormInputNumber
									isRequired={true}
									name={field.name}
									value={field.value}
									isDisabled={isLoading}
									onValueChange={field.onChange}
									label='Số lượng dự kiến (KG)'
								/>
							)}
						/>
						<FormField
							control={form.control}
							name={'price'}
							render={({ field }) => (
								<FormInputNumber
									isRequired={true}
									name={field.name}
									value={field.value}
									isDisabled={isLoading}
									onValueChange={field.onChange}
									label='Đơn giá (VNĐ)'
								/>
							)}
						/>
					</div>
				</Cardbox>
			</FormSheetDialog>
		</>
	);
};
