import { Button } from '@/components/base/ui/button';
import { Input } from '@/components/base/ui/input';
import { Cross2Icon, ReloadIcon } from '@radix-ui/react-icons';
import { Table } from '@tanstack/react-table';
import { Search } from 'lucide-react';
import React from 'react';
import { DataTableViewOptions } from '..';
import { DataTableFacetedFilter } from './table-faceted-filter';
import { createUUID } from '@/lib/create-uuid';
import { DataTableFilterOptions } from '../data-table-types';
import { on } from 'events';

export interface DataTableToolbarProps<TData> {
	table: Table<TData>;
	searchValue?: string;
	onSearchChange?: (value: string) => void;
	searchKey?: keyof TData | Array<keyof TData>;
	onReload?: () => void;
	isViewOptions?: boolean;
	filterOptions?: DataTableFilterOptions<TData>[];
	toolBars?: React.ReactNode;
}

export function DataTableToolbar<TData>({
	table,
	searchKey,
	searchValue,
	onSearchChange,
	onReload,
	filterOptions,
	isViewOptions,
	toolBars,
}: DataTableToolbarProps<TData>) {
	// const isFiltered = table.getState().columnFilters.length > 0;
	const isFiltered =
		(searchValue && searchValue.length > 0) ||
		table.getState().columnFilters.length > 0 ||
		table.getState().columnOrder.length > 0;
	return (
		<div className='flex items-center justify-between'>
			<div className='flex flex-1 items-center space-x-2'>
				{toolBars}
				{searchKey && (
					<>
						<div className='relative'>
							<Search className='absolute left-2 top-2 h-3.5 w-3.5 text-muted-foreground' />
							<Input
								type='text'
								placeholder='Tìm kiếm...'
								// value={
								// 	(table
								// 		.getColumn(searchKey.toString())
								// 		?.getFilterValue() as string) ?? ''
								// }
								value={searchValue}
								// onChange={(event) =>
								// 	table
								// 		.getColumn(searchKey.toString())
								// 		?.setFilterValue(event.target.value || '')
								// }
								onChange={(event) => onSearchChange?.(event.target.value || '')}
								className='pl-8 h-8 w-[150px] lg:w-[250px]'
							/>
						</div>
					</>
				)}
				{filterOptions &&
					filterOptions.map((option) => {
						return (
							<React.Fragment key={createUUID()}>
								{option.columnKey &&
									table.getColumn(option.columnKey as string) && (
										<DataTableFacetedFilter
											column={table.getColumn(option.columnKey as string)}
											title={option.title}
											options={option.options}
										/>
									)}
							</React.Fragment>
						);
					})}
				{isFiltered && (
					<Button
						variant='destructive'
						onClick={() => {
							table.resetColumnFilters();
							onSearchChange?.('');
						}}
						className='w-7 h-7 px-2'
					>
						<Cross2Icon className='h-3.5 w-3.5' />
					</Button>
				)}
			</div>
			{onReload && (
				<Button
					variant='outline'
					size='sm'
					className='p-1 hidden w-7 h-7 lg:flex mr-1'
					onClick={onReload}
				>
					<ReloadIcon className='h-3.5 w-3.5' />
				</Button>
			)}
			{isViewOptions && <DataTableViewOptions table={table} />}
		</div>
	);
}
