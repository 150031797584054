import { Toaster } from '@/components/base/ui/sonner';
import { useOutlet } from 'react-router-dom';

const LoginLayout = () => {
	const outlet = useOutlet();
	return (
		<>
			<div className='flex w-full relative h-screen'>
				<main className='relative z-10 flex min-h-full min-w-0 flex-auto flex-col'>
					<div className='relative z-10 flex min-h-0 flex-auto flex-col'>
						<div className='flex min-w-0 flex-1 flex-col items-center sm:flex-row sm:justify-center md:items-start md:justify-start'>
							<div className='h-full w-full px-16 py-8 ltr:border-r-1 rtl:border-l-1 sm:h-auto sm:w-auto sm:rounded-2xl sm:p-18 sm:shadow flex md:h-full md:w-1/2 items-center justify-center md:rounded-none md:p-24 md:shadow-none'>
								{outlet}
							</div>
							<div className='relative hidden h-full flex-auto items-center justify-center overflow-hidden p-24 md:flex lg:px-112 bg-zinc-900'>
								<svg
									className='pointer-events-none absolute inset-0'
									viewBox='0 0 960 540'
									width='100%'
									height='100%'
									preserveAspectRatio='xMidYMax slice'
									xmlns='http://www.w3.org/2000/svg'
								>
									<g
										className='opacity-20 MuiBox-root muiltr-1wnrr1p'
										fill='none'
										stroke='currentColor'
										strokeWidth='100'
									>
										<circle r='234' cx='196' cy='23'></circle>
										<circle r='234' cx='790' cy='491'></circle>
									</g>
								</svg>
								<svg
									className='absolute -right-64 -top-64 opacity-20 MuiBox-root muiltr-x3mbcd'
									viewBox='0 0 220 192'
									fill='none'
								>
									<defs>
										<pattern
											x='0'
											y='0'
											width='20'
											height='20'
											patternUnits='userSpaceOnUse'
										>
											<rect
												x='0'
												y='0'
												width='4'
												height='4'
												fill='currentColor'
											></rect>
										</pattern>
									</defs>
									<rect
										width='220'
										height='192'
										fill='url(#837c3e70-6c3a-44e6-8854-cc48c737b659)'
									></rect>
								</svg>
								<div className='relative z-10 w-full max-w-2xl'>
									<div className='text-5xl font-bold leading-none text-gray-100'>
										<div>Welcome to</div>
										<div>Quang Trung CMS</div>
									</div>
									<div className='mt-20 text-lg leading-6 tracking-tight text-gray-400'>
										Fuse helps developers to build organized and well coded
										dashboards full of beautiful and rich modules. Join us and
										start building your application today.
									</div>
								</div>
							</div>
						</div>
					</div>
				</main>
			</div>
		</>
	);
};

export default LoginLayout;
