import { createSlice } from '@reduxjs/toolkit';
import { Supplier } from '@/contexts/models/contract-management/catalog';
import { SupplierApi } from '@/contexts/api/contract-management/catalog/supplier-api';

type SupplierState = {
	list: Supplier[];
	detail?: Supplier;
	is_open_dialog: boolean;
	selected_ids: number[];
	is_open_confirm: boolean;
};

const initialState = {
	list: [],
	detail: undefined,
	is_open_confirm: false,
	is_open_dialog: false,
	selected_ids: [],
} as SupplierState;

const entityEndpointsApi = SupplierApi.endpoints;

const SupplierSlice = createSlice({
	name: 'SupplierSlice',
	initialState: initialState,
	reducers: {
		SupplierShowDetail: (state, { payload }) => {
			state.is_open_dialog = payload.is_open_dialog;
			state.detail = payload.detail;
		},
		SupplierShowConfirm: (state, { payload }) => {
			state.is_open_confirm = payload;
		},
		SupplierSelectedIdsChange: (state, { payload }) => {
			state.selected_ids = payload;
		},
	},
	extraReducers: (builder) => {
		builder.addMatcher(
			entityEndpointsApi.supplierGetAll.matchFulfilled,
			(state, { payload }) => {
				state.list = payload || [];
			}
		);
		builder.addMatcher(
			entityEndpointsApi.supplierSave.matchFulfilled,
			(state) => {
				state.is_open_dialog = false;
				state.detail = undefined;
			}
		);
		builder.addMatcher(
			entityEndpointsApi.supplierDelete.matchFulfilled,
			(state) => {
				state.is_open_confirm = false;
				state.selected_ids = [];
			}
		);
		builder.addMatcher(
			entityEndpointsApi.supplierDeleteMultiple.matchFulfilled,
			(state) => {
				state.is_open_confirm = false;
				state.selected_ids = [];
			}
		);
	},
});

export const supplierActions = SupplierSlice.actions;
export default SupplierSlice.reducer;
