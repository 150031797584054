import { zod, zod_valid } from '@/lib/zod-valid';
import { formatDate } from 'date-fns';

export type ContractDispatch = {
	id: number;
	dispatch_date: string;
	code: string;
	title: string;
	contract_id: number;
	document_type: string;
	goods_id: number;
	goods_code: string;
	goods_name: string;
	goods_quantity: number;
	goods_packaging_requirements: string;
	dispatch_status_id: string;
	begin_date: string;
	end_date: string;
	address: string;
	is_default: boolean;
	shipment_count: number;
	goods_quantity_total_start: number;
	goods_quantity_total_end: number;
};

export const ContractDispatchValid = zod.object({
	id: zod_valid.number(),
	dispatch_date: zod_valid.string(),
	code: zod_valid.string(),
	title: zod_valid.string(),
	contract_id: zod_valid.number(),
	document_type: zod_valid.string(),
	goods_id: zod_valid.number(),
	goods_code: zod_valid.string(),
	goods_name: zod_valid.string(),
	goods_quantity: zod_valid.number(),
	goods_packaging_requirements: zod_valid.string(),
	dispatch_status_id: zod_valid.string(),
	begin_date: zod_valid.string(),
	end_date: zod_valid.string(),
	address: zod_valid.string(),
});

export type ContractDispatchFormModel = zod.infer<typeof ContractDispatchValid>;

export const ContractDispatchFormDefault: ContractDispatchFormModel = {
	id: 0,
	code: '',
	title: '',
	document_type: '',
	dispatch_date: formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss'),
	contract_id: 0,
	goods_id: 0,
	goods_code: '',
	goods_name: '',
	goods_quantity: 0,
	goods_packaging_requirements: '',
	dispatch_status_id: '',
	begin_date: formatDate(new Date(), 'yyyy-MM-dd'),
	end_date: formatDate(new Date(), 'yyyy-MM-dd'),
	address: '',
};
