import {
	Goods,
	GoodsFormModel,
} from '@/contexts/models/contract-management/catalog/Goods';
import { NotifyHelper } from '@/utils/notify-helper';
import { apiSlice } from '../../api-slice';
import { BaseResponse } from '@/contexts/base/base-response';

const enpointApi = '/catalog/goods';
const entityName = 'Hàng hóa cung cấp';
const entityCacheTag = 'CATALOG_GOODS';

export const GoodsApi = apiSlice
	.enhanceEndpoints({ addTagTypes: [entityCacheTag] })
	.injectEndpoints({
		endpoints: (builder) => ({
			goodsGetAll: builder.query<Goods[], void>({
				query: () => ({ url: enpointApi, method: 'GET' }),
				transformResponse: (response) => response.result as Goods[],
				providesTags: (result) =>
					result
						? [
								...result.map(({ id }) => ({
									type: `${entityCacheTag}` as const,
									id,
								})),
								{ type: entityCacheTag, id: 'LIST' },
							]
						: [{ type: entityCacheTag, id: 'LIST' }],
			}),
			goodsGetDetail: builder.query({
				query: (id: number) => ({ url: `${enpointApi}/${id}`, method: 'GET' }),
				transformResponse: (response) => response.result as Goods,
				providesTags: (result, error, id) => [{ type: entityCacheTag, id }],
			}),
			goodsSave: builder.mutation({
				query: (entity: GoodsFormModel) => ({
					url: enpointApi,
					method: entity.id > 0 ? 'PUT' : 'POST',
					data: entity,
				}),
				transformResponse: (response) => response,
				async onQueryStarted(_args, { queryFulfilled }) {
					try {
						const { data } = await queryFulfilled;
						if (data.is_success)
							NotifyHelper.Success(
								data.message || `Lưu ${entityName} thành công`
							);
						else
							NotifyHelper.Error(
								data.message || `Lưu ${entityName} không thành công`
							);
					} catch (ex: any) {
						NotifyHelper.Error(ex?.error?.data || `Lưu ${entityName} có lỗi`);
					}
				},
				invalidatesTags: (result, error, arg) => [
					{ type: entityCacheTag, id: arg.id },
					{ type: entityCacheTag, id: 'LIST' },
				],
			}),
			goodsDelete: builder.mutation({
				query: (id: number) => ({
					url: `${enpointApi}/${id}`,
					method: 'DELETE',
				}),
				transformResponse: (response) => response,
				async onQueryStarted(_args, { queryFulfilled }) {
					try {
						const { data } = await queryFulfilled;
						if (data.is_success)
							NotifyHelper.Success(
								data.message || `Xóa ${entityName} thành công`
							);
						else
							NotifyHelper.Error(data.message || `Xóa ${entityName} thất bại`);
					} catch (ex: any) {
						NotifyHelper.Error(ex?.error?.data || `Xóa ${entityName} có lỗi`);
					}
				},
				invalidatesTags: [{ type: entityCacheTag, id: 'LIST' }],
			}),
			goodsDeleteMultiple: builder.mutation({
				query: (ids: number[]) => ({
					url: `${enpointApi}/deletes`,
					method: 'DELETE',
					data: { ids },
				}),
				transformResponse: (response) => response as BaseResponse,
				async onQueryStarted(_args, { queryFulfilled }) {
					try {
						const { data } = await queryFulfilled;
						if (data.is_success)
							NotifyHelper.Success(
								data.message || `Xóa ${entityName} thành công`
							);
						else
							NotifyHelper.Error(data.message || `Xóa ${entityName} thất bại`);
					} catch (ex: any) {
						NotifyHelper.Error(ex?.error?.data || `Xóa ${entityName} có lỗi`);
					}
				},
				invalidatesTags: [{ type: entityCacheTag, id: 'LIST' }],
			}),
		}),
	});

export const {
	useGoodsDeleteMultipleMutation,
	useGoodsDeleteMutation,
	useGoodsGetAllQuery,
	useGoodsSaveMutation,
	useLazyGoodsGetAllQuery,
	useGoodsGetDetailQuery,
	useLazyGoodsGetDetailQuery,
} = GoodsApi;
