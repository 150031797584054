import { zod, zod_valid } from '@/lib/zod-valid';
import { formatDate } from 'date-fns';

export type ContractDelivery = {
	id: number;
	contract_id: number;
	title: string;
	transport_status_id: string;
	quantity_confirm: number;
	begin_date: string;
	end_date: string;
	address: string;
	price: number;
	quantity: number;
	sort_order: number;
};

export const ContractDeliveryFormValid = zod
	.object({
		id: zod_valid.number(),
		contract_id: zod_valid.number(),
		title: zod_valid.string().optional(),
		address: zod_valid.string_required('Vui lòng nhập địa chỉ vận chuyển'),
		begin_date: zod_valid.string_required('Vui lòng nhập ngày bắt đầu'),
		end_date: zod_valid.string_required('Vui lòng nhập ngày kết thúc'),
		price: zod_valid.number(),
		quantity: zod_valid.number(),
		quantity_confirm: zod_valid.number(),
		sort_order: zod_valid.number()
	})
	.required();

export type ContractDeliveryFormModel = zod.infer<
	typeof ContractDeliveryFormValid
>;

export const ContractDeliveryFormDefault: ContractDeliveryFormModel = {
	id: 0,
	title: '',
	contract_id: 0,
	address: '',
	begin_date: formatDate(new Date(), 'yyyy-MM-dd'),
	end_date: formatDate(new Date(), 'yyyy-MM-dd'),
	price: 0,
	quantity: 0,
	quantity_confirm: 0,
	sort_order: 0
};
