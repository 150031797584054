import { ButtonClose, ButtonSave } from '@/components/common';
import { useContractInfoChangeStatusMutation } from '@/contexts/api/contract-management/contract/contract-info-api';
import { Button } from '@/ui/button';
import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from '@/ui/dialog';
import React, { useCallback } from 'react';
import ContractStatusCombobox from './contract-status-combobox';

interface IContractChangeStatusDialogProps {
	is_open: boolean;
	onOpenChange: (isOpen: boolean) => void;
	contract_ids: number[];
}

export function ContractChangeStatusDialog({
	is_open,
	onOpenChange,
	contract_ids,
}: IContractChangeStatusDialogProps) {
	const [status, setStatus] = React.useState<string>('');
	const [changeStatusAction] = useContractInfoChangeStatusMutation();
	const changeStatusConfirm = useCallback(async () => {
		if (contract_ids.length > 0 && status.length > 0) {
			await changeStatusAction({
				ids: contract_ids,
				contract_status_id: status,
			}).unwrap();
		}
	}, [changeStatusAction, contract_ids, status]);
	return (
		<Dialog open={is_open} onOpenChange={onOpenChange}>
			<DialogContent className='sm:max-w-md'>
				<DialogHeader>
					<DialogTitle>Đổi trạng thái hợp đồng</DialogTitle>
				</DialogHeader>
				<div className='w-full'>
					<ContractStatusCombobox
						value={status}
						onValueChange={(value) => setStatus(value)}
						name='contract_change_status'
					/>
				</div>

				<DialogFooter className='sm:justify-start'>
					<DialogClose asChild>
						<ButtonClose />
					</DialogClose>
					<ButtonSave
						title='Đổi trạng thái'
						disabled={status.length === 0}
						onClick={() => changeStatusConfirm()}
					/>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
}
