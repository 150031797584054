import {
	Card,
	CardContent,
	CardDescription,
	CardHeader,
	CardTitle,
} from '@/components/base/ui/card';
import { FormField } from '@/components/base/ui/form';
import { FormCombobox, FormInput } from '@/components/common/form';

import FromDateTimeRangePicker from '@/components/common/form/form-datetime-range-picker';
import FormInputNumber from '@/components/common/form/form-input-number';
import FormTextarea from '@/components/common/form/form-text-area';
import { Customer } from '@/contexts/models/contract-management/catalog';
import { ContractQuotationFormModel } from '@/contexts/models/contract-management/contract/ContractQuotation';
import { useCatalogCustomer } from '@/hooks/catalog/use-catalog-customer';
import { cn } from '@/lib/utils';
import { useCallback } from 'react';
import { UseFormReturn } from 'react-hook-form';
import BankInfoCard from './bank-info-card';

interface CustomerInfoCardProps {
	form: UseFormReturn<ContractQuotationFormModel, any, undefined>;
	isLoading: boolean;
}

const initCustomerInfoData: Customer = {
	id: 0,
	address: '',
	bank_info: '',
	code: '',
	is_active: true,
	name: '',
	phone_number: '',
	representative: '',
	representative_title: '',
	tin: '',
};

export const CustomerInfoCard = ({
	form,
	isLoading,
}: CustomerInfoCardProps) => {
	const { setValue } = form;
	const { all_catalog_customers } = useCatalogCustomer();

	const changeDataValue = useCallback(
		(data?: Customer) => {
			const selectedData = data || initCustomerInfoData;
			setValue('customer_name', selectedData.name);
			setValue('customer_address', selectedData.address);
			setValue('customer_phone_number', selectedData.phone_number);
			setValue('customer_tax_code', selectedData.tin);
			setValue('customer_representative_name', selectedData.representative);
			setValue(
				'customer_representative_position',
				selectedData.representative_title
			);
			setValue('customer_bank_account', selectedData.bank_info);
		},
		[setValue]
	);

	return (
		<>
			<Card className='mb-2'>
				<CardHeader
					className={cn('px-4 py-2', 'flex flex-row items-start bg-muted/50')}
				>
					<div className='grid gap-0.5'>
						<CardTitle className='group flex items-center gap-2 text-md'>
							Thông tin khách hàng
						</CardTitle>
						<CardDescription className='italic'>
							Thông tin bên sử dụng dịch vụ hoặc mua hàng hóa
						</CardDescription>
					</div>
					<div className='ml-auto flex items-center gap-1'>
						<FormField
							control={form.control}
							name='customer_id'
							render={({ field }) => (
								<FormCombobox
									isRequired={true}
									name={field.name}
									dataSource={all_catalog_customers}
									defaultValue={0}
									value={field.value}
									onValueChange={(value) =>
										field.onChange(parseInt(value || '0'))
									}
									onDataChange={(data) => changeDataValue(data)}
									isDisabled={isLoading}
									// label='Chọn hàng hóa'
									placeholder='Chọn thông tin...'
									displayExpr='name'
									valueExpr='id'
								/>
							)}
						/>
					</div>
				</CardHeader>
				<CardContent className={cn('gap-2 pt-2')}>
					<FormField
						control={form.control}
						name={'customer_name'}
						render={({ field }) => (
							<FormInput
								isRequired={true}
								name={field.name}
								value={field.value}
								onValueChange={field.onChange}
								isDisabled={isLoading}
								label='Tên công ty'
								classForm='col-span-3'
							/>
						)}
					/>
					<FormField
						control={form.control}
						name={'customer_address'}
						render={({ field }) => (
							<FormInput
								isRequired={true}
								name={field.name}
								value={field.value}
								onValueChange={field.onChange}
								isDisabled={isLoading}
								label='Địa chỉ công ty'
								classForm='col-span-3'
							/>
						)}
					/>
					<div className='sm:grid lg:grid-cols-2 space-x-2'>
						<FormField
							control={form.control}
							name={'customer_phone_number'}
							render={({ field }) => (
								<FormInput
									isRequired={true}
									name={field.name}
									value={field.value}
									onValueChange={field.onChange}
									isDisabled={isLoading}
									label='Số điện thoại'
								/>
							)}
						/>
						<FormField
							control={form.control}
							name={'customer_tax_code'}
							render={({ field }) => (
								<FormInput
									isRequired={true}
									name={field.name}
									value={field.value}
									onValueChange={field.onChange}
									isDisabled={isLoading}
									label='Mã số thuế'
								/>
							)}
						/>
					</div>
					<div className='sm:grid lg:grid-cols-2 space-x-2'>
						<FormField
							control={form.control}
							name={'customer_representative_name'}
							render={({ field }) => (
								<FormInput
									isRequired={true}
									name={field.name}
									value={field.value}
									onValueChange={field.onChange}
									isDisabled={isLoading}
									label='Người đại diện'
								/>
							)}
						/>
						<FormField
							control={form.control}
							name={'customer_representative_position'}
							render={({ field }) => (
								<FormInput
									isRequired={true}
									name={field.name}
									value={field.value}
									onValueChange={field.onChange}
									isDisabled={isLoading}
									label='Chức vụ'
								/>
							)}
						/>
					</div>
					<FormField
						control={form.control}
						name={'customer_bank_account'}
						render={({ field }) => (
							<BankInfoCard
								array_string={field.value}
								onValueChange={field.onChange}
							/>
						)}
					/>
				</CardContent>
				<CardHeader
					className={cn('px-4 py-2', 'flex flex-row items-start bg-muted/50')}
				>
					<div className='grid gap-0.5'>
						<CardTitle className='group flex items-center gap-2 text-md'>
							Thông tin báo giá/chào hàng
						</CardTitle>
						<CardDescription className='italic'>
							Thông tin báo giá/chào hàng sẽ được sử dụng trong hợp đồng
						</CardDescription>
					</div>
				</CardHeader>
				<CardContent className={cn('gap-2 pt-2')}>
					<div className='sm:grid lg:grid-cols-2 lg:space-x-2'>
						<FormField
							control={form.control}
							name={'goods_quantity'}
							render={({ field }) => (
								<FormInputNumber
									isRequired={true}
									readOnly={true}
									name={field.name}
									value={field.value}
									onValueChange={(value) => {
										field.onChange(value);
									}}
									isDisabled={isLoading}
									label='Số lượng mua vào (Kg)'
								/>
							)}
						/>
						<FormField
							control={form.control}
							name={'goods_price'}
							render={({ field }) => (
								<FormInputNumber
									isRequired={true}
									readOnly={true}
									name={field.name}
									value={field.value}
									onValueChange={(value) => {
										field.onChange(value);
									}}
									isDisabled={isLoading}
									label='Đơn giá mua vào (VNĐ)'
								/>
							)}
						/>
					</div>
					<div className='sm:grid lg:grid-cols-2 lg:space-x-2'>
						<FormField
							control={form.control}
							name={'quotation_quantity'}
							render={({ field }) => (
								<FormInputNumber
									isRequired={true}
									name={field.name}
									value={field.value}
									onValueChange={(value) => {
										form.setValue(
											'quotation_amount',
											value * form.getValues('quotation_price')
										);
										field.onChange(value);
									}}
									isDisabled={isLoading}
									label='Số lượng bán ra (Kg)'
								/>
							)}
						/>
						<FormField
							control={form.control}
							name={'quotation_price'}
							render={({ field }) => (
								<FormInputNumber
									isRequired={true}
									name={field.name}
									value={field.value}
									onValueChange={(value) => {
										form.setValue(
											'quotation_amount',
											value * form.getValues('quotation_quantity')
										);
										field.onChange(value);
									}}
									isDisabled={isLoading}
									label='Đơn giá bán ra (VNĐ)'
								/>
							)}
						/>
					</div>
					<FormField
						control={form.control}
						name={'quotation_amount'}
						render={({ field }) => (
							<FormInputNumber
								isRequired={true}
								name={field.name}
								value={field.value}
								onValueChange={(value) => {
									field.onChange(value);
								}}
								isDisabled={isLoading}
								label='Thành tiền (VNĐ)'
							/>
						)}
					/>
					<FormField
						control={form.control}
						name={'quotation_amount_words'}
						render={({ field }) => (
							<FormTextarea
								isRequired={true}
								readOnly={true}
								name={field.name}
								value={field.value}
								onValueChange={field.onChange}
								isDisabled={isLoading}
								label='Thành tiền bằng chữ'
							/>
						)}
					/>
					<div className='sm:grid lg:grid-cols-2 lg:space-x-2'>
						<FormField
							control={form.control}
							name={'quotation_delivery_date_start'}
							render={({ field }) => (
								<FromDateTimeRangePicker
									isRequired={true}
									name_from={field.name}
									name_to={'quotation_delivery_date_end'}
									value_from={field.value}
									value_to={form.getValues('quotation_delivery_date_end')}
									onValueFromChange={field.onChange}
									onValueToChange={(value) => {
										form.setValue('quotation_delivery_date_end', value || '');
									}}
									isDisabled={isLoading}
									label='Ngày dự kiến giao hàng'
								/>
							)}
						/>
					</div>
					<FormField
						control={form.control}
						name={'quotation_delivery_address'}
						render={({ field }) => (
							<FormTextarea
								isRequired={true}
								name={field.name}
								value={field.value}
								onValueChange={field.onChange}
								isDisabled={isLoading}
								label='Địa chỉ giao/nhận hàng'
							/>
						)}
					/>
				</CardContent>
			</Card>
		</>
	);
};
