import { cn } from '@/lib/utils';
import { slugHelper } from '@/utils/slug-helper';
import { CaretSortIcon } from '@radix-ui/react-icons';
import { CheckIcon, PlusCircleIcon, XIcon } from 'lucide-react';
import React, { useCallback, useMemo } from 'react';
import { Button } from '../base/ui/button';
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
} from '../base/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from '../base/ui/popover';
import { createUUID } from '@/lib/create-uuid';

interface ComboboxProps<TData> {
	name: string;
	value?: any;
	defaultValue?: any;
	valueExpr: keyof TData;
	displayExpr: keyof TData;
	dataSource: TData[];
	onValueChange?: (value?: any) => void;
	onDataChange?: (value?: TData) => void;
	onFilterChange?: (value?: string) => void;
	isDisabled?: boolean;
	placeholder?: string;
	textSize?: 'xxs' | 'xs' | 'sm' | 'base' | 'lg';
	itemRender?: (data: TData) => React.ReactNode;
	isClearable?: boolean;
	addAction?: {
		hasAddAction: boolean;
		onAddItem: () => void;
	};
	width?: string;
}

const Combobox = <TData,>({
	name,
	value,
	defaultValue,
	dataSource,
	valueExpr,
	displayExpr,
	isDisabled,
	onValueChange,
	onDataChange,
	onFilterChange,
	addAction,
	textSize = 'xxs',
	placeholder = 'Chọn nội dung',
	isClearable = false,
	itemRender,
	width,
}: ComboboxProps<TData>) => {
	const [open, setOpen] = React.useState(false);
	const [filterString, setFilterString] = React.useState('');
	const selectedItem = useMemo(() => {
		return dataSource.find((data) => data[valueExpr] === value);
	}, [dataSource, value, valueExpr]);

	const defaultItemLabel = useMemo(() => {
		return selectedItem ? (selectedItem[displayExpr] as string) : placeholder;
	}, [placeholder, selectedItem, displayExpr]);

	const handleChange = useCallback(
		(value?: string) => {
			const item = dataSource.find((data) => String(data[valueExpr]) === value);
			const selectedValue = item ? String(item[valueExpr]) : undefined;
			if (onValueChange) onValueChange(selectedValue);
			if (onDataChange) onDataChange(item);
		},
		[dataSource, valueExpr, onValueChange, onDataChange]
	);

	const renderAddAction = useMemo(() => {
		if (addAction != undefined && addAction.hasAddAction) {
			return (
				<CommandItem
					key={createUUID()}
					onSelect={() => {
						if (addAction) addAction.onAddItem();
						setOpen(false);
					}}
					className='text-xxs aria-selected:text-primary text-primary'
				>
					Thêm mới
					<PlusCircleIcon className={cn('ml-auto h-4 w-4')} />
				</CommandItem>
			);
		}
		return null;
	}, [addAction]);

	const renderedItems = useMemo(() => {
		return dataSource.map((data, idx) => (
			<CommandItem
				value={String(data[valueExpr])}
				key={idx}
				disabled={isDisabled}
				onSelect={() => {
					handleChange(String(data[valueExpr]));
					setOpen(false);
				}}
				keywords={[String(data[displayExpr])]}
				className='text-xxs'
			>
				{itemRender ? itemRender(data) : String(data[displayExpr])}
				<CheckIcon
					className={cn(
						'ml-auto h-4 w-4',
						data[valueExpr] === value ? 'opacity-100' : 'opacity-0'
					)}
				/>
			</CommandItem>
		));
	}, [
		dataSource,
		isDisabled,
		value,
		valueExpr,
		displayExpr,
		itemRender,
		handleChange,
	]);

	return (
		<Popover open={open} onOpenChange={setOpen}>
			<PopoverTrigger asChild>
				<Button
					id={`combobox-${name}-${createUUID()}`}
					name={name}
					variant='outline'
					role='combobox'
					className={cn(
						'h-7 justify-between [&_[data-description]]:hidden',
						!value && 'text-muted-foreground',
						width ? width : 'max-w-40'
					)}
					title={defaultItemLabel}
				>
					<span className={cn('truncate', `text-${textSize}`)}>
						{defaultItemLabel}
					</span>
					<CaretSortIcon className='ml-2 h-4 w-4 shrink-0 opacity-50' />
				</Button>
			</PopoverTrigger>
			<PopoverContent className='p-0'>
				<Command
					shouldFilter={true}
					value={filterString}
					onValueChange={setFilterString}
					filter={(value, search, keywords) => {
						const extendUnicodeValue =
							keywords?.map((x) => x.toLowerCase()).join(' ') || '';
						const extendValue =
							keywords
								?.map((x) => {
									return slugHelper.toKeyword(x);
								})
								.join(' ') || '';
						const searchValue = slugHelper.toKeyword(search?.toLowerCase());
						if (
							extendUnicodeValue.includes(search?.toLowerCase()) ||
							extendValue.includes(searchValue?.toLowerCase())
						)
							return 1;
						return 0;
					}}
				>
					<CommandInput placeholder='Tìm kiếm...' className='h-9' />
					<CommandList>
						<CommandEmpty>Dữ liệu trống.</CommandEmpty>
						<CommandGroup>
							{renderAddAction}
							{value !== defaultValue && isClearable == true && (
								<CommandItem
									value={defaultValue}
									key={createUUID()}
									onSelect={() => {
										handleChange(defaultValue);
										setOpen(false);
									}}
									className='text-xxs aria-selected:text-red-500 text-red-500'
								>
									Bỏ chọn
									<XIcon className={cn('ml-auto h-4 w-4')} />
								</CommandItem>
							)}

							{renderedItems}
						</CommandGroup>
					</CommandList>
				</Command>
			</PopoverContent>
		</Popover>
	);
};

export default Combobox;
