import React from 'react';
import { RouteObject } from 'react-router-dom';
import { TransportationCompanyPage } from './transportation-company';
import { TransportationFeePage } from './transportation-fee';
import { GoodsDetail, GoodsPage } from './goods';
import { SupplierPage } from './supplier';
import { CustomerPage } from './customer';
import { BankPage } from './bank';
import { WarehousePage } from './warehouse';
import { GoodsPriceQuotePage } from './goods-price-quote';
import { CompanyInfoPage } from './company-info';
import { PartnerPage } from './partner';

export const catalogRoutes: RouteObject[] = [
	{
		path: 'danh-muc/don-vi-van-chuyen',
		element: <TransportationCompanyPage />,
	},
	{
		path: 'danh-muc/bang-gia-cuoc-van-chuyen',
		element: <TransportationFeePage />,
	},
	{
		path: 'danh-muc/thong-tin-cong-ty',
		element: <CompanyInfoPage />,
	},
	{
		path: 'danh-muc/hang-hoa-cung-cap',
		children: [
			{
				path: '',
				element: <GoodsPage />,
			},
			{
				path: 'chi-tiet',
				element: <GoodsDetail />,
			},
			{
				path: 'chi-tiet/:id',
				element: <GoodsDetail />,
			},
		],
	},
	{
		path: 'danh-muc/bao-gia-hang-hoa',
		element: <GoodsPriceQuotePage />,
	},
	{
		path: 'danh-muc/nha-cung-cap-doi-tac',
		element: <PartnerPage />,
	},
	{
		path: 'danh-muc/khach-hang',
		element: <PartnerPage />,
	},
	{
		path: 'danh-muc/ngan-hang',
		element: <BankPage />,
	},
	{
		path: 'danh-muc/kho-hang',
		element: <WarehousePage />,
	},
];
