import { CheckCircle, CircleX } from 'lucide-react';
import {
	AlertDialog,
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
} from '../base/ui/alert-dialog';

interface ConfirmDialogProps {
	isOpen: boolean;
	onClose: () => void;
	onConfirm: () => void;
	title: string;
	description: string;
}

const ConfirmDialog = ({
	isOpen,
	title,
	description,
	onClose,
	onConfirm,
}: ConfirmDialogProps) => {
	return (
		<AlertDialog open={isOpen} onOpenChange={onClose}>
			<AlertDialogContent>
				<AlertDialogHeader>
					<AlertDialogTitle>{title}</AlertDialogTitle>
					<AlertDialogDescription>{description}</AlertDialogDescription>
				</AlertDialogHeader>
				<AlertDialogFooter>
					<AlertDialogCancel className='h-7'>
						<CircleX className='h-4 w-4 mr-2' /> Hủy
					</AlertDialogCancel>
					<AlertDialogAction className='h-7' onClick={onConfirm}>
						<CheckCircle className='h-4 w-4 mr-2' /> Xác nhận
					</AlertDialogAction>
				</AlertDialogFooter>
			</AlertDialogContent>
		</AlertDialog>
	);
};

export default ConfirmDialog;
