import { FormField } from '@/components/base/ui/form';
import { Cardbox } from '@/components/common';
import { FormInput, FormSwitchbox } from '@/components/common/form';
import { FormSheetDialog } from '@/components/common/form/form-sheet-dialog';
import FormTextarea from '@/components/common/form/form-text-area';
import {
    useGoodsGetDetailQuery,
    useGoodsSaveMutation,
} from '@/contexts/api/contract-management/catalog/goods-api';
import {
    GoodsFormDefault,
    GoodsFormModel,
    GoodsFormValid,
} from '@/contexts/models/contract-management/catalog';
import { slugHelper } from '@/utils/slug-helper';
import { zodResolver } from '@hookform/resolvers/zod';
import { useCallback, useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import PhysicalCard from './items/physical-card';
import SensoryCard from './items/sensory-card';

interface GoodsDetailSheetProps {
	title: string;
	isOpen: boolean;
	onOpenChange: (isOpen: boolean) => void;
	detail_id: number;
}

const GoodsDetailSheet = ({
	detail_id,
	isOpen,
	onOpenChange,
	title = 'hàng hóa cung cấp',
}: GoodsDetailSheetProps) => {
	const isAddMode = detail_id === 0;
	const {
		data,
		isLoading: loading,
		isSuccess: loadOK,
	} = useGoodsGetDetailQuery(detail_id);
	const [saveGoods, { isLoading: isSaving, isSuccess: isSaved }] =
		useGoodsSaveMutation();

	const [defaultValues, setDefaultValues] =
		useState<GoodsFormModel>(GoodsFormDefault);

	const form = useForm<GoodsFormModel>({
		resolver: zodResolver(GoodsFormValid),
		defaultValues,
	});

	const { reset, control, setValue } = form;

	const watchChangeName = useWatch({ control, name: 'name', defaultValue: '' });

	useEffect(() => {
		if (isAddMode) {
			const slug = slugHelper.toSlug(watchChangeName);
			setValue('code', slug.replace('-', '').toUpperCase());
		}
	}, [watchChangeName, setValue, isAddMode]);

	useEffect(() => {
		if (data) setDefaultValues(data);
		else setDefaultValues(GoodsFormDefault);
	}, [data]);

	useEffect(() => {
		reset(defaultValues);
	}, [reset, defaultValues]);

	const onSubmit = useCallback(
		async (formState: GoodsFormModel) => {
			await saveGoods(formState).unwrap();
		},
		[saveGoods]
	);

	useEffect(() => {
		if (isSaved) {
			onOpenChange(false);
		}
	}, [isSaved, onOpenChange]);

	return (
		<>
			<FormSheetDialog
				sheetTitle={`${detail_id > 0 ? 'Chỉnh sửa' : 'Tạo mới'} ${title}`}
				isOpen={isOpen}
				onOpenChange={onOpenChange}
				form={form}
				onSubmit={onSubmit}
				isDisabled={isSaving}
				hasScroll={true}
			>
				<Cardbox
					title='Thông tin'
					className='sm:grid md:grid-cols-* lg:grid-cols-4'
				>
					<FormField
						control={form.control}
						name='name'
						render={({ field }) => (
							<FormInput
								isRequired={true}
								name={field.name}
								value={field.value}
								onValueChange={field.onChange}
								isDisabled={isSaving}
								label='Tên'
								classForm='col-span-3'
							/>
						)}
					/>
					<FormField
						control={form.control}
						name='code'
						render={({ field }) => (
							<FormInput
								isRequired={true}
								readOnly={!isAddMode}
								disabled={!isAddMode}
								name={field.name}
								value={field.value}
								onValueChange={field.onChange}
								isDisabled={isSaving}
								label='Mã'
							/>
						)}
					/>
					<FormField
						control={form.control}
						name='description'
						render={({ field }) => (
							<FormTextarea
								isRequired={true}
								name={field.name}
								value={field.value}
								onValueChange={field.onChange}
								isDisabled={isSaving}
								label='Mô tả'
								classForm='col-span-4'
							/>
						)}
					/>
					<FormField
						control={form.control}
						name='is_active'
						render={({ field }) => (
							<FormSwitchbox
								name={field.name}
								value={field.value}
								onValueChange={field.onChange}
								isDisabled={isSaving}
								label='Áp dụng'
							/>
						)}
					/>
				</Cardbox>
				<div>
					<FormField
						control={form.control}
						name='physical_characteristics'
						render={({ field }) => (
							<PhysicalCard
								array_string={field.value}
								onValueChange={field.onChange}
							/>
						)}
					/>
					<FormField
						control={form.control}
						name='sensory_characteristics'
						render={({ field }) => (
							<SensoryCard
								array_string={field.value}
								onValueChange={field.onChange}
							/>
						)}
					/>
				</div>
			</FormSheetDialog>
		</>
	);
};

export default GoodsDetailSheet;
