import Combobox from '@/components/common/combobox';
import FormRadioGroup from '@/components/common/form/form-radio-group';
import { useContractStatus } from '@/hooks/catalog/use-contract-status';
import { useTransportType } from '@/hooks/catalog/use-transport-type';

interface ContractTransportTypeRadioProps {
	onValueChange: (value: string) => void;
	name: string;
	label?: string;
	value: string;
	defaultValue: string;
	isDisabled?: boolean;
	isRequired?: boolean;
	helpBlock?: string;
}

const ContractTransportTypeRadio = ({
	value,
	onValueChange,
	...rest
}: ContractTransportTypeRadioProps) => {
	const { all_transport_type } = useTransportType();
	return (
		<>
			<FormRadioGroup
				dataSource={all_transport_type || []}
				value={value}
				checkExpr='id'
				displayExpr='name'
				onValueChange={(value) => {
					onValueChange(value);
				}}
				{...rest}
			/>
		</>
	);
};

export default ContractTransportTypeRadio;
