import { useBrowserHistory } from '@/hooks/use-browser-history';
import { cn } from '@/lib/utils';
import {
	ArrowLeftSquareIcon,
	CopyXIcon,
	Edit,
	HomeIcon,
	Loader2Icon,
	PlusCircle,
	Save,
	Trash,
	XIcon,
} from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { Button, ButtonProps } from '../base/ui/button';
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from '../base/ui/tooltip';

export interface ButtonCustomProps extends ButtonProps {
	onClick?: () => void;
	title?: string;
	icon?: React.ReactNode;
	children?: React.ReactNode;
	isLoading?: boolean;
}
export const ButtonCustom = ({
	variant = 'outline',
	title,
	onClick,
	className,
	icon,
	size = 'sm',
	type = 'button',
	children,
	isLoading,
	...rest
}: ButtonCustomProps) => {
	return (
		<TooltipProvider>
			<Tooltip>
				<TooltipTrigger asChild>
					<Button
						type={type || 'button'}
						variant={variant}
						className={cn(
							variant != 'link'
								? 'gap-1'
								: 'm-0 p-1 gap-1 hover:text-primary text-blue-600',
							variant == 'icon' && 'm-0 p-1',
							className
						)}
						size={size}
						onClick={onClick}
						disabled={isLoading || rest.disabled}
						{...rest}
					>
						{isLoading == true ? (
							<>
								<Loader2Icon className='mr-2 h-4 w-4 animate-spin' /> Loading
							</>
						) : (
							<>
								{children ? (
									children
								) : (
									<>
										{icon}
										<span
											className={cn(
												'sr-only',
												variant != 'icon' &&
													'sm:not-sr-only sm:whitespace-nowrap'
											)}
										>
											{title}
										</span>
									</>
								)}
							</>
						)}
					</Button>
				</TooltipTrigger>
				{title && <TooltipContent>{title}</TooltipContent>}
			</Tooltip>
		</TooltipProvider>
	);
};
export const ButtonAdd = ({
	title = 'Tạo mới',
	icon = <PlusCircle className='h-3.5 w-3.5' />,
	className,
	...rest
}: ButtonCustomProps) => {
	return <ButtonCustom title={title} icon={icon} {...rest} />;
};
export const ButtonEdit = ({
	title = 'Sửa',
	icon = <Edit className='h-3.5 w-3.5' />,
	...rest
}: ButtonCustomProps) => {
	return <ButtonCustom title={title} icon={icon} {...rest} />;
};
export const ButtonDelete = ({
	title = 'Xóa',
	variant = 'destructive',
	icon = <Trash className='h-3.5 w-3.5' />,
	...rest
}: ButtonCustomProps) => {
	return <ButtonCustom title={title} icon={icon} variant={variant} {...rest} />;
};
export const ButtonSave = ({
	title = 'Lưu',
	variant = 'default',
	icon = <Save className='h-3.5 w-3.5' />,
	...rest
}: ButtonCustomProps) => {
	return <ButtonCustom title={title} icon={icon} variant={variant} {...rest} />;
};
export const ButtonCancel = ({
	title = 'Hủy',
	variant = 'destructive',
	icon = <XIcon className='h-3.5 w-3.5' />,
	...rest
}: ButtonCustomProps) => {
	return <ButtonCustom title={title} icon={icon} variant={variant} {...rest} />;
};
export const ButtonClose = ({
	title = 'Đóng',
	variant = 'destructive',
	icon = <CopyXIcon className='h-3.5 w-3.5' />,
	...rest
}: ButtonCustomProps) => {
	return <ButtonCustom title={title} icon={icon} variant={variant} {...rest} />;
};
export const ButtonBack = ({
	title = 'Quay lại',
	variant = 'outline',
	icon = <ArrowLeftSquareIcon className='h-3.5 w-3.5' />,
	onClick,
	...rest
}: ButtonCustomProps) => {
	const navigate = useNavigate();
	const { parent_path } = useBrowserHistory();
	return (
		<ButtonCustom
			title={title}
			icon={icon}
			variant={variant}
			onClick={onClick || (() => navigate(parent_path))}
			{...rest}
		/>
	);
};

export const ButtonHome = ({
	title = 'Trang chủ',
	variant = 'outline',
	icon = <HomeIcon className='h-3.5 w-3.5' />,
	onClick,
	...rest
}: ButtonCustomProps) => {
	const navigate = useNavigate();
	return (
		<ButtonCustom
			title={title}
			icon={icon}
			variant={variant}
			onClick={onClick || (() => navigate('/'))}
			{...rest}
		/>
	);
};
