import { BaseQueryFn } from '@reduxjs/toolkit/query';
import { AxiosError, AxiosRequestConfig, Method } from 'axios';
import {
	baseAxiosClientFileDownload,
	baseAxiosClientRequest,
} from './base-axios-client';
import { BaseResponse } from './base-response';

export const axiosBaseQuery =
	(
		{ baseUrl }: { baseUrl: string } = { baseUrl: '' }
	): BaseQueryFn<
		{
			url: string;
			method?: Method;
			isDownload?: boolean;
			data?: AxiosRequestConfig['data'];
			params?: AxiosRequestConfig['params'];
			headers?: AxiosRequestConfig['headers'];
			need_auth?: boolean;
		},
		BaseResponse,
		unknown
	> =>
	async ({ url, method, data, params, headers, need_auth, isDownload }) => {
		try {
			if (isDownload == true) {
				const result = await baseAxiosClientFileDownload()(
					baseUrl + url,
					(method = method || 'GET'),
					data,
					params,
					headers,
					(need_auth = need_auth ?? true)
				);
				if (result.is_success === false) {
					return { error: { data: result.message } };
				}
				return { data: result };
			}
			const result = await baseAxiosClientRequest(method || 'GET')(
				baseUrl + url,
				data,
				params,
				headers,
				(need_auth = need_auth ?? true)
			);
			if (result.is_success === false) {
				return { error: { data: result.message } };
			}
			return { data: result };
		} catch (axiosError) {
			const err = axiosError as AxiosError;
			return {
				error: {
					status: err.response?.status,
					data: err.response?.data || err.message,
				},
			};
		}
	};
