import { FormSheetDialog } from '@/components/common/form/form-sheet-dialog';
import { useContractDispatchSaveMutation } from '@/contexts/api/contract-management/contract/contract-dispatch-api';
import {
	ContractDispatchFormModel,
	ContractDispatchValid,
} from '@/contexts/models/contract-management/contract/ContractDispatchModel';
import { zodResolver } from '@hookform/resolvers/zod';
import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { ContractDispatchDetailForm } from './form/contract-dispatch-detail-form';

interface ContractDispatchDetailSheetProps {
	title: string;
	isOpen: boolean;
	onOpenChange: (isOpen: boolean) => void;
	detail: ContractDispatchFormModel;
}

export const ContractDispatchDetailSheet = ({
	title,
	isOpen,
	onOpenChange,
	detail,
}: ContractDispatchDetailSheetProps) => {
	const [saveAction, { isLoading: isSaving, isSuccess: isSaved }] =
		useContractDispatchSaveMutation();

	const defaultValues = detail;
	const form = useForm<ContractDispatchFormModel>({
		resolver: zodResolver(ContractDispatchValid),
		defaultValues,
	});

	const {
		reset,
		control,
		setValue,
		formState: { isSubmitting },
	} = form;

	const isLoading = isSaving || isSubmitting;

	const onSubmit = useCallback(
		async (formState: ContractDispatchFormModel) => {
			await saveAction(formState).unwrap();
		},
		[saveAction]
	);

	useEffect(() => {
		if (isSaved) {
			onOpenChange(false);
		}
	}, [isSaved, onOpenChange]);

	console.log('ad asd á', title);

	return (
		<>
			<FormSheetDialog
				sheetTitle={`${detail.id > 0 ? 'Chỉnh sửa' : 'Tạo mới'} ${title}`}
				isOpen={isOpen}
				onOpenChange={onOpenChange}
				form={form}
				onSubmit={onSubmit}
				isDisabled={isLoading}
				hasScroll={true}
				width='w-1/3'
			>
				<ContractDispatchDetailForm
					isSaving={isLoading}
					formData={detail}
					form={form}
				/>
			</FormSheetDialog>
		</>
	);
};
