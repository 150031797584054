import { ContractPaymentApi } from '@/contexts/api/contract-management/contract/contract-payment-api';
import {
	ContractPayment,
	ContractPaymentFormModel,
} from '@/contexts/models/contract-management/contract/ContractPayment';

import { NotifyHelper } from '@/utils/notify-helper';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const entityName = 'Thanh toán hợp đồng';

type ContractPaymentState = {
	contractPayments: ContractPayment[];
	detail?: ContractPaymentFormModel;
	is_open_dialog: boolean;
	selected_ids: number[];
	is_open_confirm: boolean;
	is_open_lock_confirm: boolean;
	is_open_unlock_confirm: boolean;
	is_open_change_status: boolean;
	inserted_id?: number;
};

const initialState = {
	contractPayments: [],
	contractPaymentTemplates: [],
	detail: undefined,
	is_open_confirm: false,
	is_open_dialog: false,
	selected_ids: [],
	inserted_id: undefined,
	is_open_lock_confirm: false,
	is_open_unlock_confirm: false,
	is_open_change_status: false,
} as ContractPaymentState;

const entityEndpointsApi = ContractPaymentApi.endpoints;

const ContractPaymentSlice = createSlice({
	name: 'ContractPaymentSlice',
	initialState: initialState,
	reducers: {
		ContractPaymentShowDetail: (
			state,
			{
				payload,
			}: PayloadAction<{
				is_open_dialog: boolean;
				detail?: ContractPaymentFormModel;
			}>
		) => {
			state.is_open_dialog = payload.is_open_dialog;
			state.detail = payload.detail;
			state.inserted_id = undefined;
		},
		ContractPaymentShowConfirm: (
			state,
			{ payload }: PayloadAction<{ is_open_confirm: boolean }>
		) => {
			state.is_open_confirm = payload.is_open_confirm;
		},
		ContractPaymentShowLockConfirm: (
			state,
			{ payload }: PayloadAction<{ is_open_lock_confirm: boolean }>
		) => {
			state.is_open_lock_confirm = payload.is_open_lock_confirm;
		},
		ContractPaymentShowUnlockConfirm: (
			state,
			{ payload }: PayloadAction<{ is_open_unlock_confirm: boolean }>
		) => {
			state.is_open_unlock_confirm = payload.is_open_unlock_confirm;
		},
		ContractPaymentSelectedIdsChange: (
			state,
			{ payload }: PayloadAction<{ selected_ids: number[] }>
		) => {
			state.selected_ids = payload.selected_ids;
		},
		ContractShipmentChangeStatus: (
			state,
			{
				payload,
			}: PayloadAction<{
				is_open_change_status: boolean;
			}>
		) => {
			state.is_open_change_status = payload.is_open_change_status;
		},
	},
	extraReducers: (builder) => {
		builder.addMatcher(
			entityEndpointsApi.contractPaymentGetAll.matchFulfilled,
			(state, { payload }) => {
				state.contractPayments = payload || [];
			}
		);
		builder.addMatcher(
			entityEndpointsApi.contractPaymentSave.matchFulfilled,
			(state, { payload }) => {
				state.is_open_dialog = false;
				state.detail = undefined;

				const { is_success, message, result } = payload;
				state.inserted_id = result?.id;
				if (is_success)
					NotifyHelper.Success(message || `Lưu ${entityName} thành công`);
				else
					NotifyHelper.Error(message || `Lưu ${entityName} không thành công`);
			}
		);
		builder.addMatcher(
			entityEndpointsApi.contractPaymentConfirm.matchFulfilled,
			(state, { payload }) => {
				const { is_success, message } = payload;
				if (is_success)
					NotifyHelper.Success(
						message || `Khóa/mở khóa ${entityName} thành công`
					);
				else
					NotifyHelper.Error(
						message || `Khóa/mở khóa ${entityName} không thành công`
					);
				state.is_open_lock_confirm = false;
				state.is_open_unlock_confirm = false;
				state.selected_ids = [];
			}
		);
		builder.addMatcher(
			entityEndpointsApi.contractPaymentDelete.matchFulfilled,
			(state, { payload }) => {
				state.is_open_confirm = false;
				state.selected_ids = [];
				const { is_success, message } = payload;
				if (is_success)
					NotifyHelper.Success(message || `Xóa ${entityName} thành công`);
				else
					NotifyHelper.Error(message || `Xóa ${entityName} không thành công`);
			}
		);
		builder.addMatcher(
			entityEndpointsApi.contractPaymentDeleteMultiple.matchFulfilled,
			(state, { payload }) => {
				state.is_open_confirm = false;
				state.selected_ids = [];

				const { is_success, message } = payload;
				if (is_success)
					NotifyHelper.Success(message || `Xóa ${entityName} thành công`);
				else
					NotifyHelper.Error(message || `Xóa ${entityName} không thành công`);
			}
		);
		builder.addMatcher(
			entityEndpointsApi.contractPaymentChangeStatus.matchFulfilled,
			(state) => {
				state.is_open_change_status = false;
				state.selected_ids = [];
			}
		);
	},
});
export const contractPaymentActions = ContractPaymentSlice.actions;
export default ContractPaymentSlice.reducer;
