import { zod, zod_valid } from '@/lib/zod-valid';

export type Goods = {
	id: number;
	name: string;
	code: string;
	description: string;
	physical_characteristics: string;
	sensory_characteristics: string;
	is_active: boolean;
};

export const GoodsFormValid = zod
	.object({
		id: zod_valid.number().optional(),
		name: zod_valid.string().min(1, { message: 'Vui lòng nhập tên' }),
		code: zod_valid.string().min(1, { message: 'Vui lòng nhập mã' }),
		description: zod_valid.string(),
		physical_characteristics: zod_valid.string(),
		sensory_characteristics: zod_valid.string(),
		is_active: zod_valid.bool(),
	})
	.required();

export type GoodsFormModel = zod.infer<typeof GoodsFormValid>;

export const GoodsFormDefault: GoodsFormModel = {
	id: 0,
	code: '',
	is_active: true,
	name: '',
	description: '',
	physical_characteristics: '',
	sensory_characteristics: '',
};
