import { axiosClient, axiosFullClient } from '@/lib/axios-client';
import { BaseResponse } from '@/contexts/base/base-response';
import { localStorageHelper } from '@/utils/localstorage-helper';
import { memoizedRefreshToken } from '@/utils/refresh-token-helper';
import { Axios, AxiosError, AxiosResponse, Method } from 'axios';

export const baseAxiosClient = {
	get: baseAxiosClientRequest('GET'),
	post: baseAxiosClientRequest('POST'),
	put: baseAxiosClientRequest('PUT'),
	delete: baseAxiosClientRequest('DELETE'),
	patch: baseAxiosClientRequest('PATCH'),
	download: baseAxiosClientFileDownload(),
};

export function baseAxiosClientRequest(method: Method) {
	return async (
		url: string,
		data: any | undefined,
		params: any | undefined,
		headers: any | undefined,
		need_auth: boolean
	): Promise<BaseResponse> => {
		try {
			if (need_auth === true) await memoizedRefreshToken();
			const headers_config =
				need_auth === true
					? {
							Authorization: `Bearer ${localStorageHelper.GET_TOKEN()}`,
							'Content-Type': 'application/json',
							...headers,
						}
					: { 'Content-Type': 'application/json', ...headers };
			const config = {
				method,
				url: url,
				headers: headers_config,
				data: data ? JSON.stringify(data) : '',
				params,
			};
			const reponse = await axiosClient<any, BaseResponse>(config);
			return reponse;
		} catch (error: any) {
			if (error?.name === 'AbortError')
				return {
					is_success: false,
					message: 'Request đã bị hủy',
				} as BaseResponse;
			// if (error?.name === 'AxiosError')
			// 	return { is_success: false, message: 'Có lỗi xảy ra' } as BaseResponse;
			if (error?.response?.status === 401) {
				return {
					is_success: false,
					message:
						'Bạn không được phân quyền để thực hiện thao tác này. Vui lòng liên hệ Quản trị viên để được hỗ trợ.',
				} as BaseResponse;
			} else if (error?.response?.status === 500) {
				const message =
					'Có lỗi hệ thống. Vui lòng liên hệ Quản trị viên để được hỗ trợ.';
				return {
					is_success: false,
					message: message,
				} as BaseResponse;
			} else {
				const message =
					error?.response?.data?.message ||
					'Có lỗi xảy ra . Vui lòng liên hệ Quản trị viên để được hỗ trợ.';
				return {
					is_success: false,
					message: message,
				} as BaseResponse;
			}
		}
	};
}

export function baseAxiosClientFileDownload() {
	return async (
		url: string,
		method: Method,
		data: any | undefined,
		params: any | undefined,
		headers: any | undefined,
		need_auth: boolean
	): Promise<BaseResponse> => {
		try {
			if (need_auth === true) await memoizedRefreshToken();
			const headers_config =
				need_auth === true
					? {
							Authorization: `Bearer ${localStorageHelper.GET_TOKEN()}`,
							'Content-Type': 'application/json',
							...headers,
						}
					: { 'Content-Type': 'application/json', ...headers };
			await axiosFullClient<any, AxiosResponse>({
				method,
				url: url,
				headers: headers_config,
				data: data ? JSON.stringify(data) : '',
				params,
				responseType: 'blob',
			}).then((response) => {
				console.log(response);
				// Create a blob object from the response
				const blob = new Blob([response.data], {
					type: response.headers['content-type'],
				});

				// Create a temporary link element
				const link = document.createElement('a');
				link.href = window.URL.createObjectURL(blob);

				// Set the filename for the downloaded file (if provided by the server)
				const contentDisposition = response.headers['content-disposition'];
				const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
				const matches = fileNameRegex.exec(contentDisposition);
				let fileName = 'downloaded_file';
				if (matches != null && matches[1]) {
					fileName = matches[1].replace(/['"]/g, '');
				}
				link.download = fileName;
				// Append the link to the body and trigger the download
				document.body.appendChild(link);
				link.click();

				// Clean up
				window.URL.revokeObjectURL(link.href);
				document.body.removeChild(link);
			});
			return {
				is_success: true,
				message: 'Download thành công',
			} as BaseResponse;
		} catch (ex) {
			const error = ex as AxiosError;
			if (error?.name === 'AbortError')
				return {
					is_success: false,
					message: 'Request đã bị hủy',
				} as BaseResponse;
			if (error?.name === 'AxiosError')
				return { is_success: false, message: 'Có lỗi xảy ra' } as BaseResponse;
			if (error?.response?.status === 401) {
				return {
					is_success: false,
					message:
						'Bạn không được phân quyền để thực hiện thao tác này. Vui lòng liên hệ Quản trị viên để được hỗ trợ.',
				} as BaseResponse;
			} else if (error?.response?.status === 500) {
				const message =
					'Có lỗi hệ thống. Vui lòng liên hệ Quản trị viên để được hỗ trợ.';
				return {
					is_success: false,
					message: message,
				} as BaseResponse;
			} else {
				const message =
					error?.response?.data ||
					'Có lỗi hệ thống. Vui lòng liên hệ Quản trị viên để được hỗ trợ.';
				return {
					is_success: false,
					message: message,
				} as BaseResponse;
			}
		}
	};
}
