import { RouteObject } from 'react-router-dom';
import { BaoCaoPage, CongNoPage, CongNoVayTraHangHoaPage, KeHoachGiaoHangPage, ThucHienGiaoHangPage, TongHopGiaoHangPage, VayTraHangHoaPage, XuatNhapTonPage } from '.';

export const reportRoutes: RouteObject[] = [
	{
		path: '/bao-cao-nhap-xuat-ton',
		element: <XuatNhapTonPage />,
	},
	{
		path: '/bao-cao-cong-no',
		element: <CongNoPage />,
	},
	{
		path: '/bao-cao-ke-hoach-giao-hang',
		element: <KeHoachGiaoHangPage />,
	},
	{
		path: '/bao-cao-thuc-hien-giao-hang',
		element: <ThucHienGiaoHangPage />,
	},
	{
		path: '/tong-hop-giao-hang',
		element: <TongHopGiaoHangPage />,
	},
	{
		path: '/bao-cao-thanh-toan-cuoc-giao-hang',
		element: <CongNoPage />,
	},
	{
		path: '/bao-cao-loi-nhuan-gop',
		element: <CongNoPage />,
	},
	{
		path: '/bang-theo-doi-hang-vay-tra',
		element: <VayTraHangHoaPage />,
	},
	{
		path: '/bang-cong-no-vay-tra',
		element: <CongNoVayTraHangHoaPage />,
	},
	{
		path: '/bang-theo-doi-hop-dong-mua-ban',
		element: <XuatNhapTonPage />,
	},
	{
		path: '/bao-cao-lai-lo',
		element: <CongNoPage />,
	},
];
