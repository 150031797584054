import { RouteObject } from 'react-router-dom';
import { MenuPage } from './menu';
import { UserDetailPage, UserPage } from './user';
import { RoleScopePage } from './role-scope';
import { RolePage } from './role';
import { AppFeaturePage } from './app-feature';
import { MenuRolePage } from './menu-role';

const superAdminRoutes: RouteObject[] = [
	{
		path: 'super-admin/menu-manager',
		element: <MenuPage />,
	},
	{
		path: 'super-admin/role-manager',
		element: <RolePage />,
	},
	{
		path: 'super-admin/role-scope-manager',
		element: <RoleScopePage />,
	},
	{
		path: 'super-admin/app-feature-manager',
		element: <AppFeaturePage />,
	},
	{
		path: 'super-admin/menu-role-manager',
		element: <MenuRolePage />,
	},
	{
		path: 'super-admin/user-manager',
		children: [
			{
				path: '',
				element: <UserPage />,
			},
			{
				path: 'chi-tiet',
				element: <UserDetailPage />,
			},
			{
				path: 'chi-tiet/:id',
				element: <UserDetailPage />,
			},
		],
	},
];

export { superAdminRoutes };
