import { zod } from '@/lib/zod-valid';
import { formatDate } from 'date-fns';

export type ContractPayment = {
	id: number;
	contract_id: number;
	contract_code: string;
	payment_name: string;
	payment_date: string;
	payment_amount: number;
	payment_amount_words: string;
	payment_status_id: string;
	payment_notes: string;
};

export const ContractPaymentValid = zod.object({
	id: zod.number(),
	contract_id: zod.number(),
	contract_code: zod.string(),
	payment_name: zod.string(),
	payment_date: zod.string(),
	payment_amount: zod.number(),
	payment_amount_words: zod.string(),
	payment_status_id: zod.string(),
	payment_notes: zod.string(),
});

export type ContractPaymentFormModel = zod.infer<typeof ContractPaymentValid>;

export const ContractPaymentFormDefault: ContractPaymentFormModel = {
	id: 0,
	contract_id: 0,
	contract_code: '',
	payment_name: '',
	payment_date: formatDate(new Date(), 'yyyy-MM-dd'),
	payment_amount: 0,
	payment_amount_words: '',
	payment_status_id: '',
	payment_notes: '',
};
