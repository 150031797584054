import { FormField } from '@/components/base/ui/form';
import { Cardbox } from '@/components/common';
import { FormInput } from '@/components/common/form';
import FromDatePicker from '@/components/common/form/form-date-picker';
import { FormSheetDialog } from '@/components/common/form/form-sheet-dialog';
import { useContractQuotationSaveMutation } from '@/contexts/api/contract-management/contract/contract-quotation-api';
import {
	ContractQuotationFormModel,
	ContractQuotationValid,
} from '@/contexts/models/contract-management/contract/ContractQuotation';
import { convertNumberToWords } from '@/utils/convert-number-to-words';
import { zodResolver } from '@hookform/resolvers/zod';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { QuotationDetailForm } from './form/quotation-detail-form';
import { ContractInfo } from '@/contexts/models/contract-management/contract';
import { set } from 'lodash';
import {
	useContractInfoGetDetailQuery,
	useLazyContractInfoGetDetailQuery,
} from '@/contexts/api/contract-management/contract/contract-info-api';

interface ContractQuotationProps {
	title: string;
	isOpen: boolean;
	onOpenChange: (isOpen: boolean) => void;
	detail: ContractQuotationFormModel;
	contract_id: number;
	contract_code: string;
}

export const ContractQuotationDetailSheet = ({
	title,
	isOpen,
	onOpenChange,
	detail,
	contract_id,
	contract_code,
}: ContractQuotationProps) => {
	const isEdit = useMemo(() => {
		return detail.id > 0;
	}, [detail]);

	const [saveAction, { isLoading: isSaving, isSuccess: isSaved }] =
		useContractQuotationSaveMutation();

	const [getContractDetail, { data: contractDetail }] =
		useLazyContractInfoGetDetailQuery();

	const [defaultValues, setDefaultValues] =
		useState<ContractQuotationFormModel>(detail);

	const form = useForm<ContractQuotationFormModel>({
		resolver: zodResolver(ContractQuotationValid),
		defaultValues,
	});

	const {
		reset,
		control,
		setValue,
		formState: { isSubmitting },
	} = form;

	const isLoading = isSaving || isSubmitting;

	// const changeGoodsInfo = (contract?: ContractInfo) => {
	// 	setDefaultValues((prev) => ({
	// 		...prev,
	// 		contract_id: contract?.id || 0,
	// 		goods_id: contract?.goods_id || 0,
	// 		goods_code: contract?.goods_code || '',
	// 		goods_description: contract?.goods_description || '',
	// 		goods_name: contract?.goods_name || '',
	// 		goods_physical_characteristics:
	// 			contract?.goods_physical_characteristics || '',
	// 		goods_sensory_characteristics:
	// 			contract?.goods_sensory_characteristics || '',
	// 		goods_quantity: contract?.goods_quantity || 0,
	// 		goods_price: contract?.goods_price || 0,
	// 		quotation_price:
	// 			prev.quotation_price <= 0
	// 				? contract?.goods_price || 0
	// 				: prev.quotation_price,
	// 	}));
	// };

	useEffect(() => {
		if (contractDetail && !isEdit) {
			setDefaultValues((prev) => ({
				...prev,
				contract_id: contractDetail?.id || 0,
				goods_id: contractDetail?.goods_id || 0,
				goods_code: contractDetail?.goods_code || '',
				goods_description: contractDetail?.goods_description || '',
				goods_name: contractDetail?.goods_name || '',
				goods_physical_characteristics:
					contractDetail?.goods_physical_characteristics || '',
				goods_sensory_characteristics:
					contractDetail?.goods_sensory_characteristics || '',
				goods_quantity: contractDetail?.goods_quantity || 0,
				goods_price: contractDetail?.goods_price || 0,
				quotation_price:
					prev.quotation_price <= 0
						? contractDetail?.goods_price || 0
						: prev.quotation_price,
			}));
		}
	}, [contractDetail, isEdit]);

	useEffect(() => {
		if (!isEdit) getContractDetail(contract_id);
	}, [getContractDetail, contract_id, isEdit]);

	const onSubmit = useCallback(
		async (formState: ContractQuotationFormModel) => {
			await saveAction(formState).unwrap();
		},
		[saveAction]
	);

	useEffect(() => {
		setDefaultValues(detail);
	}, [detail]);

	useEffect(() => {
		if (isSaved) {
			onOpenChange(false);
		}
	}, [isSaved, onOpenChange]);

	useEffect(() => {
		reset(defaultValues);
	}, [reset, defaultValues]);

	return (
		<>
			<FormSheetDialog
				sheetTitle={`${detail.id > 0 ? 'Chỉnh sửa' : 'Tạo mới'} ${title}`}
				isOpen={isOpen}
				onOpenChange={onOpenChange}
				form={form}
				onSubmit={onSubmit}
				isDisabled={isLoading}
				hasScroll={true}
				width='w-full'
			>
				<QuotationDetailForm
					form={form}
					formData={defaultValues}
					isSaving={isLoading}
					// changeGoodsInfo={changeGoodsInfo}
				/>
			</FormSheetDialog>
		</>
	);
};
