export const localStorageHelper = {
	SET_TOKEN: setToken,
	SET_USERID: setUserId,
	GET_TOKEN: getToken,
	GET_USERID: getUserId,
	CLEAR_TOKEN: clearToken,
	LANGCODE: getLang(),
};

function setToken(jwtToken: string) {
	if (jwtToken && jwtToken !== '') {
		const [token, refreshToken] = jwtToken.split(' ');
		localStorage.jwtToken = token;
		localStorage.refreshToken = refreshToken;
	}
}

function setUserId(user_id?: any) {
	if (user_id) {
		localStorage.USERID = user_id;
	}
}

function getUserId() {
	return localStorage.USERID;
}

function getToken(key?: 'refresh-token' | 'jwt-token') {
	const value =
		!key || key === 'jwt-token'
			? localStorage.jwtToken
			: localStorage.refreshToken;
	return value;
}

function clearToken() {
	localStorage.removeItem('USERID');
	localStorage.removeItem('jwtToken');
	localStorage.removeItem('refreshToken');
}

function getLang(langCode?: string) {
	let currentLang = 'vi-vn';
	if (langCode && langCode !== '') {
		currentLang = langCode;
		localStorage.langCode = langCode;
	} else {
		if (localStorage.langCode) {
			currentLang = localStorage.langCode;
		}
	}
	return currentLang;
}
