import { ZodType, z } from 'zod';
import { AccountInfo } from './Account';

export interface LoginRequest {
	user_name: string;
	password: string;
}

export type LoginResponse = {
	account_info: AccountInfo | null;
	jwt_token: string;
	refresh_token: string;
};

export const formLoginSchema: ZodType<LoginRequest> = z
	.object({
		user_name: z
			.string()
			.min(1, { message: 'Vui lòng nhập tài khoản' })
			.max(35, { message: 'Tài khoản không được quá 35 ký tự' }),
		password: z.string().min(1, { message: 'Vui lòng nhập mật khẩu' }),
	})
	.required();

export const formLoginDefaultValues: LoginRequest = {
	user_name: '',
	password: '',
};
