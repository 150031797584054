import { zod } from '@/lib/zod-valid';
import { formatDate, parseISO } from 'date-fns';

export type ContractQuotation = {
	id: number;
	contract_id: number;
	quotation_date: string;
	quotation_code: string;
	quotation_status_id: string;
	customer_id: number;
	customer_name: string;
	customer_address: string;
	customer_phone_number: string;
	customer_tax_code: string;
	customer_bank_account: string;
	customer_representative_name: string;
	customer_representative_position: string;
	goods_id: number;
	goods_name: string;
	goods_code: string;
	goods_description: string;
	goods_physical_characteristics: string;
	goods_sensory_characteristics: string;
	goods_packaging_requirements: string;
	goods_quantity: number;
	goods_price: number;
    quotation_quantity: number;
	quotation_price: number;
	quotation_amount: number;
	quotation_amount_words: string;
    quotation_delivery_date_start?: string;
	quotation_delivery_date_end?: string;
	quotation_delivery_address: string;
};

export const ContractQuotationValid = zod
	.object({
		id: zod.number().optional(),
		contract_id: zod.number(),
		quotation_date: zod.string(),
		quotation_code: zod.string(),
		customer_id: zod.number(),
		customer_name: zod.string(),
		customer_address: zod.string(),
		customer_phone_number: zod.string(),
		customer_tax_code: zod.string(),
		customer_bank_account: zod.string(),
		customer_representative_name: zod.string(),
		customer_representative_position: zod.string(),
		goods_id: zod.number(),
		goods_name: zod.string(),
		goods_code: zod.string(),
		goods_description: zod.string(),
		goods_physical_characteristics: zod.string(),
		goods_sensory_characteristics: zod.string(),
		goods_packaging_requirements: zod.string(),
		goods_quantity: zod.number(),
		goods_price: zod.number(),
        quotation_quantity: zod.number(),
        quotation_price: zod.number(),
		quotation_amount: zod.number(),
		quotation_amount_words: zod.string(),
        quotation_delivery_date_start: zod.string().optional(),
        quotation_delivery_date_end: zod.string().optional(),
        quotation_delivery_address: zod.string(),
	})
	.required();

export type ContractQuotationFormModel = zod.infer<
	typeof ContractQuotationValid
>;

export const ContractQuotationFormDefault: ContractQuotationFormModel = {
	id: 0,
	contract_id: 0,
	quotation_date: parseISO(new Date().toISOString()).toISOString(),
	quotation_code: '',
	customer_id: 0,
	customer_name: '',
	customer_address: '',
	customer_phone_number: '',
	customer_tax_code: '',
	customer_bank_account: '',
	customer_representative_name: '',
	customer_representative_position: '',
	goods_id: 0,
	goods_name: '',
	goods_code: '',
	goods_description: '',
	goods_physical_characteristics: '',
	goods_sensory_characteristics: '',
	goods_packaging_requirements: '',
	goods_quantity: 0,
	goods_price: 0,
    quotation_quantity: 0,
    quotation_price: 0,
	quotation_amount: 0,
	quotation_amount_words: '',
    quotation_delivery_date_start: parseISO(new Date().toISOString()).toISOString(),
    quotation_delivery_date_end: parseISO(new Date().toISOString()).toISOString(),
    quotation_delivery_address: '',
};
