import { LucideIcon, Package } from 'lucide-react';
import React from 'react';
import { ButtonBack } from '../common/button';

interface PageHeaderProps {
	pageTitle?: string;
	pageIcon?: {
		icon: LucideIcon;
	};
	children?: React.ReactNode;
	hasBack?: boolean;
}
export const PageHeader = ({
	pageTitle,
	pageIcon,
	children,
	hasBack,
}: PageHeaderProps) => {
	return (
		<>
			<div className='flex items-center'>
				<div className='flex items-center justify-center'>
					{hasBack && <ButtonBack className='mr-2' />}
					{pageIcon ? (
						<pageIcon.icon className='inline-flex w-6 h-6 mr-2' />
					) : (
						<Package className='inline-flex w-6 h-6 mr-2' />
					)}

					<h1 className='text-sm font-semibold md:text-sm'>{pageTitle}</h1>
				</div>
				<div className='ml-auto flex items-center gap-2'>{children}</div>
			</div>
		</>
	);
};
