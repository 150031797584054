export enum eDocumentType {
	HOP_DONG_MUA_HANG = '001',
	HOP_DONG_BAN_HANG = '002',
	HOP_DONG_VAY_HANG = '003',
	HOP_DONG_TRA_HANG = '004',
}

export const eDocumentTypeLabel = {
	[eDocumentType.HOP_DONG_MUA_HANG]: 'Hợp đồng mua hàng',
	[eDocumentType.HOP_DONG_BAN_HANG]: 'Hợp đồng bán hàng',
	[eDocumentType.HOP_DONG_VAY_HANG]: 'Hợp đồng vay hàng',
	[eDocumentType.HOP_DONG_TRA_HANG]: 'Hợp đồng trả hàng',
};

export const eDocumentTypeOptions = [
	{
		id: eDocumentType.HOP_DONG_MUA_HANG,
		name: eDocumentTypeLabel[eDocumentType.HOP_DONG_MUA_HANG],
		class: 'text-blue-500',
	},
	{
		id: eDocumentType.HOP_DONG_BAN_HANG,
		name: eDocumentTypeLabel[eDocumentType.HOP_DONG_BAN_HANG],
		class: 'text-green-500',
	},
	{
		id: eDocumentType.HOP_DONG_VAY_HANG,
		name: eDocumentTypeLabel[eDocumentType.HOP_DONG_VAY_HANG],
		class: 'text-yellow-500',
	},
	{
		id: eDocumentType.HOP_DONG_TRA_HANG,
		name: eDocumentTypeLabel[eDocumentType.HOP_DONG_TRA_HANG],
		class: 'text-purple-500',
	},
];
