import { TransportationFee } from '@/contexts/models/contract-management/catalog/TransportationFee';
import { NotifyHelper } from '@/utils/notify-helper';
import { apiSlice } from '../../api-slice';
import { BaseResponse } from '@/contexts/base/base-response';

const enpointApi = '/catalog/transportation-fee';
const entityName = 'Giá cước vận chuyển';
const entityCacheTag = 'CATALOG_TRANSPORTATION_FEE';

export const TransportationFeeApi = apiSlice
	.enhanceEndpoints({ addTagTypes: [entityCacheTag] })
	.injectEndpoints({
		endpoints: (builder) => ({
			getAllTransportationFee: builder.query<TransportationFee[], void>({
				query: () => ({ url: enpointApi, method: 'GET' }),
				transformResponse: (response) => response.result as TransportationFee[],
				providesTags: (result) =>
					result
						? [
								...result.map(({ id }) => ({
									type: `${entityCacheTag}` as const,
									id,
								})),
								{ type: entityCacheTag, id: 'LIST' },
							]
						: [{ type: entityCacheTag, id: 'LIST' }],
			}),
			createTransportationFee: builder.mutation({
				query: (entity) => ({ url: enpointApi, method: 'POST', data: entity }),
				transformResponse: (response) => response as BaseResponse,
				async onQueryStarted(_args, { queryFulfilled }) {
					try {
						const { data } = await queryFulfilled;
						if (data.is_success)
							NotifyHelper.Success(
								data.message || `Thêm mới ${entityName} thành công`
							);
						else
							NotifyHelper.Error(
								data.message || `Thêm mới ${entityName} thất bại`
							);
					} catch (ex: any) {
						NotifyHelper.Error(
							ex?.error?.data || `Thêm mới ${entityName} có lỗi`
						);
					}
				},
				invalidatesTags: [{ type: entityCacheTag, id: 'LIST' }],
			}),
			updateTransportationFee: builder.mutation({
				query: (entity) => ({ url: enpointApi, method: 'PUT', data: entity }),
				transformResponse: (response) => response as BaseResponse,
				async onQueryStarted(_args, { queryFulfilled }) {
					try {
						const { data } = await queryFulfilled;
						if (data.is_success)
							NotifyHelper.Success(
								data.message || `Cập nhật ${entityName} thành công`
							);
						else
							NotifyHelper.Error(
								data.message || `Cập nhật ${entityName} thất bại`
							);
					} catch (ex: any) {
						NotifyHelper.Error(
							ex?.error?.data || `Cập nhật ${entityName} có lỗi`
						);
					}
				},
				invalidatesTags: [{ type: entityCacheTag, id: 'LIST' }],
			}),
			deleteTransportationFee: builder.mutation<any, number>({
				query: (id) => ({ url: `${enpointApi}/${id}`, method: 'DELETE' }),
				transformResponse: (response) => response as BaseResponse,
				async onQueryStarted(_args, { queryFulfilled }) {
					try {
						const { data } = await queryFulfilled;
						if (data.is_success)
							NotifyHelper.Success(
								data.message || `Xóa ${entityName} thành công`
							);
						else
							NotifyHelper.Error(data.message || `Xóa ${entityName} thất bại`);
					} catch (ex: any) {
						NotifyHelper.Error(ex?.error?.data || `Xóa ${entityName} có lỗi`);
					}
				},
				invalidatesTags: [{ type: entityCacheTag, id: 'LIST' }],
			}),
			deletesTransportationFee: builder.mutation({
				query: (ids: number[]) => ({
					url: `${enpointApi}/deletes`,
					method: 'DELETE',
					data: { ids },
				}),
				transformResponse: (response) => response as BaseResponse,
				async onQueryStarted(_args, { queryFulfilled }) {
					try {
						const { data } = await queryFulfilled;
						if (data.is_success)
							NotifyHelper.Success(
								data.message || `Xóa ${entityName} thành công`
							);
						else
							NotifyHelper.Error(data.message || `Xóa ${entityName} thất bại`);
					} catch (ex: any) {
						NotifyHelper.Error(ex?.error?.data || `Xóa ${entityName} có lỗi`);
					}
				},
				invalidatesTags: [{ type: entityCacheTag, id: 'LIST' }],
			}),
		}),
	});

export const {
	useCreateTransportationFeeMutation,
	useDeleteTransportationFeeMutation,
	useDeletesTransportationFeeMutation,
	useGetAllTransportationFeeQuery,
	useLazyGetAllTransportationFeeQuery,
	useUpdateTransportationFeeMutation,
} = TransportationFeeApi;
