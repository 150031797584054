import { Button } from '@/components/base/ui/button';
import { useSidebar } from '@/hooks/use-sidebar';
import { cn } from '@/lib/utils';
import { ArrowLeftCircle, ArrowRightCircle } from 'lucide-react';
import { useState } from 'react';
import MainMenu from './main-menu';
import { ScrollArea } from '@/components/base/ui/scroll-area';

const MainSidebar = () => {
	const { isOpen, toggle } = useSidebar();
	const [status, setStatus] = useState(false);
	const handleToggle = () => {
		setStatus(true);
		toggle();
		setTimeout(() => setStatus(false), 500);
	};
	return (
		<>
			<nav
				className={cn(
					`relative pt-12 hidden h-dvh border-r lg:block`,
					status && 'duration-500',
					isOpen ? 'w-56' : 'w-[120px]'
				)}
			>
				<Button
					variant={'ghost'}
					size={'sm'}
					onClick={handleToggle}
					className={cn(
						'absolute -right-4 top-14 z-50 rounded-full p-0 bg-background/95 w-8 h-8 hover:text-primary'
					)}
				>
					{isOpen ? <ArrowLeftCircle /> : <ArrowRightCircle />}
				</Button>
				<div className='space-y-2 p-2'>
					<ScrollArea className='sidebar-scrollable py-2 ps-2 pe-4'>
						<MainMenu isOpen={isOpen} setOpen={toggle} />
					</ScrollArea>
				</div>
			</nav>
		</>
	);
};

export default MainSidebar;
