import { FormField } from '@/components/base/ui/form';
import { Cardbox } from '@/components/common';
import { FormCombobox, FormInput } from '@/components/common/form';
import FromDateRangePicker from '@/components/common/form/form-date-range-picker';
import FromDateTimeRangePicker from '@/components/common/form/form-datetime-range-picker';
import FormInputNumber from '@/components/common/form/form-input-number';
import FormTextarea from '@/components/common/form/form-text-area';
import { ContractShipmentFormModel } from '@/contexts/models/contract-management/contract/ContractShipmentModel';
import { useCatalogGoods } from '@/hooks/catalog/use-catalog-goods';
import { useCatalogTransportationCompany } from '@/hooks/catalog/use-transportation-company';
import ContractDispatchByGoodsCombobox from '@/modules/contract-dispatch/form/contract-dispatch-by-goods-combobox';
import { UseFormReturn } from 'react-hook-form';

interface IContractShipmentDetailFormProps {
	contract_dispatch_id: number;
	document_type: string;
	goods_id: number;
	isSaving: boolean;
	formData: ContractShipmentFormModel;
	form: UseFormReturn<ContractShipmentFormModel, any, undefined>;
}
export const ContractShipmentDetailForm = ({
	document_type,
	contract_dispatch_id,
	goods_id,
	isSaving,
	formData,
	form,
}: IContractShipmentDetailFormProps) => {
	const { reset, control, setValue, formState } = form;
	const { all_transportation_companys } = useCatalogTransportationCompany();

	console.log(formData);

	return (
		<>
			<div className='sm:grid lg:grid-cols-2 lg:space-x-2'>
				<Cardbox title='1. Thông tin'>
					{/* <FormField
						control={form.control}
						name='contract_dispatch_id'
						render={({ field }) => (
							<ContractCombobox
								isRequired={true}
								name={field.name}
								value={field.value}
								onValueChange={(value) => field.onChange(value)}
								isDisabled={isSaving}
								label='Hợp đồng bán hàng'
								classForm='col-span-2'
								document_type={eDocumentType.HOP_DONG_BAN_HANG}
							/>
						)}
					/> */}
					<FormField
						control={form.control}
						name={'start_date'}
						render={({ field }) => (
							<FromDateTimeRangePicker
								isRequired={true}
								name_from={field.name}
								name_to={'end_date'}
								value_from={field.value}
								value_to={form.getValues('end_date')}
								onValueFromChange={field.onChange}
								onValueToChange={(value) => {
									form.setValue('end_date', value || '');
								}}
								isDisabled={isSaving}
								label='Thời gian giao hàng'
							/>
						)}
					/>
					<FormField
						control={form.control}
						name='start_address'
						render={({ field }) => (
							<FormTextarea
								isRequired={true}
								name={field.name}
								value={field.value}
								onValueChange={field.onChange}
								isDisabled={isSaving}
								label='Địa chỉ nhận hàng'
							/>
						)}
					/>
					<FormField
						control={form.control}
						name={'ref_contract_dispatch_id'}
						render={({ field }) => (
							<ContractDispatchByGoodsCombobox
								isRequired={true}
								goods_id={goods_id}
								name={field.name}
								value={field.value}
								onValueChange={(value) => field.onChange(Number(value))}
								onDataChange={(value) => {
									form.setValue('end_address', value?.address || '');
								}}
								isDisabled={isSaving}
								label='Liên kết điều vận bán hàng'
								document_type={document_type}
							/>
						)}
					/>
					<FormField
						control={form.control}
						name='end_address'
						render={({ field }) => (
							<FormTextarea
								isRequired={true}
								name={field.name}
								value={field.value}
								onValueChange={field.onChange}
								isDisabled={isSaving}
								label='Địa chỉ giao hàng'
							/>
						)}
					/>
					<FormField
						control={form.control}
						name='transportation_company_id'
						render={({ field }) => (
							<FormCombobox
								isRequired={true}
								name={field.name}
								dataSource={all_transportation_companys}
								defaultValue={0}
								value={field.value}
								onValueChange={(value) =>
									field.onChange(parseInt(value || '0'))
								}
								isDisabled={isSaving}
								label='Chọn công ty vận chuyển'
								placeholder='Chọn thông tin...'
								displayExpr='name'
								width='w-full max-w-full'
								valueExpr='id'
							/>
						)}
					/>

					<div className='sm:grid lg:grid-cols-2 lg:space-x-2'>
						<FormField
							control={form.control}
							name={'vehicle_license_plate'}
							render={({ field }) => (
								<FormInput
									isRequired={true}
									name={field.name}
									value={field.value}
									onValueChange={field.onChange}
									isDisabled={isSaving}
									label='Biển số xe'
								/>
							)}
						/>
						<FormField
							control={form.control}
							name={'vehicle_capacity'}
							render={({ field }) => (
								<FormInputNumber
									isRequired={true}
									name={field.name}
									value={field.value}
									onValueChange={(value) => {
										field.onChange(value);
									}}
									isDisabled={isSaving}
									label='Trọng tải (KG)'
								/>
							)}
						/>
					</div>
					<div className='sm:grid lg:grid-cols-2 lg:space-x-2'>
						<FormField
							control={form.control}
							name={'driver_name'}
							render={({ field }) => (
								<FormInput
									isRequired={true}
									name={field.name}
									value={field.value}
									onValueChange={field.onChange}
									isDisabled={isSaving}
									label='Tài xế'
								/>
							)}
						/>
						<FormField
							control={form.control}
							name={'driver_phone_number'}
							render={({ field }) => (
								<FormInput
									isRequired={true}
									name={field.name}
									value={field.value}
									onValueChange={field.onChange}
									isDisabled={isSaving}
									label='Số điện thoại'
								/>
							)}
						/>
					</div>
					<div className='sm:grid lg:grid-cols-2 lg:space-x-2'>
						<FormField
							control={form.control}
							name={'road_cost'}
							render={({ field }) => (
								<FormInputNumber
									isRequired={true}
									name={field.name}
									value={field.value}
									onValueChange={(value) => {
										field.onChange(value);
									}}
									isDisabled={isSaving}
									label='Chi phí vận chuyển'
								/>
							)}
						/>
						<FormField
							control={form.control}
							name={'goods_quantity'}
							render={({ field }) => (
								<FormInputNumber
									isRequired={true}
									name={field.name}
									value={field.value}
									onValueChange={(value) => {
										field.onChange(value);
									}}
									isDisabled={isSaving}
									label='Số lượng hàng hóa (KG)'
								/>
							)}
						/>
					</div>
				</Cardbox>
				<div>
					<Cardbox title='2. Cân tại điểm nhận'>
						<FormField
							control={form.control}
							name='start_weighing_slip_file'
							render={({ field }) => (
								<FormInput
									isRequired={true}
									name={field.name}
									// value={field.value}
									type='file'
									onValueChange={field.onChange}
									onFilesChange={(files) => {
										
									}}
									isDisabled={isSaving}
									label='Phiếu cân tại điểm nhận'
								/>
							)}
						/>
						<FormField
							control={form.control}
							name={'start_total_weight'}
							render={({ field }) => (
								<FormInputNumber
									isRequired={true}
									name={field.name}
									value={field.value}
									onValueChange={(value) => {
										field.onChange(value);
									}}
									isDisabled={isSaving}
									label='Xe và hàng (KG)'
								/>
							)}
						/>
						<div className='sm:grid sm:grid-cols-2 sm:space-x-2'>
							<FormField
								control={form.control}
								name={'start_vehicle_weight'}
								render={({ field }) => (
									<FormInputNumber
										isRequired={true}
										name={field.name}
										value={field.value}
										onValueChange={(value) => {
											field.onChange(value);
										}}
										isDisabled={isSaving}
										label='Xe (KG)'
									/>
								)}
							/>
							<FormField
								control={form.control}
								name={'start_goods_weight'}
								render={({ field }) => (
									<FormInputNumber
										isRequired={true}
										name={field.name}
										value={field.value}
										onValueChange={(value) => {
											field.onChange(value);
										}}
										isDisabled={isSaving}
										label='Hàng hóa (KG)'
									/>
								)}
							/>
						</div>
					</Cardbox>
					<Cardbox title='3. Cân tại điểm giao'>
						<FormField
							control={form.control}
							name='end_weighing_slip_file'
							render={({ field }) => (
								<FormInput
									isRequired={true}
									name={field.name}
									// value={field.value}
									type='file'
									onValueChange={field.onChange}
									onFilesChange={(files) => {
										
									}}
									isDisabled={isSaving}
									label='Phiếu cân tại điểm nhận'
								/>
							)}
						/>
						<FormField
							control={form.control}
							name={'end_total_weight'}
							render={({ field }) => (
								<FormInputNumber
									isRequired={true}
									name={field.name}
									value={field.value}
									onValueChange={(value) => {
										field.onChange(value);
									}}
									isDisabled={isSaving}
									label='Xe và hàng (KG)'
								/>
							)}
						/>
						<div className='sm:grid sm:grid-cols-2 sm:space-x-2'>
							<FormField
								control={form.control}
								name={'end_vehicle_weight'}
								render={({ field }) => (
									<FormInputNumber
										isRequired={true}
										name={field.name}
										value={field.value}
										onValueChange={(value) => {
											field.onChange(value);
										}}
										isDisabled={isSaving}
										label='Xe (KG)'
									/>
								)}
							/>
							<FormField
								control={form.control}
								name={'end_goods_weight'}
								render={({ field }) => (
									<FormInputNumber
										isRequired={true}
										name={field.name}
										value={field.value}
										onValueChange={(value) => {
											field.onChange(value);
										}}
										isDisabled={isSaving}
										label='Hàng hóa (KG)'
									/>
								)}
							/>
						</div>
					</Cardbox>
				</div>
			</div>
		</>
	);
};
