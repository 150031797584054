import { FormField } from '@/components/base/ui/form';
import { Cardbox } from '@/components/common';
import { FormInput } from '@/components/common/form';
import FromDatePicker from '@/components/common/form/form-date-picker';
import {
	ContractInfoFormModel,
	eDocumentType,
} from '@/contexts/models/contract-management/contract';
import { convertNumberToWords } from '@/utils/convert-number-to-words';
import { formatDate } from 'date-fns';
import { useEffect } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import CompanyInfoCard from '../card/company-info-card';
import { CustomerInfoCard } from '../card/customer-info-card';
import { GoodsInfoCard } from '../card/goods-info-card';
import { PaymentInfoCard } from '../card/payment-info-card';
import { SupplierInfoCard } from '../card/supplier-info-card';
import FromDateTimePicker from '@/components/common/form/form-datetime-picker';

interface IContractDetailFormProps {
	isSaving: boolean;
	formData: ContractInfoFormModel;
	form: UseFormReturn<ContractInfoFormModel, any, undefined>;
	document_type: string;
}
export const ContractDetailForm = ({
	isSaving,
	formData,
	form,
	document_type,
}: IContractDetailFormProps) => {
	const { reset, control, setValue } = form;

	const watchChangeContractCode = useWatch({
		control,
		name: 'contract_code',
		defaultValue: '',
	});

	const watchChangeGoodsAmount = useWatch({
		control,
		name: 'goods_amount',
		defaultValue: 0,
	});

	useEffect(() => {
		const slug = convertNumberToWords(watchChangeGoodsAmount);
		setValue('goods_amount_words', slug);
	}, [watchChangeGoodsAmount, setValue]);

	useEffect(() => {
		setValue('contract_name', watchChangeContractCode);
	}, [watchChangeContractCode, setValue]);

	return (
		<>
			<Cardbox
				title='Thông tin'
				className='sm:grid md:grid-cols-* lg:grid-cols-4'
			>
				<FormField
					control={form.control}
					name='contract_date'
					render={({ field }) => (
						<FromDateTimePicker
							isRequired={true}
							name={field.name}
							value={field.value}
							onValueChange={field.onChange}
							isDisabled={isSaving}
							label='Ngày lập hợp đồng'
						/>
					)}
				/>
				<FormField
					control={form.control}
					name='contract_code'
					render={({ field }) => (
						<FormInput
							isRequired={true}
							name={field.name}
							value={field.value}
							onValueChange={field.onChange}
							isDisabled={isSaving}
							label='Số hợp đồng'
							placeholder={`123.${formatDate(formData.contract_date, 'ddMMyy')}/HĐMB/RC-TA`}
							// useInputMask={{
							// 	mask: `___.${formatDate(formData.contract_date, 'ddMMyy')}/HĐMB/RC-TA`,
							// 	replacement: { _: /\d/ },
							// }}
						/>
					)}
				/>
				<FormField
					control={form.control}
					name='contract_name'
					render={({ field }) => (
						<FormInput
							isRequired={true}
							name={field.name}
							value={field.value}
							onValueChange={field.onChange}
							isDisabled={isSaving}
							label='Tên hợp đồng'
							classForm='col-span-2'
							placeholder='HỢP ĐỒNG MUA BÁN HÀNG HOÁ'
						/>
					)}
				/>
			</Cardbox>
			<div className='sm:grid lg:grid-cols-2 space-x-2'>
				{document_type === eDocumentType.HOP_DONG_BAN_HANG && (
					<>
						<CompanyInfoCard
							form={form}
							isLoading={isSaving}
							infoType='SELLER'
						/>
						<CustomerInfoCard
							form={form}
							isLoading={isSaving}
							infoType='BUYER'
						/>
					</>
				)}
				{document_type === eDocumentType.HOP_DONG_MUA_HANG && (
					<>
						<SupplierInfoCard
							form={form}
							isLoading={isSaving}
							infoType='SELLER'
						/>
						<CompanyInfoCard
							infoType='BUYER'
							form={form}
							isLoading={isSaving}
						/>
					</>
				)}
			</div>
			<div className='sm:grid lg:grid-cols-2 space-x-2'>
				<GoodsInfoCard form={form} isLoading={isSaving} contract={formData} />
				<PaymentInfoCard form={form} isLoading={isSaving} contract={formData} />
			</div>
		</>
	);
};
