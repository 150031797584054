import { combineReducers, combineSlices } from '@reduxjs/toolkit';

import bankSlice from './bank-slice';
import customerSlice from './customer-slice';
import goodsSlice from './goods-slice';
import supplierSlice from './supplier-slice';
import transportationCompanySlice from './transportation-company-slice';
import transportationFeeSlice from './transportation-fee-slice';
import warehouseSlice from './warehouse-slice';
import companyInfoSlice from './company-info-slice';
import partnerSlice from './partner-slice';

export const catalogReducer = combineReducers({
	TransportationCompany: transportationCompanySlice,
	TransportationFee: transportationFeeSlice,
	Supplier: supplierSlice,
	Bank: bankSlice,
	Goods: goodsSlice,
	Customer: customerSlice,
	Warehouse: warehouseSlice,
	CompanyInfo: companyInfoSlice,
	Partner: partnerSlice,
});
