import {
	Avatar,
	AvatarFallback,
	AvatarImage,
} from '@/components/base/ui/avatar';
import { Button } from '@/components/base/ui/button';
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuGroup,
	DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuSeparator,
	DropdownMenuShortcut,
	DropdownMenuTrigger,
} from '@/components/base/ui/dropdown-menu';
import { useAuth } from '@/hooks/use-auth';
import { CircleUser, SquareUser } from 'lucide-react';
const UserNav = () => {
	const { account, signOutAction } = useAuth();
	return (
		<>
			<DropdownMenu>
				<DropdownMenuTrigger asChild>
					<Button
						variant='ghost'
						size='icon'
						className='relative h-8 w-8 rounded-full'
					>
						<Avatar className='h-8 w-8'>
							<AvatarImage
								src={account?.avatar ?? ''}
								alt={account?.full_name ?? ''}
							/>
							<AvatarFallback>
								<SquareUser className='h-5 w-5' />
							</AvatarFallback>
						</Avatar>
					</Button>
				</DropdownMenuTrigger>
				<DropdownMenuContent className='w-56' align='end' forceMount>
					<DropdownMenuLabel className='font-normal'>
						<div className='flex flex-col space-y-1'>
							<p className='text-sm font-medium leading-none'>
								{account?.username || account?.full_name}
							</p>
							<p className='text-xs leading-none text-muted-foreground'>
								{account?.email}
							</p>
						</div>
					</DropdownMenuLabel>
					<DropdownMenuSeparator />
					<DropdownMenuGroup>
						<DropdownMenuItem>
							Profile
							<DropdownMenuShortcut>⇧⌘P</DropdownMenuShortcut>
						</DropdownMenuItem>
						<DropdownMenuItem>
							Billing
							<DropdownMenuShortcut>⌘B</DropdownMenuShortcut>
						</DropdownMenuItem>
						<DropdownMenuItem>
							Settings
							<DropdownMenuShortcut>⌘S</DropdownMenuShortcut>
						</DropdownMenuItem>
						<DropdownMenuItem>New Team</DropdownMenuItem>
					</DropdownMenuGroup>
					<DropdownMenuSeparator />
					<DropdownMenuItem onClick={() => signOutAction()}>
						Đăng xuất
						<DropdownMenuShortcut>⇧⌘Q</DropdownMenuShortcut>
					</DropdownMenuItem>
				</DropdownMenuContent>
			</DropdownMenu>
		</>
	);
};

export default UserNav;
