import { useLazyCompanyInfoGetAllQuery } from '@/contexts/api/contract-management/catalog/company-info-api';
import { useAppSelector } from '@/contexts/store';
import { useEffect } from 'react';

export const useCatalogCompanyInfo = () => {
	const { list } = useAppSelector((state) => state.Catalog.CompanyInfo);
	const [trigger] = useLazyCompanyInfoGetAllQuery();

	useEffect(() => {
		if (list.length === 0) {
			trigger();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return { all_catalog_company_infos: list};
};
