import { createSlice } from '@reduxjs/toolkit';
import { TransportationCompany } from '@/contexts/models/contract-management/catalog';
import { TransportationCompanyApi } from '@/contexts/api/contract-management/catalog/transportation-company-api';

type TransportationCompanyState = {
	list: TransportationCompany[];
	detail?: TransportationCompany;
	is_open_dialog: boolean;
	selected_ids: number[];
	is_open_confirm: boolean;
};

const initialState = {
	list: [],
	detail: undefined,
	is_open_confirm: false,
	is_open_dialog: false,
	selected_ids: [],
} as TransportationCompanyState;

const entityEndpointsApi = TransportationCompanyApi.endpoints;

const TransportationCompanySlice = createSlice({
	name: 'TransportationCompanySlice',
	initialState: initialState,
	reducers: {
		TransportationCompanyShowDetail: (state, { payload }) => {
			state.is_open_dialog = payload.is_open_dialog;
			state.detail = payload.detail;
		},
		TransportationCompanyShowConfirm: (state, { payload }) => {
			state.is_open_confirm = payload;
		},
		TransportationCompanySelectedIdsChange: (state, { payload }) => {
			state.selected_ids = payload;
		},
	},
	extraReducers: (builder) => {
		builder.addMatcher(
			entityEndpointsApi.transportationCompanyGetAll.matchFulfilled,
			(state, { payload }) => {
				state.list = payload || [];
			}
		);
		builder.addMatcher(
			entityEndpointsApi.transportationCompanySave.matchFulfilled,
			(state) => {
				state.is_open_dialog = false;
				state.detail = undefined;
			}
		);
		builder.addMatcher(
			entityEndpointsApi.transportationCompanyDelete.matchFulfilled,
			(state) => {
				state.is_open_confirm = false;
				state.selected_ids = [];
			}
		);
		builder.addMatcher(
			entityEndpointsApi.transportationCompanyDeleteMultiple.matchFulfilled,
			(state) => {
				state.is_open_confirm = false;
				state.selected_ids = [];
			}
		);
	},
});

export const transportationcompanyActions = TransportationCompanySlice.actions;
export default TransportationCompanySlice.reducer;
