import { zod, zod_valid } from '@/lib/zod-valid';

export type Partner = {
	id: number;
	name: string;
	code: string;
	is_active: boolean;
	bank_info: string;
	address: string;
	phone_number: string;
	tin: string;
	representative: string;
	representative_title: string;
};

export const PartnerFormValid = zod
	.object({
		id: zod_valid.number().optional(),
		name: zod_valid.string().min(1, { message: 'Vui lòng nhập tên đối tác' }),
		code: zod_valid.string().min(1, { message: 'Vui lòng nhập mã đối tác' }),
		is_active: zod_valid.bool(),
		bank_info: zod_valid.string(),
		address: zod_valid.string(),
		phone_number: zod_valid.string(),
		tin: zod_valid.string(),
		representative: zod_valid.string(),
		representative_title: zod_valid.string(),
	})
	.required();

export type PartnerFormModel = zod.infer<typeof PartnerFormValid>;

export const PartnerFormDefault: PartnerFormModel = {
	id: 0,
	name: '',
	code: '',
	is_active: true,
	bank_info: '',
	address: '',
	phone_number: '',
	tin: '',
	representative: '',
	representative_title: '',
};
