import { cn } from '@/lib/utils';
import {
	FormControl,
	FormDescription,
	FormItem,
	FormLabel,
	FormMessage,
} from '../../base/ui/form';
import { Textarea } from '../../base/ui/textarea';

interface FormTextareaProps
	extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
	onValueChange: (value: string) => void;
	name: string;
	label?: string;
	isDisabled?: boolean;
	placeholder?: string;
	isRequired?: boolean;
	button?: React.ReactNode;
	helpBlock?: string;
	classForm?: string;
}

const FormTextarea = ({
	name,
	value,
	onValueChange,
	label,
	isDisabled,
	placeholder,
	isRequired,
	button,
	helpBlock,
	classForm,
	...rest
}: FormTextareaProps) => {
	return (
		<>
			<FormItem className={cn('flex flex-col pb-2', classForm)}>
				{label && (
					<FormLabel htmlFor={name}>
						{label} {isRequired ? <code className='text-red-400'>*</code> : ''}
					</FormLabel>
				)}
				<FormControl>
					<div className='flex w-full'>
						<Textarea
							id={name}
							name={name}
							value={value}
							onChange={(event) => onValueChange(event.target.value || '')}
							placeholder={placeholder}
							disabled={isDisabled || false}
							{...rest}
						/>
						{button && <>{button}</>}
					</div>
				</FormControl>
				{helpBlock && (
					<FormDescription
						className='text-xs italic'
						dangerouslySetInnerHTML={{ __html: helpBlock }}
					></FormDescription>
				)}
				<FormMessage />
			</FormItem>
		</>
	);
};

export default FormTextarea;
