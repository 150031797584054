import { Badge } from '@/components/base/ui/badge';
import { eDocumentTypeOptions } from '@/contexts/models/contract-management/contract';
import { cn } from '@/lib/utils';
import { DotFilledIcon } from '@radix-ui/react-icons';
import { FileTypeIcon } from 'lucide-react';
import { useMemo } from 'react';

interface IContractDocumentTypeBadgeProps {
	value: string;
}

export const ContractDocumentTypeBadge = ({
	value,
}: IContractDocumentTypeBadgeProps) => {
	const data = useMemo(() => {
		return (
			eDocumentTypeOptions?.find((item) => item.id === value) || {
				id: '000',
				name: 'Chưa xác định',
				class: 'text-red-500',
			}
		);
	}, [value]);

	return (
		<>
			<Badge variant='outline' className={cn('inline-flex me-1', `${data.class}`)}>
				<FileTypeIcon className={cn('w-3 h-3')} />{' '}
				<span className='text-[11px]'>{data.name}</span>
			</Badge>
		</>
	);
};
