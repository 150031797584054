import { NotifyHelper } from '@/utils/notify-helper';
import { apiSlice } from '../../api-slice';
import { BaseResponse } from '@/contexts/base/base-response';
import {
	CompanyInfo,
	CompanyInfoFormModel,
} from '@/contexts/models/contract-management/catalog/CompanyInfo';

const enpointApi = '/catalog/company-info';
const entityName = 'Thông tin công ty';
const entityCacheTag = 'CATALOG_COMPANY_INFO';

export const CompanyInfoApi = apiSlice
	.enhanceEndpoints({ addTagTypes: [entityCacheTag] })
	.injectEndpoints({
		endpoints: (builder) => ({
			companyInfoGetAll: builder.query<CompanyInfo[], void>({
				query: () => ({ url: enpointApi, method: 'GET' }),
				transformResponse: (response) => response.result as CompanyInfo[],
				providesTags: (result) =>
					result
						? [
								...result.map(({ id }) => ({
									type: `${entityCacheTag}` as const,
									id,
								})),
								{ type: entityCacheTag, id: 'LIST' },
							]
						: [{ type: entityCacheTag, id: 'LIST' }],
			}),
			companyInfoGetDetail: builder.query({
				query: (id: number) => ({ url: `${enpointApi}/${id}`, method: 'GET' }),
				transformResponse: (response) => response.result as CompanyInfo,
				providesTags: (result, error, id) => [{ type: entityCacheTag, id }],
			}),
			companyInfoSave: builder.mutation({
				query: (entity: CompanyInfoFormModel) => ({
					url: enpointApi,
					method: entity.id > 0 ? 'PUT' : 'POST',
					data: entity,
				}),
				transformResponse: (response) => response,
				async onQueryStarted(_args, { queryFulfilled }) {
					try {
						const { data } = await queryFulfilled;
						if (data.is_success)
							NotifyHelper.Success(
								data.message || `Lưu ${entityName} thành công`
							);
						else
							NotifyHelper.Error(
								data.message || `Lưu ${entityName} không thành công`
							);
					} catch (ex: any) {
						NotifyHelper.Error(ex?.error?.data || `Lưu ${entityName} có lỗi`);
					}
				},
				invalidatesTags: (result, error, arg) => [
					{ type: entityCacheTag, id: arg.id },
					{ type: entityCacheTag, id: 'LIST' },
				],
			}),
			companyInfoDelete: builder.mutation({
				query: (id: number) => ({
					url: `${enpointApi}/${id}`,
					method: 'DELETE',
				}),
				transformResponse: (response) => response,
				async onQueryStarted(_args, { queryFulfilled }) {
					try {
						const { data } = await queryFulfilled;
						if (data.is_success)
							NotifyHelper.Success(
								data.message || `Xóa ${entityName} thành công`
							);
						else
							NotifyHelper.Error(data.message || `Xóa ${entityName} thất bại`);
					} catch (ex: any) {
						NotifyHelper.Error(ex?.error?.data || `Xóa ${entityName} có lỗi`);
					}
				},
				invalidatesTags: [{ type: entityCacheTag, id: 'LIST' }],
			}),
			companyInfoDeleteMultiple: builder.mutation({
				query: (ids: number[]) => ({
					url: `${enpointApi}/deletes`,
					method: 'DELETE',
					data: { ids },
				}),
				transformResponse: (response) => response as BaseResponse,
				async onQueryStarted(_args, { queryFulfilled }) {
					try {
						const { data } = await queryFulfilled;
						if (data.is_success)
							NotifyHelper.Success(
								data.message || `Xóa ${entityName} thành công`
							);
						else
							NotifyHelper.Error(data.message || `Xóa ${entityName} thất bại`);
					} catch (ex: any) {
						NotifyHelper.Error(ex?.error?.data || `Xóa ${entityName} có lỗi`);
					}
				},
				invalidatesTags: [{ type: entityCacheTag, id: 'LIST' }],
			}),
		}),
	});

export const {
	useCompanyInfoDeleteMultipleMutation,
	useCompanyInfoDeleteMutation,
	useCompanyInfoGetAllQuery,
	useCompanyInfoSaveMutation,
	useLazyCompanyInfoGetAllQuery,
	useCompanyInfoGetDetailQuery,
	useLazyCompanyInfoGetDetailQuery,
} = CompanyInfoApi;
