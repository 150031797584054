import { createSlice } from '@reduxjs/toolkit';
import { Customer } from '@/contexts/models/contract-management/catalog/Customer';
import { CustomerApi } from '@/contexts/api/contract-management/catalog/customer-api';

type CustomerState = {
	list: Customer[];
	detail?: Customer;
	is_open_dialog: boolean;
	selected_ids: number[];
	is_open_confirm: boolean;
};

const initialState = {
	list: [],
	detail: undefined,
	is_open_confirm: false,
	is_open_dialog: false,
	selected_ids: [],
} as CustomerState;

const entityEndpointsApi = CustomerApi.endpoints;

const CustomerSlice = createSlice({
	name: 'CustomerSlice',
	initialState: initialState,
	reducers: {
		CustomerShowDetail: (state, { payload }) => {
			state.is_open_dialog = payload.is_open_dialog;
			state.detail = payload.detail;
		},
		CustomerShowConfirm: (state, { payload }) => {
			state.is_open_confirm = payload;
		},
		CustomerSelectedIdsChange: (state, { payload }) => {
			state.selected_ids = payload;
		},
	},
	extraReducers: (builder) => {
		builder.addMatcher(
			entityEndpointsApi.customerGetAll.matchFulfilled,
			(state, { payload }) => {
				state.list = payload || [];
			}
		);
		builder.addMatcher(
			entityEndpointsApi.customerSave.matchFulfilled,
			(state) => {
				state.is_open_dialog = false;
				state.detail = undefined;
			}
		);
		builder.addMatcher(
			entityEndpointsApi.customerDelete.matchFulfilled,
			(state) => {
				state.is_open_confirm = false;
				state.selected_ids = [];
			}
		);
		builder.addMatcher(
			entityEndpointsApi.customerDeleteMultiple.matchFulfilled,
			(state) => {
				state.is_open_confirm = false;
				state.selected_ids = [];
			}
		);
	},
});

export const customerActions = CustomerSlice.actions;
export default CustomerSlice.reducer;
