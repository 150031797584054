import {
	AccountingRecord,
	AccountingRecordFormModel,
} from '@/contexts/models/contract-management/contract/AccountingRecord';
import { createQueryString } from '@/utils/create-query-string';
import { apiSlice } from '../../api-slice';
import { AccountingRecordTemplate } from '@/contexts/models/contract-management/contract/AccountingRecordTemplate';

const enpointApi = '/accounting-record';
const entityCacheTag = 'ACCOUNTING_RECORD';

export const AccountingRecordApi = apiSlice
	.enhanceEndpoints({ addTagTypes: [entityCacheTag] })
	.injectEndpoints({
		endpoints: (builder) => ({
			accountingRecordGetAll: builder.query({
				query: (document_code: string) => ({
					url: `${enpointApi}${createQueryString({ document_code })}`,
					method: 'GET',
				}),
				transformResponse: (response) => response.result as AccountingRecord[],
				providesTags: (result) =>
					result
						? [
								...result.map(({ id }) => ({
									type: `${entityCacheTag}` as const,
									id,
								})),
								{ type: entityCacheTag, id: 'LIST' },
							]
						: [{ type: entityCacheTag, id: 'LIST' }],
			}),
			accountingRecordGetAllTemplate: builder.query<AccountingRecordTemplate[], void>({
				query: () => ({
					url: `${enpointApi}/templates`,
					method: 'GET',
				}),
				transformResponse: (response) => response.result as AccountingRecordTemplate[],
				providesTags: (result) =>
					result
						? [
								...result.map(({ id }) => ({
									type: `${entityCacheTag}` as const,
									id,
								})),
								{ type: entityCacheTag, id: 'TEMPLATE' },
							]
						: [{ type: entityCacheTag, id: 'TEMPLATE' }],
			}),
			accountingRecordGetDetail: builder.query({
				query: (id: number) => ({ url: `${enpointApi}/${id}`, method: 'GET' }),
				transformResponse: (response) => response.result as AccountingRecord,
				providesTags: (result, error, id) => [{ type: entityCacheTag, id }],
			}),
			accountingRecordSave: builder.mutation({
				query: (entity: AccountingRecordFormModel) => ({
					url: enpointApi,
					method: entity.id > 0 ? 'PUT' : 'POST',
					data: entity,
				}),
				transformResponse: (response) => response,
				invalidatesTags: (result, error, arg) => [
					{ type: entityCacheTag, id: arg.id },
					{ type: entityCacheTag, id: 'LIST' },
				],
			}),
			accountingRecordConfirm: builder.mutation({
				query: (id: number[]) => ({
					url: `${enpointApi}/confirm/${id}`,
					method: 'PUT',
				}),
				transformResponse: (response) => response,
				invalidatesTags: [{ type: entityCacheTag, id: 'LIST' }],
			}),
			accountingRecordDelete: builder.mutation({
				query: (id: number) => ({
					url: `${enpointApi}/${id}`,
					method: 'DELETE',
				}),
				transformResponse: (response) => response,
				invalidatesTags: [{ type: entityCacheTag, id: 'LIST' }],
			}),
			accountingRecordDeleteMultiple: builder.mutation({
				query: (ids: number[]) => ({
					url: `${enpointApi}/deletes`,
					method: 'DELETE',
					data: { ids },
				}),
				transformResponse: (response) => response,
				invalidatesTags: [{ type: entityCacheTag, id: 'LIST' }],
			}),
		}),
	});

export const {
	useLazyAccountingRecordGetAllQuery,
	useAccountingRecordGetAllQuery,
	useAccountingRecordDeleteMultipleMutation,
	useAccountingRecordDeleteMutation,
	useAccountingRecordSaveMutation,
	useLazyAccountingRecordGetDetailQuery,
	useAccountingRecordGetDetailQuery,
	useAccountingRecordConfirmMutation,
	useAccountingRecordGetAllTemplateQuery,
	useLazyAccountingRecordGetAllTemplateQuery
} = AccountingRecordApi;
