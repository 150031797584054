import { useLazyCustomerGetAllQuery } from '@/contexts/api/contract-management/catalog/customer-api';
import { useAppSelector } from '@/contexts/store';
import { useEffect } from 'react';

export const useCatalogCustomer = () => {
	const { list } = useAppSelector((state) => state.Catalog.Customer);
	const [trigger] = useLazyCustomerGetAllQuery();

	useEffect(() => {
		if (list.length === 0) {
			trigger();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return { all_catalog_customers: list };
};
