import { FormSheetDialog } from '@/components/common/form/form-sheet-dialog';
import { useContractShipmentSaveMutation } from '@/contexts/api/contract-management/contract/contract-shipment-api';
import {
	ContractShipmentFormModel,
	ContractShipmentValid,
} from '@/contexts/models/contract-management/contract/ContractShipmentModel';
import { zodResolver } from '@hookform/resolvers/zod';
import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { ContractShipmentDetailForm } from './form/contract-shipment-detail-form';

interface ContractShipmentDetailSheetProps {
	title: string;
	isOpen: boolean;
	onOpenChange: (isOpen: boolean) => void;
	detail: ContractShipmentFormModel;
	contract_dispatch_id: number;
	goods_id: number;
	document_type: string;
}

export const ContractShipmentDetailSheet = ({
	title,
	isOpen,
	onOpenChange,
	detail,
	contract_dispatch_id,
	goods_id,
	document_type,
}: ContractShipmentDetailSheetProps) => {
	const [saveAction, { isLoading: isSaving, isSuccess: isSaved }] =
		useContractShipmentSaveMutation();

	const defaultValues = detail;
	const form = useForm<ContractShipmentFormModel>({
		resolver: zodResolver(ContractShipmentValid),
		defaultValues,
	});

	const {
		reset,
		control,
		setValue,
		formState: { isSubmitting },
	} = form;

	const isLoading = isSaving || isSubmitting;

	const onSubmit = useCallback(
		async (formState: ContractShipmentFormModel) => {
			const formSubmit = {
				...formState,
				contract_dispatch_id,
			};
			await saveAction(formSubmit).unwrap();
		},
		[saveAction, contract_dispatch_id]
	);

	useEffect(() => {
		if (isSaved) {
			onOpenChange(false);
		}
	}, [isSaved, onOpenChange]);

	return (
		<>
			<FormSheetDialog
				sheetTitle={`${detail.id > 0 ? 'Chỉnh sửa' : 'Tạo mới'} ${title}`}
				isOpen={isOpen}
				onOpenChange={onOpenChange}
				form={form}
				onSubmit={onSubmit}
				isDisabled={isLoading}
				hasScroll={true}
				width='w-2/3'
			>
				<ContractShipmentDetailForm
					isSaving={isLoading}
					formData={detail}
					form={form}
					contract_dispatch_id={detail.contract_dispatch_id}
					goods_id={goods_id}
					document_type={document_type}
				/>
			</FormSheetDialog>
		</>
	);
};
