import { createUUID } from '@/lib/create-uuid';
import { Label } from '../base/ui/label';
import { Switch } from '../base/ui/switch';

interface SwitchboxProps {
	label: string;
	name: string;
	value: boolean;
	onValueChange: (value: boolean) => void;
	isDisabled?: boolean;
}

const Switchbox = ({
	name,
	value,
	label,
	onValueChange,
	isDisabled,
}: SwitchboxProps) => {
	const html_id = createUUID();
	return (
		<div className='flex items-center space-x-2'>
			<Switch
				id={html_id}
				name={name}
				checked={value}
				onCheckedChange={onValueChange}
				disabled={isDisabled}
			/>
			<Label htmlFor={html_id}>{label}</Label>
		</div>
	);
};

export default Switchbox;
