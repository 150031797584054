export const listContractStatus = [
	{ id: 'NEW', name: 'Hóa đơn mới', class: '' },
	{ id: 'PROCESSING', name: 'Đang xử lý', class: 'text-blue-600' },
	// { id: 'SHIPPED', name: 'Vận chuyển', class: 'text-green-600' },
	// { id: 'DELIVERED', name: 'Giao hàng', class: 'purple-200' },
	// { id: 'ON_HOLD', name: 'Tạm dừng', class: 'text-orange-600' },
	// { id: 'CANCELLED', name: 'Hủy', class: 'text-red-600' },
	{ id: 'COMPLETED', name: 'Hoàn thành', class: 'text-green-600' },
	// { id: 'RETURNED', name: 'Trả lại', class: 'bg-red-200'},
];

export const useContractStatus = () => {
	return { all_contract_status: listContractStatus };
};
