/* eslint-disable @typescript-eslint/no-use-before-define */
import { chunk, reverse } from 'lodash';

export const convertNumberToWords = (number: number): string => {
	// trả về chuỗi trống cho đầu vào không hợp lệ
	if (isValidInteger(number)) {
		return '';
	}
	// xử lý trường hợp đặc biệt số không và -số không
	if (Object.is(number, -0)) return 'Âm không';
	if (number === 0) return 'Không';

	const words = toWords(Math.abs(number));

	const numberWords = number < 0 ? `âm ${words}` : words;
	return camelCase(numberWords);
};

// Function to convert into camel Case
function camelCase(str: string) {
	// Using replace method with regEx
	return str
		.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
			return index == 0 ? word.toUpperCase() : word.toLowerCase();
		})
		.replace(/\s+/g, ' ');
}

export const clampToSafeIntegerString = (numberStr: string): string => {
	const number = Number.parseInt(numberStr, 10);
	if (number > Number.MAX_SAFE_INTEGER) {
		return `${Number.MAX_SAFE_INTEGER}`;
	}
	if (number < Number.MIN_SAFE_INTEGER) {
		return `${Number.MIN_SAFE_INTEGER}`;
	}
	return numberStr;
};

const isValidInteger = (int: number): boolean =>
	!Number.isInteger(int) ||
	int > Number.MAX_SAFE_INTEGER ||
	int < Number.MIN_SAFE_INTEGER;

const toWords = (num: number) =>
	reverseAndChunkDigitsByThree(num)
		.map(mapToWordGroup)
		.map(addMagnitude)
		.filter((str) => str.length)
		.reverse()
		.join(' ');

// Chuyển đổi 3 (hoặc ít hơn) chữ số thành chuỗi nhóm từ
// một nhóm từ chỉ đơn giản là mô tả số dưới 1000
// có thể được sử dụng với một lực lượng (nghìn, triệu, v.v..)
const mapToWordGroup = (
	[ones = 0, tens = 0, huns = 0]: number[],
	i: number,
	c: number[][]
): string => {
	const hundredsPlace = huns === 0 ? '' : zeroToNineteenNames[huns] + ' trăm ';
	const tensPlace =
		ones === 0 ? tensNames[tens] : tensNames[tens] && tensNames[tens] + ' ';
	const onesPlace =
		zeroToNineteenNames[parseInt(`${tens}${ones}`, 10)] ||
		zeroToNineteenNames[ones];
	// Phần này phức tạp một chút và cần thiết để thêm từ and giữa
	// hàng trăm và hàng chục/hàng đơn vị (ví dụ: Chín trăm và sáu)
	// chỉ đến nhóm từ cuối cùng (hoặc đầu tiên được truyền vào hàm này)
	const addAnAnd =
		(hundredsPlace || c.length > 1) && (tensPlace || onesPlace) && i === 0
			? 'lẻ '
			: '';

	return [hundredsPlace, addAnAnd, tensPlace, onesPlace].join('').trim();
};

const addMagnitude = (group: string, i: number) => {
	const magnitude = magnitudeName[i];
	return magnitude === '' ? group : group ? `${group} ${magnitude}` : '';
};

const zeroToNineteenNames = [
	'',
	'một',
	'hai',
	'ba',
	'bốn',
	'năm',
	'sáu',
	'bảy',
	'tám',
	'chín',
	'mười',
	'mười một',
	'mười hai',
	'mười ba',
	'mười bốn',
	'mười lăm',
	'mười sáu',
	'mười bảy',
	'mười tám',
	'mười chín',
];
const tensNames = [
	'',
	'',
	'hai mươi',
	'ba mươi',
	'bốn mươi',
	'năm mươi',
	'sáu mươi',
	'bảy mươi',
	'tám mươi',
	'chín mươi',
];
const magnitudeName = ['', 'nghìn', 'triệu', 'tỷ', 'nghìn tỷ', 'triệu tỷ'];

// Typing the pipe function proved harder than I thought, so this just
// allows you to specifiy in Generics the input and output types of
// the resulting function
const pipe =
	<T, R>(firstFn: (arg: T) => any, ...fns: any[]) =>
	(arg: any): R =>
		fns.reduce((argument, fn) => fn(argument), firstFn(arg));

const splitNumberIntoDigits = (n: number): number[] =>
	`${n}`.split('').map((v) => parseInt(v, 10));

const reverseAndChunkDigitsByThree = pipe<number, number[][]>(
	splitNumberIntoDigits,
	reverse,
	(a: unknown[]) => chunk(a, 3)
);
