import { useLazyTransportationCompanyGetAllQuery } from '@/contexts/api/contract-management/catalog/transportation-company-api';
import { useAppSelector } from '@/contexts/store';
import { useEffect } from 'react';

export const useCatalogTransportationCompany = () => {
	const { list } = useAppSelector(
		(state) => state.Catalog.TransportationCompany
	);
	const [trigger] = useLazyTransportationCompanyGetAllQuery();

	useEffect(() => {
		if (list.length === 0) {
			trigger();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return { all_transportation_companys: list };
};
