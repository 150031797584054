import { NotifyHelper } from '@/utils/notify-helper';
import { BaseResponse } from '@/contexts/base/base-response';
import { apiSlice } from '../api-slice';
import { UserFormModel, UserModel } from '@/contexts/models/super-admin/User';

const enpointApi = '/super-admin/user-manager';
const entityName = 'Người dùng';
const entityCacheTag = 'USER_MANAGER';

export const UserApi = apiSlice
	.enhanceEndpoints({ addTagTypes: [entityCacheTag] })
	.injectEndpoints({
		endpoints: (builder) => ({
			userGetAll: builder.query<UserModel[], void>({
				query: () => ({ url: enpointApi, method: 'GET' }),
				transformResponse: (response) => response.result as UserModel[],
				providesTags: (result) =>
					result
						? [
								...result.map(({ id }) => ({
									type: `${entityCacheTag}` as const,
									id,
								})),
								{ type: entityCacheTag, id: 'LIST' },
							]
						: [{ type: entityCacheTag, id: 'LIST' }],
			}),
			userGetDetail: builder.query({
				query: (id: number) => ({ url: `${enpointApi}/${id}`, method: 'GET' }),
				transformResponse: (response) => response.result as UserModel,
				providesTags: (result, error, id) => [{ type: entityCacheTag, id }],
			}),
			userSave: builder.mutation({
				query: (entity: UserFormModel) => ({
					url: enpointApi,
					method: entity.id > 0 ? 'PUT' : 'POST',
					data: entity,
				}),
				transformResponse: (response) => response,
				async onQueryStarted(_args, { queryFulfilled }) {
					try {
						const { data } = await queryFulfilled;
						if (data.is_success)
							NotifyHelper.Success(
								data.message || `Lưu ${entityName} thành công`
							);
						else
							NotifyHelper.Error(
								data.message || `Lưu ${entityName} không thành công`
							);
					} catch (ex: any) {
						NotifyHelper.Error(ex?.error?.data || `Lưu ${entityName} có lỗi`);
					}
				},
				invalidatesTags: (result, error, arg) => [
					{ type: entityCacheTag, id: arg.id },
					{ type: entityCacheTag, id: 'LIST' },
				],
			}),
			userDelete: builder.mutation({
				query: (id: number) => ({
					url: `${enpointApi}/${id}`,
					method: 'DELETE',
				}),
				transformResponse: (response) => response,
				async onQueryStarted(_args, { queryFulfilled }) {
					try {
						const { data } = await queryFulfilled;
						if (data.is_success)
							NotifyHelper.Success(
								data.message || `Xóa ${entityName} thành công`
							);
						else
							NotifyHelper.Error(data.message || `Xóa ${entityName} thất bại`);
					} catch (ex: any) {
						NotifyHelper.Error(ex?.error?.data || `Xóa ${entityName} có lỗi`);
					}
				},
				invalidatesTags: [{ type: entityCacheTag, id: 'LIST' }],
			}),
			userDeleteMultiple: builder.mutation({
				query: (ids: number[]) => ({
					url: `${enpointApi}/deletes`,
					method: 'DELETE',
					data: { ids },
				}),
				transformResponse: (response) => response,
				async onQueryStarted(_args, { queryFulfilled }) {
					try {
						const { data } = await queryFulfilled;
						if (data.is_success)
							NotifyHelper.Success(
								data.message || `Xóa ${entityName} thành công`
							);
						else
							NotifyHelper.Error(data.message || `Xóa ${entityName} thất bại`);
					} catch (ex: any) {
						NotifyHelper.Error(ex?.error?.data || `Xóa ${entityName} có lỗi`);
					}
				},
				invalidatesTags: [{ type: entityCacheTag, id: 'LIST' }],
			}),
		}),
	});

export const {
	useLazyUserGetAllQuery,
	useUserGetAllQuery,
	useUserDeleteMultipleMutation,
	useUserDeleteMutation,
	useUserSaveMutation,
	useLazyUserGetDetailQuery,
	useUserGetDetailQuery,
} = UserApi;
