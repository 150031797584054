import { createSlice } from '@reduxjs/toolkit';
import { Warehouse } from '@/contexts/models/contract-management/catalog/Warehouse';
import { WarehouseApi } from '@/contexts/api/contract-management/catalog/warehouse-api';

type WarehouseState = {
	list: Warehouse[];
	detail?: Warehouse;
	is_open_dialog: boolean;
	selected_ids: number[];
	is_open_confirm: boolean;
};

const initialState = {
	list: [],
	detail: undefined,
	is_open_confirm: false,
	is_open_dialog: false,
	selected_ids: [],
} as WarehouseState;

const entityEndpointsApi = WarehouseApi.endpoints;

const WarehouseSlice = createSlice({
	name: 'WarehouseSlice',
	initialState: initialState,
	reducers: {
		WarehouseShowDetail: (state, { payload }) => {
			state.is_open_dialog = payload.is_open_dialog;
			state.detail = payload.detail;
		},
		WarehouseShowConfirm: (state, { payload }) => {
			state.is_open_confirm = payload;
		},
		WarehouseSelectedIdsChange: (state, { payload }) => {
			state.selected_ids = payload;
		},
	},
	extraReducers: (builder) => {
		builder.addMatcher(
			entityEndpointsApi.getAllWarehouse.matchFulfilled,
			(state, { payload }) => {
				state.list = payload || [];
			}
		);
		builder.addMatcher(
			entityEndpointsApi.createWarehouse.matchFulfilled,
			(state) => {
				state.is_open_dialog = false;
				state.detail = undefined;
			}
		);
		builder.addMatcher(
			entityEndpointsApi.updateWarehouse.matchFulfilled,
			(state) => {
				state.is_open_dialog = false;
				state.detail = undefined;
			}
		);
		builder.addMatcher(
			entityEndpointsApi.deletesWarehouse.matchFulfilled,
			(state) => {
				state.is_open_confirm = false;
				state.selected_ids = [];
			}
		);
	},
});
export const {
	WarehouseSelectedIdsChange,
	WarehouseShowConfirm,
	WarehouseShowDetail,
} = WarehouseSlice.actions;
export default WarehouseSlice.reducer;
