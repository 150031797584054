import { ArrowRightToLine, BellRingIcon } from 'lucide-react';
import { toast } from 'sonner';

export type NotifyResponse = {
	title: string;
	time_ago?: string;
	message?: string;
	redirect_url?: string;
};
export const NotifyHelper = {
	Success: (message?: string) =>
		message ? toast.success(message, { position: 'bottom-center' }) : null,
	Error: (message?: string) =>
		message ? toast.error(message, { position: 'bottom-center' }) : null,
	Info: (message?: string) =>
		message ? toast.info(message, { position: 'bottom-center' }) : null,
	Warning: (message?: string) =>
		message ? toast.warning(message, { position: 'bottom-center' }) : null,
	Notify: (notify: NotifyResponse) =>
		toast(notify.title, {
			icon: <BellRingIcon className='h-7 w-5' color={'hsl(var(--primary))'} />,
			className: 'bg-white text-primary text-sm',
			classNames: { description: 'text-gray-500 text-xs' },
			description: notify.message,
			action: notify.redirect_url ? (
				<a href={notify.redirect_url} className=''>
					<ArrowRightToLine className='mr-2 h-4 w-4' />
				</a>
			) : undefined,
			duration: 5000,
		}),
};
