import { RouteObject } from 'react-router-dom';
import {
	PurchaseContractDetail,
	PurchaseContractPage,
} from './purchase-contract';
import { SalesContractDetail, SalesContractPage } from './sales-contract';
import { ContractQuotationPage } from '../quotation';
import { ContractQuotationTemplatePage } from '../quotation-template';
import { ContractTransportationPage } from '../contract-dispatch';
import { ContractPaymentPage } from '../payment';
import LoanContractList from './loan-contract/list-page';
import { LoanContractDetail } from './loan-contract';
import ReturnContractList from './return-contract/list-page';
import { ReturnContractDetail } from './return-contract';

export const contractRoutes: RouteObject[] = [
	{
		path: 'hop-dong-mua-hang',
		children: [
			{
				path: '',
				element: <PurchaseContractPage />,
			},
			{
				path: 'chi-tiet',
				element: <PurchaseContractDetail />,
			},
			{
				path: 'chi-tiet/:id',
				element: <PurchaseContractDetail />,
			},
		],
	},
	{
		path: 'hop-dong-ban-hang',
		children: [
			{
				path: '',
				element: <SalesContractPage />,
			},
			{
				path: 'chi-tiet',
				element: <SalesContractDetail />,
			},
			{
				path: 'chi-tiet/:id',
				element: <SalesContractDetail />,
			},
		],
	},
	{
		path: 'hop-dong-vay-hang',
		children: [
			{
				path: '',
				element: <LoanContractList />,
			},
			{
				path: 'chi-tiet',
				element: <LoanContractDetail />,
			},
			{
				path: 'chi-tiet/:id',
				element: <LoanContractDetail />,
			},
		],
	},
	{
		path: 'hop-dong-tra-hang',
		children: [
			{
				path: '',
				element: <ReturnContractList />,
			},
			{
				path: 'chi-tiet',
				element: <ReturnContractDetail />,
			},
			{
				path: 'chi-tiet/:id',
				element: <ReturnContractDetail />,
			},
		],
	},
	{
		path: 'phieu-de-nghi-thanh-toan',
		children: [
			{
				path: '',
				element: <ContractPaymentPage />,
			},
		],
	},
	{
		path: 'phieu-bao-gia-chao-hang',
		children: [
			{
				path: '',
				element: <ContractQuotationPage />,
			},
		],
	},
	{
		path: 'bao-gia-mau',
		children: [
			{
				path: '',
				element: <ContractQuotationTemplatePage />,
			},
		],
	},
	{
		path: 'phieu-van-don',
		children: [
			{
				path: '',
				element: <ContractTransportationPage />,
			},
		],
	},
];
