import { ZodType, z } from 'zod';

export type TransportationFee = {
	id: number;
	name: string;
	code: string;
	is_active: boolean;
};

export const TransportationFeeValid: ZodType<TransportationFee> = z
	.object({
		id: z.number().optional(),
		name: z.string().min(1, { message: 'Vui lòng nhập tên' }),
		code: z.string().min(1, { message: 'Vui lòng nhập mã' }),
		is_active: z.boolean(),
	})
	.required();

export const TransportationFeeInitialValues: TransportationFee = {
	id: 0,
	code: '',
	is_active: true,
	name: '',
};
