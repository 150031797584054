import { AccountInfo } from '@/contexts/models/Account';
import { LoginRequest, LoginResponse } from '@/contexts/models/Login';
import { apiSlice } from '../api-slice';
import { NotifyHelper } from '@/utils/notify-helper';

export const AuthApi = apiSlice
	.enhanceEndpoints({ addTagTypes: ['AUTH'] })
	.injectEndpoints({
		endpoints: (builder) => ({
			profileInfo: builder.query<AccountInfo, void>({
				query: () => ({ url: '/auth/account-info', method: 'GET' }),
				transformResponse: (response) => response.result as AccountInfo,
				providesTags: ['AUTH'],
			}),
			signIn: builder.mutation<LoginResponse, Partial<LoginRequest>>({
				query: (entity) => ({
					url: '/auth/login',
					method: 'POST',
					data: entity,
					need_auth: false,
				}),
				transformResponse: (response) => response.result as any,
				async onQueryStarted(_args, { queryFulfilled }) {
					try {
						const { data } = await queryFulfilled;
						NotifyHelper.Success('Đăng nhập thành công');
					} catch (error: any) {
						NotifyHelper.Error(error?.data || 'Có lỗi xảy ra');
					}
				},
				invalidatesTags: ['AUTH'],
			}),
		}),
	});

export const { useSignInMutation, useProfileInfoQuery } = AuthApi;
