import { TransportationFee } from '@/contexts/models/contract-management/catalog/TransportationFee';
import { TransportationFeeApi } from '@/contexts/api/contract-management/catalog/transportation-fee-api';
import { createSlice } from '@reduxjs/toolkit';

type TransportationFeeState = {
	list: TransportationFee[];
	detail?: TransportationFee;
	is_open_dialog: boolean;
	selected_ids: number[];
	is_open_confirm: boolean;
};

const initialState = {
	list: [],
	detail: undefined,
	is_open_confirm: false,
	is_open_dialog: false,
	selected_ids: [],
} as TransportationFeeState;

const entityEndpointsApi = TransportationFeeApi.endpoints;

const TransportationFeeSlice = createSlice({
	name: 'TransportationFeeSlice',
	initialState: initialState,
	reducers: {
		TransportationFeeShowDetail: (state, { payload }) => {
			state.is_open_dialog = payload.is_open_dialog;
			state.detail = payload.detail;
		},
		TransportationFeeShowConfirm: (state, { payload }) => {
			state.is_open_confirm = payload;
		},
		TransportationFeeSelectedIdsChange: (state, { payload }) => {
			state.selected_ids = payload;
		},
	},
	extraReducers: (builder) => {
		builder.addMatcher(
			entityEndpointsApi.getAllTransportationFee.matchFulfilled,
			(state, { payload }) => {
				state.list = payload || [];
			}
		);
		builder.addMatcher(
			entityEndpointsApi.createTransportationFee.matchFulfilled,
			(state) => {
				state.is_open_dialog = false;
				state.detail = undefined;
			}
		);
		builder.addMatcher(
			entityEndpointsApi.updateTransportationFee.matchFulfilled,
			(state) => {
				state.is_open_dialog = false;
				state.detail = undefined;
			}
		);
		builder.addMatcher(
			entityEndpointsApi.deletesTransportationFee.matchFulfilled,
			(state) => {
				state.is_open_confirm = false;
				state.selected_ids = [];
			}
		);
	},
});
export const {
	TransportationFeeSelectedIdsChange,
	TransportationFeeShowConfirm,
	TransportationFeeShowDetail,
} = TransportationFeeSlice.actions;
export default TransportationFeeSlice.reducer;
