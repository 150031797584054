import { combineReducers, combineSlices } from '@reduxjs/toolkit';
import menuSlice from './menu-slice';
import roleSlice from './role-slice';
import roleScopeSlice from './role-scope-slice';
import userSlice from './user-slice';
import appFeatureSlice from './app-feature-slice';

export const superAdminReducer = combineReducers({
	Menu: menuSlice,
	Role: roleSlice,
	RoleScope: roleScopeSlice,
	User: userSlice,
	AppFeature: appFeatureSlice,
});
