import { eDocumentType } from '@/contexts/models/contract-management/contract';
import ContractListPage from '@/modules/contract/contract-list-page';

const LoanContractList = () => {
	const title = 'hợp đồng vay hàng';
	const document_type = eDocumentType.HOP_DONG_VAY_HANG;

	return (
		<>
			<ContractListPage title={title} document_type={document_type} />
		</>
	);
};

export default LoanContractList;
