import { createSlice } from '@reduxjs/toolkit';

import { Bank } from '@/contexts/models/contract-management/catalog/Bank';
import { BankApi } from '@/contexts/api/contract-management/catalog/bank-api';

type BankState = {
	list: Bank[];
	detail?: Bank;
	is_open_dialog: boolean;
	selected_ids: number[];
	is_open_confirm: boolean;
};

const initialState = {
	list: [],
	detail: undefined,
	is_open_confirm: false,
	is_open_dialog: false,
	selected_ids: [],
} as BankState;

const entityEndpointsApi = BankApi.endpoints;

const BankSlice = createSlice({
	name: 'BankSlice',
	initialState: initialState,
	reducers: {
		BankShowDetail: (state, { payload }) => {
			state.is_open_dialog = payload.is_open_dialog;
			state.detail = payload.detail;
		},
		BankShowConfirm: (state, { payload }) => {
			state.is_open_confirm = payload;
		},
		BankSelectedIdsChange: (state, { payload }) => {
			state.selected_ids = payload;
		},
	},
	extraReducers: (builder) => {
		builder.addMatcher(
			entityEndpointsApi.getAllBank.matchFulfilled,
			(state, { payload }) => {
				state.list = payload || [];
			}
		);
		builder.addMatcher(
			entityEndpointsApi.createBank.matchFulfilled,
			(state) => {
				state.is_open_dialog = false;
				state.detail = undefined;
			}
		);
		builder.addMatcher(
			entityEndpointsApi.updateBank.matchFulfilled,
			(state) => {
				state.is_open_dialog = false;
				state.detail = undefined;
			}
		);
		builder.addMatcher(
			entityEndpointsApi.deletesBank.matchFulfilled,
			(state) => {
				state.is_open_confirm = false;
				state.selected_ids = [];
			}
		);
	},
});
export const { BankSelectedIdsChange, BankShowConfirm, BankShowDetail } =
	BankSlice.actions;
export default BankSlice.reducer;
