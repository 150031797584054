import DataTable, { DataTableColumnHeader } from '@/components/data-table';

import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from '@/components/base/ui/dropdown-menu';
import {
	useContractInfoGetAllQuery,
	useLazyContractInfoGetWordFileQuery,
} from '@/contexts/api/contract-management/contract/contract-info-api';
import {
	ContractInfo,
	eDocumentType,
} from '@/contexts/models/contract-management/contract';

import { ContractPaymentListSheet } from '@/modules/payment/payment-list-sheet';
import ContractTransportationListSheet from '@/modules/contract-dispatch/contract-dispatch-list-sheet';
import { IsMobile } from '@/utils/check-mobile';
import { ColumnDef } from '@tanstack/react-table';
import { formatDate } from 'date-fns';
import { useState } from 'react';
import ContractDetailFormSheet from '../contract-detail-form-sheet';
import { ContractStatusBadge } from '../form/contract-status-badge';
import { ContractPurchaseListCard } from './purchase-mobile-table';
import { Button } from '@/components/base/ui/button';
import {
	DollarSignIcon,
	EllipsisIcon,
	FileArchiveIcon,
	FileDownIcon,
	TagIcon,
	TruckIcon,
} from 'lucide-react';
import { ButtonCustom } from '@/components/common/button';
import { ContractChangeStatusDialog } from '../form/contract-change-status-dialog';
import { AppState, useAppDispatch, useAppSelector } from '@/contexts/store';
import { contractInfoActions } from '@/contexts/reducers/contract-management/contract/contract-info.slice';
import { ContractDocumentTypeBadge } from '../form/contract-document-type-badge';

interface IContractSalesTableProps {
	title?: string;
}

export const ContractSalesTable = ({ title }: IContractSalesTableProps) => {
	const document_type = eDocumentType.HOP_DONG_BAN_HANG.valueOf();
	const dispatch = useAppDispatch();

	const { is_open_change_status, selected_ids } = useAppSelector(
		(state: AppState) => state.Contract.ContractInfo
	);
	const [openTransportationList, setOpenTransportationList] = useState({
		isOpen: false,
		contract_id: 0,
		contract_code: '',
	});
	const [openPaymentList, setOpenPaymentList] = useState({
		isOpen: false,
		contract_id: 0,
		contract_code: '',
	});

	const [openDetail, setOpenDetail] = useState({
		isOpen: false,
		detail_id: 0,
	});

	const {
		data,
		isFetching,
		isLoading,
		refetch: usePrefetch,
	} = useContractInfoGetAllQuery(document_type);

	const [downloadWord] = useLazyContractInfoGetWordFileQuery();

	const dataLoading = isFetching || isLoading;

	const selectedIdsChange = (ids: number[]) => {
		dispatch(
			contractInfoActions.ContractInfoSelectedIdsChange({ selected_ids: ids })
		);
	};

	const changeStatusHandle = (isOpen: boolean) => {
		dispatch(
			contractInfoActions.ContractInfoChangeStatus({
				is_open_change_status: isOpen,
			})
		);
	};

	const columns: ColumnDef<ContractInfo>[] = [
		{
			accessorKey: 'contract_date',
			header: 'Ngày',
			cell: ({ row }) => {
				return (
					<div className='text-center'>
						<div className='text-xxs'>
							{formatDate(row.original.contract_date, 'dd/MM/yyyy')}
						</div>
						<ContractStatusBadge value={row.original.contract_status_id} />
					</div>
				);
			},
		},
		{
			id: 'action_button',
			header: '...',
			cell: ({ row }) => {
				return (
					<div className='text-center'>
						<DropdownMenu>
							<DropdownMenuTrigger asChild>
								<Button size='icon' variant='outline' className='h-5 w-5'>
									<EllipsisIcon className='h-3.5 w-3.5' />
									<span className='sr-only'>More</span>
								</Button>
							</DropdownMenuTrigger>
							<DropdownMenuContent align='start'>
								<DropdownMenuItem>
									<ButtonCustom
										variant={'link'}
										icon={<FileArchiveIcon className='h-3.5 w-3.5' />}
										onClick={() =>
											setOpenDetail({
												isOpen: true,
												detail_id: row.original.id,
											})
										}
										className='text-black h-5'
										title='Chỉnh sửa'
									/>
								</DropdownMenuItem>
								<DropdownMenuItem>
									<ButtonCustom
										variant={'link'}
										icon={<FileDownIcon className='h-3.5 w-3.5' />}
										onClick={() => downloadWord(row.original.id)}
										className='text-black h-5'
										title='Tải hợp đồng'
									/>
								</DropdownMenuItem>
								<DropdownMenuItem>
									<ButtonCustom
										variant={'link'}
										icon={<TagIcon className='h-3.5 w-3.5' />}
										onClick={() => {
											selectedIdsChange([row.original.id]);
											changeStatusHandle(true);
										}}
										className='text-black h-5'
										title='Đổi trạng thái'
									/>
								</DropdownMenuItem>
								<DropdownMenuSeparator />
								<DropdownMenuItem>
									<ButtonCustom
										variant={'link'}
										icon={<TruckIcon className='h-3.5 w-3.5' />}
										className='text-black h-5'
										onClick={() => {
											setOpenTransportationList({
												isOpen: true,
												contract_id: row.original.id,
												contract_code: row.original.contract_code,
											});
										}}
										title='Đợt giao hàng'
									/>
								</DropdownMenuItem>
								<DropdownMenuItem>
									<ButtonCustom
										variant={'link'}
										icon={<DollarSignIcon className='h-3.5 w-3.5' />}
										className='text-black h-5'
										onClick={() => {
											setOpenPaymentList({
												isOpen: true,
												contract_id: row.original.id,
												contract_code: row.original.contract_code,
											});
										}}
										title='Tài chính'
									/>
								</DropdownMenuItem>
							</DropdownMenuContent>
						</DropdownMenu>
					</div>
				);
			},
		},
		{
			id: 'contract_code',
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title='Thông tin hợp đồng' />
			),
			cell: ({ row }) => {
				return (
					<div className='text-left text-xxs'>
						<div className='text-sm'>
							<span
								className='font-semibold text-primary hover:underline hover:text-primary/90 hover:cursor-pointer'
								// to={`/hop-dong-mua-hang/chi-tiet/${row.original.id}`}
								onClick={() =>
									setOpenDetail({ isOpen: true, detail_id: row.original.id })
								}
							>
								<ContractDocumentTypeBadge value={row.original.document_type} />
								{row.original.contract_code}
							</span>
						</div>
						<div className='text-xxs'>
							Hàng hóa: <b className='text-xxs'>{row.original.goods_name}</b>{' '}
						</div>

						{document_type == eDocumentType.HOP_DONG_MUA_HANG ? (
							<div className='text-xxs'>
								Bên bán: <b>{row.original.seller_company_name}</b>
							</div>
						) : (
							<div className='text-xxs'>
								Bên mua: <b>{row.original.buyer_company_name}</b>
							</div>
						)}
					</div>
				);
			},
		},
		{
			id: 'goods_quantity',
			header: 'Số lượng (Kg)',
			enableSorting: false,
			cell: ({ row }) => {
				return (
					<div className='text-center text-xxs'>
						<div className='text-sm'>
							<code className='text-xs'>
								{row.original.goods_quantity.toLocaleString('vi')}
							</code>
						</div>
					</div>
				);
			},
		},
		{
			id: 'goods_price',
			header: 'Đơn giá (VNĐ)',
			enableSorting: false,
			cell: ({ row }) => {
				return (
					<div className='text-center text-xxs'>
						<div className='text-sm'>
							<code className='text-xs'>
								{row.original.goods_price.toLocaleString('vi')}
							</code>
						</div>
					</div>
				);
			},
		},
		{
			id: 'goods_quantity_delivery',
			header: ({ column }) => (
				<div className='text-right'>
					Số lượng<br></br> đã giao/nhận (Kg)
				</div>
			),
			enableSorting: false,
			cell: ({ row }) => {
				return (
					<div className='text-right text-xxs'>
						<div className='text-sm'>
							<code className='text-xs'>
								{row.original.goods_quantity.toLocaleString('vi')}
							</code>{' '}
						</div>
					</div>
				);
			},
		},
		{
			accessorKey: 'goods_sold',
			header: 'Dự kiến giao hàng',
			cell: ({ row }) => {
				return (
					<div className='text-center text-xxs'>
						<div className='text-xxs'>
							<div className='hidden text-muted-foreground md:block'>
								<b>
									{formatDate(
										row.original.goods_delivery_date_start || '',
										'dd/MM/yyyy'
									)}{' '}
									-{' '}
									{formatDate(
										row.original.goods_delivery_date_end || '',
										'dd/MM/yyyy'
									)}
								</b>
							</div>
							<div className='hidden text-muted-foreground md:block'>
								<b>{row.original.goods_delivery_address}</b>
							</div>
						</div>
					</div>
				);
			},
		},
	];

	return (
		<>
			{IsMobile() ? (
				<ContractPurchaseListCard />
			) : (
				<DataTable
					isLoading={dataLoading}
					data={data || []}
					columns={columns}
					keyExpr={'id'}
					searchKey={'contract_code'}
					onReload={usePrefetch}
					isScrollable={true}
					height='h-[440px]'
				/>
			)}
			{openDetail.isOpen && (
				<ContractDetailFormSheet
					isOpen={openDetail.isOpen}
					onOpenChange={() => setOpenDetail({ isOpen: false, detail_id: 0 })}
					title={title || 'Hơp đồng bán hàng'}
					contract_id={openDetail.detail_id}
					document_type={eDocumentType.HOP_DONG_BAN_HANG}
				/>
			)}
			{openPaymentList.isOpen && (
				<ContractPaymentListSheet
					isOpen={openPaymentList.isOpen}
					contract_id={openPaymentList.contract_id}
					contract_code={openPaymentList.contract_code}
					onOpenChange={() =>
						setOpenPaymentList({
							isOpen: false,
							contract_id: 0,
							contract_code: '',
						})
					}
				/>
			)}
			{is_open_change_status && (
				<ContractChangeStatusDialog
					is_open={is_open_change_status}
					onOpenChange={changeStatusHandle}
					contract_ids={selected_ids}
				/>
			)}
			{openTransportationList.isOpen && (
				<ContractTransportationListSheet
					isOpen={openTransportationList.isOpen}
					contract_id={openTransportationList.contract_id}
					sheetTitle={`của hợp đồng bán hàng ${openTransportationList.contract_code}`}
					onOpenChange={() =>
						setOpenTransportationList({
							isOpen: false,
							contract_id: 0,
							contract_code: '',
						})
					}
				/>
			)}
		</>
	);
};
