import { cn } from '@/lib/utils';
import { CaretSortIcon } from '@radix-ui/react-icons';
import { CheckIcon } from 'lucide-react';
import { Button } from '../../base/ui/button';
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
} from '../../base/ui/command';
import {
	FormControl,
	FormDescription,
	FormItem,
	FormLabel,
	FormMessage,
} from '../../base/ui/form';
import { Popover, PopoverContent, PopoverTrigger } from '../../base/ui/popover';
import React, { useMemo } from 'react';
import Combobox from '../combobox';

interface FormComboboxProps<TData> {
	label?: string;
	helpBlock?: string;
	isRequired?: boolean;
	name: string;
	value?: any;
	defaultValue?: any;
	valueExpr: keyof TData;
	displayExpr: keyof TData;
	dataSource: TData[];
	onValueChange?: (value?: any) => void;
	onDataChange?: (value?: TData) => void;
	isDisabled?: boolean;
	placeholder?: string;
	itemRender?: (data: TData) => React.ReactNode;
	isClearable?: boolean;
	addAction?: {
		hasAddAction: boolean;
		onAddItem: () => void;
	};
	width?: string;
}

const FormCombobox = <TData,>({
	label,
	isRequired,
	name,
	helpBlock,
	addAction,
	...rest
}: FormComboboxProps<TData>) => {
	return (
		<FormItem className='flex flex-col pb-2'>
			{label && (
				<FormLabel htmlFor={name}>
					{label} {isRequired ? <code className='text-red-400'>*</code> : ''}
				</FormLabel>
			)}

			<Combobox name={name} addAction={addAction} {...rest} />
			{helpBlock && (
				<FormDescription
					className='text-xs italic'
					dangerouslySetInnerHTML={{ __html: helpBlock }}
				></FormDescription>
			)}
			<FormMessage />
		</FormItem>
	);
};

export default FormCombobox;
