import { zod, zod_valid } from '@/lib/zod-valid';
import { formatDate } from 'date-fns';

export type ContractShipment = {
	id: number;
	shipment_date: string;
	contract_dispatch_id: number;
	ref_contract_dispatch_id: number;
	vehicle_license_plate: string;
	vehicle_capacity: number;
	goods_quantity: number;
	driver_name: string;
	driver_phone_number: string;
	shipment_status_id: string;
	road_cost: number;
	start_date: string;
	end_date: string;
	start_address: string;
	end_address: string;
	start_weighing_slip_file: string;
	end_weighing_slip_file: string;
	start_vehicle_weight: number;
	end_vehicle_weight: number;
	start_goods_weight: number;
	start_total_weight: number;
	end_goods_weight: number;
	end_total_weight: number;
};

export const ContractShipmentValid = zod.object({
	id: zod_valid.number(),
	ref_contract_dispatch_id: zod_valid.number_required(
		'Vui lòng chọn Liên kết điều vận bán hàng'
	),
	shipment_date: zod_valid.string(),
	contract_dispatch_id: zod_valid.number(),
	vehicle_license_plate: zod_valid.string_required('Vui lòng nhập biển số xe'),
	vehicle_capacity: zod_valid.number_required('Vui lòng nhập trọng tải xe'),
	goods_quantity: zod_valid.number_required('Vui lòng nhập số lượng hàng'),
	driver_name: zod_valid.string_required('Vui lòng nhập tên tài xế'),
	driver_phone_number: zod_valid.string_required(
		'Vui lòng nhập số điện thoại tài xế'
	),
	shipment_status_id: zod_valid.string(),
	road_cost: zod_valid.number(),
	start_date: zod_valid.string(),
	end_date: zod_valid.string(),
	start_address: zod_valid.string_required('Vui lòng nhập địa chỉ điểm nhận hàng'),
	end_address: zod_valid.string_required('Vui lòng nhập địa chỉ điểm giao hàng'),
	start_weighing_slip_file: zod_valid.string(),
	end_weighing_slip_file: zod_valid.string(),
	start_vehicle_weight: zod_valid.number(),
	end_vehicle_weight: zod_valid.number(),
	start_goods_weight: zod_valid.number(),
	start_total_weight: zod_valid.number(),
	end_goods_weight: zod_valid.number(),
	end_total_weight: zod_valid.number(),
	transportation_company_id: zod_valid.number(),
});

export type ContractShipmentFormModel = zod.infer<typeof ContractShipmentValid>;

export const ContractShipmentFormDefault: ContractShipmentFormModel = {
	id: 0,
	shipment_date: formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss'),
	contract_dispatch_id: 0,
	ref_contract_dispatch_id: 0,
	goods_quantity: 0,
	vehicle_license_plate: '',
	vehicle_capacity: 0,
	driver_name: '',
	driver_phone_number: '',
	shipment_status_id: 'NEW',
	road_cost: 0,
	start_date: formatDate(new Date(), 'yyyy-MM-dd'),
	end_date: formatDate(new Date(), 'yyyy-MM-dd'),
	start_address: '',
	end_address: '',
	start_weighing_slip_file: '',
	end_weighing_slip_file: '',
	start_vehicle_weight: 0,
	end_vehicle_weight: 0,
	start_goods_weight: 0,
	start_total_weight: 0,
	end_goods_weight: 0,
	end_total_weight: 0,
	transportation_company_id: 0,
};
