import { RoleScopeApi } from '@/contexts/api/super-admin';
import { RoleScopeModel } from '@/contexts/models/super-admin/RoleScope';
import { createSlice } from '@reduxjs/toolkit';

type RoleScopeState = {
	list: RoleScopeModel[];
	detail?: RoleScopeModel;
	is_open_dialog: boolean;
	selected_ids: number[];
	is_open_confirm: boolean;
};

const initialState = {
	list: [],
	detail: undefined,
	is_open_confirm: false,
	is_open_dialog: false,
	selected_ids: [],
} as RoleScopeState;

const entityEndpointsApi = RoleScopeApi.endpoints;

const RoleScopeSlice = createSlice({
	name: 'RoleScopeSlice',
	initialState: initialState,
	reducers: {
		RoleScopeShowDetail: (state, { payload }) => {
			state.is_open_dialog = payload.is_open_dialog;
			state.detail = payload.detail;
		},
		RoleScopeShowConfirm: (state, { payload }) => {
			state.is_open_confirm = payload;
		},
		RoleScopeSelectedIdsChange: (state, { payload }) => {
			state.selected_ids = payload;
		},
	},
	extraReducers: (builder) => {
		builder.addMatcher(
			entityEndpointsApi.roleScopeGetAll.matchFulfilled,
			(state, { payload }) => {
				state.list = payload || [];
			}
		);
		builder.addMatcher(
			entityEndpointsApi.roleScopeSave.matchFulfilled,
			(state) => {
				state.is_open_dialog = false;
				state.detail = undefined;
			}
		);
		builder.addMatcher(
			entityEndpointsApi.roleScopeDelete.matchFulfilled,
			(state) => {
				state.is_open_confirm = false;
				state.selected_ids = [];
			}
		);
		builder.addMatcher(
			entityEndpointsApi.roleScopeDeleteMultiple.matchFulfilled,
			(state) => {
				state.is_open_confirm = false;
				state.selected_ids = [];
			}
		);
	},
});
export const roleScopeActions = {
	...RoleScopeSlice.actions,
};
export default RoleScopeSlice.reducer;
