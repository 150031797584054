import LoadingForm from '@/components/base/skeleton/loading-form';
import { NotifyHelper } from '@/utils/notify-helper';
import React, { useEffect } from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { Form } from '../../base/ui/form';
import { ScrollArea } from '../../base/ui/scroll-area';
import { LoadingTable } from '@/components/base/skeleton/loading-table';
interface FromContainerProps<TData extends FieldValues> {
	form: UseFormReturn<TData, any, undefined>;
	onSubmit: (formData: TData) => void;
	children?: React.ReactNode;
	hasScroll?: boolean;
	isLoading?: boolean;
}
const FromContainer = <TData extends FieldValues>({
	form,
	onSubmit,
	children,
	hasScroll,
	isLoading,
}: FromContainerProps<TData>) => {
	const {
		formState: { isValid, isSubmitting, errors },
	} = form;
	useEffect(() => {
		if (!isValid && isSubmitting) {
			NotifyHelper.Error('Vui lòng kiểm tra lại thông tin');
		}
	}, [isValid, isSubmitting, errors]);
	return (
		<>
			{isLoading && <LoadingForm />}
			{!isLoading && (
				<Form {...form}>
					<form
						onSubmit={isSubmitting ? undefined : form.handleSubmit(onSubmit)}
						className='space-y-2 w-full'
					>
						{hasScroll === true ? (
							<ScrollArea>
								<div className='form-dialog-scrollable pr-3'>{children}</div>
							</ScrollArea>
						) : (
							<div className='p-2'>{children}</div>
						)}
					</form>
				</Form>
			)}
		</>
	);
};

export default FromContainer;
