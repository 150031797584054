import { CompanyInfoApi } from '@/contexts/api/contract-management/catalog/company-info-api';
import { CompanyInfo } from '@/contexts/models/contract-management/catalog/CompanyInfo';
import { createSlice } from '@reduxjs/toolkit';

type CompanyInfoState = {
	list: CompanyInfo[];
	detail?: CompanyInfo;
	is_open_dialog: boolean;
	selected_ids: number[];
	is_open_confirm: boolean;
};

const initialState = {
	list: [],
	detail: undefined,
	is_open_confirm: false,
	is_open_dialog: false,
	selected_ids: [],
} as CompanyInfoState;

const entityEndpointsApi = CompanyInfoApi.endpoints;

const CompanyInfoSlice = createSlice({
	name: 'CompanyInfoSlice',
	initialState: initialState,
	reducers: {
		CompanyInfoShowDetail: (state, { payload }) => {
			state.is_open_dialog = payload.is_open_dialog;
			state.detail = payload.detail;
		},
		CompanyInfoShowConfirm: (state, { payload }) => {
			state.is_open_confirm = payload;
		},
		CompanyInfoSelectedIdsChange: (state, { payload }) => {
			state.selected_ids = payload;
		},
	},
	extraReducers: (builder) => {
		builder.addMatcher(
			entityEndpointsApi.companyInfoGetAll.matchFulfilled,
			(state, { payload }) => {
				state.list = payload || [];
			}
		);
		builder.addMatcher(
			entityEndpointsApi.companyInfoSave.matchFulfilled,
			(state) => {
				state.is_open_dialog = false;
				state.detail = undefined;
			}
		);
		builder.addMatcher(
			entityEndpointsApi.companyInfoDelete.matchFulfilled,
			(state) => {
				state.is_open_confirm = false;
				state.selected_ids = [];
			}
		);
		builder.addMatcher(
			entityEndpointsApi.companyInfoDeleteMultiple.matchFulfilled,
			(state) => {
				state.is_open_confirm = false;
				state.selected_ids = [];
			}
		);
	},
});
export const companyInfoActions = CompanyInfoSlice.actions;
export default CompanyInfoSlice.reducer;
