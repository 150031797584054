import { jwtDecode } from 'jwt-decode';
import memoize from 'memoize';

import { localStorageHelper } from './localstorage-helper';
import { axiosClient } from '@/lib/axios-client';
import {
	BaseResponse,
	RefreshTokenResponse,
} from '@/contexts/base/base-response';

const refreshTokenUrl: string =
	import.meta.env.VITE_APP_REFRESH_TOKEN_URL?.toString() as string;

type jwt_decodeResult = {
	exp: number;
};

let refreshTokenRequest: Promise<any> | null;

const checkIsTokenExpired = (): boolean => {
	try {
		const token = localStorageHelper.GET_TOKEN();
		if (token) {
			const data: jwt_decodeResult = jwtDecode(token);
			const exp = data.exp;
			if (exp > Math.floor(Date.now() / 1000)) {
				return false;
			}
		}
		return true;
	} catch (error) {
		return true;
	}
};

const refreshToken = async (): Promise<{
	is_success: boolean;
	tokens: string;
}> => {
	try {
		const token = localStorageHelper.GET_TOKEN('jwt-token');
		const refreshToken = localStorageHelper.GET_TOKEN('refresh-token');
		const config = {
			method: 'POST',
			url: `${refreshTokenUrl}`,
			headers: { 'Content-Type': 'application/json' },
			data: JSON.stringify({ jwt_token: token, refresh_token: refreshToken }),
			withCredentials: true,
		};
		const { is_success, result } = await axiosClient<any, BaseResponse>(config);
		if (is_success && result) {
			const { jwt_token, refresh_token } = result as RefreshTokenResponse;
			return {
				is_success: true,
				tokens: `${jwt_token} ${refresh_token}`,
			};
		} else {
			return {
				is_success: false,
				tokens: '',
			};
		}
	} catch (error) {
		return {
			is_success: false,
			tokens: '',
		};
	}
};

const refreshIfTokenExpired = async () => {
	const isTokenExpired = checkIsTokenExpired();
	if (isTokenExpired) {
		refreshTokenRequest = refreshTokenRequest
			? refreshTokenRequest
			: refreshToken();
		const newTokens = await refreshTokenRequest;
		refreshTokenRequest = null;
		if (newTokens.is_success && newTokens.tokens.length > 0) {
			localStorageHelper.SET_TOKEN(newTokens.tokens);
		} else {
			localStorageHelper.CLEAR_TOKEN();
			window.location.href = "/login";
		}
	}
};

export const memoizedRefreshToken = memoize(refreshIfTokenExpired, {
	maxAge: 10000,
});
