import { cn } from '@/lib/utils';
import {
	CalendarDate,
	createCalendar,
	endOfMonth,
	getDayOfWeek,
	getWeeksInMonth,
	isSameDay,
	isSameMonth,
	DateDuration,
} from '@internationalized/date';
import { ChevronLeftIcon, ChevronRightIcon } from 'lucide-react';
import React from 'react';
import {
	DateValue,
	RangeCalendarProps,
	useButton,
	useCalendarCell,
	useCalendarGrid,
	useDateFormatter,
	useFocusRing,
	useLocale,
	useRangeCalendar,
} from 'react-aria';
import { RangeCalendarState, useRangeCalendarState } from 'react-stately';
import { Button } from './button';

function CalendarRangeBox(props: RangeCalendarProps<DateValue>) {
	const ref = React.useRef<HTMLDivElement | null>(null);
	const prevButtonRef = React.useRef<HTMLButtonElement | null>(null);
	const nextButtonRef = React.useRef<HTMLButtonElement | null>(null);

	const { locale } = useLocale();
	const state = useRangeCalendarState({
		...props,
		locale,
		visibleDuration: { months: 2 },
		createCalendar,
	});
	const {
		calendarProps,
		prevButtonProps: _prevButtonProps,
		nextButtonProps: _nextButtonProps,
		title,
	} = useRangeCalendar(props, state, ref);
	const { buttonProps: prevButtonProps } = useButton(
		_prevButtonProps,
		prevButtonRef
	);
	const { buttonProps: nextButtonProps } = useButton(
		_nextButtonProps,
		nextButtonRef
	);

	const monthDateFormatter = useDateFormatter({
		month: 'long',
		year: 'numeric',
		timeZone: state.timeZone,
	});

	return (
		<div {...calendarProps} ref={ref} className='space-y-4'>
			<div className='relative flex items-center justify-center pt-1'>
				<Button
					{...prevButtonProps}
					ref={prevButtonRef}
					variant={'outline'}
					className={cn(
						'absolute left-1 h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100'
					)}
				>
					<ChevronLeftIcon className='w-4 h-4' />
				</Button>
				<div className='flex align-center text-sm font-medium space-x-14'>
					<div>
						{monthDateFormatter.format(
							state.visibleRange.start.toDate(state.timeZone)
						)}
					</div>
					<div>
						{monthDateFormatter.format(
							state.visibleRange.start.add({ months: 1 }).toDate(state.timeZone)
						)}
					</div>
				</div>
				<Button
					{...nextButtonProps}
					ref={nextButtonRef}
					variant={'outline'}
					className={cn(
						'absolute right-1 h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100'
					)}
				>
					<ChevronRightIcon className='w-4 h-4' />
				</Button>
			</div>
			<div className='flex gap-8'>
				<CalendarGrid state={state} offset={{}} />
				<CalendarGrid state={state} offset={{ months: 1 }} />
			</div>
		</div>
	);
}
interface CalendarGridProps {
	state: RangeCalendarState;
	offset: DateDuration;
}

function CalendarGrid({ state, offset, ...props }: CalendarGridProps) {
	const { locale } = useLocale();
	const startDate = state.visibleRange.start.add(offset);
	const endDate = endOfMonth(startDate);

	const { gridProps, headerProps, weekDays } = useCalendarGrid(
		{
			...props,
			startDate,
			endDate
		},
		state
	);

	// Get the number of weeks in the month so we can render the proper number of rows.
	const weeksInMonth = getWeeksInMonth(startDate, locale);

	return (
		<table
			{...gridProps}
			className={cn(gridProps.className, 'w-full border-collapse space-y-1')}
		>
			<thead {...headerProps}>
				<tr className='flex'>
					{weekDays.map((day, index) => (
						<th
							className='w-9 rounded-md text-[0.8rem] font-normal text-muted-foreground'
							key={index}
						>
							{day}
						</th>
					))}
				</tr>
			</thead>
			<tbody>
				{[...new Array(weeksInMonth).keys()].map((weekIndex) => (
					<tr className='flex w-full mt-2' key={weekIndex}>
						{state
							.getDatesInWeek(weekIndex, startDate)
							.map((date, i) =>
								date ? (
									<CalendarCell
										key={i}
										state={state}
										date={date}
										currentMonth={startDate}
									/>
								) : (
									<td key={i} />
								)
							)}
					</tr>
				))}
			</tbody>
		</table>
	);
}

interface CalendarCellProps {
	state: RangeCalendarState;
	date: CalendarDate;
	currentMonth: CalendarDate;
}

function CalendarCell({ state, date,currentMonth }: CalendarCellProps) {
	const ref = React.useRef<HTMLButtonElement | null>(null);
	const {
		cellProps,
		buttonProps,
		isSelected,
		isInvalid,
		isOutsideVisibleRange,
		isDisabled,
		formattedDate,
	} = useCalendarCell({ date }, state, ref);

	const isOutsideMonth = !isSameMonth(currentMonth, date);

	const isSelectionStart = state.highlightedRange
		? isSameDay(date, state.highlightedRange.start)
		: isSelected;
	const isSelectionEnd = state.highlightedRange
		? isSameDay(date, state.highlightedRange.end)
		: isSelected;

	const { locale } = useLocale();
	const dayOfWeek = getDayOfWeek(date, locale);
	const isRoundedLeft =
		isSelected && (isSelectionStart || dayOfWeek === 0 || date.day === 1);
	const isRoundedRight =
		isSelected &&
		(isSelectionEnd ||
			dayOfWeek === 6 ||
			date.day === date.calendar.getDaysInMonth(date));

	const { focusProps, isFocusVisible } = useFocusRing();

	return (
		<td
			{...cellProps}
			className={cn(
				cellProps.className,
				'relative p-0 text-center text-sm focus-within:relative focus-within:z-20 [&:has([aria-selected])]:bg-accent first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md',
				isRoundedLeft ? 'rounded-l-full' : '',
				isRoundedRight ? 'rounded-r-full' : '',
				isSelected ? (isInvalid ? 'bg-red-300' : 'bg-primary/20') : '',
				isDisabled ? 'disabled' : ''
			)}
		>
			<Button
				{...buttonProps}
				type='button'
				variant={'ghost'}
				hidden={isOutsideMonth}
				ref={ref}
				className={cn(
					buttonProps.className,
					'h-9 w-9',
					isDisabled && !isInvalid ? 'text-muted-foreground opacity-50' : '',
					isFocusVisible
						? 'ring-2 group-focus:z-2 ring-violet-600 ring-offset-2'
						: '',
					isSelectionStart || isSelectionEnd
						? isInvalid
							? 'bg-red-600 text-white hover:bg-red-700'
							: 'bg-violet-600 text-white hover:bg-violet-700'
						: '',
					isSelected && !isDisabled && !(isSelectionStart || isSelectionEnd)
						? isInvalid
							? 'hover:bg-red-400'
							: 'hover:bg-violet-400'
						: '',
					!isSelected && !isDisabled ? 'hover:bg-violet-100' : '',
					isOutsideVisibleRange ? 'text-muted-foreground opacity-50' : ''
				)}
			>
				{formattedDate}
			</Button>
		</td>
	);
}

export { CalendarRangeBox };
