import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from '@/components/base/ui/dropdown-menu';
import { ButtonAdd, ButtonDelete, ConfirmDialog } from '@/components/common';
import DataTable from '@/components/data-table';
import {
	useContractShipmentDeleteMultipleMutation,
	useContractShipmentGetAllQuery,
} from '@/contexts/api/contract-management/contract/contract-shipment-api';
import {
	ContractShipment,
	ContractShipmentFormDefault,
	ContractShipmentFormModel,
} from '@/contexts/models/contract-management/contract/ContractShipmentModel';

import { ButtonCustom } from '@/components/common/button';
import { SheetDialog } from '@/components/common/sheet-dialog';
import { contractShipmentActions } from '@/contexts/reducers/contract-management/contract/contract-shipment.slice';
import { AppState, useAppDispatch, useAppSelector } from '@/contexts/store';
import { ColumnDef } from '@tanstack/react-table';
import { formatDate } from 'date-fns';
import { useCallback } from 'react';
import { Button } from '@/components/base/ui/button';
import {
	EllipsisIcon,
	FileArchiveIcon,
	TagIcon,
	TruckIcon,
} from 'lucide-react';
import { TransportStatusBadge } from '../contract-dispatch/form/transport-status-badge';
import { ContractShipmentDetailSheet } from './contract-shipment-detail-sheet';
import { ContractShipmentChangeStatusDialog } from './form/contract-shipment-change-status-dialog';
import { ContractDispatch } from '@/contexts/models/contract-management/contract/ContractDispatchModel';

interface ContractShipmentListSheetProps {
	dispatch_id?: number;
	sheetTitle: string;
	isOpen: boolean;
	dispatch_info?: ContractDispatch;
	onOpenChange: (isOpen: boolean) => void;
}

const ContractShipmentListSheet = ({
	dispatch_id = 0,
	isOpen,
	onOpenChange,
	sheetTitle,
	dispatch_info,
}: ContractShipmentListSheetProps) => {
	const dispatch = useAppDispatch();
	const title = 'điều vận';
	const {
		is_open_confirm,
		is_open_dialog,
		selected_ids,
		detail,
		is_open_change_status,
	} = useAppSelector((state: AppState) => state.Contract.ContractShipment);

	const { data, isFetching, isLoading, refetch } =
		useContractShipmentGetAllQuery(dispatch_id);

	const [deletesAction] = useContractShipmentDeleteMultipleMutation();
	const addEditHandle = (
		isOpen: boolean,
		detail?: ContractShipmentFormModel
	) => {
		dispatch(
			contractShipmentActions.ContractShipmentShowDetail({
				is_open_dialog: isOpen,
				detail: detail,
			})
		);
	};

	const deleteMultiHandle = (isOpen: boolean) => {
		dispatch(
			contractShipmentActions.ContractShipmentShowConfirm({
				is_open_confirm: isOpen,
			})
		);
	};

	const selectedIdsChange = (ids: number[]) => {
		dispatch(
			contractShipmentActions.ContractShipmentSelectedIdsChange({
				selected_ids: ids,
			})
		);
	};

	const deleteMultiConfirm = useCallback(async () => {
		if (selected_ids.length > 0) await deletesAction(selected_ids).unwrap();
	}, [deletesAction, selected_ids]);

	const changeStatusHandle = (isOpen: boolean) => {
		dispatch(
			contractShipmentActions.ContractShipmentChangeStatus({
				is_open_change_status: isOpen,
			})
		);
	};

	const columns: ColumnDef<ContractShipment>[] = [
		{
			accessorKey: 'shipment_status_id',
			header: 'Trạng thái',
			cell: ({ row }) => {
				return (
					<div className='text-center'>
						<TransportStatusBadge value={row.original.shipment_status_id} />
					</div>
				);
			},
		},
		{
			id: 'action_button',
			header: '...',
			cell: ({ row }) => {
				return (
					<div className='text-center'>
						<DropdownMenu>
							<DropdownMenuTrigger asChild>
								<Button size='icon' variant='outline' className='h-5 w-5'>
									<EllipsisIcon className='h-3.5 w-3.5' />
									<span className='sr-only'>More</span>
								</Button>
							</DropdownMenuTrigger>
							<DropdownMenuContent align='start'>
								<DropdownMenuItem>
									<ButtonCustom
										variant={'link'}
										icon={<FileArchiveIcon className='h-3.5 w-3.5' />}
										onClick={() => {
											addEditHandle(
												true,
												row.original as ContractShipmentFormModel
											);
										}}
										className='text-black h-5'
										title='Chỉnh sửa'
									/>
								</DropdownMenuItem>
							</DropdownMenuContent>
						</DropdownMenu>
					</div>
				);
			},
		},
		{
			id: 'vehicle_license_plate',
			header: 'Thông tin điều vận',
			cell: ({ row }) => {
				return (
					<div className='text-left text-xxs'>
						<div className='text-xs font-semibold'>
							{row.original.vehicle_license_plate || 'N/A'}
						</div>
						<div className='text-xxs'>
							Tài xế: <b>{row.original.driver_name}</b>; Sđt:{' '}
							<b>{row.original.driver_phone_number}</b>
						</div>
						<div className='text-xxs'>
							Trọng tải:{' '}
							<b>{row.original.vehicle_capacity.toLocaleString('vi')} (KG)</b>
						</div>
						<div className='text-xxs'>
							Số lượng hàng:{' '}
							<b>{row.original.goods_quantity.toLocaleString('vi')} (KG)</b>
						</div>
					</div>
				);
			},
		},
		{
			id: 'start_weighing_slip_file',
			header: 'Cân tại điểm nhận hàng',
			enableSorting: false,
			cell: ({ row }) => {
				return (
					<div className='text-right text-xxs'>
						<div className='text-xxs'>
							{formatDate(row.original.start_date || '', 'dd/MM/yyyy')} -{' '}
							<b>{row.original.start_address}</b>
						</div>
						<div className='text-xxs'>
							Xe và hàng: {row.original.start_total_weight.toLocaleString('vi')}
						</div>
						<div className='text-xxs'>
							Xe: {row.original.start_vehicle_weight.toLocaleString('vi')}
						</div>
						<div className='text-xxs'>
							Hàng: {row.original.start_goods_weight.toLocaleString('vi')}
						</div>
					</div>
				);
			},
		},
		{
			id: 'end_weighing_slip_file',
			header: 'Cân tại điểm giao hàng',
			enableSorting: false,
			cell: ({ row }) => {
				return (
					<div className='text-right text-xxs'>
						<div className='text-xxs'>
							{formatDate(row.original.end_date || '', 'dd/MM/yyyy')} -{' '}
							<b>{row.original.end_address}</b>
						</div>
						<div className='text-xxs'>
							Xe và hàng: {row.original.end_total_weight.toLocaleString('vi')}
						</div>
						<div className='text-xxs'>
							Xe: {row.original.end_vehicle_weight.toLocaleString('vi')}
						</div>
						<div className='text-xxs'>
							Hàng: {row.original.end_goods_weight.toLocaleString('vi')}
						</div>
					</div>
				);
			},
		},
		{
			id: 'shipping_cost',
			header: 'Chi phí vận chuyển',
			enableSorting: false,
			cell: ({ row }) => {
				return (
					<div className='text-center text-xxs'>
						<div className='text-xxs'>
							{row.original.road_cost.toLocaleString('vi')}
						</div>
					</div>
				);
			},
		},
	];

	return (
		<>
			<SheetDialog
				sheetTitle={`Danh sách ${title} của ${sheetTitle} (${dispatch_info?.code})`}
				isOpen={isOpen}
				onOpenChange={onOpenChange}
				hasScroll={true}
				width='w-full'
			>
				<div className='text-left text-xs'>
					<p>
						Hàng hoá:{' '}
						<b>
							{dispatch_info?.goods_code} - {dispatch_info?.goods_name}
						</b>
						; Số lượng:{' '}
						<b>{dispatch_info?.goods_quantity.toLocaleString('vi')} (KG)</b>;
						Quy cách: <b>{dispatch_info?.goods_packaging_requirements}</b>
					</p>
					<p>
						Thời gian:{' '}
						<b>
							{formatDate(
								dispatch_info?.begin_date || '1900-01-01',
								'dd/MM/yyyy'
							)}
						</b>{' '}
						-{' '}
						<b>
							{formatDate(
								dispatch_info?.end_date || '1900-01-01',
								'dd/MM/yyyy'
							)}
						</b>
						; Địa chỉ: <b>{dispatch_info?.address}</b>
					</p>
				</div>
				<DataTable
					isLoading={isFetching || isLoading}
					toolBars={
						<>
							<ButtonAdd onClick={() => addEditHandle(true)} />
							<ButtonCustom
								title='Đổi trạng thái'
								icon={<TagIcon className='h-4 w-4' />}
								disabled={selected_ids.length <= 0}
								onClick={() => changeStatusHandle(true)}
							/>
							<ButtonDelete
								variant={'destructive'}
								title={`Xóa (${selected_ids.length})`}
								disabled={selected_ids.length <= 0}
								onClick={() => deleteMultiHandle(true)}
							/>
						</>
					}
					data={data || []}
					columns={columns}
					keyExpr={'id'}
					searchKey={'vehicle_license_plate'}
					onReload={refetch}
					selectionRows={{
						selectedRowsKey: selected_ids,
						onSelectedRowsKeyChange: (values) =>
							selectedIdsChange(values as number[]),
					}}
				/>
			</SheetDialog>
			{is_open_dialog && (
				<ContractShipmentDetailSheet
					title={title}
					isOpen={is_open_dialog}
					onOpenChange={() => addEditHandle(false)}
					detail={
						detail || {
							...ContractShipmentFormDefault,
							contract_dispatch_id: dispatch_id,
							start_address: dispatch_info?.address || '',
							start_date:
								dispatch_info?.begin_date ||
								ContractShipmentFormDefault.start_date,
							end_date:
								dispatch_info?.end_date || ContractShipmentFormDefault.end_date,
						}
					}
					goods_id={dispatch_info?.goods_id || 0}
					document_type={dispatch_info?.document_type || ''}
					contract_dispatch_id={dispatch_id || 0}
				/>
			)}
			{is_open_change_status && (
				<ContractShipmentChangeStatusDialog
					is_open={is_open_change_status}
					onOpenChange={changeStatusHandle}
					contract_ids={selected_ids}
				/>
			)}
			{is_open_confirm && (
				<ConfirmDialog
					isOpen={is_open_confirm}
					onClose={() => deleteMultiHandle(false)}
					onConfirm={() => deleteMultiConfirm()}
					title='Thông báo'
					description={`Bạn có chắc chắn muốn xóa ${selected_ids.length} ${title} này không?`}
				/>
			)}
		</>
	);
};

export default ContractShipmentListSheet;
