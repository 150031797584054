import { FormCombobox } from '@/components/common/form';
import { companyInfoActions } from '@/contexts/reducers/contract-management/catalog/company-info-slice';
import { AppState, useAppDispatch, useAppSelector } from '@/contexts/store';
import { useCatalogCompanyInfo } from '@/hooks/catalog/use-catalog-company-info';
import CompanyInfoDetail from '@/pages/contract-management/catalog/company-info/detail';
import React from 'react';

interface CompanyInfoComboboxProps {
	label?: string;
	isRequired?: boolean;
	name: string;
	value?: any;
	defaultValue?: any;
	onValueChange?: (value?: any) => void;
	onDataChange?: (value?: any) => void;
	isDisabled?: boolean;
	itemRender?: (data: any) => React.ReactNode;
	isClearable?: boolean;
	isLoading?: boolean;
}

export const CompanyInfoCombobox = ({
	name,
	value,
	...rest
}: CompanyInfoComboboxProps) => {
	const dispatch = useAppDispatch();
	const { all_catalog_company_infos } = useCatalogCompanyInfo();
	const { is_open_dialog } = useAppSelector(
		(state: AppState) => state.Catalog.CompanyInfo
	);
	const addHandle = (isOpen: boolean) => {
		dispatch(
			companyInfoActions.CompanyInfoShowDetail({ is_open_dialog: isOpen })
		);
	};
	return (
		<>
			<FormCombobox
				name={name}
				dataSource={all_catalog_company_infos}
				addAction={{
					hasAddAction: true,
					onAddItem: () => addHandle(true),
				}}
				displayExpr='name'
				valueExpr='id'
				placeholder='Chọn thông tin...'
				{...rest}
			/>
			{is_open_dialog && (
				<CompanyInfoDetail
					isOpen={is_open_dialog}
					onOpenChange={() => addHandle(false)}
					title='thông tin công ty'
				/>
			)}
		</>
	);
};
