import { Button } from '@/components/base/ui/button';
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from '@/components/base/ui/dropdown-menu';
import { cn } from '@/lib/utils';
import { CaretSortIcon, EyeNoneIcon } from '@radix-ui/react-icons';
import { Column } from '@tanstack/react-table';
import { ArrowDownIcon, ArrowUpIcon } from 'lucide-react';

interface DataTableColumnHeaderProps<TData, TValue>
	extends React.HTMLAttributes<HTMLDivElement> {
	column: Column<TData, TValue>;
	title: string;
}

export function DataTableColumnHeader<TData, TValue>({
	column,
	title,
	className,
	...rest
}: DataTableColumnHeaderProps<TData, TValue>) {
	if (!column.getCanSort()) {
		return <div className={cn(className)}>{title}</div>;
	}

	return (
		<div className={cn('flex text-center items-center justify-center', className)} {...rest}>
			<DropdownMenu>
				<DropdownMenuTrigger asChild>
					<Button
						variant='link'
						size='sm'
						className='p-2 h-8 data-[state=open]:bg-accent'
					>
						<span>{title}</span>
						{column.getIsSorted() === 'desc' ? (
							<ArrowDownIcon className='h-4 w-4' />
						) : column.getIsSorted() === 'asc' ? (
							<ArrowUpIcon className='h-4 w-4' />
						) : (
							<CaretSortIcon className='h-4 w-4' />
						)}
					</Button>
				</DropdownMenuTrigger>
				<DropdownMenuContent align='start'>
					<DropdownMenuItem onClick={() => column.toggleSorting(false)}>
						<ArrowUpIcon className='mr-2 h-3.5 w-3.5 text-muted-foreground/70' />
						<small>Tăng dần</small>
					</DropdownMenuItem>
					<DropdownMenuItem onClick={() => column.toggleSorting(true)}>
						<ArrowDownIcon className='mr-2 h-3.5 w-3.5 text-muted-foreground/70' />
						<small>Giảm dần</small>
					</DropdownMenuItem>
					{column.getCanHide() && (
						<>
							<DropdownMenuSeparator />
							<DropdownMenuItem onClick={() => column.toggleVisibility(false)}>
								<EyeNoneIcon className='mr-2 h-3.5 w-3.5 text-muted-foreground/70' />
								<small>Ẩn</small>
							</DropdownMenuItem>
						</>
					)}
				</DropdownMenuContent>
			</DropdownMenu>
		</div>
	);
}
