import { baseApiUrl } from '@/contexts/base/base-api-url';
import { axiosBaseQuery } from '@/contexts/base/base-axios-query';
import { createApi } from '@reduxjs/toolkit/query/react';

export const apiSlice = createApi({
	reducerPath: 'api',
	baseQuery: axiosBaseQuery({
		baseUrl: baseApiUrl,
	}),
	endpoints: (builder) => ({}),
});
