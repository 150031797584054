import { FormField } from '@/components/base/ui/form';
import { Cardbox } from '@/components/common';
import { FormInput } from '@/components/common/form';
import FromDateTimePicker from '@/components/common/form/form-datetime-picker';
import { Goods } from '@/contexts/models/contract-management/catalog';
import { ContractQuotationFormModel } from '@/contexts/models/contract-management/contract/ContractQuotation';
import { convertNumberToWords } from '@/utils/convert-number-to-words';
import { formatDate } from 'date-fns';
import { useEffect } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { CustomerInfoCard } from '../card/customer-info-card';
import { GoodsInfoCard } from '../card/goods-info-card';

interface IQuotationDetailFormProps {
	isSaving: boolean;
	formData: ContractQuotationFormModel;
	form: UseFormReturn<ContractQuotationFormModel, any, undefined>;
	// changeGoodsInfo: (contract?: ContractInfo) => void;
}

const initGoodsData: Goods = {
	id: 0,
	code: '',
	description: '',
	is_active: true,
	name: '',
	physical_characteristics: '',
	sensory_characteristics: '',
};

export const QuotationDetailForm = ({
	isSaving,
	formData,
	form,
	// changeGoodsInfo,
}: IQuotationDetailFormProps) => {
	const { reset, control, setValue } = form;

	const watchChangeGoodsAmount = useWatch({
		control,
		name: 'quotation_amount',
		defaultValue: 0,
	});

	// const changeGoodsInfo = (contract?: ContractInfo) => {
	// 	setValue('contract_id', contract?.id || 0);
	// 	setValue('goods_id', contract?.goods_id || 0);
	// 	setValue('goods_code', contract?.goods_code || '');
	// 	setValue('goods_description', contract?.goods_description || '');
	// 	setValue('goods_name', contract?.goods_name || '');
	// 	setValue('goods_physical_characteristics', contract?.goods_physical_characteristics || '');
	// 	setValue('goods_sensory_characteristics', contract?.goods_sensory_characteristics || '');
	// 	setValue('goods_quantity', contract?.goods_quantity || 0);
	// 	setValue('goods_price', contract?.goods_price || 0);
	// 	setValue('quotation_price', contract?.goods_price || 0);
	// };

	// const changeDataValue = (data?: Goods) => {
	// 	const selectedData = data || initGoodsData;
	// 	setValue('goods_id', selectedData.id);
	// 	setValue('goods_code', selectedData.code);
	// 	setValue('goods_description', selectedData.description);
	// 	setValue('goods_name', selectedData.name);
	// 	setValue(
	// 		'goods_physical_characteristics',
	// 		selectedData.physical_characteristics
	// 	);
	// 	setValue(
	// 		'goods_sensory_characteristics',
	// 		selectedData.sensory_characteristics
	// 	);
	// };

	useEffect(() => {
		const slug = convertNumberToWords(watchChangeGoodsAmount);
		setValue('quotation_amount_words', slug);
	}, [watchChangeGoodsAmount, setValue]);

	return (
		<>
			<Cardbox
				title='Thông tin'
				className='sm:grid md:grid-cols-* lg:grid-cols-4'
			>
				{/* <FormField
					control={form.control}
					name='contract_id'
					render={({ field }) => (
						<ContractCombobox
							isRequired={true}
							name={field.name}
							value={field.value}
							onValueChange={(value) => field.onChange(value)}
							onDataChange={(value) => {
								changeGoodsInfo(value);
							}}
							isDisabled={isSaving}
							label='Hợp đồng mua hàng'
							classForm='col-span-2'
							document_type={eDocumentType.HOP_DONG_MUA_HANG}
						/>
					)}
				/> */}
				{/* <FormField
					control={form.control}
					name='goods_id'
					render={({ field }) => (
						<GoodsCombobox
							isRequired={true}
							name={field.name}
							defaultValue={0}
							value={field.value}
							onValueChange={(value) => field.onChange(parseInt(value || '0'))}
							onDataChange={(data) => changeDataValue(data)}
							isDisabled={isSaving}
							label='Hàng hóa'
						/>
					)}
				/> */}
				<FormField
					control={form.control}
					name='quotation_date'
					render={({ field }) => (
						<FromDateTimePicker
							isRequired={true}
							name={field.name}
							value={field.value}
							onValueChange={field.onChange}
							isDisabled={isSaving}
							label='Ngày lập báo giá'
						/>
					)}
				/>
				<FormField
					control={form.control}
					name='quotation_code'
					render={({ field }) => (
						<FormInput
							isRequired={true}
							name={field.name}
							value={field.value}
							onValueChange={field.onChange}
							isDisabled={isSaving}
							label='Số chào hàng báo giá'
							placeholder={`---.${formatDate(formData.quotation_date, 'ddMMyy')}/BG/RC-TA`}
							useInputMask={{
								mask: `___.${formatDate(formData.quotation_date, 'ddMMyy')}/BG/RC-TA`,
								replacement: { _: /\d/ },
							}}
						/>
					)}
				/>
			</Cardbox>
			<div className='sm:grid lg:grid-cols-2 space-x-2'>
				<GoodsInfoCard
					quotationData={formData}
					form={form}
					isLoading={isSaving}
				/>
				<CustomerInfoCard isLoading={isSaving} form={form} />
			</div>
		</>
	);
};
