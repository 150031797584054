import { ContractInfoFormModel } from '@/contexts/models/contract-management/contract';
import { createQueryString } from '@/utils/create-query-string';
import { NotifyHelper } from '@/utils/notify-helper';
import { apiSlice } from '../../api-slice';
import { eContractDeliveryCacheTag } from './contract-delivery-api';

const endpointApi = '/contract-info';
const entityName = 'Hợp đồng';
const entityCacheTag = 'CONTRACT_INFO';

type ContractInfoSelectRequest = {
	document_type: string;
	ref_contract_id?: number;
	quotation_id?: number;
};

type ContractInfoChangeStatusRequest = {
	contract_status_id: string;
	ids: number[];
};

export const ContractInfoApi = apiSlice
	.enhanceEndpoints({ addTagTypes: [entityCacheTag, eContractDeliveryCacheTag] })
	.injectEndpoints({
		endpoints: (builder) => ({
			contractInfoGetAll: builder.query({
				query: (document_type: string, contract_id?: number) => ({
					url: `${endpointApi}${createQueryString({ document_type, contract_id })}`,
					method: 'GET',
				}),
				transformResponse: (response) =>
					response.result as ContractInfoFormModel[],

				providesTags: (result) =>
					result
						? [
								...result.map(({ id }) => ({
									type: `${entityCacheTag}` as const,
									id,
								})),
								{ type: entityCacheTag, id: 'LIST' },
							]
						: [{ type: entityCacheTag, id: 'LIST' }],
			}),
			contractInfoGetAllByContract: builder.query({
				query: (request: ContractInfoSelectRequest) => ({
					url: `${endpointApi}/select-by-contract${createQueryString({ ...request })}`,
					method: 'GET',
				}),
				transformResponse: (response) =>
					response.result as ContractInfoFormModel[],
				providesTags: (result) =>
					result
						? [
								...result.map(({ id }) => ({
									type: `${entityCacheTag}` as const,
									id,
								})),
								{ type: entityCacheTag, id: 'LIST' },
							]
						: [{ type: entityCacheTag, id: 'LIST' }],
			}),
			contractInfoGetAllByQuotation: builder.query({
				query: (request: ContractInfoSelectRequest) => ({
					url: `${endpointApi}/select-by-quotation${createQueryString({ ...request })}`,
					method: 'GET',
				}),
				transformResponse: (response) =>
					response.result as ContractInfoFormModel[],
				providesTags: (result) =>
					result
						? [
								...result.map(({ id }) => ({
									type: `${entityCacheTag}` as const,
									id,
								})),
								{ type: entityCacheTag, id: 'LIST' },
							]
						: [{ type: entityCacheTag, id: 'LIST' }],
			}),
			contractInfoGetDetail: builder.query({
				query: (id: number) => ({ url: `${endpointApi}/${id}`, method: 'GET' }),
				transformResponse: (response) =>
					response.result as ContractInfoFormModel,
				providesTags: (result, error, id) => [{ type: entityCacheTag, id }],
			}),
			contractInfoGetWordFile: builder.query({
				query: (id: number) => ({
					url: `${endpointApi}/${id}/word-file`,
					method: 'GET',
					isDownload: true,
				}),
			}),
			contractInfoGenerateCode: builder.query({
				query: (date: Date) => ({
					url: `${endpointApi}/generate-contract-code${createQueryString({ dateCreate: date })}`,
					method: 'GET',
				}),
				transformResponse: (response) => response.result as string,
			}),
			contractInfoSave: builder.mutation({
				query: (entity: ContractInfoFormModel) => ({
					url: endpointApi,
					method: entity.id > 0 ? 'PUT' : 'POST',
					data: entity,
				}),
				transformResponse: (response) =>
					response.result as ContractInfoFormModel,
				async onQueryStarted(_args, { queryFulfilled }) {
					try {
						const { data } = await queryFulfilled;
						if (data.id > 0)
							NotifyHelper.Success(`Lưu ${entityName} thành công`);
						else NotifyHelper.Error(`Lưu ${entityName} không thành công`);
					} catch (ex: any) {
						NotifyHelper.Error(ex?.error?.data || `Lưu ${entityName} có lỗi`);
					}
				},
				invalidatesTags: (result, error, arg) => [
					{ type: entityCacheTag, id: arg.id },
					{ type: entityCacheTag, id: 'LIST' },
					{ type: eContractDeliveryCacheTag, id: 'LIST_BY_CONTRACT' },
				],
			}),
			contractInfoDelete: builder.mutation({
				query: (id: number) => ({
					url: `${endpointApi}/${id}`,
					method: 'DELETE',
				}),
				transformResponse: (response) => response,
				async onQueryStarted(_args, { queryFulfilled }) {
					try {
						const { data } = await queryFulfilled;
						if (data.is_success)
							NotifyHelper.Success(
								data.message || `Xóa ${entityName} thành công`
							);
						else
							NotifyHelper.Error(data.message || `Xóa ${entityName} thất bại`);
					} catch (ex: any) {
						NotifyHelper.Error(ex?.error?.data || `Xóa ${entityName} có lỗi`);
					}
				},
				invalidatesTags: [{ type: entityCacheTag, id: 'LIST' }],
			}),
			contractInfoDeleteMultiple: builder.mutation({
				query: (ids: number[]) => ({
					url: `${endpointApi}/deletes`,
					method: 'DELETE',
					data: { ids },
				}),
				transformResponse: (response) => response,
				async onQueryStarted(_args, { queryFulfilled }) {
					try {
						const { data } = await queryFulfilled;
						if (data.is_success)
							NotifyHelper.Success(
								data.message || `Xóa ${entityName} thành công`
							);
						else
							NotifyHelper.Error(data.message || `Xóa ${entityName} thất bại`);
					} catch (ex: any) {
						NotifyHelper.Error(ex?.error?.data || `Xóa ${entityName} có lỗi`);
					}
				},
				invalidatesTags: [{ type: entityCacheTag, id: 'LIST' }],
			}),
			contractInfoChangeStatus: builder.mutation({
				query: (request: ContractInfoChangeStatusRequest) => ({
					url: `${endpointApi}/change-status`,
					method: 'PUT',
					data: request,
				}),
				transformResponse: (response) => response,
				async onQueryStarted(_args, { queryFulfilled }) {
					try {
						const { data } = await queryFulfilled;
						if (data.is_success)
							NotifyHelper.Success(
								data.message || `Thay đổi trạng thái ${entityName} thành công`
							);
						else
							NotifyHelper.Error(
								data.message || `Thay đổi trạng thái ${entityName} thất bại`
							);
					} catch (ex: any) {
						NotifyHelper.Error(
							ex?.error?.data || `Thay đổi trạng thái ${entityName} có lỗi`
						);
					}
				},
				invalidatesTags: [{ type: entityCacheTag, id: 'LIST' }],
			}),
		}),
	});

export const {
	useLazyContractInfoGetAllQuery,
	useContractInfoGetAllQuery,
	useContractInfoDeleteMultipleMutation,
	useContractInfoDeleteMutation,
	useContractInfoSaveMutation,
	useLazyContractInfoGetDetailQuery,
	useContractInfoGetDetailQuery,
	useContractInfoGenerateCodeQuery,
	useLazyContractInfoGenerateCodeQuery,
	useContractInfoGetWordFileQuery,
	useLazyContractInfoGetWordFileQuery,
	useContractInfoGetAllByContractQuery,
	useContractInfoGetAllByQuotationQuery,
	useLazyContractInfoGetAllByContractQuery,
	useLazyContractInfoGetAllByQuotationQuery,
	useContractInfoChangeStatusMutation
} = ContractInfoApi;
