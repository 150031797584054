import Combobox from '@/components/common/combobox';
import { FormCombobox } from '@/components/common/form';
import { useContractDispatchGetByGoodsQuery } from '@/contexts/api/contract-management/contract/contract-dispatch-api';

interface TransportStatusComboboxProps {
	document_type: string;
	label?: string;
	isRequired?: boolean;
	name: string;
	value?: any;
	defaultValue?: any;
	onValueChange?: (value?: any) => void;
	onDataChange?: (value?: any) => void;
	isDisabled?: boolean;
	itemRender?: (data: any) => React.ReactNode;
	isClearable?: boolean;
	isLoading?: boolean;
	goods_id: number;
}

const ContractDispatchByGoodsCombobox = ({
	document_type,
	goods_id,
	name,
	value,
	...rest
}: TransportStatusComboboxProps) => {
	const { data: contract_dispatchs } = useContractDispatchGetByGoodsQuery({
		goods_id,
		document_type,
	});
	return (
		<>
			<FormCombobox
				name={name}
				value={value}
				dataSource={contract_dispatchs || []}
				displayExpr='title'
				valueExpr='id'
				width='w-full w-max-full'
				placeholder='Chọn đợt giao hàng...'
				{...rest}
			/>
		</>
	);
};

export default ContractDispatchByGoodsCombobox;
