import { ThemeProvider } from '@/lib/theme-provider.tsx';
import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { appRoutes } from './app-routes';
import { TooltipProvider } from './components/base/ui/tooltip';
import './globals.css';
import { store } from '@/contexts/store';
import LoadingScreen from '@/components/base/skeleton/loading-screen';
import { Toaster } from './components/base/ui/sonner';
import { I18nProvider } from 'react-aria';

ReactDOM.createRoot(document.getElementById('root')!).render(
	// <React.StrictMode>
	<Provider store={store}>
		<React.Suspense fallback={<LoadingScreen />}>
			<I18nProvider locale='vi-VN'>
				<ThemeProvider defaultTheme='light' storageKey='cms-zenax-theme'>
					<TooltipProvider delayDuration={0}>
						<RouterProvider router={appRoutes} />
					</TooltipProvider>
					<Toaster
						toastOptions={{
							unstyled: false,
							classNames: {
								error: 'bg-red-50 text-red-600',
								success: 'bg-green-50 text-green-600',
								warning: 'bg-yellow-50 text-yellow-600',
								info: 'bg-blue-50 text-blue-600',
							},
							duration: 2000,
						}}
					/>
				</ThemeProvider>
			</I18nProvider>
		</React.Suspense>
	</Provider>
	// </React.StrictMode>
);
