import React from 'react';

export const BaoCaoPage = React.lazy(() => import('./report-001'));
export const XuatNhapTonPage = React.lazy(() => import('./xuat-nhap-ton'));
export const KeHoachGiaoHangPage = React.lazy(() => import('./ke-hoach-giao-hang'));
export const ThucHienGiaoHangPage = React.lazy(() => import('./thuc-hien-giao-hang'));
export const TongHopGiaoHangPage = React.lazy(() => import('./tong-hop-giao-hang'));
export const VayTraHangHoaPage = React.lazy(() => import('./vay-tra-hang-hoa'));
export const CongNoVayTraHangHoaPage = React.lazy(() => import('./cong-no-vay-tra-hang-hoa'));
export const CongNoPage = React.lazy(() => import('./cong-no'));
