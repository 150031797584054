import { Badge } from '@/components/base/ui/badge';
import { buttonVariants } from '@/components/base/ui/button';
import IconLucide, { IconLucideIcons } from '@/components/base/ui/icon';
import {
	MenuAccordion,
	MenuAccordionContent,
	MenuAccordionItem,
	MenuAccordionTrigger,
} from '@/components/base/ui/menu-accordion';
import {
	Tooltip,
	TooltipContent,
	TooltipTrigger,
} from '@/components/base/ui/tooltip';
import { MenuModel } from '@/contexts/models/super-admin/Menu';
import { useAuth } from '@/hooks/use-auth';
import { createUUID } from '@/lib/create-uuid';
import { cn } from '@/lib/utils';
import { ChevronDownIcon } from 'lucide-react';
import React, { useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

interface MainMenuProps {
	isOpen?: boolean;
	setOpen?: (open: boolean) => void;
}

interface MenuTree {
	id: number;
	name: string;
	path: string;
	icon: keyof typeof IconLucideIcons;
	parent_id: number;
	sort_order: number;
	is_active: boolean;
	count_data: number;
	childs?: MenuTree[];
	variant: 'default' | 'ghost';
	tree_ids: string;
}

const buildTree = (
	parent_id: number,
	tree_ids: string,
	path_name: string,
	menus: MenuModel[]
) => {
	return menus
		.filter((m) => m.parent_id === parent_id)
		.sort((a, b) => a.sort_order - b.sort_order)
		.map((menu) => {
			const current_tree_ids = `${tree_ids}p${menu.id};`;
			const menuPath = menu.path.replace(/^\|/, '');
			const menuTree: MenuTree = {
				...menu,
				is_active: path_name === menuPath || path_name.includes(menuPath),
				variant: 'ghost',
				count_data: 0,
				icon: menu.icon as keyof typeof IconLucideIcons,
				childs: buildTree(menu.id, current_tree_ids, path_name, menus),
				tree_ids: current_tree_ids,
			};
			return menuTree;
		});
};

const MainMenu = React.memo(({ isOpen }: MainMenuProps) => {
	const { menus } = useAuth();
	const location = useLocation();
	const menuTrees: MenuTree[] = useMemo(() => {
		const result = menus
			? buildTree(0, 'p0;', location.pathname.replace(/^\|/, ''), menus)
			: [];
		return result;
	}, [menus, location]);

	const [openItem, setOpenItem] = useState('');
	const [lastOpenItem, setLastOpenItem] = useState('');

	useEffect(() => {
		if (isOpen) {
			setOpenItem(lastOpenItem);
		} else {
			setLastOpenItem(openItem);
			setOpenItem('');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen]);

	return (
		<>
			<nav className='grid gap-1 group-[[data-collapsed=true]]:justify-center group-[[data-collapsed=true]]:px-2 z-50'>
				{menuTrees.map((group) => (
					<MenuTopRender
						key={createUUID()}
						menu={group}
						isOpen={isOpen}
						openItem={openItem}
						setOpenItem={setOpenItem}
					/>
				))}
			</nav>
		</>
	);
});

const MenuTopRender = ({
	menu,
	isOpen,
	openItem,
	setOpenItem,
}: {
	menu: MenuTree;
	isOpen?: boolean;
	openItem: string;
	setOpenItem: (value: string) => void;
}) => {
	const isActive = useMemo(() => {
		const hasActiveChild = menu.childs?.some((p) => p.is_active) || false;
		return menu.is_active || hasActiveChild;
	}, [menu]);

	const childMenus = useMemo(() => {
		return menu.childs || [];
	}, [menu]);

	const isParent = useMemo(() => {
		return childMenus.length > 0;
	}, [childMenus]);

	return (
		<>
			<MenuAccordion
				type='single'
				collapsible={isParent}
				key={menu.id}
				value={openItem}
				onValueChange={(value) => setOpenItem(value)}
			>
				<MenuAccordionItem
					value={`menu_${menu.id}`}
					className={cn('border-none')}
				>
					<MenuAccordionTrigger
						className={cn(
							buttonVariants({ variant: 'link' }),
							'group w-full relative flex justify-between p-0 text-base duration-200 hover:no-underline',
							'px-2 border-b-2 rounded-bl-lg hover:bg-transparent hover:no-underline hover:border-b-primary hover:text-primary',
							isActive && 'border-b-primary text-primary'
						)}
						asChild={true}
					>
						<div>
							<MenuTooltipParent menu={menu} isOpen={isOpen} />
							{isParent && (
								<ChevronDownIcon className='h-3.5 w-3.5 shrink-0 text-muted-foreground transition-transform duration-200' />
							)}
						</div>
					</MenuAccordionTrigger>
					{menu.childs && menu.childs.length > 0 && (
						<MenuAccordionContent
							className={cn(
								'mt-2 space-y-2 pb-1 ml-2 pl-2 border-l-2 rounded-bl-lg text-xs'
							)}
						>
							{<MenuSubRender isOpen={isOpen} menus={childMenus} />}
						</MenuAccordionContent>
					)}
				</MenuAccordionItem>
			</MenuAccordion>
		</>
	);
};

const MenuSubRender = ({
	menus,
	isOpen,
}: {
	menus: MenuTree[];
	isOpen?: boolean;
}) => {
	const [openItem, setOpenItem] = useState('');
	const [lastOpenItem, setLastOpenItem] = useState('');

	useEffect(() => {
		if (isOpen) {
			setOpenItem(lastOpenItem);
		} else {
			setLastOpenItem(openItem);
			setOpenItem('');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen]);
	return (
		<>
			{menus.map((group) => (
				<MenuChildRender
					key={createUUID()}
					menu={group}
					isOpen={isOpen}
					openItem={openItem}
					setOpenItem={setOpenItem}
				/>
			))}
		</>
	);
};

const MenuChildRender = ({
	menu,
	isOpen,
	openItem,
	setOpenItem,
}: {
	menu: MenuTree;
	isOpen?: boolean;
	openItem: string;
	setOpenItem: (value: string) => void;
}) => {
	const isActive = useMemo(() => {
		const hasActiveChild = menu.childs?.some((p) => p.is_active) || false;
		return menu.is_active || hasActiveChild;
	}, [menu]);

	const childMenus = useMemo(() => {
		return menu.childs ?? [];
	}, [menu]);

	const isParent = useMemo(() => {
		return childMenus.length > 0;
	}, [childMenus]);

	return (
		<>
			<MenuAccordion
				type='single'
				collapsible={isParent}
				key={menu.id}
				value={openItem}
				onValueChange={(value) => setOpenItem(value)}
			>
				<MenuAccordionItem
					value={`menu_${menu.id}`}
					className={cn('border-none')}
				>
					<MenuAccordionTrigger
						className={cn(
							buttonVariants({ variant: 'link' }),
							'group w-full relative flex justify-between p-0 text-base duration-200 hover:no-underline',
							'px-2 border-b-2 rounded-bl-lg hover:bg-transparent hover:no-underline hover:border-b-primary hover:text-primary',
							isActive && 'border-b-primary text-primary'
						)}
						asChild={true}
					>
						<div>
							<MenuTooltipChild menu={menu} isOpen={isOpen} />
							{isParent && (
								<ChevronDownIcon className='h-2 w-2 shrink-0 text-muted-foreground transition-transform duration-200' />
							)}
						</div>
					</MenuAccordionTrigger>
					{menu.childs && menu.childs.length > 0 && (
						<MenuAccordionContent
							className={cn(
								'mt-2 space-y-2 pb-1 ml-2 pl-2 border-l-2 rounded-bl-lg text-xs'
							)}
						>
							{<MenuSubRender isOpen={isOpen} menus={childMenus} />}
						</MenuAccordionContent>
					)}
				</MenuAccordionItem>
			</MenuAccordion>
		</>
	);
};

const MenuTooltipChild = ({
	menu,
	isOpen,
}: {
	menu: MenuTree;
	isOpen?: boolean;
}) => {
	return (
		<>
			<Tooltip key={createUUID()} delayDuration={0}>
				<TooltipTrigger asChild>
					<Link
						key={createUUID()}
						to={menu.path}
						className={cn(
							buttonVariants({ variant: 'link', size: 'sm' }),
							'w-full p-0 m-0 justify-start'
						)}
					>
						<IconLucide name={menu.icon} className='mr-2 h-3.5 w-3.5' />
						{isOpen && (
							<span
								className={cn(
									'text-left truncate',
									menu.count_data > 0 ? 'w-20' : 'w-28'
								)}
							>
								{menu.name}
							</span>
						)}
						{menu.count_data > 0 && (
							<Badge
								variant='outline'
								className='px-2 bg-green-100 text-green-600 text-xs'
							>
								{menu.count_data > 100 ? '99+' : menu.count_data}
							</Badge>
						)}
					</Link>
				</TooltipTrigger>
				<TooltipContent
					side='right'
					className='flex items-center gap-4 line-clamp-4 text-xs'
					sideOffset={25}
				>
					{menu.name}
					{menu.count_data > 0 && (
						<Badge
							variant='outline'
							className='ml-2 px-2 bg-secondar text-white'
						>
							{menu.count_data}
						</Badge>
					)}
				</TooltipContent>
			</Tooltip>
		</>
	);
};

const MenuTooltipParent = ({
	menu,
	isOpen,
}: {
	menu: MenuTree;
	isOpen?: boolean;
}) => {
	const hasChild = menu.childs && menu.childs.length > 0;
	return (
		<>
			<Tooltip key={createUUID()} delayDuration={0}>
				<TooltipTrigger asChild>
					{hasChild ? (
						<>
							<div
								key={createUUID()}
								className={cn(
									buttonVariants({ variant: 'link', size: 'sm' }),
									'w-full p-0 m-0 justify-start'
								)}
							>
								<IconLucide name={menu.icon} className='mr-2 h-3.5 w-3.5' />
								{isOpen && (
									<span
										className={cn(
											'text-left truncate',
											menu.count_data > 0 ? 'w-20' : 'w-28'
										)}
									>
										{menu.name}
									</span>
								)}
								{menu.count_data > 0 && (
									<Badge
										variant='outline'
										className='px-2 bg-green-100 text-green-600 text-xs'
									>
										{menu.count_data > 100 ? '99+' : menu.count_data}
									</Badge>
								)}
							</div>
						</>
					) : (
						<>
							<Link
								key={createUUID()}
								to={hasChild ? '#' : menu.path}
								className={cn(
									buttonVariants({ variant: 'link', size: 'sm' }),
									'w-full p-0 m-0 justify-start'
								)}
							>
								<IconLucide name={menu.icon} className='mr-2 h-3.5 w-3.5' />
								{isOpen && (
									<span
										className={cn(
											'text-left truncate',
											menu.count_data > 0 ? 'w-20' : 'w-28'
										)}
									>
										{menu.name}
									</span>
								)}
								{menu.count_data > 0 && (
									<Badge
										variant='outline'
										className='px-2 bg-green-100 text-green-600 text-xs'
									>
										{menu.count_data > 100 ? '99+' : menu.count_data}
									</Badge>
								)}
							</Link>
						</>
					)}
				</TooltipTrigger>
				<TooltipContent
					side='right'
					className='flex items-center gap-4 line-clamp-4 text-xs'
					sideOffset={25}
				>
					{menu.name}
					{menu.count_data > 0 && (
						<Badge
							variant='outline'
							className='ml-2 px-2 bg-secondar text-white'
						>
							{menu.count_data}
						</Badge>
					)}
				</TooltipContent>
			</Tooltip>
		</>
	);
};

export default MainMenu;
