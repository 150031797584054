import { FormCombobox } from '@/components/common/form';
import { partnerActions } from '@/contexts/reducers/contract-management/catalog/partner-slice';
import { AppState, useAppDispatch, useAppSelector } from '@/contexts/store';
import { useCatalogPartner } from '@/hooks/catalog/use-catalog-partner';
import PartnerDetail from '@/pages/contract-management/catalog/partner/detail';
import React, { useMemo } from 'react';

interface PartnerComboboxProps {
	label?: string;
	isRequired?: boolean;
	name: string;
	value?: any;
	defaultValue?: any;
	onValueChange?: (value?: any) => void;
	onDataChange?: (value?: any) => void;
	isDisabled?: boolean;
	itemRender?: (data: any) => React.ReactNode;
	isClearable?: boolean;
	isLoading?: boolean;
}

export const PartnerCombobox = ({
	name,
	value,
	...rest
}: PartnerComboboxProps) => {
	const dispatch = useAppDispatch();
	const { all_catalog_partners } = useCatalogPartner();
	const { is_open_dialog, detail } = useAppSelector(
		(state: AppState) => state.Catalog.Partner
	);

	const dataSource = useMemo(() => {
		return all_catalog_partners.map((item) => ({
			...item,
			name_code: `${item.code} - ${item.name}`,
		}));
	}, [all_catalog_partners]);

	const addHandle = (isOpen: boolean) => {
		dispatch(partnerActions.PartnerShowDetail({ is_open_dialog: isOpen }));
	};
	return (
		<>
			<FormCombobox
				name={name}
				dataSource={dataSource}
				addAction={{
					hasAddAction: true,
					onAddItem: () => addHandle(true),
				}}
				displayExpr='name_code'
				valueExpr='id'
				placeholder='Chọn đối tác...'
				{...rest}
			/>
			{is_open_dialog && (
				<PartnerDetail
					isOpen={is_open_dialog}
					onOpenChange={() => addHandle(false)}
					title='đối tác'
				/>
			)}
		</>
	);
};
