import notifySignalrConnector from '@/lib/notify-signalr-connector';
import { useEffect } from 'react';
import { NotifyHelper } from './notify-helper';
import { useLocation, useNavigate } from 'react-router-dom';
import LoadingScreen from '@/components/base/skeleton/loading-screen';
import { useDebounce } from '@/hooks/use-debounce';
import { useProfileInfoQuery } from '@/contexts/api/auth/auth-api';

const AuthProvider = ({ children }: { children: any }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const { events } = notifySignalrConnector();
	const { data, isLoading, isSuccess, isError } = useProfileInfoQuery();

	const debouncedValue = useDebounce(
		{ data, isLoading, isSuccess, isError },
		500
	);

	useEffect(() => {
		if (
			(debouncedValue.isSuccess || debouncedValue.isError) &&
			!debouncedValue.data
		) {
			navigate('/login', { replace: false, state: { from: location } });
		}
	}, [debouncedValue, navigate, location]);

	useEffect(() => {
		events((message: any) => {
			if (message) NotifyHelper.Notify(message);
		});
	}, [events]);

	if (debouncedValue.isLoading || !data) {
		return <LoadingScreen />;
	}

	return <>{children}</>;
};
export default AuthProvider;
