import Combobox from '@/components/common/combobox';
import { useContractStatus } from '@/hooks/catalog/use-contract-status';
import { cn } from '@/lib/utils';
import { DotFilledIcon } from '@radix-ui/react-icons';
import { da } from 'date-fns/locale';

interface ContractStatusComboboxProps {
	value: string;
	onValueChange: (value: string) => void;
	name: string;
	label?: string;
	isDisabled?: boolean;
	helpBlock?: string;
}

const ContractStatusCombobox = ({
	value,
	onValueChange,
	...rest
}: ContractStatusComboboxProps) => {
	const { all_contract_status } = useContractStatus();
	return (
		<>
			<Combobox
				dataSource={all_contract_status || []}
				valueExpr={'id'}
				displayExpr={'name'}
				value={value}
				onValueChange={(value) => {
					onValueChange(value);
				}}
				placeholder='Chọn trạng thái...'
				{...rest}
				width='w-full w-max-full'
				itemRender={(data) => (
					<div className={cn('inline-flex', data.class)}>
						<DotFilledIcon className={cn('w-3 h-3')} /> {data.name}
					</div>
				)}
			/>
		</>
	);
};

export default ContractStatusCombobox;
