import {
	Card,
	CardContent,
	CardDescription,
	CardHeader,
	CardTitle,
} from '@/components/base/ui/card';
import { FormField } from '@/components/base/ui/form';
import { FormCombobox, FormInput } from '@/components/common/form';
import FormInputNumber from '@/components/common/form/form-input-number';
import FormTextarea from '@/components/common/form/form-text-area';
import { Goods } from '@/contexts/models/contract-management/catalog';
import { ContractQuotationFormModel } from '@/contexts/models/contract-management/contract/ContractQuotation';
import { useCatalogGoods } from '@/hooks/catalog/use-catalog-goods';
import { cn } from '@/lib/utils';
import PhysicalCard from '@/pages/contract-management/catalog/goods/items/physical-card';
import SensoryCard from '@/pages/contract-management/catalog/goods/items/sensory-card';
import { useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';

interface GoodsInfoCardProps {
	form: UseFormReturn<ContractQuotationFormModel, any, undefined>;
	isLoading: boolean;
	quotationData: ContractQuotationFormModel;
}
const initGoodsData: Goods = {
	id: 0,
	code: '',
	description: '',
	is_active: true,
	name: '',
	physical_characteristics: '',
	sensory_characteristics: '',
};

export const GoodsInfoCard = ({
	form,
	isLoading,
	quotationData,
}: GoodsInfoCardProps) => {
	const { setValue } = form;
	const { all_catalog_goods } = useCatalogGoods();
	const [selectedGoodsInfoData, setSelectedGoodsInfoData] =
		useState<Goods>(initGoodsData);

	const changeDataValue = (data?: Goods) => {
		const selectedData = data || initGoodsData;
		setSelectedGoodsInfoData(selectedData);
		setValue('goods_id', selectedData.id);
		setValue('goods_code', selectedData.code);
		setValue('goods_description', selectedData.description);
		setValue('goods_name', selectedData.name);
		setValue(
			'goods_physical_characteristics',
			selectedData.physical_characteristics
		);
		setValue(
			'goods_sensory_characteristics',
			selectedData.sensory_characteristics
		);
	};

	useEffect(() => {
		setSelectedGoodsInfoData({
			id: quotationData.goods_id,
			code: quotationData.goods_code,
			description: quotationData.goods_description,
			is_active: true,
			name: quotationData.goods_name,
			physical_characteristics: quotationData.goods_physical_characteristics,
			sensory_characteristics: quotationData.goods_sensory_characteristics,
		});
	}, [quotationData]);

	return (
		<>
			<Card className='mb-2'>
				<CardHeader
					className={cn('px-4 py-2', 'flex flex-row items-start bg-muted/50')}
				>
					<div className='grid gap-0.5'>
						<CardTitle className='group flex items-center gap-2 text-md'>
							Thông tin hàng hóa
						</CardTitle>
						<CardDescription className='italic'>
							Thông tin hàng hóa sẽ được sử dụng trong hợp đồng
						</CardDescription>
					</div>
					<div className='ml-auto flex items-center gap-1'>
						<FormField
							control={form.control}
							name='goods_id'
							render={({ field }) => (
								<FormCombobox
									isRequired={true}
									name={field.name}
									dataSource={all_catalog_goods}
									defaultValue={0}
									value={field.value}
									onValueChange={(value) =>
										field.onChange(parseInt(value || '0'))
									}
									onDataChange={(data) => changeDataValue(data)}
									isDisabled={isLoading}
									// label='Chọn hàng hóa'
									placeholder='Chọn hàng hóa...'
									displayExpr='name'
									valueExpr='id'
								/>
							)}
						/>
					</div>
				</CardHeader>
				<CardContent className={cn('gap-2 pt-2', 'text-xxs')}>
					<div className='sm:grid lg:grid-cols-3 lg:space-x-2'>
						<FormField
							control={form.control}
							name={'goods_name'}
							render={({ field }) => (
								<FormInput
									isRequired={true}
									name={field.name}
									value={field.value}
									onValueChange={field.onChange}
									isDisabled={isLoading}
									label='Tên hàng hóa'
									classForm='col-span-2'
								/>
							)}
						/>
						<FormField
							control={form.control}
							name={'goods_code'}
							render={({ field }) => (
								<FormInput
									isRequired={true}
									readOnly={true}
									name={field.name}
									value={field.value}
									onValueChange={field.onChange}
									isDisabled={isLoading}
									label='Mã hàng hóa'
								/>
							)}
						/>
					</div>
			
					<div className='sm:grid lg:grid-cols-2 lg:space-x-2'>
						<FormField
							control={form.control}
							name={'goods_description'}
							render={({ field }) => (
								<FormTextarea
									isRequired={true}
									name={field.name}
									value={field.value}
									onValueChange={field.onChange}
									isDisabled={isLoading}
									label='Mô tả'
									classForm='col-span-3'
								/>
							)}
						/>
					</div>
					<FormField
						control={form.control}
						name={'goods_physical_characteristics'}
						render={({ field }) => (
							<PhysicalCard
								array_string={field.value}
								onValueChange={field.onChange}
							/>
						)}
					/>
					<FormField
						control={form.control}
						name={'goods_sensory_characteristics'}
						render={({ field }) => (
							<SensoryCard
								array_string={field.value}
								onValueChange={field.onChange}
							/>
						)}
					/>
				</CardContent>
			</Card>
		</>
	);
};
