import { AuthApi } from '@/contexts/api/auth/auth-api';
import { AccountInfo } from '@/contexts/models/Account';
import { localStorageHelper } from '@/utils/localstorage-helper';
import { createSlice } from '@reduxjs/toolkit';

type AuthState = {
	account: AccountInfo | null;
	access_token: string | null;
	refresh_token: string | null;
};

const initialState = { account: null } as AuthState;

const entityEndpointsApi = AuthApi.endpoints;

const AuthSlice = createSlice({
	name: 'auth',
	initialState: initialState,
	reducers: {
		signOut: (state) => {
			state.access_token = null;
			state.refresh_token = null;
			state.account = null;
			localStorageHelper.CLEAR_TOKEN();
		},
	},
	extraReducers: (builder) => {
		builder.addMatcher(
			entityEndpointsApi.signIn.matchFulfilled,
			(state, { payload }) => {
				state.account = payload.account_info;
				state.access_token = payload.jwt_token;
				state.refresh_token = payload.refresh_token;
				localStorageHelper.SET_USERID(payload.account_info?.id || 0);
				localStorageHelper.SET_TOKEN(
					`${payload.jwt_token} ${payload.refresh_token}`
				);
			}
		);
		builder.addMatcher(
			entityEndpointsApi.profileInfo.matchFulfilled,
			(state, { payload }) => {
				state.account = payload;
			}
		);
	},
});

export const { signOut } = AuthSlice.actions;
export default AuthSlice.reducer;
