import { NotifyHelper } from '@/utils/notify-helper';
import { BaseResponse } from '@/contexts/base/base-response';
import { apiSlice } from '../api-slice';
import { AppFeatureModel } from '@/contexts/models/super-admin/AppFeature';

const enpointApi = '/super-admin/app-feature-manager';
const entityName = 'Tính năng ứng dụng';
const entityCacheTag = 'APP_FEATURE_MANAGER';

export const AppFeatureApi = apiSlice
	.enhanceEndpoints({ addTagTypes: [entityCacheTag] })
	.injectEndpoints({
		endpoints: (builder) => ({
			appFeatureGetAll: builder.query<AppFeatureModel[], void>({
				query: () => ({ url: enpointApi, method: 'GET' }),
				transformResponse: (response) => response.result as AppFeatureModel[],
				providesTags: (result) =>
					result
						? [
								...result.map(({ id }) => ({
									type: `${entityCacheTag}` as const,
									id,
								})),
								{ type: entityCacheTag, id: 'LIST' },
							]
						: [{ type: entityCacheTag, id: 'LIST' }],
			}),
			appFeatureSave: builder.mutation({
				query: (entity: AppFeatureModel) => ({
					url: enpointApi,
					method: entity.id > 0 ? 'PUT' : 'POST',
					data: entity,
				}),
				transformResponse: (response) => response,
				async onQueryStarted(_args, { queryFulfilled }) {
					try {
						const { data } = await queryFulfilled;
						if (data.is_success)
							NotifyHelper.Success(
								data.message || `Lưu ${entityName} thành công`
							);
						else
							NotifyHelper.Error(
								data.message || `Lưu ${entityName} không thành công`
							);
					} catch (ex: any) {
						NotifyHelper.Error(ex?.error?.data || `Lưu ${entityName} có lỗi`);
					}
				},
				invalidatesTags: [{ type: entityCacheTag, id: 'LIST' }],
			}),
			appFeatureDelete: builder.mutation({
				query: (id) => ({ url: `${enpointApi}/${id}`, method: 'DELETE' }),
				transformResponse: (response) => response,
				async onQueryStarted(_args, { queryFulfilled }) {
					try {
						const { data } = await queryFulfilled;
						if (data.is_success)
							NotifyHelper.Success(
								data.message || `Xóa ${entityName} thành công`
							);
						else
							NotifyHelper.Error(data.message || `Xóa ${entityName} thất bại`);
					} catch (ex: any) {
						NotifyHelper.Error(ex?.error?.data || `Xóa ${entityName} có lỗi`);
					}
				},
				invalidatesTags: [{ type: entityCacheTag, id: 'LIST' }],
			}),
			appFeatureDeleteMultiple: builder.mutation({
				query: (ids: number[]) => ({
					url: `${enpointApi}/deletes`,
					method: 'DELETE',
					data: { ids },
				}),
				transformResponse: (response) => response,
				async onQueryStarted(_args, { queryFulfilled }) {
					try {
						const { data } = await queryFulfilled;
						if (data.is_success)
							NotifyHelper.Success(
								data.message || `Xóa ${entityName} thành công`
							);
						else
							NotifyHelper.Error(data.message || `Xóa ${entityName} thất bại`);
					} catch (ex: any) {
						NotifyHelper.Error(ex?.error?.data || `Xóa ${entityName} có lỗi`);
					}
				},
				invalidatesTags: [{ type: entityCacheTag, id: 'LIST' }],
			}),
		}),
	});

export const {
	useLazyAppFeatureGetAllQuery,
	useAppFeatureGetAllQuery,
	useAppFeatureDeleteMultipleMutation,
	useAppFeatureDeleteMutation,
	useAppFeatureSaveMutation,
} = AppFeatureApi;
