import { ContractInfoApi } from '@/contexts/api/contract-management/contract/contract-info-api';
import { ContractInfoFormModel } from '@/contexts/models/contract-management/contract';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type ContractInfoState = {
	contractInfos: ContractInfoFormModel[];
	detail_id: number;
	is_open_dialog: boolean;
	is_open_change_status: boolean;
	selected_ids: number[];
	is_open_confirm: boolean;
};

const initialState = {
	contractInfos: [],
	detail_id: 0,
	is_open_confirm: false,
	is_open_change_status: false,
	is_open_dialog: false,
	selected_ids: [],
} as ContractInfoState;

const entityEndpointsApi = ContractInfoApi.endpoints;

const ContractInfoSlice = createSlice({
	name: 'ContractInfoSlice',
	initialState: initialState,
	reducers: {
		ContractInfoShowDetail: (
			state,
			{ payload }: PayloadAction<{ is_open_dialog: boolean; detail_id: number }>
		) => {
			state.is_open_dialog = payload.is_open_dialog;
			state.detail_id = payload.detail_id;
		},
		ContractInfoShowConfirm: (
			state,
			{ payload }: PayloadAction<{ is_open_confirm: boolean }>
		) => {
			state.is_open_confirm = payload.is_open_confirm;
		},
		ContractInfoSelectedIdsChange: (
			state,
			{ payload }: PayloadAction<{ selected_ids: number[] }>
		) => {
			state.selected_ids = payload.selected_ids;
		},
		ContractInfoChangeStatus: (
			state,
			{
				payload,
			}: PayloadAction<{
				is_open_change_status: boolean;
			}>
		) => {
			state.is_open_change_status = payload.is_open_change_status;
		},
	},
	extraReducers: (builder) => {
		builder.addMatcher(
			entityEndpointsApi.contractInfoGetAll.matchFulfilled,
			(state, { payload }) => {
				state.contractInfos = payload || [];
			}
		);
		builder.addMatcher(
			entityEndpointsApi.contractInfoDelete.matchFulfilled,
			(state) => {
				state.is_open_confirm = false;
				state.selected_ids = [];
			}
		);
		builder.addMatcher(
			entityEndpointsApi.contractInfoDeleteMultiple.matchFulfilled,
			(state) => {
				state.is_open_confirm = false;
				state.selected_ids = [];
			}
		);
		builder.addMatcher(
			entityEndpointsApi.contractInfoChangeStatus.matchFulfilled,
			(state) => {
				state.is_open_change_status = false;
				state.selected_ids = [];
			}
		);
	},
});
export const contractInfoActions = ContractInfoSlice.actions;
export default ContractInfoSlice.reducer;
