import { icons, LucideProps } from 'lucide-react';
import { FC } from 'react';

export interface IconProps extends LucideProps {
	name: keyof typeof icons;
}

const IconLucide = ({ name, color, size, ...rest }: IconProps) => {
	const LucideIcon = icons[name];
	return <LucideIcon color={color} size={size} {...rest} />;
};
export { icons as IconLucideIcons };

export default IconLucide;
