import { combineReducers } from '@reduxjs/toolkit';
import contractInfoSlice from './contract-info.slice';
import accountingRecordSlice from './accounting-record.slice';
import contractQuotationSlice from './contract-quotation.slice';
import contractDispatchSlice from './contract-dispatch.slice';
import contractPaymentSlice from './contract-payment.slice';
import contractShipmentSlice from './contract-shipment.slice';

export const contractReducer = combineReducers({
	ContractInfo: contractInfoSlice,
	AccountingRecord: accountingRecordSlice,
	ContractQuotation: contractQuotationSlice,
	ContractDispatch: contractDispatchSlice,
	ContractPayment: contractPaymentSlice,
	ContractShipment: contractShipmentSlice
});
