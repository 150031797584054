import {
	Card,
	CardContent,
	CardHeader,
	CardTitle,
} from '@/components/base/ui/card';
import { Input } from '@/components/base/ui/input';
import { ButtonDelete } from '@/components/common';
import { BankInfoOptionObject } from '@/contexts/models/Common';
import { cn } from '@/lib/utils';
import { useCallback, useMemo } from 'react';

interface BankInfoCardProps {
	array_string?: string;
	name?: string;
	onValueChange: (value: string) => void;
}

export const BankInfoCard = ({
	array_string,
	onValueChange,
}: BankInfoCardProps) => {
	const dataSource = useMemo(() => {
		const currentList: BankInfoOptionObject[] = [];
		if (array_string && array_string.length > 0) {
			const result = JSON.parse(array_string) as BankInfoOptionObject[];
			result.forEach((item, index) => {
				currentList.push({ ...item, sort_order: index + 1 });
			});
		}
		return currentList;
	}, [array_string]);

	const handleDelete = useCallback(
		(data: BankInfoOptionObject) => {
			const index = data.sort_order - 1;
			const newList = dataSource;
			if (index > -1) newList.splice(index, 1);

			const reOrders = newList.map((item, index) => ({
				...item,
				sort_order: index + 1,
			}));
			onValueChange(JSON.stringify(reOrders));
		},
		[dataSource, onValueChange]
	);

	return (
		<>
			<Card className='mb-2'>
				<CardHeader
					className={cn('p-4 py-2', 'flex flex-row items-start bg-muted/50')}
				>
					<div className='grid gap-0.5'>
						<CardTitle className='group flex items-center gap-2 text-md space-x-1'>
							Tài khoản
						</CardTitle>
					</div>
				</CardHeader>
				<CardContent className={cn('pt-2 space-y-1')}>
					{dataSource.map((item, index) => (
						<div key={index} className='flex w-full  items-center space-x-2'>
							<Input
								type='text'
								value={item.description}
								placeholder='Số tài khoản -Ngân hàng - Chi nhánh'
								readOnly={true}
							/>
							{dataSource.length > 1 && (
								<ButtonDelete
									onClick={() => handleDelete(item)}
									variant={'icon'}
									className='text-red-600'
								/>
							)}
						</div>
					))}
				</CardContent>
			</Card>
		</>
	);
};
