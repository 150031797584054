import { RadioGroup, RadioGroupItem } from '@/components/base/ui/radio-group';
import {
	FormControl,
	FormDescription,
	FormItem,
	FormLabel,
	FormMessage,
} from '../../base/ui/form';

interface FormRadioGroupProps<TData> {
	onValueChange: (value: string) => void;
	name: string;
	label?: string;
	dataSource: TData[];
	checkExpr: keyof TData;
	displayExpr: keyof TData;
	value: string;
	defaultValue: string;
	isDisabled?: boolean;
	isRequired?: boolean;
	helpBlock?: string;
}

const FormRadioGroup = <TData,>({
	name,
	label,
	value,
	defaultValue,
	dataSource,
	checkExpr,
	displayExpr,
	onValueChange,
	isDisabled,
	isRequired,
	helpBlock,
}: FormRadioGroupProps<TData>) => {
	return (
		<>
			<FormItem className='mb-2'>
				<div className=''>
					{label && (
						<FormLabel htmlFor={name}>
							{label}{' '}
							{isRequired ? <code className='text-red-400'>*</code> : ''}
						</FormLabel>
					)}
					{helpBlock && (
						<FormDescription
							className='text-xs italic'
							dangerouslySetInnerHTML={{ __html: helpBlock }}
						></FormDescription>
					)}
					<FormMessage />
				</div>
				<div className='py-2'>
					<RadioGroup
						onValueChange={onValueChange}
						value={value}
						name={name}
						defaultValue={defaultValue}
						className='flex flex-col space-y-1'
						disabled={isDisabled}
					>
						{dataSource.map((data, index) => {
							return (
								<FormItem
									key={`${name}-${index}`}
									className='flex items-center space-x-3 space-y-0'
								>
									<FormControl>
										<RadioGroupItem value={String(data[checkExpr])} />
									</FormControl>
									<FormLabel className='font-normal'>
										{String(data[displayExpr])}
									</FormLabel>
								</FormItem>
							);
						})}
					</RadioGroup>
				</div>
			</FormItem>
		</>
	);
};

export default FormRadioGroup;
