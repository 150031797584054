import Combobox from '@/components/common/combobox';
import FormRadioGroup from '@/components/common/form/form-radio-group';
import { useContractStatus } from '@/hooks/catalog/use-contract-status';
import { useTransportType } from '@/hooks/catalog/use-transport-type';

interface PackagingRequirementRadioProps {
	onValueChange: (value: string) => void;
	name: string;
	label?: string;
	value: string;
	defaultValue: string;
	isDisabled?: boolean;
	isRequired?: boolean;
	helpBlock?: string;
}

const PackagingRequirementRadio = ({
	value,
	onValueChange,
	...rest
}: PackagingRequirementRadioProps) => {
	const all_packaging_requirements = [
        {
            id: 'Hàng rời trên xe',
            name: 'Hàng rời trên xe',
        },
        {
            id: 'Hàng cont',
            name: 'Hàng cont',
        }
    ]
	return (
		<>
			<FormRadioGroup
				dataSource={all_packaging_requirements || []}
				value={value}
				checkExpr='id'
				displayExpr='name'
				onValueChange={(value) => {
					onValueChange(value);
				}}
				{...rest}
			/>
		</>
	);
};

export default PackagingRequirementRadio;
