import { MenuApi } from '@/contexts/api/super-admin';
import { MenuFormModel, MenuModel } from '@/contexts/models/super-admin/Menu';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type MenuState = {
	menus: MenuModel[];
	menu?: MenuModel;
	menuForm?: MenuFormModel;
	is_open_dialog: boolean;
	selected_ids: number[];
	is_open_confirm: boolean;
};

const initialState = {
	menus: [],
	menu: undefined,
	menuForm: undefined,
	is_open_confirm: false,
	is_open_dialog: false,
	selected_ids: [],
} as MenuState;

const entityEndpointsApi = MenuApi.endpoints;

const MenuSlice = createSlice({
	name: 'menu',
	initialState: initialState,
	reducers: {
		MenuShowDetail: (
			state,
			{
				payload,
			}: PayloadAction<{ is_open_dialog: boolean; menu?: MenuFormModel }>
		) => {
			state.is_open_dialog = payload.is_open_dialog;
			state.menuForm = payload.menu;
		},
		MenuRoleShowDetail: (
			state,
			{ payload }: PayloadAction<{ is_open_dialog: boolean; menu?: MenuModel }>
		) => {
			state.is_open_dialog = payload.is_open_dialog;
			state.menu = payload.menu;
		},
		MenuShowConfirm: (state, { payload }: PayloadAction<boolean>) => {
			state.is_open_confirm = payload;
		},
		MenuSelectedIdsChange: (state, { payload }: PayloadAction<number[]>) => {
			state.selected_ids = payload;
		},
	},
	extraReducers: (builder) => {
		builder.addMatcher(
			entityEndpointsApi.menuGetAll.matchFulfilled,
			(state, { payload }) => {
				state.menus = payload || [];
			}
		);
		builder.addMatcher(entityEndpointsApi.menuSave.matchFulfilled, (state) => {
			state.is_open_dialog = false;
			state.menuForm = undefined;
		});
		builder.addMatcher(
			entityEndpointsApi.menuUpdateRole.matchFulfilled,
			(state) => {
				state.is_open_dialog = false;
				state.menu = undefined;
			}
		);
		builder.addMatcher(
			entityEndpointsApi.menuDelete.matchFulfilled,
			(state) => {
				state.is_open_confirm = false;
				state.selected_ids = [];
			}
		);
		builder.addMatcher(
			entityEndpointsApi.menuDeleteMultiple.matchFulfilled,
			(state) => {
				state.is_open_confirm = false;
				state.selected_ids = [];
			}
		);
		builder.addMatcher(
			entityEndpointsApi.menuUpdateRoles.matchFulfilled,
			(state) => {
				state.is_open_confirm = false;
				state.selected_ids = [];
			}
		);
		builder.addMatcher(
			entityEndpointsApi.menuRemoveRoles.matchFulfilled,
			(state) => {
				state.is_open_confirm = false;
				state.selected_ids = [];
			}
		);
	},
});

export const menuActions = MenuSlice.actions;
export default MenuSlice.reducer;
