import { NotifyHelper } from '@/utils/notify-helper';
import { BaseResponse } from '@/contexts/base/base-response';
import { apiSlice } from '../api-slice';
import { RoleModel } from '@/contexts/models/super-admin/Role';

const enpointApi = '/super-admin/role-manager';
const entityName = 'Vai trò';
const entityCacheTag = 'ROLE_MANAGER';

export const RoleApi = apiSlice
	.enhanceEndpoints({ addTagTypes: [entityCacheTag] })
	.injectEndpoints({
		endpoints: (builder) => ({
			roleGetAll: builder.query<RoleModel[], void>({
				query: () => ({ url: enpointApi, method: 'GET' }),
				transformResponse: (response) => response.result as RoleModel[],
				providesTags: (result) =>
					result
						? [
								...result.map(({ id }) => ({
									type: `${entityCacheTag}` as const,
									id,
								})),
								{ type: entityCacheTag, id: 'LIST' },
							]
						: [{ type: entityCacheTag, id: 'LIST' }],
			}),
			roleSave: builder.mutation({
				query: (entity: RoleModel) => ({
					url: enpointApi,
					method: entity.id > 0 ? 'PUT' : 'POST',
					data: entity,
				}),
				transformResponse: (response) => response,
				async onQueryStarted(_args, { queryFulfilled }) {
					try {
						const { data } = await queryFulfilled;
						if (data.is_success)
							NotifyHelper.Success(
								data.message || `Lưu ${entityName} thành công`
							);
						else
							NotifyHelper.Error(
								data.message || `Lưu ${entityName} không thành công`
							);
					} catch (ex: any) {
						NotifyHelper.Error(ex?.error?.data || `Lưu ${entityName} có lỗi`);
					}
				},
				invalidatesTags: [{ type: entityCacheTag, id: 'LIST' }],
			}),
			roleDelete: builder.mutation({
				query: (id) => ({ url: `${enpointApi}/${id}`, method: 'DELETE' }),
				transformResponse: (response) => response,
				async onQueryStarted(_args, { queryFulfilled }) {
					try {
						const { data } = await queryFulfilled;
						if (data.is_success)
							NotifyHelper.Success(
								data.message || `Xóa ${entityName} thành công`
							);
						else
							NotifyHelper.Error(data.message || `Xóa ${entityName} thất bại`);
					} catch (ex: any) {
						NotifyHelper.Error(ex?.error?.data || `Xóa ${entityName} có lỗi`);
					}
				},
				invalidatesTags: [{ type: entityCacheTag, id: 'LIST' }],
			}),
			roleDeleteMultiple: builder.mutation({
				query: (ids: number[]) => ({
					url: `${enpointApi}/deletes`,
					method: 'DELETE',
					data: { ids },
				}),
				transformResponse: (response) => response,
				async onQueryStarted(_args, { queryFulfilled }) {
					try {
						const { data } = await queryFulfilled;
						if (data.is_success)
							NotifyHelper.Success(
								data.message || `Xóa ${entityName} thành công`
							);
						else
							NotifyHelper.Error(data.message || `Xóa ${entityName} thất bại`);
					} catch (ex: any) {
						NotifyHelper.Error(ex?.error?.data || `Xóa ${entityName} có lỗi`);
					}
				},
				invalidatesTags: [{ type: entityCacheTag, id: 'LIST' }],
			}),
		}),
	});

export const {
	useLazyRoleGetAllQuery,
	useRoleGetAllQuery,
	useRoleDeleteMultipleMutation,
	useRoleDeleteMutation,
	useRoleSaveMutation,
} = RoleApi;
