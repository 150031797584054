import { AccountingRecordApi } from '@/contexts/api/contract-management/contract/accounting-record-api';
import {
	AccountingRecord,
	AccountingRecordFormModel,
} from '@/contexts/models/contract-management/contract/AccountingRecord';
import { AccountingRecordTemplate } from '@/contexts/models/contract-management/contract/AccountingRecordTemplate';
import { NotifyHelper } from '@/utils/notify-helper';
import { createSlice } from '@reduxjs/toolkit';

const entityName = 'Bút toán';

type AccountingRecordState = {
	accountingRecords: AccountingRecord[];
	accountingRecordTemplates: AccountingRecordTemplate[];
	detail?: AccountingRecord;
	is_open_dialog: boolean;
	selected_ids: number[];
	is_open_confirm: boolean;
	is_open_lock_confirm: boolean;
	is_open_unlock_confirm: boolean;
	inserted_id?: number;
};

const initialState = {
	accountingRecords: [],
	accountingRecordTemplates: [],
	detail: undefined,
	is_open_confirm: false,
	is_open_dialog: false,
	selected_ids: [],
	inserted_id: undefined,
	is_open_lock_confirm: false,
	is_open_unlock_confirm: false,
} as AccountingRecordState;

const entityEndpointsApi = AccountingRecordApi.endpoints;

const AccountingRecordSlice = createSlice({
	name: 'AccountingRecordSlice',
	initialState: initialState,
	reducers: {
		AccountingRecordShowDetail: (
			state,
			{
				payload,
			}: { payload: { is_open_dialog: boolean; detail?: AccountingRecord } }
		) => {
			state.is_open_dialog = payload.is_open_dialog;
			state.detail = payload.detail;
			state.inserted_id = undefined;
		},
		AccountingRecordShowConfirm: (
			state,
			{ payload }: { payload: { is_open_confirm: boolean } }
		) => {
			state.is_open_confirm = payload.is_open_confirm;
		},
		AccountingRecordShowLockConfirm: (
			state,
			{ payload }: { payload: { is_open_lock_confirm: boolean } }
		) => {
			state.is_open_lock_confirm = payload.is_open_lock_confirm;
		},
		AccountingRecordShowUnlockConfirm: (
			state,
			{ payload }: { payload: { is_open_unlock_confirm: boolean } }
		) => {
			state.is_open_unlock_confirm = payload.is_open_unlock_confirm;
		},
		AccountingRecordSelectedIdsChange: (
			state,
			{ payload }: { payload: { selected_ids: number[] } }
		) => {
			state.selected_ids = payload.selected_ids;
		},
	},
	extraReducers: (builder) => {
		builder.addMatcher(
			entityEndpointsApi.accountingRecordGetAll.matchFulfilled,
			(state, { payload }) => {
				state.accountingRecords = payload || [];
			}
		);
		builder.addMatcher(
			entityEndpointsApi.accountingRecordSave.matchFulfilled,
			(state, { payload }) => {
				state.is_open_dialog = false;
				state.detail = undefined;

				const { is_success, message, result } = payload;
				state.inserted_id = result?.id;
				if (is_success)
					NotifyHelper.Success(message || `Lưu ${entityName} thành công`);
				else
					NotifyHelper.Error(message || `Lưu ${entityName} không thành công`);
			}
		);
		builder.addMatcher(
			entityEndpointsApi.accountingRecordConfirm.matchFulfilled,
			(state, { payload }) => {
				const { is_success, message } = payload;
				if (is_success)
					NotifyHelper.Success(
						message || `Khóa/mở khóa ${entityName} thành công`
					);
				else
					NotifyHelper.Error(
						message || `Khóa/mở khóa ${entityName} không thành công`
					);
				state.is_open_lock_confirm = false;
				state.is_open_unlock_confirm = false;
				state.selected_ids = [];
			}
		);
		builder.addMatcher(
			entityEndpointsApi.accountingRecordDelete.matchFulfilled,
			(state, { payload }) => {
				state.is_open_confirm = false;
				state.selected_ids = [];
				const { is_success, message } = payload;
				if (is_success)
					NotifyHelper.Success(message || `Xóa ${entityName} thành công`);
				else
					NotifyHelper.Error(message || `Xóa ${entityName} không thành công`);
			}
		);
		builder.addMatcher(
			entityEndpointsApi.accountingRecordDeleteMultiple.matchFulfilled,
			(state, { payload }) => {
				state.is_open_confirm = false;
				state.selected_ids = [];

				const { is_success, message } = payload;
				if (is_success)
					NotifyHelper.Success(message || `Xóa ${entityName} thành công`);
				else
					NotifyHelper.Error(message || `Xóa ${entityName} không thành công`);
			}
		);
		builder.addMatcher(
			entityEndpointsApi.accountingRecordGetAllTemplate.matchFulfilled,
			(state, { payload }) => {
				state.accountingRecordTemplates = payload || [];
			}
		);
	},
});
export const accountingRecordActions = AccountingRecordSlice.actions;
export default AccountingRecordSlice.reducer;
