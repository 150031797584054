import { zod, zod_valid } from '@/lib/zod-valid';
import { formatDate } from 'date-fns';
import { ContractDeliveryFormValid } from './ContractDeliveryModel';

export type ContractInfo = {
	id: number;

	document_code: string;
	document_type: string;
	document_date: string;

	contract_name: string;
	contract_code: string;
	contract_date: string;
	contract_notes: string;

	contract_status_id: string;
	transport_status_id: string;
	payment_status_id: string;

	company_id: number;
	customer_id: number;
	supplier_id: number;

	ref_contract_id: number;
	quotation_id: number;

	buyer_company_name: string;
	buyer_address: string;
	buyer_phone_number: string;
	buyer_tax_code: string;
	buyer_bank_account: string;
	buyer_representative_name: string;
	buyer_representative_position: string;

	seller_company_name: string;
	seller_address: string;
	seller_phone_number: string;
	seller_tax_code: string;
	seller_bank_account: string;
	seller_representative_name: string;
	seller_representative_position: string;

	goods_id: number;
	goods_name: string;
	goods_code: string;
	goods_description: string;
	goods_physical_characteristics: string;
	goods_sensory_characteristics: string;
	goods_packaging_requirements: string;
	goods_quantity: number;
	goods_price: number;
	goods_amount: number;
	goods_amount_words: string;
	goods_vat: number;
	goods_vat_amount: number;
	goods_deposit: number;
	goods_deposit_amount: number;
	goods_delivery_date_start?: string;
	goods_delivery_date_end?: string;
	goods_delivery_address: string;
};

export type ContractSellerBuyerInfo = {
	id: number;
	company_name: string;
	address: string;
	phone_number: string;
	tax_code: string;
	bank_account: string;
	representative_name: string;
	representative_position: string;
};

const contractDeliverySchema = zod.array(ContractDeliveryFormValid)

export const ContractInfoFormValid = zod
	.object({
		id: zod_valid.number().optional(),
		contract_name: zod_valid.string_required('Vui lòng nhập tiêu đề hợp đồng'),
		contract_code: zod_valid.string_required('Vui lòng nhập số hợp đồng'),
		contract_date: zod_valid.string_required('Vui lòng nhập ngày hợp đồng'),

		ref_contract_id: zod_valid.number(),
		quotation_id: zod_valid.number(),

		document_code: zod_valid.string(),
		document_type: zod_valid.string(),
		document_date: zod_valid.string(),

		contract_notes: zod_valid.string(),
		contract_status_id: zod_valid.string(),
		transport_status_id: zod_valid.string(),
		payment_status_id: zod_valid.string(),

		company_id: zod_valid.number(),
		customer_id: zod_valid.number(),
		supplier_id: zod_valid.number(),

		buyer_company_name: zod_valid.string(),
		buyer_address: zod_valid.string(),
		buyer_phone_number: zod_valid.string(),
		buyer_tax_code: zod_valid.string(),
		buyer_bank_account: zod_valid.string(),
		buyer_representative_name: zod_valid.string(),
		buyer_representative_position: zod_valid.string(),

		seller_company_name: zod_valid.string(),
		seller_address: zod_valid.string(),
		seller_phone_number: zod_valid.string(),
		seller_tax_code: zod_valid.string(),
		seller_bank_account: zod_valid.string(),
		seller_representative_name: zod_valid.string(),
		seller_representative_position: zod_valid.string(),

		goods_id: zod_valid.number(),
		goods_name: zod_valid.string(),
		goods_code: zod_valid.string(),
		goods_description: zod_valid.string(),
		goods_physical_characteristics: zod_valid.string(),
		goods_sensory_characteristics: zod_valid.string(),
		goods_packaging_requirements: zod_valid.string(),
		goods_quantity: zod_valid.number(),
		goods_price: zod_valid.number(),
		goods_amount: zod_valid.number(),
		goods_amount_words: zod_valid.string(),
		goods_vat: zod_valid.number(),
		goods_vat_amount: zod_valid.number(),
		goods_deposit: zod_valid.number(),
		goods_deposit_amount: zod_valid.number(),
		goods_delivery_date_start: zod_valid.string().optional(),
		goods_delivery_date_end: zod_valid.string().optional(),
		goods_delivery_address: zod_valid.string(),

		transport_type_code: zod_valid.string(),
		contract_deliveries: contractDeliverySchema,
	})
	.required();

export type ContractInfoFormModel = zod.infer<typeof ContractInfoFormValid>;

export const ContractInfoFormDefault: ContractInfoFormModel = {
	id: 0,
	contract_name: '',
	contract_code: '',
	contract_date: formatDate(new Date(), 'yyyy-MM-dd'),
	ref_contract_id: 0,
	quotation_id: 0,
	buyer_company_name: '',
	buyer_address: '',
	buyer_phone_number: '',
	buyer_tax_code: '',
	buyer_bank_account: '',
	buyer_representative_name: '',
	buyer_representative_position: '',
	seller_company_name: '',
	seller_address: '',
	seller_phone_number: '',
	seller_tax_code: '',
	seller_bank_account: '',
	seller_representative_name: '',
	seller_representative_position: '',
	contract_notes: '',
	document_code: '',
	document_date: formatDate(new Date(), 'yyyy-MM-dd'),
	document_type: '',
	goods_id: 0,
	goods_code: '',
	goods_description: '',
	goods_name: '',
	goods_physical_characteristics: '',
	goods_sensory_characteristics: '',
	goods_quantity: 0,
	goods_price: 0,
	goods_amount: 0,
	goods_amount_words: '',
	goods_vat: 0,
	goods_vat_amount: 0,
	goods_deposit: 0,
	goods_deposit_amount: 0,
	goods_delivery_date_start: formatDate(new Date(), 'yyyy-MM-dd'),
	goods_delivery_date_end: formatDate(new Date(), 'yyyy-MM-dd'),
	goods_delivery_address: '',
	contract_status_id: 'NEW',
	company_id: 0,
	customer_id: 0,
	supplier_id: 0,
	goods_packaging_requirements: 'Hàng rời trên xe',
	payment_status_id: 'NEW',
	transport_status_id: 'NEW',
	transport_type_code: 'SINGLE',
	contract_deliveries: [],
};