
export const listQuotationStatus = [
	{ id: 'NEW', name: 'Báo giá mới', class: ''},
	{ id: 'PROCESSING', name: 'Đang xử lý', class: 'text-blue-600' },
	{ id: 'APPROVED', name: 'Đã chấp nhận', class: 'text-purple-600'},
	{ id: 'REJECTED', name: 'Từ chối', class: 'text-red-600'},
	{ id: 'CANCELLED', name: 'Hủy', class: 'text-red-600'},
	{ id: 'COMPLETED', name: 'Đã tạo HĐ', class: 'text-green-600'},
];

export const useQuotationStatus = () => {
	return { all_quotation_status: listQuotationStatus };
};


