export const listTransportStatus = [
	{ id: 'NEW', name: 'Chưa xử lý', class: '' },
	{ id: 'PROCESSING', name: 'Đang xử lý', class: 'text-blue-600' },
	{ id: 'PLANNING', name: 'Đang điều xe', class: 'text-yellow-600' },
	{ id: 'DISPATCHED', name: 'Xe đang trên đường', class: 'text-orange-400' },
	{
		id: 'ARRIVED_AT_HUB',
		name: 'Đã đến nơi trung chuyển',
		class: 'text-orange-600',
	},
	{ id: 'IN_TRANSIT', name: 'Đang vận chuyển', class: 'text-purple-600' },
	{ id: 'ON_HOLD', name: 'Tạm dừng', class: 'text-orange-600' },
	{ id: 'COMPLETED', name: 'Đã giao hàng', class: 'text-green-600' },
];

export const useTransportStatus = () => {
	return { all_transport_status: listTransportStatus };
};
