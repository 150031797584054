import { ContractShipmentApi } from '@/contexts/api/contract-management/contract/contract-shipment-api';
import {
	ContractShipment,
	ContractShipmentFormModel,
} from '@/contexts/models/contract-management/contract/ContractShipmentModel';

import { NotifyHelper } from '@/utils/notify-helper';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const entityName = 'Điều vận';

type ContractShipmentState = {
	contractShipments: ContractShipment[];
	detail?: ContractShipmentFormModel;
	is_open_dialog: boolean;
	selected_ids: number[];
	is_open_confirm: boolean;
	is_open_lock_confirm: boolean;
	is_open_unlock_confirm: boolean;
	is_open_change_status: boolean;
	inserted_id?: number;
};

const initialState = {
	contractShipments: [],
	detail: undefined,
	is_open_confirm: false,
	is_open_dialog: false,
	selected_ids: [],
	inserted_id: undefined,
	is_open_lock_confirm: false,
	is_open_unlock_confirm: false,
	is_open_change_status: false,
} as ContractShipmentState;

const entityEndpointsApi = ContractShipmentApi.endpoints;

const ContractShipmentSlice = createSlice({
	name: 'ContractShipmentSlice',
	initialState: initialState,
	reducers: {
		ContractShipmentShowDetail: (
			state,
			{
				payload,
			}: PayloadAction<{
				is_open_dialog: boolean;
				detail?: ContractShipmentFormModel;
			}>
		) => {
			state.is_open_dialog = payload.is_open_dialog;
			state.detail = payload.detail;
			state.inserted_id = undefined;
		},
		ContractShipmentShowConfirm: (
			state,
			{ payload }: PayloadAction<{ is_open_confirm: boolean }>
		) => {
			state.is_open_confirm = payload.is_open_confirm;
		},
		ContractShipmentShowLockConfirm: (
			state,
			{ payload }: PayloadAction<{ is_open_lock_confirm: boolean }>
		) => {
			state.is_open_lock_confirm = payload.is_open_lock_confirm;
		},
		ContractShipmentShowUnlockConfirm: (
			state,
			{ payload }: PayloadAction<{ is_open_unlock_confirm: boolean }>
		) => {
			state.is_open_unlock_confirm = payload.is_open_unlock_confirm;
		},
		ContractShipmentSelectedIdsChange: (
			state,
			{ payload }: PayloadAction<{ selected_ids: number[] }>
		) => {
			state.selected_ids = payload.selected_ids;
		},
		ContractShipmentChangeStatus: (
			state,
			{
				payload,
			}: PayloadAction<{
				is_open_change_status: boolean;
			}>
		) => {
			state.is_open_change_status = payload.is_open_change_status;
		},
	},
	extraReducers: (builder) => {
		builder.addMatcher(
			entityEndpointsApi.contractShipmentGetAll.matchFulfilled,
			(state, { payload }) => {
				state.contractShipments = payload || [];
			}
		);
		builder.addMatcher(
			entityEndpointsApi.contractShipmentSave.matchFulfilled,
			(state, { payload }) => {
				state.is_open_dialog = false;
				state.detail = undefined;

				const { is_success, message, result } = payload;
				state.inserted_id = result?.id;
				if (is_success)
					NotifyHelper.Success(message || `Lưu ${entityName} thành công`);
				else
					NotifyHelper.Error(message || `Lưu ${entityName} không thành công`);
			}
		);
		builder.addMatcher(
			entityEndpointsApi.contractShipmentConfirm.matchFulfilled,
			(state, { payload }) => {
				const { is_success, message } = payload;
				if (is_success)
					NotifyHelper.Success(
						message || `Khóa/mở khóa ${entityName} thành công`
					);
				else
					NotifyHelper.Error(
						message || `Khóa/mở khóa ${entityName} không thành công`
					);
				state.is_open_lock_confirm = false;
				state.is_open_unlock_confirm = false;
				state.selected_ids = [];
			}
		);
		builder.addMatcher(
			entityEndpointsApi.contractShipmentDelete.matchFulfilled,
			(state, { payload }) => {
				state.is_open_confirm = false;
				state.selected_ids = [];
				const { is_success, message } = payload;
				if (is_success)
					NotifyHelper.Success(message || `Xóa ${entityName} thành công`);
				else
					NotifyHelper.Error(message || `Xóa ${entityName} không thành công`);
			}
		);
		builder.addMatcher(
			entityEndpointsApi.contractShipmentDeleteMultiple.matchFulfilled,
			(state, { payload }) => {
				state.is_open_confirm = false;
				state.selected_ids = [];

				const { is_success, message } = payload;
				if (is_success)
					NotifyHelper.Success(message || `Xóa ${entityName} thành công`);
				else
					NotifyHelper.Error(message || `Xóa ${entityName} không thành công`);
			}
		);
		builder.addMatcher(
			entityEndpointsApi.contractShipmentChangeStatus.matchFulfilled,
			(state) => {
				state.is_open_change_status = false;
				state.selected_ids = [];
			}
		);
	},
});
export const contractShipmentActions = ContractShipmentSlice.actions;
export default ContractShipmentSlice.reducer;
