import {
	useContractInfoGetAllQuery,
	useLazyContractInfoGetWordFileQuery,
} from '@/contexts/api/contract-management/contract/contract-info-api';
import {
	Card,
	CardContent,
	CardDescription,
	CardHeader,
	CardTitle,
} from '@/components/base/ui/card';
import { useAppDispatch } from '@/contexts/store';

import { Link, useLocation, useNavigate } from 'react-router-dom';
import { cn } from '@/lib/utils';
import {
	DollarSignIcon,
	FileArchive,
	FileArchiveIcon,
	FileBadge2Icon,
} from 'lucide-react';
import { ButtonCustom } from '@/components/common/button';
import { eDocumentType } from '@/contexts/models/contract-management/contract';

interface IContractPurchaseListCardProps {
	title?: string;
}

export const ContractPurchaseListCard = ({
	title,
}: IContractPurchaseListCardProps) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const location = useLocation();

	const {
		data,
		isFetching,
		isLoading,
		refetch: usePrefetch,
	} = useContractInfoGetAllQuery(eDocumentType.HOP_DONG_MUA_HANG);

	const [downloadWord] = useLazyContractInfoGetWordFileQuery();

	const dataLoading = isFetching || isLoading;

	return (
		<>
			{data?.map((data, index) => {
				return (
					<Card key={index} className='mb-2'>
						<CardHeader
							className={cn(
								'px-4 py-2',
								'flex flex-row items-start bg-muted/50'
							)}
						>
							<div className='grid gap-0.5'>
								<CardTitle className='group flex items-center gap-2 text-xs'>
									<Link to={`/hop-dong-mua-hang/chi-tiet/${data.id}`}>
										{data.contract_code}
									</Link>
								</CardTitle>
								<CardDescription>
									<ButtonCustom
										variant={'outline'}
										icon={<FileArchive className='h-4 w-4' />}
										onClick={() =>
											navigate(`/bao-gia-chao-hang/hdbh/${data.id}`)
										}
										title='Báo giá'
									/>
									<ButtonCustom
										variant={'outline'}
										icon={<FileBadge2Icon className='h-4 w-4' />}
										onClick={() =>
											navigate(`/hop-dong-ban-hang/hdbh/${data.id}`)
										}
										title='HĐ bán hàng'
									/>
									<ButtonCustom
										variant={'outline'}
										icon={<DollarSignIcon className='h-4 w-4' />}
										onClick={() =>
											navigate(`/hop-dong-ban-hang/hdbh/${data.id}`)
										}
										title='Tài chính'
									/>
								</CardDescription>
							</div>
						</CardHeader>
						<CardContent className={cn('gap-2 pt-2', 'text-xxs')}>
							<div className='text-left text-xxs'>
								<div className='text-sm'>
									<code className='text-xs'>{data.goods_name}</code>{' '}
									<div>Bên bán: {data.seller_company_name}</div>
									<div>
										Số lượng: {data.goods_quantity.toLocaleString('vi')}
									</div>
									<div>Đơn giá: {data.goods_price.toLocaleString('vi')}</div>
									<div>Tồn: {data.goods_quantity.toLocaleString('vi')}</div>
									<div>Đã bán: {data.goods_quantity.toLocaleString('vi')}</div>
									<div>Báo giá: {data.goods_quantity.toLocaleString('vi')}</div>
								</div>
							</div>
						</CardContent>
					</Card>
				);
			})}
		</>
	);
};
