import { DateTimeRangePicker } from '@/components/base/ui/datetime-range-picker';
import { parseDate } from '@internationalized/date';
import { formatDate } from 'date-fns';
import React, { useEffect } from 'react';
import { DateRange, DateValue } from 'react-aria';
import {
	FormDescription,
	FormItem,
	FormLabel,
	FormMessage,
} from '../../base/ui/form';
import { createUUID } from '@/lib/create-uuid';
interface FromDateTimeRangePickerProps {
	name_from: string;
	name_to: string;
	label: string;
	value_from?: string | Date | null;
	value_to?: string | Date | null;
	/* Trả về ngày */
	onValueFromChange?: (value?: string) => void;
	/* Trả về ngày */
	onValueToChange?: (value?: string) => void;
	helpBlock?: string;
	isDisabled?: boolean;
	placeholder?: string;
	isRequired?: boolean;
	classForm?: string;
}
const FromDateTimeRangePicker = ({
	name_from,
	name_to,
	value_from,
	value_to,
	label,
	onValueFromChange,
	onValueToChange,
	placeholder,
	helpBlock,
	isRequired,
	classForm,
}: FromDateTimeRangePickerProps) => {
	const [date, setDate] = React.useState<DateRange | undefined>();

	useEffect(() => {
		const dateFrom = value_from
			? parseDate(formatDate(value_from, 'yyyy-MM-dd'))
			: parseDate(formatDate(new Date(), 'yyyy-MM-dd'));
		const dateTo = value_to
			? parseDate(formatDate(value_to, 'yyyy-MM-dd'))
			: parseDate(formatDate(new Date(), 'yyyy-MM-dd'));
		setDate({ start: dateFrom, end: dateTo });
	}, [value_from, value_to]);

	return (
		<FormItem className='flex flex-col pb-2'>
			<FormLabel htmlFor={`date_range_${name_from}_${name_to}_${createUUID()}`}>
				{label} {isRequired ? <code className='text-red-400'>*</code> : ''}
			</FormLabel>
			<DateTimeRangePicker
				value={date}
				defaultValue={date}
				onChange={(data) => {
					setDate(data);
					onValueFromChange && onValueFromChange(data.start.toString());
					onValueToChange && onValueToChange(data.end.toString());
				}}
			/>
			{helpBlock && (
				<FormDescription
					className='text-xs italic'
					dangerouslySetInnerHTML={{ __html: helpBlock }}
				></FormDescription>
			)}
			<FormMessage />
		</FormItem>
	);
};

export default FromDateTimeRangePicker;
