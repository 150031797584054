import {
	Card,
	CardContent,
	CardHeader,
	CardTitle,
} from '@/components/base/ui/card';
import { ScrollArea } from '@/components/base/ui/scroll-area';
import { ButtonCustom } from '@/components/common/button';
import { useAuth } from '@/hooks/use-auth';
import { cn } from '@/lib/utils';
import { ContractPurchaseTable } from '@/modules/contract/table/purchase-table';
import { ContractSalesTable } from '@/modules/contract/table/sales-table';
import { QuotationTable } from '@/modules/quotation/table/quotation-table';
import { TransportTable } from '@/modules/contract-dispatch/table/contract-dispatch-table';
import { NotifyHelper } from '@/utils/notify-helper';
import { Link1Icon } from '@radix-ui/react-icons';
import { formatDate } from 'date-fns';
import { vi } from 'date-fns/locale';
import { useNavigate } from 'react-router-dom';

const Homepage = () => {
	const { account } = useAuth();
	const navigate = useNavigate();
	const PushNoty = () => {
		NotifyHelper.Notify({
			redirect_url: '/menu-12',
			title:
				'HLV Đoàn Kiến Quốc: "Bóng bàn Việt Nam đủ sức tranh suất dự Olympic 2024"',
			message:
				'Đội tuyển bóng bàn Việt Nam đặt mục tiêu lớn tranh suất tham dự Olympic Paris 2024 ở cả nội dung của nam và nữ. Toàn đội sẽ có 2 tháng tập huấn tại Trung Quốc vào tháng 4 này.',
		});
	};
	const PushMessage = () => {
		NotifyHelper.Success('Hello');
	};

	const dateFormat = formatDate(new Date(), 'eeee, dd/MM/yyyy', { locale: vi });

	return (
		<>
			<ScrollArea className='h-[calc(100vh-4rem)]'>
				<div className='flex-1 space-y-4 p-4 md:p-8 pt-6'>
					<div className='flex items-center justify-between space-y-2'>
						<h2 className='text-3xl font-bold tracking-tight'>
							Chào, <b>{account?.full_name}</b> 👋
						</h2>
						<div className='hidden md:flex items-center space-x-2'>
							{dateFormat}
							{/* <CalendarDateRangePicker /> */}
							{/* <Button onClick={() => PushMessage()}>OK</Button>
							<Button onClick={() => PushNoty()}>AKE</Button> */}
						</div>
					</div>
					{/* <Totals /> */}
					{/* <TotalsPurchase /> */}
					<Card className={''}>
						<CardHeader
							className={cn(
								'px-4 py-2',
								'flex flex-row items-start bg-muted/50'
							)}
						>
							<CardTitle className='text-lg'>
								1. Hợp đồng mua hàng{' '}
								<ButtonCustom
									variant={'link'}
									size={'sm'}
									icon={<Link1Icon className='w-3.5 h-3.5' />}
									title='Xem thêm...'
									onClick={() => navigate('/hop-dong-mua-hang')}
								/>
							</CardTitle>
						</CardHeader>
						<CardContent className='pt-2'>
							<ContractPurchaseTable />
						</CardContent>
					</Card>
					{/* <PurchaseList /> */}
					<Card className={''}>
						<CardHeader
							className={cn(
								'px-4 py-2',
								'flex flex-row items-start bg-muted/50'
							)}
						>
							<CardTitle className='text-lg'>
								2. Phiếu báo giá{' '}
								<ButtonCustom
									variant={'link'}
									size={'sm'}
									icon={<Link1Icon className='w-3.5 h-3.5' />}
									title='Xem thêm...'
									onClick={() => navigate('/phieu-bao-gia-chao-hang')}
								/>
							</CardTitle>
						</CardHeader>
						<CardContent className='pt-2'>
							<QuotationTable />
						</CardContent>
					</Card>
					{/* <PriceQuoteList /> */}
					<Card className={''}>
						<CardHeader
							className={cn(
								'px-4 py-2',
								'flex flex-row items-start bg-muted/50'
							)}
						>
							<CardTitle className='text-lg'>
								3. Hợp đồng bán hàng{' '}
								<ButtonCustom
									variant={'link'}
									size={'sm'}
									icon={<Link1Icon className='w-3.5 h-3.5' />}
									title='Xem thêm...'
									onClick={() => navigate('/hop-dong-ban-hang')}
								/>
							</CardTitle>
						</CardHeader>
						<CardContent className='pt-2'>
							<ContractSalesTable />
						</CardContent>
					</Card>
					{/* <SalesList /> */}
					<Card className={''}>
						<CardHeader
							className={cn(
								'px-4 py-2',
								'flex flex-row items-start bg-muted/50'
							)}
						>
							<CardTitle className='text-lg'>
								4. Phiếu đợt giao hàng{' '}
								<ButtonCustom
									variant={'link'}
									size={'sm'}
									icon={<Link1Icon className='w-3.5 h-3.5' />}
									title='Xem thêm...'
									onClick={() => navigate('/phieu-van-don')}
								/>
							</CardTitle>
						</CardHeader>
						<CardContent className='pt-2'>
							<TransportTable />
						</CardContent>
					</Card>
					{/* <TransportionList /> */}
					{/* <TotalsSales /> */}
					{/* <TotalsShip /> */}
				</div>
			</ScrollArea>
		</>
	);
};

export default Homepage;
