import Combobox from '@/components/common/combobox';
import { usePaymentStatus } from '@/hooks/catalog/use-payment-status';
import { cn } from '@/lib/utils';
import { DotFilledIcon } from '@radix-ui/react-icons';

interface PaymentStatusComboboxProps {
	value: string;
	onValueChange: (value: string) => void;
	name: string;
	label?: string;
	isDisabled?: boolean;
	helpBlock?: string;
}

const PaymentStatusCombobox = ({
	value,
	onValueChange,
	...rest
}: PaymentStatusComboboxProps) => {
	const { all_payment_status } = usePaymentStatus();
	return (
		<>
			<Combobox
				dataSource={all_payment_status || []}
				valueExpr={'id'}
				displayExpr={'name'}
				value={value}
				onValueChange={(value) => {
					onValueChange(value);
				}}
				placeholder='Chọn trạng thái...'
				width='w-full max-w-full'
				{...rest}
				itemRender={(data) => (
					<div className={cn('inline-flex', data.class)}>
						<DotFilledIcon className={cn('w-3 h-3')} /> {data.name}
					</div>
				)}
			/>
		</>
	);
};

export default PaymentStatusCombobox;
