import { cn } from '@/lib/utils';
import {
	FormControl,
	FormDescription,
	FormItem,
	FormLabel,
	FormMessage,
} from '../../base/ui/form';
import { InputPassword } from '../../base/ui/input-password';

interface FormInputPasswordProps
	extends React.InputHTMLAttributes<HTMLInputElement> {
	onValueChange: (value: string) => void;
	name: string;
	label?: string;
	isDisabled?: boolean;
	isRequired?: boolean;
	placeholder?: string;
	helpBlock?: string;
	classForm?: string;
}

const FormInputPassword = ({
	name,
	value,
	onValueChange,
	label,
	isDisabled,
	isRequired,
	placeholder,
	helpBlock,
	classForm,
	...rest
}: FormInputPasswordProps) => {
	return (
		<>
			<FormItem className={cn('flex flex-col pb-2', classForm)}>
				{label && (
					<FormLabel htmlFor={name}>
						{label} {isRequired ? <code className='text-red-400'>*</code> : ''}
					</FormLabel>
				)}
				<FormControl>
					<InputPassword
						id={name}
						name={name}
						value={value || ''}
						onChange={(event) => onValueChange(event.target.value || '')}
						placeholder={placeholder}
						disabled={isDisabled || false}
						{...rest}
					/>
				</FormControl>
				{helpBlock && (
					<FormDescription
						className='text-xs italic'
						dangerouslySetInnerHTML={{ __html: helpBlock }}
					></FormDescription>
				)}
				<FormMessage />
			</FormItem>
		</>
	);
};

export default FormInputPassword;
