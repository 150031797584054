
const listPaymenttStatus = [
    { id: 'NEW', name: 'Chưa xử lý', class: ''},
    { id: 'PROCESSING', name: 'Đang xử lý', class: 'text-blue-600'},
    { id: 'UNPAID', name: 'Chưa thanh toán', class: 'text-orange-600'},
    { id: 'PARTIALLY_PAID', name: 'Đã thanh toán một phần', class: 'text-yellow-600'},
    { id: 'PAID', name: 'Đã thanh toán', class: 'text-green-600'},
];

export const usePaymentStatus = () => {
    return { all_payment_status: listPaymenttStatus };
};
