import { FormField } from '@/components/base/ui/form';
import { Cardbox } from '@/components/common';
import { FormInput } from '@/components/common/form';
import FromDatePicker from '@/components/common/form/form-date-picker';
import FromDateTimePicker from '@/components/common/form/form-datetime-picker';
import FormInputCurrency from '@/components/common/form/form-input-currency';
import FormInputNumber from '@/components/common/form/form-input-number';
import FormTextarea from '@/components/common/form/form-text-area';
import { Goods } from '@/contexts/models/contract-management/catalog';
import { eDocumentType } from '@/contexts/models/contract-management/contract';
import { ContractPaymentFormModel } from '@/contexts/models/contract-management/contract/ContractPayment';
import { ContractCombobox } from '@/modules/contract/form/contract-combobox';
import { convertNumberToWords } from '@/utils/convert-number-to-words';
import { formatDate } from 'date-fns';
import { useEffect } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';

interface IPaymentDetailFormProps {
	isSaving: boolean;
	formData: ContractPaymentFormModel;
	form: UseFormReturn<ContractPaymentFormModel, any, undefined>;
	// changeGoodsInfo: (contract?: ContractInfo) => void;
}

const initGoodsData: Goods = {
	id: 0,
	code: '',
	description: '',
	is_active: true,
	name: '',
	physical_characteristics: '',
	sensory_characteristics: '',
};

export const PaymentDetailForm = ({
	isSaving,
	formData,
	form,
	// changeGoodsInfo,
}: IPaymentDetailFormProps) => {
	const { reset, control, setValue } = form;

	const watchChangeGoodsAmount = useWatch({
		control,
		name: 'payment_amount',
		defaultValue: 0,
	});

	useEffect(() => {
		const slug = convertNumberToWords(watchChangeGoodsAmount);
		setValue('payment_amount_words', slug);
	}, [watchChangeGoodsAmount, setValue]);

	return (
		<>
			<Cardbox
				title='Thông tin'
			>
				<FormField
					control={form.control}
					name='payment_date'
					render={({ field }) => (
						<FromDateTimePicker
							isRequired={true}
							name={field.name}
							value={field.value}
							onValueChange={field.onChange}
							isDisabled={isSaving}
							label='Ngày thanh toán'
						/>
					)}
				/>
				<FormField
					control={form.control}
					name='payment_name'
					render={({ field }) => (
						<FormInput
							isRequired={true}
							name={field.name}
							value={field.value}
							onValueChange={field.onChange}
							isDisabled={isSaving}
							label='Nội dung thanh toán'
						/>
					)}
				/>
				<FormField
					control={form.control}
					name={'payment_amount'}
					render={({ field }) => (
						<FormInputNumber
							isRequired={true}
							name={field.name}
							value={field.value}
							onValueChange={(value) => {
								field.onChange(value);
							}}
							isDisabled={isSaving}
							label='Thành tiền (VNĐ)'
						/>
					)}
				/>
				<FormField
					control={form.control}
					name={'payment_amount_words'}
					render={({ field }) => (
						<FormInput
							isRequired={true}
							readOnly={true}
							name={field.name}
							value={field.value}
							onValueChange={field.onChange}
							isDisabled={isSaving}
							label='Thành tiền bằng chữ'
						/>
					)}
				/>
				<FormField
					control={form.control}
					name={'payment_notes'}
					render={({ field }) => (
						<FormTextarea
							isRequired={true}
							name={field.name}
							value={field.value}
							onValueChange={field.onChange}
							isDisabled={isSaving}
							label='Ghi chú'
						/>
					)}
				/>
			</Cardbox>
		</>
	);
};
