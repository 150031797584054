import { createSlice } from '@reduxjs/toolkit';
import { Partner } from '@/contexts/models/contract-management/catalog/Partner';
import { PartnerApi } from '@/contexts/api/contract-management/catalog/partner-api';

type PartnerState = {
	list: Partner[];
	detail?: Partner;
	is_open_dialog: boolean;
	selected_ids: number[];
	is_open_confirm: boolean;
};

const initialState = {
	list: [],
	detail: undefined,
	is_open_confirm: false,
	is_open_dialog: false,
	selected_ids: [],
} as PartnerState;

const entityEndpointsApi = PartnerApi.endpoints;

const PartnerSlice = createSlice({
	name: 'PartnerSlice',
	initialState: initialState,
	reducers: {
		PartnerShowDetail: (state, { payload }) => {
			state.is_open_dialog = payload.is_open_dialog;
			state.detail = payload.detail;
		},
		PartnerShowConfirm: (state, { payload }) => {
			state.is_open_confirm = payload;
		},
		PartnerSelectedIdsChange: (state, { payload }) => {
			state.selected_ids = payload;
		},
	},
	extraReducers: (builder) => {
		builder.addMatcher(
			entityEndpointsApi.partnerGetAll.matchFulfilled,
			(state, { payload }) => {
				state.list = payload || [];
			}
		);
		builder.addMatcher(
			entityEndpointsApi.partnerSave.matchFulfilled,
			(state) => {
				state.is_open_dialog = false;
				state.detail = undefined;
			}
		);
		builder.addMatcher(
			entityEndpointsApi.partnerDelete.matchFulfilled,
			(state) => {
				state.is_open_confirm = false;
				state.selected_ids = [];
			}
		);
		builder.addMatcher(
			entityEndpointsApi.partnerDeleteMultiple.matchFulfilled,
			(state) => {
				state.is_open_confirm = false;
				state.selected_ids = [];
			}
		);
	},
});

export const partnerActions = PartnerSlice.actions;
export default PartnerSlice.reducer;
