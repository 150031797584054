import { Badge } from '@/components/base/ui/badge';
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from '@/components/base/ui/tooltip';
import { usePaymentStatus } from '@/hooks/catalog/use-payment-status';
import { cn } from '@/lib/utils';
import { DotFilledIcon } from '@radix-ui/react-icons';
import { useMemo } from 'react';

interface IPaymentStatusBadgeProps {
	value: string;
}

export const PaymentStatusBadge = ({ value }: IPaymentStatusBadgeProps) => {
	const { all_payment_status } = usePaymentStatus();

	const data = useMemo(() => {
		return (
			all_payment_status?.find((item) => item.id === value) || {
				id: 'NEW',
				name: 'Chưa xử lý',
				class: 'bg-secondary text-secondary',
			}
		);
	}, [all_payment_status, value]);

	return (
		<>
			<TooltipProvider>
				<Tooltip>
					<TooltipTrigger>
						<Badge
							variant='outline'
							className={cn('flex-inline text-xxs', data.class)}
						>
							<DotFilledIcon className={cn('w-3 h-3')} />{' '}
							<span className='truncate w-14'>{data.name}</span>
						</Badge>
					</TooltipTrigger>
					<TooltipContent>
						<p>{data.name}</p>
					</TooltipContent>
				</Tooltip>
			</TooltipProvider>
		</>
	);
};
