import { Card, CardContent } from '@/components/base/ui/card';
import React from 'react';
import { ScrollArea } from '../base/ui/scroll-area';

interface PageBodyProps {
	children?: React.ReactNode;
	hasScroll?: boolean;
}
export const PageBody = ({ hasScroll, children }: PageBodyProps) => {
	return (
		<>
			<Card className='my-2'>
				<CardContent className='py-2'>
					{hasScroll == true ? (
						<ScrollArea className='page-body page-scrollable py-4 ps-2 pe-4'>
							{children}
						</ScrollArea>
					) : (
						<div className='p-2'>{children}</div>
					)}
				</CardContent>
			</Card>
		</>
	);
};
