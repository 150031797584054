import * as signalR from '@microsoft/signalr';
const HUB_URL: string = import.meta.env.VITE_APP_HUB_URL?.toString() as string;

class NotifySignalrConnector {
	private connection: signalR.HubConnection;
	public events: (
		BroadcastNotification: (message: any, user_id: string) => void
	) => void;
	static instance: NotifySignalrConnector;
	constructor() {
		this.connection = new signalR.HubConnectionBuilder()
			.withUrl(HUB_URL)
			.withAutomaticReconnect()
			.build();
		this.connection
			.start()
			.then(() => {
				console.log('Connection established');
			})
			.then(() => this.registerUser())
			.catch((err) => console.error(err));
		this.events = (BroadcastNotification) => {
			this.connection.on('BroadcastNotification', (message, user_id) => {
				BroadcastNotification(message, user_id);
			});
		};
	}
	public sentArticleAction = (messages: string) => {
		this.connection.send('sentArticleAction', messages);
	};
	private registerUser = () => {
		this.connection.invoke('RegisterUser', localStorage.USERID);
	};
	public static getInstance(): NotifySignalrConnector {
		if (!NotifySignalrConnector.instance)
			NotifySignalrConnector.instance = new NotifySignalrConnector();
		return NotifySignalrConnector.instance;
	}
}

export default NotifySignalrConnector.getInstance;
