import { HTMLInputTypeAttribute } from 'react';
import Switchbox from '../switchbox';
import {
	FormControl,
	FormDescription,
	FormItem,
	FormLabel,
	FormMessage,
} from '../../base/ui/form';

interface FormSwitchboxProps {
	value: boolean;
	onValueChange: (value: boolean) => void;
	name: string;
	label: string;
	isDisabled?: boolean;
	helpBlock?: string;
}

const FormSwitchbox = ({
	name,
	value,
	onValueChange,
	label,
	isDisabled,
	helpBlock,
}: FormSwitchboxProps) => {
	return (
		<>
			<FormItem className='flex flex-col pb-2'>
				{label && <FormLabel>{label}</FormLabel>}
				<FormControl>
					<Switchbox
						name={name}
						value={value}
						onValueChange={onValueChange}
						isDisabled={isDisabled || false}
						label={label}
					/>
				</FormControl>
				{helpBlock && (
					<FormDescription
						className='text-xs italic'
						dangerouslySetInnerHTML={{ __html: helpBlock }}
					></FormDescription>
				)}
				<FormMessage />
			</FormItem>
		</>
	);
};

export default FormSwitchbox;
