import { useLazyPartnerGetAllQuery } from '@/contexts/api/contract-management/catalog/partner-api';
import { useAppSelector } from '@/contexts/store';
import { useEffect } from 'react';

export const useCatalogPartner = () => {
	const { list } = useAppSelector((state) => state.Catalog.Partner);
	const [trigger] = useLazyPartnerGetAllQuery();

	useEffect(() => {
		if (list.length === 0) {
			trigger();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return { all_catalog_partners: list };
};
