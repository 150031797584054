import { cn } from '@/lib/utils';
import React, { useEffect } from 'react';
import { Link, LinkProps, useLocation, useNavigate } from 'react-router-dom';
import MainMenu from './main-menu';
import { Sheet, SheetContent, SheetTrigger } from '@/components/base/ui/sheet';
import { Button } from '@/components/base/ui/button';
import { ScrollArea } from '@/components/base/ui/scroll-area';

const MobileSidebar = () => {
	const [open, setOpen] = React.useState(false);
	const location = useLocation();
	useEffect(() => {
		setOpen(false);
	}, [location.pathname]);
	return (
		<Sheet open={open} onOpenChange={setOpen}>
			<SheetTrigger asChild>
				<Button
					variant='ghost'
					className='mr-2 px-0 text-base hover:bg-transparent focus-visible:bg-transparent focus-visible:ring-0 focus-visible:ring-offset-0 xl:hidden'
				>
					<svg
						className='w-6 h-6'
						//
						fill='currentColor'
						viewBox='0 0 20 20'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path
							clipRule='evenodd'
							fillRule='evenodd'
							d='M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z'
						></path>
					</svg>
					<span className=''>
						<MobileLink
							to='/'
							className='flex items-center h-12 w-full px-4'
							onOpenChange={setOpen}
						>
							<span className='font-bold'>CMS</span>
						</MobileLink>
					</span>
				</Button>
			</SheetTrigger>
			<SheetContent side='left' className='p-0'>
				<MobileLink
					to='/'
					className='flex items-center h-12 w-full px-4'
					onOpenChange={setOpen}
				>
					<span className='font-bold'>CMS</span>
				</MobileLink>
				<ScrollArea className='h-[calc(100vh-0rem)] p-4'>
					<MainMenu isOpen={true} />
				</ScrollArea>
			</SheetContent>
		</Sheet>
	);
};

interface MobileLinkProps extends LinkProps {
	onOpenChange?: (open: boolean) => void;
	children: React.ReactNode;
	className?: string;
}

function MobileLink({
	to,
	onOpenChange,
	className,
	children,
	...props
}: MobileLinkProps) {
	const router = useNavigate();
	return (
		<Link
			to={to}
			onClick={() => {
				router(to.toString());
				onOpenChange?.(false);
			}}
			className={cn(className)}
			{...props}
		>
			{children}
		</Link>
	);
}

export default MobileSidebar;
