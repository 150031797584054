import { AppFeatureApi } from '@/contexts/api/super-admin';
import { AppFeatureModel } from '@/contexts/models/super-admin/AppFeature';
import { createSlice } from '@reduxjs/toolkit';

type AppFeatureState = {
	list: AppFeatureModel[];
	detail?: AppFeatureModel;
	is_open_dialog: boolean;
	selected_ids: number[];
	is_open_confirm: boolean;
};

const initialState = {
	list: [],
	detail: undefined,
	is_open_confirm: false,
	is_open_dialog: false,
	selected_ids: [],
} as AppFeatureState;

const entityEndpointsApi = AppFeatureApi.endpoints;

const AppFeatureSlice = createSlice({
	name: 'AppFeatureSlice',
	initialState: initialState,
	reducers: {
		AppFeatureShowDetail: (state, { payload }) => {
			state.is_open_dialog = payload.is_open_dialog;
			state.detail = payload.detail;
		},
		AppFeatureShowConfirm: (state, { payload }) => {
			state.is_open_confirm = payload;
		},
		AppFeatureSelectedIdsChange: (state, { payload }) => {
			state.selected_ids = payload;
		},
	},
	extraReducers: (builder) => {
		builder.addMatcher(
			entityEndpointsApi.appFeatureGetAll.matchFulfilled,
			(state, { payload }) => {
				state.list = payload || [];
			}
		);
		builder.addMatcher(
			entityEndpointsApi.appFeatureSave.matchFulfilled,
			(state) => {
				state.is_open_dialog = false;
				state.detail = undefined;
			}
		);
		builder.addMatcher(
			entityEndpointsApi.appFeatureDelete.matchFulfilled,
			(state) => {
				state.is_open_confirm = false;
				state.selected_ids = [];
			}
		);
		builder.addMatcher(
			entityEndpointsApi.appFeatureDeleteMultiple.matchFulfilled,
			(state) => {
				state.is_open_confirm = false;
				state.selected_ids = [];
			}
		);
	},
});
export const appFeatureActions = {
	...AppFeatureSlice.actions,
};
export default AppFeatureSlice.reducer;
