import React from 'react';
import {
	Card,
	CardContent,
	CardFooter,
	CardHeader,
	CardTitle,
} from '../base/ui/card';
import { cn } from '@/lib/utils';
interface CardboxProps {
	children?: React.ReactNode;
	className?: string;
	title?: string;
	footer?: React.ReactNode;
	header?: {
		className?: string;
		children?: React.ReactNode;
	};
	useScrollArea?: boolean;
}
const Cardbox = ({
	title,
	header,
	footer,
	className,
	children,
}: CardboxProps) => {
	return (
		<>
			<Card className='mb-2'>
				<CardHeader
					className={cn(
						'p-4 py-2',
						!title && 'p-2',
						header && header.className
					)}
				>
					{title && <CardTitle className='text-md'>{title}</CardTitle>}
					{header && header.children}
				</CardHeader>
				<CardContent className={cn('gap-2', className)}>{children}</CardContent>
				{footer && (
					<CardFooter className='flex justify-between'>{footer}</CardFooter>
				)}
			</Card>
		</>
	);
};

export default Cardbox;
