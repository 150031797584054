import { DateTimePicker } from '@/components/base/ui/datetime-picker';
import { parseDate } from '@internationalized/date';
import { formatDate } from 'date-fns';
import React, { useEffect } from 'react';
import { DateValue } from 'react-aria';
import {
	FormDescription,
	FormItem,
	FormLabel,
	FormMessage,
} from '../../base/ui/form';
interface FromDateTimePickerProps {
	name: string;
	label: string;
	value?: string;
	/* Trả về ngày */
	onValueChange?: (value?: string) => void;
	helpBlock?: string;
	isDisabled?: boolean;
	placeholder?: string;
	isRequired?: boolean;
}
const FromDateTimePicker = ({
	name,
	value,
	label,
	onValueChange,
	placeholder,
	helpBlock,
	isRequired,
}: FromDateTimePickerProps) => {
	const [date, setDate] = React.useState<DateValue | undefined>();

	useEffect(() => {
		const date = value ? parseDate(formatDate(value, 'yyyy-MM-dd')) : undefined;
		setDate(date);
	}, [value]);

	return (
		<FormItem className='flex flex-col pb-2'>
			<FormLabel htmlFor={name}>
				{label} {isRequired ? <code className='text-red-400'>*</code> : ''}
			</FormLabel>
			<DateTimePicker
				value={date}
				onChange={(data) => {
					setDate(data);
					onValueChange && onValueChange(data.toString());
				}}
			/>
			{helpBlock && (
				<FormDescription
					className='text-xs italic'
					dangerouslySetInnerHTML={{ __html: helpBlock }}
				></FormDescription>
			)}
			<FormMessage />
		</FormItem>
	);
};

export default FromDateTimePicker;
