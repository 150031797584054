import LoginLayout from '@/layouts/login/login-layout';
import MainLayout from '@/layouts/main/main-layout';
import Homepage from '@/pages/home/home-page';
import LoginPage from '@/pages/login/login-page';
import { createBrowserRouter, useRoutes } from 'react-router-dom';
import { catalogRoutes } from './pages/contract-management/catalog';
import { contractRoutes } from './pages/contract-management/contract';
import { RootBoundary } from './pages/error-page/boundary';
import { superAdminRoutes } from './pages/super-admin';
import { BaoCao } from './pages/home/bao-cao';
import { reportRoutes } from './pages/contract-management/report/report-routes';

export const appRoutes = createBrowserRouter([
	{
		element: <MainLayout />,
		// errorElement: <RootBoundary />,
		path: '/',
		children: [
			{
				path: '',
				element: <Homepage />,
			},
			{ path: '*', element: <RootBoundary /> },
			...superAdminRoutes,
			...catalogRoutes,
			...contractRoutes,
			...reportRoutes
		],
	},
	{
		element: <LoginLayout />,
		children: [
			{
				path: 'login',
				element: <LoginPage />,
			},
		],
	},
]);
