import { FormCombobox } from '@/components/common/form';
import { goodsActions } from '@/contexts/reducers/contract-management/catalog/goods-slice';
import { AppState, useAppDispatch, useAppSelector } from '@/contexts/store';
import { useCatalogGoods } from '@/hooks/catalog/use-catalog-goods';
import GoodsDetailSheet from '@/pages/contract-management/catalog/goods/detail-sheet';
import React from 'react';

interface GoodsComboboxProps {
	label?: string;
	isRequired?: boolean;
	name: string;
	value?: any;
	defaultValue?: any;
	onValueChange?: (value?: any) => void;
	onDataChange?: (value?: any) => void;
	isDisabled?: boolean;
	itemRender?: (data: any) => React.ReactNode;
	isClearable?: boolean;
	isLoading?: boolean;
}

export const GoodsCombobox = ({ name, value, ...rest }: GoodsComboboxProps) => {
	const dispatch = useAppDispatch();
	const { all_catalog_goods } = useCatalogGoods();
	const { is_open_dialog, detail } = useAppSelector(
		(state: AppState) => state.Catalog.Goods
	);
	const addHandle = (isOpen: boolean) => {
		dispatch(goodsActions.GoodsShowDetail({ is_open_dialog: isOpen }));
	};
	return (
		<>
			<FormCombobox
				name={name}
				dataSource={all_catalog_goods}
				addAction={{
                    hasAddAction: true,
                    onAddItem: () => addHandle(true),
                }}
				displayExpr='name'
				valueExpr='id'
				placeholder='Chọn hàng hóa...'
				{...rest}
			/>
			{is_open_dialog && (
				<GoodsDetailSheet
					isOpen={is_open_dialog}
					onOpenChange={() => addHandle(false)}
					detail_id={0}
					title='hàng hóa'
				/>
			)}
		</>
	);
};
