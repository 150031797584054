import { z, ZodType } from 'zod';

const zod_valid = {
	/* */
	boolean: () => z.boolean(),
	bool: () => z.boolean(),
	date: () => z.date(),
	date_required: (message?: string) =>
		z.date().min(new Date(1900, 1, 1), message || 'Vui lòng chọn ngày.'),
	string: () => z.string(),
	string_required: (message?: string) =>
		z.string().min(1, message || 'Vui lòng nhập nội dung.'),
	string_array_required: (message?: string) =>
		z.array(z.string()).min(1, message || 'Danh sách không thể trống.'),
	number: () => z.number(),
	number_required: (message?: string) =>
		z.number().min(1, message || 'Vui lòng nhập giá trị số.'),
	number_array_required: (message?: string) =>
		z.array(z.number()).min(1, message || 'Danh sách không thể trống.'),
	number_min_required: (min?: number, message?: string) =>
		z
			.number()
			.min(
				!min || min > Number.MIN_SAFE_INTEGER ? Number.MIN_SAFE_INTEGER : min,
				{
					message:
						message ||
						`Vui lòng nhập giá trị số lớn hơn ${(!min || min > Number.MIN_SAFE_INTEGER ? Number.MIN_SAFE_INTEGER : min).toLocaleString('vi-VN')}.`,
				}
			),
	number_max_required: (max?: number, message?: string) =>
		z
			.number()
			.max(
				!max || max > Number.MAX_SAFE_INTEGER ? Number.MAX_SAFE_INTEGER : max,
				{
					message:
						message ||
						`Vui lòng nhập giá trị số nhỏ hơn ${(!max || max > Number.MAX_SAFE_INTEGER ? Number.MAX_SAFE_INTEGER : max).toLocaleString('vi-VN')}.`,
				}
			),
	number_minmax_required: (min?: number, max?: number, message?: string) =>
		z
			.number()
			.min(
				!min || min > Number.MIN_SAFE_INTEGER ? Number.MIN_SAFE_INTEGER : min
			)
			.max(
				!max || max > Number.MAX_SAFE_INTEGER ? Number.MAX_SAFE_INTEGER : max
			)
			.refine(
				(value) => {
					const setMin =
						!min || min > Number.MIN_SAFE_INTEGER
							? Number.MIN_SAFE_INTEGER
							: min;
					const setMax =
						!max || max > Number.MAX_SAFE_INTEGER
							? Number.MAX_SAFE_INTEGER
							: max;
					if (value < setMin || value > setMax) {
						return false;
					}
					return true;
				},
				{
					message:
						message ||
						`Vui lòng nhập giá trị số trong khoảng từ ${(!min ||
						min > Number.MIN_SAFE_INTEGER
							? Number.MIN_SAFE_INTEGER
							: min
						).toLocaleString('vi-VN')} đến ${(!max ||
						max > Number.MAX_SAFE_INTEGER
							? Number.MAX_SAFE_INTEGER
							: max
						).toLocaleString('vi-VN')}.`,
				}
			),
	email_required: (is_valid: true | false, message?: string) => {
		const errorMessage = message || 'Định dạng email không phù hợp.';
		const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		if (is_valid) {
			return z
				.string()
				.min(1, 'Vui lòng nhập email')
				.refine((value) => regex.test(value), {
					message: errorMessage,
				});
		}
		return z.string().refine(
			(value) => {
				if (value.length > 0) {
					return regex.test(value);
				}
				return true;
			},
			{
				message: errorMessage,
			}
		);
	},
	phone_required: (is_valid: true | false, message?: string) => {
		const errorMessage = message || 'Định dạng email không phù hợp.';
		const regex = /^(84|0[3|5|7|8|9])+([0-9]{8})$/;
		if (is_valid) {
			return z
				.string()
				.min(1, 'Vui lòng nhập số điện thoại')
				.refine((value) => regex.test(value), {
					message: errorMessage,
				});
		}
		return z.string().refine(
			(value) => {
				if (value.length > 0) {
					return regex.test(value);
				}
				return true;
			},
			{
				message: errorMessage,
			}
		);
	},
	password: (is_valid: true | false, min?: number, message?: string) => {
		const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
		const minLength = min || 6;
		const errorMessage =
			message ||
			`Mật khẩu tối thiểu ${minLength} ký tự và phải bao gồm chữ hoa, chữ thường và số`;
		if (is_valid) {
			return z
				.string()
				.min(minLength, errorMessage)
				.refine((value) => regex.test(value), {
					message: message || errorMessage,
				});
		}
		return z.string().refine(
			(value) => {
				if (value.length > 0) {
					return regex.test(value);
				}
				return true;
			},
			{
				message: message || errorMessage,
			}
		);
	},
};
export { ZodType as zod_type, z as zod, zod_valid };
