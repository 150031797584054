import { Badge } from '@/components/base/ui/badge';
import { ButtonCustom } from '@/components/common/button';
import DataTable from '@/components/data-table';
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from '@/components/base/ui/dropdown-menu';
import {
	useContractQuotationDeleteMultipleMutation,
	useContractQuotationGetAllQuery,
} from '@/contexts/api/contract-management/contract/contract-quotation-api';
import {
	ContractQuotation,
	ContractQuotationFormDefault,
	ContractQuotationFormModel,
} from '@/contexts/models/contract-management/contract/ContractQuotation';
import { contractQuotationActions } from '@/contexts/reducers/contract-management/contract/contract-quotation.slice';

import { AppState, useAppDispatch, useAppSelector } from '@/contexts/store';
import { ContractPurchaseListCard } from '@/modules/contract/table/purchase-mobile-table';
import { IsMobile } from '@/utils/check-mobile';

import { eDocumentType } from '@/contexts/models/contract-management/contract';
import { ContractSalesListByQuotationSheet } from '@/modules/contract/contract-list-by-quotation-sheet';
import { ColumnDef } from '@tanstack/react-table';
import { formatDate } from 'date-fns';
import {
	EllipsisIcon,
	FileArchiveIcon,
	FileBadge2Icon,
	FileDownIcon,
} from 'lucide-react';
import { useCallback, useState } from 'react';
import { QuotationStatusBadge } from '../form/quotation-status-badge';
import { ContractQuotationDetailSheet } from '../quotation-detail-sheet';
import { Button } from '@/components/base/ui/button';

interface IQuotationTableProps {
	contract_id?: number;
}

export const QuotationTable = ({ contract_id = 0 }: IQuotationTableProps) => {
	const dispatch = useAppDispatch();
	const title = 'phiếu báo giá chào hàng';
	const contractId = 0;
	const { is_open_dialog, selected_ids, detail } = useAppSelector(
		(state: AppState) => state.Contract.ContractQuotation
	);
	const [openSaleList, setOpenSaleList] = useState({
		isOpen: false,
		quotation_id: 0,
	});
	const {
		data,
		isFetching,
		isLoading,
		refetch: usePrefetch,
	} = useContractQuotationGetAllQuery(contractId);

	const [deletesAction] = useContractQuotationDeleteMultipleMutation();
	const addEditHandle = (
		isOpen: boolean,
		detail?: ContractQuotationFormModel
	) => {
		dispatch(
			contractQuotationActions.ContractQuotationShowDetail({
				is_open_dialog: isOpen,
				detail: detail,
			})
		);
	};

	const deleteMultiHandle = (isOpen: boolean) => {
		dispatch(
			contractQuotationActions.ContractQuotationShowConfirm({
				is_open_confirm: isOpen,
			})
		);
	};

	const selectedIdsChange = (ids: number[]) => {
		dispatch(
			contractQuotationActions.ContractQuotationSelectedIdsChange({
				selected_ids: ids,
			})
		);
	};

	const deleteMultiConfirm = useCallback(async () => {
		if (selected_ids.length > 0) await deletesAction(selected_ids).unwrap();
	}, [deletesAction, selected_ids]);

	const columns: ColumnDef<ContractQuotation>[] = [
		{
			accessorKey: 'quotation_date',
			header: 'Ngày',
			cell: ({ row }) => {
				return (
					<div className='text-center'>
						<div className='text-xxs'>
							{formatDate(row.original.quotation_date, 'dd/MM/yyyy')}
						</div>
						<QuotationStatusBadge value={row.original.quotation_status_id} />
					</div>
				);
			},
		},
		{
			id: 'action_button',
			header: '...',
			cell: ({ row }) => {
				return (
					<div className='text-center'>
						<DropdownMenu>
							<DropdownMenuTrigger asChild>
								<Button size='icon' variant='outline' className='h-5 w-5'>
									<EllipsisIcon className='h-3.5 w-3.5' />
									<span className='sr-only'>More</span>
								</Button>
							</DropdownMenuTrigger>
							<DropdownMenuContent align='start'>
								<DropdownMenuItem>
									<ButtonCustom
										variant={'link'}
										icon={<FileArchiveIcon className='h-3.5 w-3.5' />}
										onClick={() => {
											addEditHandle(
												true,
												row.original as ContractQuotationFormModel
											);
										}}
										className='text-black h-5'
										title='Chỉnh sửa'
									/>
								</DropdownMenuItem>
								<DropdownMenuSeparator />
								<DropdownMenuItem>
									<ButtonCustom
										variant={'link'}
										className='text-black h-5'
										icon={<FileArchiveIcon className='h-3.5 w-3.5' />}
										onClick={() => {
											setOpenSaleList({
												isOpen: true,
												quotation_id: row.original.id,
											});
										}}
										title='HĐ bán hàng'
									/>
								</DropdownMenuItem>
							</DropdownMenuContent>
						</DropdownMenu>
					</div>
				);
			},
		},
		{
			id: 'customer_name',
			header: 'Khách hàng',
			cell: ({ row }) => {
				return (
					<div className='text-left text-xxs'>
						<div className='text-xs'>
							<span
								className='font-semibold text-primary hover:underline hover:text-primary/90 hover:cursor-pointer'
								onClick={() =>
									addEditHandle(
										true,
										row.original as ContractQuotationFormModel
									)
								}
							>
								{row.original.quotation_code || 'N/A'}
							</span>
						</div>
						<div className='text-xxs'>
							Hàng hóa: <b className='text-xxs'>{row.original.goods_name}</b>{' '}
						</div>
						<div className='text-xxs'>
							Khách hàng: <b>{row.original.customer_name}</b>
						</div>
					</div>
				);
			},
		},
		{
			id: 'goods_quantity',
			header: 'Số lượng (Kg)',
			enableSorting: false,
			cell: ({ row }) => {
				return (
					<div className='text-center text-xxs'>
						<div className='text-sm'>
							<code className='text-xs'>
								{row.original.goods_quantity.toLocaleString('vi')}
							</code>
						</div>
					</div>
				);
			},
		},
		{
			id: 'goods_price',
			header: 'Đơn giá (VNĐ)',
			enableSorting: false,
			cell: ({ row }) => {
				return (
					<div className='text-center text-xxs'>
						<div className='text-sm'>
							<code className='text-xs'>
								{row.original.goods_price.toLocaleString('vi')}
							</code>
						</div>
					</div>
				);
			},
		},
		{
			accessorKey: 'goods_sold',
			header: 'Dự kiến giao hàng',
			cell: ({ row }) => {
				return (
					<div className='text-center text-xxs'>
						<div className='text-xxs'>
							<div className='hidden text-muted-foreground md:block'>
								<b>
									{formatDate(
										row.original.quotation_delivery_date_start || '',
										'dd/MM/yyyy'
									)}{' '}
									-{' '}
									{formatDate(
										row.original.quotation_delivery_date_end || '',
										'dd/MM/yyyy'
									)}
								</b>
							</div>
							<div className='hidden text-muted-foreground md:block'>
								<b>{row.original.quotation_delivery_address}</b>
							</div>
						</div>
					</div>
				);
			},
		},
	];

	return (
		<>
			{IsMobile() ? (
				<ContractPurchaseListCard />
			) : (
				<DataTable
					isLoading={isLoading || isFetching}
					data={data || []}
					columns={columns}
					keyExpr={'id'}
					searchKey={'customer_name'}
					onReload={usePrefetch}
					isScrollable={true}
					height='h-[300px]'
				/>
			)}
			{is_open_dialog && (
				<ContractQuotationDetailSheet
					title={title}
					contract_id={contractId}
					contract_code=''
					isOpen={is_open_dialog}
					onOpenChange={() => addEditHandle(false)}
					detail={detail || ContractQuotationFormDefault}
				/>
			)}
			{openSaleList.isOpen && (
				<ContractSalesListByQuotationSheet
					isOpen={openSaleList.isOpen}
					quotation_id={openSaleList.quotation_id}
					document_type={eDocumentType.HOP_DONG_BAN_HANG}
					title='hợp đồng bán hàng'
					onOpenChange={() =>
						setOpenSaleList({
							isOpen: false,
							quotation_id: 0,
						})
					}
				/>
			)}
		</>
	);
};
