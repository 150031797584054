import { ContractDispatchApi } from '@/contexts/api/contract-management/contract/contract-dispatch-api';
import {
	ContractDispatch,
	ContractDispatchFormModel,
} from '@/contexts/models/contract-management/contract/ContractDispatchModel';

import { NotifyHelper } from '@/utils/notify-helper';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const entityName = 'Phiếu đợt giao hàng';

type ContractDispatchState = {
	contractDispatches: ContractDispatch[];
	detail?: ContractDispatchFormModel;
	is_open_dialog: boolean;
	selected_ids: number[];
	is_open_confirm: boolean;
	is_open_lock_confirm: boolean;
	is_open_change_status: boolean;
	is_open_unlock_confirm: boolean;
	inserted_id?: number;
};

const initialState = {
	contractDispatches: [],
	detail: undefined,
	is_open_confirm: false,
	is_open_dialog: false,
	selected_ids: [],
	inserted_id: undefined,
	is_open_lock_confirm: false,
	is_open_change_status: false,
	is_open_unlock_confirm: false,
} as ContractDispatchState;

const entityEndpointsApi = ContractDispatchApi.endpoints;

const ContractDispatchSlice = createSlice({
	name: 'ContractDispatchSlice',
	initialState: initialState,
	reducers: {
		ContractDispatchShowDetail: (
			state,
			{
				payload,
			}: PayloadAction<{
				is_open_dialog: boolean;
				detail?: ContractDispatchFormModel;
			}>
		) => {
			state.is_open_dialog = payload.is_open_dialog;
			state.detail = payload.detail;
			state.inserted_id = undefined;
		},
		ContractDispatchShowConfirm: (
			state,
			{ payload }: PayloadAction<{ is_open_confirm: boolean }>
		) => {
			state.is_open_confirm = payload.is_open_confirm;
		},
		ContractDispatchShowLockConfirm: (
			state,
			{ payload }: PayloadAction<{ is_open_lock_confirm: boolean }>
		) => {
			state.is_open_lock_confirm = payload.is_open_lock_confirm;
		},
		ContractDispatchShowUnlockConfirm: (
			state,
			{ payload }: PayloadAction<{ is_open_unlock_confirm: boolean }>
		) => {
			state.is_open_unlock_confirm = payload.is_open_unlock_confirm;
		},
		ContractDispatchSelectedIdsChange: (
			state,
			{ payload }: PayloadAction<{ selected_ids: number[] }>
		) => {
			state.selected_ids = payload.selected_ids;
		},
		ContractDispatchChangeStatus: (
			state,
			{
				payload,
			}: PayloadAction<{
				is_open_change_status: boolean;
			}>
		) => {
			state.is_open_change_status = payload.is_open_change_status;
		},
	},
	extraReducers: (builder) => {
		builder.addMatcher(
			entityEndpointsApi.contractDispatchGetAll.matchFulfilled,
			(state, { payload }) => {
				state.contractDispatches = payload || [];
			}
		);
		builder.addMatcher(
			entityEndpointsApi.contractDispatchSave.matchFulfilled,
			(state, { payload }) => {
				state.is_open_dialog = false;
				state.detail = undefined;

				const { is_success, message, result } = payload;
				state.inserted_id = result?.id;
				if (is_success)
					NotifyHelper.Success(message || `Lưu ${entityName} thành công`);
				else
					NotifyHelper.Error(message || `Lưu ${entityName} không thành công`);
			}
		);
		builder.addMatcher(
			entityEndpointsApi.contractDispatchConfirm.matchFulfilled,
			(state, { payload }) => {
				const { is_success, message } = payload;
				if (is_success)
					NotifyHelper.Success(
						message || `Khóa/mở khóa ${entityName} thành công`
					);
				else
					NotifyHelper.Error(
						message || `Khóa/mở khóa ${entityName} không thành công`
					);
				state.is_open_lock_confirm = false;
				state.is_open_unlock_confirm = false;
				state.selected_ids = [];
			}
		);
		builder.addMatcher(
			entityEndpointsApi.contractDispatchDelete.matchFulfilled,
			(state, { payload }) => {
				state.is_open_confirm = false;
				state.selected_ids = [];
				const { is_success, message } = payload;
				if (is_success)
					NotifyHelper.Success(message || `Xóa ${entityName} thành công`);
				else
					NotifyHelper.Error(message || `Xóa ${entityName} không thành công`);
			}
		);
		builder.addMatcher(
			entityEndpointsApi.contractDispatchDeleteMultiple.matchFulfilled,
			(state, { payload }) => {
				state.is_open_confirm = false;
				state.selected_ids = [];

				const { is_success, message } = payload;
				if (is_success)
					NotifyHelper.Success(message || `Xóa ${entityName} thành công`);
				else
					NotifyHelper.Error(message || `Xóa ${entityName} không thành công`);
			}
		);
		builder.addMatcher(
			entityEndpointsApi.contractDispatchChangeStatus.matchFulfilled,
			(state) => {
				state.is_open_change_status = false;
				state.selected_ids = [];
			}
		);
	},
});
export const contractDispatchActions = ContractDispatchSlice.actions;
export default ContractDispatchSlice.reducer;
