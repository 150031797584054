import { RoleApi } from '@/contexts/api/super-admin';
import { RoleModel } from '@/contexts/models/super-admin/Role';
import { createSlice } from '@reduxjs/toolkit';

type RoleState = {
	list: RoleModel[];
	detail?: RoleModel;
	is_open_dialog: boolean;
	selected_ids: number[];
	is_open_confirm: boolean;
};

const initialState = {
	list: [],
	detail: undefined,
	is_open_confirm: false,
	is_open_dialog: false,
	selected_ids: [],
} as RoleState;

const entityEndpointsApi = RoleApi.endpoints;

const RoleSlice = createSlice({
	name: 'RoleSlice',
	initialState: initialState,
	reducers: {
		RoleShowDetail: (state, { payload }) => {
			state.is_open_dialog = payload.is_open_dialog;
			state.detail = payload.detail;
		},
		RoleShowConfirm: (state, { payload }) => {
			state.is_open_confirm = payload;
		},
		RoleSelectedIdsChange: (state, { payload }) => {
			state.selected_ids = payload;
		},
	},
	extraReducers: (builder) => {
		builder.addMatcher(
			entityEndpointsApi.roleGetAll.matchFulfilled,
			(state, { payload }) => {
				state.list = payload || [];
			}
		);
		builder.addMatcher(entityEndpointsApi.roleSave.matchFulfilled, (state) => {
			state.is_open_dialog = false;
			state.detail = undefined;
		});
		builder.addMatcher(
			entityEndpointsApi.roleDelete.matchFulfilled,
			(state) => {
				state.is_open_confirm = false;
				state.selected_ids = [];
			}
		);
		builder.addMatcher(
			entityEndpointsApi.roleDeleteMultiple.matchFulfilled,
			(state) => {
				state.is_open_confirm = false;
				state.selected_ids = [];
			}
		);
	},
});
export const roleActions = {
	...RoleSlice.actions,
};
export default RoleSlice.reducer;
