import { ButtonClose, ButtonSave } from '@/components/common';
import { useContractPaymentChangeStatusMutation } from '@/contexts/api/contract-management/contract/contract-payment-api';

import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from '@/ui/dialog';
import React, { useCallback } from 'react';
import PaymentStatusCombobox from './payment-status-combobox';

interface IContractPaymentChangeStatusDialogProps {
	is_open: boolean;
	onOpenChange: (isOpen: boolean) => void;
	contract_ids: number[];
}

export function ContractPaymentChangeStatusDialog({
	is_open,
	onOpenChange,
	contract_ids,
}: IContractPaymentChangeStatusDialogProps) {
	const [status, setStatus] = React.useState<string>('');
	const [changeStatusAction] = useContractPaymentChangeStatusMutation();
	const changeStatusConfirm = useCallback(async () => {
		if (contract_ids.length > 0 && status.length > 0) {
			await changeStatusAction({
				ids: contract_ids,
				payment_status_id: status,
			}).unwrap();
		}
	}, [changeStatusAction, contract_ids, status]);
	return (
		<Dialog open={is_open} onOpenChange={onOpenChange}>
			<DialogContent className='sm:max-w-md'>
				<DialogHeader>
					<DialogTitle>Đổi trạng thái phiếu đề nghị thanh toán</DialogTitle>
				</DialogHeader>
				<PaymentStatusCombobox
					value={status}
					onValueChange={(value) => setStatus(value)}
					name='contract_payment_change_status'
				/>
				<DialogFooter className='sm:justify-start'>
					<DialogClose asChild>
						<ButtonClose />
					</DialogClose>
					<ButtonSave
						title='Đổi trạng thái'
						onClick={() => changeStatusConfirm()}
					/>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
}
