import { signOut } from '@/contexts/reducers/auth/auth-slice';
import { useAppDispatch, useAppSelector } from '@/contexts/store';
import { useCallback, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const useAuth = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useAppDispatch();
	const { account } = useAppSelector((state) => state.Auth);
	const signOutAction = useCallback(() => {
		dispatch(signOut());
		navigate('/login', { replace: false, state: { from: location } });
	}, [dispatch, navigate, location]);

	useEffect(() => {
		if (!account) {
			navigate('/login', { replace: false, state: { from: location } });
		}
	}, [account, navigate, location]);

	return useMemo(
		() => ({ account, menus: account?.menus, signOutAction }),
		[account, signOutAction]
	);
};
