import { useLazyGoodsGetAllQuery } from '@/contexts/api/contract-management/catalog/goods-api';
import { useLazySupplierGetAllQuery } from '@/contexts/api/contract-management/catalog/supplier-api';
import { useAppSelector } from '@/contexts/store';
import { useEffect } from 'react';

export const useCatalogGoods = () => {
	const { list } = useAppSelector((state) => state.Catalog.Goods);
	const [trigger] = useLazyGoodsGetAllQuery();

	useEffect(() => {
		if (list.length === 0) {
			trigger();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	
	return { all_catalog_goods: list };
};
