import { FormField } from '@/components/base/ui/form';
import { FormInput, FormSwitchbox } from '@/components/common/form';
import { FormSheetDialog } from '@/components/common/form/form-sheet-dialog';
import FormTextarea from '@/components/common/form/form-text-area';
import { usePartnerSaveMutation } from '@/contexts/api/contract-management/catalog/partner-api';
import {
	Partner,
	PartnerFormDefault,
	PartnerFormModel,
	PartnerFormValid,
} from '@/contexts/models/contract-management/catalog';
import { zodResolver } from '@hookform/resolvers/zod';
import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import BankInfoCard from './items/bank-info-card';

interface PartnerProps {
	title: string;
	isOpen: boolean;
	onOpenChange: (isOpen: boolean) => void;
	detail?: Partner;
}

const PartnerDetail = ({
	title,
	isOpen,
	onOpenChange,
	detail,
}: PartnerProps) => {
	const [saveAction, { isLoading: isSaving, isSuccess: isSaved }] =
		usePartnerSaveMutation();

	const defaultValues = detail || PartnerFormDefault;
	const form = useForm<PartnerFormModel>({
		resolver: zodResolver(PartnerFormValid),
		defaultValues,
	});

	const onSubmit = useCallback(
		async (formState: PartnerFormModel) => {
			await saveAction(formState).unwrap();
		},
		[saveAction]
	);

	useEffect(() => {
		if (isSaved) {
			onOpenChange(false);
		}
	}, [isSaved, onOpenChange]);

	return (
		<>
			<FormSheetDialog
				sheetTitle={`${defaultValues.id > 0 ? 'Chỉnh sửa' : 'Tạo mới'} ${title}`}
				isOpen={isOpen}
				onOpenChange={onOpenChange}
				form={form}
				onSubmit={onSubmit}
				isDisabled={isSaving}
				hasScroll={true}
			>
				<div>
					<FormField
						control={form.control}
						name='code'
						render={({ field }) => (
							<FormInput
								isRequired={true}
								name={field.name}
								value={field.value}
								onValueChange={field.onChange}
								isDisabled={isSaving}
								label='Mã'
							/>
						)}
					/>
					<FormField
						control={form.control}
						name='name'
						render={({ field }) => (
							<FormInput
								isRequired={true}
								name={field.name}
								value={field.value}
								onValueChange={field.onChange}
								isDisabled={isSaving}
								label='Tên'
							/>
						)}
					/>
					<FormField
						control={form.control}
						name='is_active'
						render={({ field }) => (
							<FormSwitchbox
								name={field.name}
								value={field.value}
								onValueChange={field.onChange}
								isDisabled={isSaving}
								label='Áp dụng'
							/>
						)}
					/>
					<FormField
						control={form.control}
						name='address'
						render={({ field }) => (
							<FormTextarea
								isRequired={true}
								name={field.name}
								value={field.value}
								onValueChange={field.onChange}
								isDisabled={isSaving}
								label='Địa chỉ công ty'
							/>
						)}
					/>
					<FormField
						control={form.control}
						name='phone_number'
						render={({ field }) => (
							<FormInput
								isRequired={true}
								name={field.name}
								value={field.value}
								onValueChange={field.onChange}
								isDisabled={isSaving}
								label='Số điện thoại'
							/>
						)}
					/>
					<FormField
						control={form.control}
						name='tin'
						render={({ field }) => (
							<FormInput
								isRequired={true}
								name={field.name}
								value={field.value}
								onValueChange={field.onChange}
								isDisabled={isSaving}
								label='Mã số thuế'
							/>
						)}
					/>
					<FormField
						control={form.control}
						name='representative'
						render={({ field }) => (
							<FormInput
								isRequired={true}
								name={field.name}
								value={field.value}
								onValueChange={field.onChange}
								isDisabled={isSaving}
								label='Người đại diện'
							/>
						)}
					/>
					<FormField
						control={form.control}
						name='representative_title'
						render={({ field }) => (
							<FormInput
								isRequired={true}
								name={field.name}
								value={field.value}
								onValueChange={field.onChange}
								isDisabled={isSaving}
								label='Chức vụ người đại diện'
							/>
						)}
					/>
					<FormField
						control={form.control}
						name='bank_info'
						render={({ field }) => (
							<BankInfoCard
								array_string={field.value}
								onValueChange={field.onChange}
							/>
						)}
					/>
				</div>
			</FormSheetDialog>
		</>
	);
};

export default PartnerDetail;
