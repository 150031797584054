import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from '@/components/base/ui/dropdown-menu';
import { ButtonAdd, ButtonDelete, ConfirmDialog } from '@/components/common';
import DataTable from '@/components/data-table';
import {
	useContractDispatchDeleteMultipleMutation,
	useContractDispatchGetAllByFilterQuery,
} from '@/contexts/api/contract-management/contract/contract-dispatch-api';
import {
	ContractDispatch,
	ContractDispatchFormDefault,
	ContractDispatchFormModel,
} from '@/contexts/models/contract-management/contract/ContractDispatchModel';

import { ButtonCustom } from '@/components/common/button';
import { SheetDialog } from '@/components/common/sheet-dialog';
import { contractDispatchActions } from '@/contexts/reducers/contract-management/contract/contract-dispatch.slice';
import { AppState, useAppDispatch, useAppSelector } from '@/contexts/store';
import { ColumnDef } from '@tanstack/react-table';
import { formatDate } from 'date-fns';
import { useCallback, useState } from 'react';
import { TransportStatusBadge } from './form/transport-status-badge';
import { ContractDispatchDetailSheet } from './contract-dispatch-detail-sheet';
import { Button } from '@/components/base/ui/button';
import {
	EllipsisIcon,
	FileArchiveIcon,
	TagIcon,
	TruckIcon,
} from 'lucide-react';
import { Badge } from '@/components/base/ui/badge';
import ContractShipmentListSheet from '../contract-shipment/contract-shipment-list-sheet';
import { ContractDispatchChangeStatusDialog } from './form/contract-dispatch-change-status-dialog';
import { ContractDocumentTypeBadge } from '../contract/form/contract-document-type-badge';

interface ContractDispatchListSheetProps {
	contract_id?: number;
	sheetTitle: string;
	isOpen: boolean;
	onOpenChange: (isOpen: boolean) => void;
}

interface IOpenShipments {
	isOpen: boolean;
	dispatchInfo?: ContractDispatch;
}

const ContractDispatchListSheet = ({
	contract_id = 0,
	isOpen,
	onOpenChange,
	sheetTitle,
}: ContractDispatchListSheetProps) => {
	const dispatch = useAppDispatch();
	const title = 'đợt giao hàng';
	const {
		is_open_confirm,
		is_open_dialog,
		selected_ids,
		detail,
		is_open_change_status,
	} = useAppSelector((state: AppState) => state.Contract.ContractDispatch);
	const [openShipments, setOpenShipments] = useState<IOpenShipments>({
		isOpen: false,
		dispatchInfo: undefined,
	});
	const { data, isFetching, isLoading, refetch } =
		useContractDispatchGetAllByFilterQuery(contract_id);
	const [deletesAction] = useContractDispatchDeleteMultipleMutation();
	const addEditHandle = (
		isOpen: boolean,
		detail?: ContractDispatchFormModel
	) => {
		dispatch(
			contractDispatchActions.ContractDispatchShowDetail({
				is_open_dialog: isOpen,
				detail: detail,
			})
		);
	};

	const deleteMultiHandle = (isOpen: boolean) => {
		dispatch(
			contractDispatchActions.ContractDispatchShowConfirm({
				is_open_confirm: isOpen,
			})
		);
	};

	const selectedIdsChange = (ids: number[]) => {
		dispatch(
			contractDispatchActions.ContractDispatchSelectedIdsChange({
				selected_ids: ids,
			})
		);
	};

	const deleteMultiConfirm = useCallback(async () => {
		if (selected_ids.length > 0) await deletesAction(selected_ids).unwrap();
	}, [deletesAction, selected_ids]);

	const changeStatusHandle = (isOpen: boolean) => {
		dispatch(
			contractDispatchActions.ContractDispatchChangeStatus({
				is_open_change_status: isOpen,
			})
		);
	};

	const columns: ColumnDef<ContractDispatch>[] = [
		{
			accessorKey: 'contract_status_id',
			header: 'Trạng thái',
			cell: ({ row }) => {
				return (
					<div className='text-center'>
						<TransportStatusBadge value={row.original.dispatch_status_id} />
					</div>
				);
			},
		},
		{
			id: 'action_button',
			header: '...',
			cell: ({ row }) => {
				return (
					<div className='text-center'>
						<DropdownMenu>
							<DropdownMenuTrigger asChild>
								<Button size='icon' variant='outline' className='h-5 w-5'>
									<EllipsisIcon className='h-3.5 w-3.5' />
									<span className='sr-only'>More</span>
								</Button>
							</DropdownMenuTrigger>
							<DropdownMenuContent align='start'>
								<DropdownMenuItem>
									<ButtonCustom
										variant={'link'}
										icon={<FileArchiveIcon className='h-3.5 w-3.5' />}
										onClick={() => {
											addEditHandle(true, row.original);
										}}
										className='text-black h-5'
										title='Chỉnh sửa'
									/>
								</DropdownMenuItem>
								<DropdownMenuSeparator />
								<DropdownMenuItem>
									<ButtonCustom
										variant={'link'}
										className='text-black h-5'
										icon={<TruckIcon className='h-3.5 w-3.5' />}
										onClick={() =>
											setOpenShipments({
												isOpen: true,
												dispatchInfo: row.original,
											})
										}
										title='Điều vận'
									/>
								</DropdownMenuItem>
							</DropdownMenuContent>
						</DropdownMenu>
					</div>
				);
			},
		},
		{
			accessorKey: 'title',
			header: 'Thông tin đợt giao hàng',
			cell: ({ row }) => {
				return (
					<div className='text-left text-xxs'>
						<div className='text-xs font-semibold'>
							<span
								className='font-semibold text-primary hover:underline hover:text-primary/90 hover:cursor-pointer'
								// to={`/hop-dong-mua-hang/chi-tiet/${row.original.id}`}
								// onClick={() => addEditHandle(true, row.original)}
							>
								{row.original.is_default == false && (
									<>
										<Badge
											variant={'outline'}
											className='h-4 text-xxs text-blue-500'
										>
											Bổ sung
										</Badge>
									</>
								)}
								<ContractDocumentTypeBadge value={row.original.document_type} />
								{row.original.title || 'N/A'}
							</span>
						</div>
						<div className='text-xxs'>
							Hàng hoá:{' '}
							<b>
								{row.original.goods_code} - {row.original.goods_name}
							</b>
						</div>
						<div className='text-xxs'>
							Số lượng dự kiến:{' '}
							<b>{row.original.goods_quantity?.toLocaleString('vi')} (KG)</b>
						</div>
					</div>
				);
			},
		},
		{
			accessorKey: 'address',
			header: 'Thông tin giao/nhận hàng',
			cell: ({ row }) => {
				return (
					<div className='text-left text-xxs'>
						<div className='text-xxs'>
							<b>{formatDate(row.original.begin_date, 'dd/MM/yyyy')}</b> -{' '}
							<b>{formatDate(row.original.end_date, 'dd/MM/yyyy')}</b>
						</div>
						<div className='text-xxs'>
							Địa chỉ: <b>{row.original.address}</b>
						</div>
						<div className='text-xxs'>
							Quy cách: <b>{row.original.goods_packaging_requirements}</b>
						</div>
					</div>
				);
			},
		},
		{
			id: 'shipment_count',
			header: 'Tổng điều vận đã tạo',
			cell: ({ row }) => {
				return (
					<div className='text-center text-xxs'>
						{row.original.shipment_count?.toLocaleString('vi')}
					</div>
				);
			},
		},
		{
			id: 'goods_quantity_total_start',
			header(props) {
				return (
					<div className='text-right text-xxs'>
						Tổng lượng hàng <br></br> cân tại điểm nhận hàng (KG)
					</div>
				);
			},
			cell: ({ row }) => {
				return (
					<div className='text-right text-xxs'>
						{row.original.goods_quantity_total_start?.toLocaleString('vi')}
					</div>
				);
			},
		},
		{
			id: 'goods_quantity_total_end',
			header(props) {
				return (
					<div className='text-right text-xxs'>
						Tổng lượng hàng <br></br> cân tại điểm giao hàng (KG)
					</div>
				);
			},
			cell: ({ row }) => {
				return (
					<div className='text-right text-xxs'>
						{row.original.goods_quantity_total_end?.toLocaleString('vi')}
					</div>
				);
			},
		},
	];

	return (
		<>
			<SheetDialog
				sheetTitle={`Danh sách ${title} của ${sheetTitle}`}
				isOpen={isOpen}
				onOpenChange={onOpenChange}
				hasScroll={true}
				width='w-full'
			>
				<DataTable
					isLoading={isFetching || isLoading}
					toolBars={
						<>
							<ButtonAdd onClick={() => addEditHandle(true)} />
							<ButtonCustom
								title='Đổi trạng thái'
								icon={<TagIcon className='h-4 w-4' />}
								disabled={selected_ids.length <= 0}
								onClick={() => changeStatusHandle(true)}
							/>
							<ButtonDelete
								variant={'destructive'}
								title={`Xóa (${selected_ids.length})`}
								disabled={selected_ids.length <= 0}
								onClick={() => deleteMultiHandle(true)}
							/>
						</>
					}
					data={data || []}
					columns={columns}
					keyExpr={'id'}
					searchKey={['address', 'title']}
					onReload={refetch}
					selectionRows={{
						selectedRowsKey: selected_ids,
						onSelectedRowsKeyChange: (values) =>
							selectedIdsChange(values as number[]),
					}}
				/>
			</SheetDialog>
			{is_open_dialog && (
				<ContractDispatchDetailSheet
					title={title}
					isOpen={is_open_dialog}
					onOpenChange={() => addEditHandle(false)}
					detail={detail || ContractDispatchFormDefault}
				/>
			)}
			{is_open_change_status && (
				<ContractDispatchChangeStatusDialog
					is_open={is_open_change_status}
					onOpenChange={changeStatusHandle}
					contract_ids={selected_ids}
				/>
			)}
			{is_open_confirm && (
				<ConfirmDialog
					isOpen={is_open_confirm}
					onClose={() => deleteMultiHandle(false)}
					onConfirm={() => deleteMultiConfirm()}
					title='Thông báo'
					description={`Bạn có chắc chắn muốn xóa ${selected_ids.length} ${title} này không?`}
				/>
			)}
			{openShipments.isOpen && (
				<>
					<ContractShipmentListSheet
						isOpen={openShipments.isOpen}
						onOpenChange={() => setOpenShipments({ isOpen: false })}
						sheetTitle={openShipments.dispatchInfo?.title || ''}
						dispatch_id={openShipments.dispatchInfo?.id || 0}
						dispatch_info={openShipments.dispatchInfo}
					/>
				</>
			)}
		</>
	);
};

export default ContractDispatchListSheet;
