import { FormSheetDialog } from '@/components/common/form/form-sheet-dialog';
import {
    useLazyContractInfoGetDetailQuery
} from '@/contexts/api/contract-management/contract/contract-info-api';
import { useContractPaymentSaveMutation } from '@/contexts/api/contract-management/contract/contract-payment-api';
import {
    ContractPaymentFormModel,
    ContractPaymentValid,
} from '@/contexts/models/contract-management/contract/ContractPayment';
import { zodResolver } from '@hookform/resolvers/zod';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { PaymentDetailForm } from './form/payment-detail-form';

interface ContractPaymentProps {
	title: string;
	isOpen: boolean;
	onOpenChange: (isOpen: boolean) => void;
	detail: ContractPaymentFormModel;
	contract_id: number;
	contract_code: string;
}

export const ContractPaymentDetailSheet = ({
	title,
	isOpen,
	onOpenChange,
	detail,
	contract_id,
	contract_code,
}: ContractPaymentProps) => {
	const isEdit = useMemo(() => {
		return detail.id > 0;
	}, [detail]);

	const [saveAction, { isLoading: isSaving, isSuccess: isSaved }] =
		useContractPaymentSaveMutation();

	const [getContractDetail, { data: contractDetail }] =
		useLazyContractInfoGetDetailQuery();

	const [defaultValues, setDefaultValues] =
		useState<ContractPaymentFormModel>(detail);

	const form = useForm<ContractPaymentFormModel>({
		resolver: zodResolver(ContractPaymentValid),
		defaultValues,
	});

	const {
		reset,
		control,
		setValue,
		formState: { isSubmitting },
	} = form;

	const isLoading = isSaving || isSubmitting;

	useEffect(() => {
		if (contractDetail && !isEdit) {
			setDefaultValues((prev) => ({
				...prev,
				contract_id: contractDetail?.id || 0,
                contract_code: contractDetail?.contract_code || '',
                payment_status_id: contractDetail?.payment_status_id || 'NEW',
			}));
		}
	}, [contractDetail, isEdit]);

	useEffect(() => {
		if (!isEdit) getContractDetail(contract_id);
	}, [getContractDetail, contract_id, isEdit]);

	const onSubmit = useCallback(
		async (formState: ContractPaymentFormModel) => {
			await saveAction(formState).unwrap();
		},
		[saveAction]
	);

	useEffect(() => {
		setDefaultValues(detail);
	}, [detail]);

	useEffect(() => {
		if (isSaved) {
			onOpenChange(false);
		}
	}, [isSaved, onOpenChange]);

	useEffect(() => {
		reset(defaultValues);
	}, [reset, defaultValues]);

	return (
		<>
			<FormSheetDialog
				sheetTitle={`${detail.id > 0 ? 'Chỉnh sửa' : 'Tạo mới'} ${title}`}
				isOpen={isOpen}
				onOpenChange={onOpenChange}
				form={form}
				onSubmit={onSubmit}
				isDisabled={isLoading}
				hasScroll={true}
				width='w-1/3'
			>
				<PaymentDetailForm
					form={form}
					formData={defaultValues}
					isSaving={isLoading}
					// changeGoodsInfo={changeGoodsInfo}
				/>
			</FormSheetDialog>
		</>
	);
};
