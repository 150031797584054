import { GoodsApi } from '@/contexts/api/contract-management/catalog/goods-api';
import { Goods } from '@/contexts/models/contract-management/catalog/Goods';
import { createSlice } from '@reduxjs/toolkit';

type GoodsState = {
	list: Goods[];
	detail?: Goods;
	is_open_dialog: boolean;
	selected_ids: number[];
	is_open_confirm: boolean;
};

const initialState = {
	list: [],
	detail: undefined,
	is_open_confirm: false,
	is_open_dialog: false,
	selected_ids: [],
} as GoodsState;

const entityEndpointsApi = GoodsApi.endpoints;

const GoodsSlice = createSlice({
	name: 'GoodsSlice',
	initialState: initialState,
	reducers: {
		GoodsShowDetail: (state, { payload }) => {
			state.is_open_dialog = payload.is_open_dialog;
			state.detail = payload.detail;
		},
		GoodsShowConfirm: (state, { payload }) => {
			state.is_open_confirm = payload;
		},
		GoodsSelectedIdsChange: (state, { payload }) => {
			state.selected_ids = payload;
		},
	},
	extraReducers: (builder) => {
		builder.addMatcher(
			entityEndpointsApi.goodsGetAll.matchFulfilled,
			(state, { payload }) => {
				state.list = payload || [];
			}
		);
		builder.addMatcher(entityEndpointsApi.goodsSave.matchFulfilled, (state) => {
			state.is_open_dialog = false;
			state.detail = undefined;
		});
		builder.addMatcher(
			entityEndpointsApi.goodsDelete.matchFulfilled,
			(state) => {
				state.is_open_confirm = false;
				state.selected_ids = [];
			}
		);
		builder.addMatcher(
			entityEndpointsApi.goodsDeleteMultiple.matchFulfilled,
			(state) => {
				state.is_open_confirm = false;
				state.selected_ids = [];
			}
		);
	},
});
export const goodsActions = GoodsSlice.actions;
export default GoodsSlice.reducer;
