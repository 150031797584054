import { ConfirmDialog } from '@/components/common';
import {
	ButtonAdd,
	ButtonCustom,
	ButtonDelete,
} from '@/components/common/button';
import { SheetDialog } from '@/components/common/sheet-dialog';
import DataTable, { DataTableColumnHeader } from '@/components/data-table';
import {
	useContractInfoDeleteMultipleMutation,
	useContractInfoGetAllByQuotationQuery,
	useLazyContractInfoGetWordFileQuery,
} from '@/contexts/api/contract-management/contract/contract-info-api';
import { ContractInfo } from '@/contexts/models/contract-management/contract';
import { contractInfoActions } from '@/contexts/reducers/contract-management/contract/contract-info.slice';
import { AppState, useAppDispatch, useAppSelector } from '@/contexts/store';
import { IsMobile } from '@/utils/check-mobile';
import { ColumnDef } from '@tanstack/react-table';
import { formatDate } from 'date-fns';
import { FileDownIcon } from 'lucide-react';
import { useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ContractStatusBadge } from './form/contract-status-badge';
import { ContractPurchaseListCard } from './table/purchase-mobile-table';
import ContractDetailFormSheet from './contract-detail-form-sheet';

interface ContractSalesListByQuotationSheetProps {
	quotation_id: number;
	contract_id?: number;
	title: string;
	isOpen: boolean;
	onOpenChange: (isOpen: boolean) => void;
	document_type: string;
}

export const ContractSalesListByQuotationSheet = ({
	quotation_id = 0,
	contract_id = 0,
	title = 'hợp đồng',
	isOpen,
	onOpenChange,
	document_type = '000',
}: ContractSalesListByQuotationSheetProps) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const location = useLocation();

	const { is_open_confirm, selected_ids } = useAppSelector(
		(state: AppState) => state.Contract.ContractInfo
	);

	const [openDetail, setOpenDetail] = useState({
		isOpen: false,
		detail_id: 0,
	});

	const {
		data,
		isFetching,
		isLoading,
		refetch: usePrefetch,
	} = useContractInfoGetAllByQuotationQuery({
		document_type: document_type || '000',
		ref_contract_id: contract_id || 0,
		quotation_id: quotation_id || 0,
	});

	const [downloadWord] = useLazyContractInfoGetWordFileQuery();

	const dataLoading = isFetching || isLoading;

	const [deletesAction] = useContractInfoDeleteMultipleMutation();

	const deleteMultiHandle = (isOpen: boolean) => {
		dispatch(
			contractInfoActions.ContractInfoShowConfirm({ is_open_confirm: isOpen })
		);
	};

	const selectedIdsChange = (ids: number[]) => {
		dispatch(
			contractInfoActions.ContractInfoSelectedIdsChange({ selected_ids: ids })
		);
	};

	const deleteMultiConfirm = useCallback(async () => {
		if (selected_ids.length > 0) await deletesAction(selected_ids).unwrap();
	}, [deletesAction, selected_ids]);

	const columns: ColumnDef<ContractInfo>[] = [
		{
			accessorKey: 'contract_date',
			header: 'Ngày',
			cell: ({ row }) => {
				return (
					<div className='text-center'>
						{formatDate(row.original.contract_date, 'dd/MM/yyyy')}
					</div>
				);
			},
		},
		{
			accessorKey: 'contract_status_id',
			header: 'Trạng thái',
			cell: ({ row }) => {
				return (
					<div className='text-center'>
						<ContractStatusBadge value={row.original.contract_status_id} />
					</div>
				);
			},
		},
		{
			id: 'contract_code',
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title='Thông tin hợp đồng' />
			),
			cell: ({ row }) => {
				return (
					<div className='text-left text-xxs'>
						<div className='text-sm'>
							<ButtonCustom
								variant={'link'}
								title={row.original.contract_code}
								onClick={() =>
									setOpenDetail({
										isOpen: true,
										detail_id: row.original.id,
									})
								}
							/>

							<ButtonCustom
								variant={'icon'}
								icon={<FileDownIcon className='h-3.5 w-3.5' />}
								onClick={() => downloadWord(row.original.id)}
								className='text-black'
								title='Tải hợp đồng'
							/>
						</div>
					</div>
				);
			},
		},
		{
			id: 'goods_name',
			header: 'Hàng hóa',
			enableSorting: true,
			cell: ({ row }) => {
				return (
					<div className='text-center text-xxs'>
						<div className='text-sm'>
							<code className='text-xs'>{row.original.goods_name}</code>{' '}
							<div className='text-xxs'>
								Bên mua: {row.original.buyer_company_name}
							</div>
						</div>
					</div>
				);
			},
		},
		{
			id: 'goods_quantity',
			header: 'Số lượng (Kg)',
			enableSorting: false,
			cell: ({ row }) => {
				return (
					<div className='text-center text-xxs'>
						<div className='text-sm'>
							<code className='text-xs'>
								{row.original.goods_quantity.toLocaleString('vi')}
							</code>
						</div>
					</div>
				);
			},
		},
		{
			id: 'goods_price',
			header: 'Đơn giá (VNĐ)',
			enableSorting: false,
			cell: ({ row }) => {
				return (
					<div className='text-center text-xxs'>
						<div className='text-sm'>
							<code className='text-xs'>
								{row.original.goods_price.toLocaleString('vi')}
							</code>
						</div>
					</div>
				);
			},
		},
		{
			accessorKey: 'goods_sold',
			header: 'Dự kiến giao hàng',
			cell: ({ row }) => {
				return (
					<div className='text-center text-xxs'>
						<div className='text-xxs'>
							<div className='hidden text-muted-foreground md:block'>
								<b>
									{formatDate(
										row.original.goods_delivery_date_start || '',
										'dd/MM/yyyy'
									)}{' '}
									-{' '}
									{formatDate(
										row.original.goods_delivery_date_end || '',
										'dd/MM/yyyy'
									)}
								</b>
							</div>
							<div className='hidden text-muted-foreground md:block'>
								<b>{row.original.goods_delivery_address}</b>
							</div>
						</div>
					</div>
				);
			},
		},
	];

	return (
		<>
			<SheetDialog
				sheetTitle={`Danh sách hợp đồng bán hàng`}
				isOpen={isOpen}
				onOpenChange={onOpenChange}
				hasScroll={true}
				width='w-full'
			>
				{IsMobile() ? (
					<ContractPurchaseListCard />
				) : (
					<DataTable
						isLoading={dataLoading}
						toolBars={
							<>
								<ButtonAdd
									onClick={() => setOpenDetail({ isOpen: true, detail_id: 0 })}
								/>
								<ButtonDelete
									variant={'destructive'}
									title={`Xóa (${selected_ids.length})`}
									disabled={selected_ids.length <= 0}
									onClick={() => deleteMultiHandle(true)}
								/>
							</>
						}
						data={data || []}
						columns={columns}
						keyExpr={'id'}
						searchKey={'contract_code'}
						onReload={usePrefetch}
					/>
				)}
			</SheetDialog>
			{is_open_confirm && (
				<ConfirmDialog
					isOpen={is_open_confirm}
					onClose={() => deleteMultiHandle(false)}
					onConfirm={() => deleteMultiConfirm()}
					title='Thông báo'
					description={`Bạn có chắc chắn muốn xóa ${selected_ids.length} ${title} này không?`}
				/>
			)}
			{openDetail.isOpen && (
				<ContractDetailFormSheet
					isOpen={openDetail.isOpen}
					onOpenChange={() => setOpenDetail({ isOpen: false, detail_id: 0 })}
					title={title || 'Hơp đồng mua hàng'}
					contract_id={openDetail.detail_id}
					document_type={document_type}
					quotation_id={quotation_id}
				/>
			)}
		</>
	);
};
