import { Button } from '@/components/base/ui/button';
import { Form, FormField } from '@/components/base/ui/form';
import { useToast } from '@/components/base/ui/use-toast';
import FormInput from '@/components/common/form/form-input';
import FormInputPassword from '@/components/common/form/form-input-password';
import {
	LoginRequest,
	formLoginDefaultValues,
	formLoginSchema,
} from '@/contexts/models/Login';
import { zodResolver } from '@hookform/resolvers/zod';

import { useSignInMutation } from '@/contexts/api/auth/auth-api';
import { LogIn } from 'lucide-react';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

const LoginPage = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const [signInAction, { isLoading }] = useSignInMutation();

	const defaultValues = {
		...formLoginDefaultValues,
		// user_name: 'host',
		// password: 'Zxcvbnm@123',
	};
	const form = useForm<LoginRequest>({
		resolver: zodResolver(formLoginSchema),
		defaultValues,
	});

	const onSubmit = useCallback(
		async (formState: LoginRequest) => {
			await signInAction(formState).unwrap();
			navigate(location.state?.from || '/', { replace: true });
		},
		[navigate, signInAction, location]
	);
	return (
		<>
			<div className='flex w-full flex-col justify-end md:justify-end max-w-[320px] sm:w-[320px]'>
				<p className='text-3xl font-extrabold leading-tight tracking-tight pb-4'>
					Đăng nhập
				</p>
				<Form {...form}>
					<form
						onSubmit={form.handleSubmit(onSubmit)}
						className='space-y-2 w-full'
					>
						<FormField
							control={form.control}
							name='user_name'
							render={({ field }) => (
								<FormInput
									name={field.name}
									value={field.value}
									onValueChange={field.onChange}
									isDisabled={isLoading}
									label='Tài khoản'
									placeholder='Nhập tài khoản...'
								/>
							)}
						/>
						<FormField
							control={form.control}
							name='password'
							render={({ field }) => (
								<FormInputPassword
									name={field.name}
									value={field.value}
									onValueChange={field.onChange}
									isDisabled={isLoading}
									label='Mật khẩu'
									placeholder='Nhập mật khẩu...'
								/>
							)}
						/>
						<div className='pt-4'>
							<Button
								disabled={isLoading}
								className='ml-auto w-full rounded-full'
								type='submit'
							>
								<LogIn className='mr-2 h-4 w-4' /> Đăng nhập
							</Button>
						</div>
					</form>
				</Form>
			</div>
		</>
	);
};

export default LoginPage;
