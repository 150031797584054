import { Button } from '@/components/base/ui/button';
import { cn } from '@/lib/utils';
import AuthProvider from '@/utils/auth-provider';
import { Bell } from 'lucide-react';
import { Link, useOutlet } from 'react-router-dom';
import MainSidebar from './main-sidebar';
import MobileSidebar from './mobile-sidebar';
import { ModeToggle } from './mode-toggle';
import UserNav from './user-nav';

const MainLayout = () => {
	const outlet = useOutlet();
	return (
		<>
			<AuthProvider>
				<div className='fixed top-0 left-0 right-0 supports-backdrop-blur:bg-background/60 border-b bg-background/95 backdrop-blur z-20'>
					<nav className='h-14 flex items-center justify-between px-4'>
						<div className='hidden lg:block'>
							<Link to={'/'}>
								<b>Quang Trung CMS</b>
							</Link>
						</div>
						<div className={cn('block lg:!hidden')}>
							<MobileSidebar />
						</div>
						<div className='flex items-center gap-2'>
							<Button variant='outline' size='icon' className='ml-auto h-8 w-8'>
								<Bell className='h-4 w-4' />
								<span className='sr-only'>Toggle notifications</span>
							</Button>
							<ModeToggle />
							<UserNav />
						</div>
					</nav>
				</div>
				<div className='flex'>
					<MainSidebar />
					<main className='relative w-full flex flex-1 flex-col pt-16 bg-muted/40'>
						{outlet}
					</main>
				</div>
			</AuthProvider>
		</>
	);
};

export default MainLayout;
