import { ContractQuotationApi } from '@/contexts/api/contract-management/contract/contract-quotation-api';
import {
	ContractQuotation,
	ContractQuotationFormModel,
} from '@/contexts/models/contract-management/contract/ContractQuotation';

import { NotifyHelper } from '@/utils/notify-helper';
import { createSlice } from '@reduxjs/toolkit';

const entityName = 'Phiếu báo giá chào hàng';

type ContractQuotationState = {
	contractQuotations: ContractQuotation[];
	detail?: ContractQuotationFormModel;
	is_open_dialog: boolean;
	selected_ids: number[];
	is_open_confirm: boolean;
	is_open_lock_confirm: boolean;
	is_open_unlock_confirm: boolean;
	inserted_id?: number;
};

const initialState = {
	contractQuotations: [],
	contractQuotationTemplates: [],
	detail: undefined,
	is_open_confirm: false,
	is_open_dialog: false,
	selected_ids: [],
	inserted_id: undefined,
	is_open_lock_confirm: false,
	is_open_unlock_confirm: false,
} as ContractQuotationState;

const entityEndpointsApi = ContractQuotationApi.endpoints;

const ContractQuotationSlice = createSlice({
	name: 'ContractQuotationSlice',
	initialState: initialState,
	reducers: {
		ContractQuotationShowDetail: (
			state,
			{
				payload,
			}: { payload: { is_open_dialog: boolean; detail?: ContractQuotationFormModel } }
		) => {
			state.is_open_dialog = payload.is_open_dialog;
			state.detail = payload.detail;
			state.inserted_id = undefined;
		},
		ContractQuotationShowConfirm: (
			state,
			{ payload }: { payload: { is_open_confirm: boolean } }
		) => {
			state.is_open_confirm = payload.is_open_confirm;
		},
		ContractQuotationShowLockConfirm: (
			state,
			{ payload }: { payload: { is_open_lock_confirm: boolean } }
		) => {
			state.is_open_lock_confirm = payload.is_open_lock_confirm;
		},
		ContractQuotationShowUnlockConfirm: (
			state,
			{ payload }: { payload: { is_open_unlock_confirm: boolean } }
		) => {
			state.is_open_unlock_confirm = payload.is_open_unlock_confirm;
		},
		ContractQuotationSelectedIdsChange: (
			state,
			{ payload }: { payload: { selected_ids: number[] } }
		) => {
			state.selected_ids = payload.selected_ids;
		},
	},
	extraReducers: (builder) => {
		builder.addMatcher(
			entityEndpointsApi.contractQuotationGetAll.matchFulfilled,
			(state, { payload }) => {
				state.contractQuotations = payload || [];
			}
		);
		builder.addMatcher(
			entityEndpointsApi.contractQuotationSave.matchFulfilled,
			(state, { payload }) => {
				state.is_open_dialog = false;
				state.detail = undefined;

				const { is_success, message, result } = payload;
				state.inserted_id = result?.id;
				if (is_success)
					NotifyHelper.Success(message || `Lưu ${entityName} thành công`);
				else
					NotifyHelper.Error(message || `Lưu ${entityName} không thành công`);
			}
		);
		builder.addMatcher(
			entityEndpointsApi.contractQuotationConfirm.matchFulfilled,
			(state, { payload }) => {
				const { is_success, message } = payload;
				if (is_success)
					NotifyHelper.Success(
						message || `Khóa/mở khóa ${entityName} thành công`
					);
				else
					NotifyHelper.Error(
						message || `Khóa/mở khóa ${entityName} không thành công`
					);
				state.is_open_lock_confirm = false;
				state.is_open_unlock_confirm = false;
				state.selected_ids = [];
			}
		);
		builder.addMatcher(
			entityEndpointsApi.contractQuotationDelete.matchFulfilled,
			(state, { payload }) => {
				state.is_open_confirm = false;
				state.selected_ids = [];
				const { is_success, message } = payload;
				if (is_success)
					NotifyHelper.Success(message || `Xóa ${entityName} thành công`);
				else
					NotifyHelper.Error(message || `Xóa ${entityName} không thành công`);
			}
		);
		builder.addMatcher(
			entityEndpointsApi.contractQuotationDeleteMultiple.matchFulfilled,
			(state, { payload }) => {
				state.is_open_confirm = false;
				state.selected_ids = [];

				const { is_success, message } = payload;
				if (is_success)
					NotifyHelper.Success(message || `Xóa ${entityName} thành công`);
				else
					NotifyHelper.Error(message || `Xóa ${entityName} không thành công`);
			}
		);
	},
});
export const contractQuotationActions = ContractQuotationSlice.actions;
export default ContractQuotationSlice.reducer;
