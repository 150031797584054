import logo from '@/assets/logo/favicon.png';

const LoadingScreen = () => {
	return (
		<div className='relative flex justify-center items-center h-screen'>
			<div className='absolute animate-spin rounded-full h-20 w-20 border-t-4 border-b-4 border-primary'></div>
			<img src={logo} className='rounded-full h-16 w-16' />
		</div>
	);
};
export default LoadingScreen;
