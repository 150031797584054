import { cn } from '@/lib/utils';
import { LucideIcon } from 'lucide-react';
import React from 'react';

interface PageContainerProps {
	className?: string;
	children?: React.ReactNode;
}

export const PageContainer = ({ className, children }: PageContainerProps) => {
	return (
		<>
			<div
				className={cn(
					'h-full gap-4 p-4 sm:px-4 sm:py-0 md:gap-4 overflow-hidden',
					className
				)}
			>
				{children}
			</div>
		</>
	);
};
