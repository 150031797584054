import {
	ContractDispatch,
	ContractDispatchFormModel,
} from '@/contexts/models/contract-management/contract/ContractDispatchModel';
import { createQueryString } from '@/utils/create-query-string';
import { apiSlice } from '../../api-slice';
import { NotifyHelper } from '@/utils/notify-helper';

const endpointApi = '/contract-dispatch';
const entityName = 'Đợt giao hàng';
export const eContractDispatchCacheTag = 'CONTRACT_DISPATCH';

type ContractDispatchChangeStatusRequest = {
	dispatch_status_id: string;
	ids: number[];
};

export const ContractDispatchApi = apiSlice
	.enhanceEndpoints({ addTagTypes: [eContractDispatchCacheTag] })
	.injectEndpoints({
		endpoints: (builder) => ({
			contractDispatchGetAll: builder.query({
				query: (contract_id?: number) => ({
					url: `${endpointApi}${createQueryString({ contract_id })}`,
					method: 'GET',
				}),
				transformResponse: (response) => response.result as ContractDispatch[],
				providesTags: (result) =>
					result
						? [
								...result.map(({ id }) => ({
									type: `${eContractDispatchCacheTag}` as const,
									id,
								})),
								{ type: eContractDispatchCacheTag, id: 'LIST' },
							]
						: [{ type: eContractDispatchCacheTag, id: 'LIST' }],
			}),
			contractDispatchGetAllByFilter: builder.query({
				query: (contract_id?: number) => ({
					url: `${endpointApi}/select-by-contract${createQueryString({ contract_id })}`,
					method: 'GET',
				}),
				transformResponse: (response) => response.result as ContractDispatch[],
				providesTags: (result) =>
					result
						? [
								...result.map(({ id }) => ({
									type: `${eContractDispatchCacheTag}` as const,
									id,
								})),
								{ type: eContractDispatchCacheTag, id: 'LIST_BY_CONTRACT' },
							]
						: [{ type: eContractDispatchCacheTag, id: 'LIST_BY_CONTRACT' }],
			}),
			contractDispatchGetByGoods: builder.query({
				query: ({
					goods_id,
					document_type,
				}: {
					goods_id: number;
					document_type: string;
				}) => ({
					url: `${endpointApi}/select-by-goods${createQueryString({ goods_id, document_type })}`,
					method: 'GET',
				}),
				transformResponse: (response) => response.result as ContractDispatch[],
				providesTags: (result) =>
					result
						? [
								...result.map(({ id }) => ({
									type: `${eContractDispatchCacheTag}` as const,
									id,
								})),
								{ type: eContractDispatchCacheTag, id: 'LIST_BY_GOODS' },
							]
						: [{ type: eContractDispatchCacheTag, id: 'LIST_BY_GOODS' }],
			}),
			contractDispatchGetDetail: builder.query({
				query: (id: number) => ({ url: `${endpointApi}/${id}`, method: 'GET' }),
				transformResponse: (response) => response.result as ContractDispatch,
				providesTags: (result, error, id) => [
					{ type: eContractDispatchCacheTag, id },
				],
			}),
			contractDispatchSave: builder.mutation({
				query: (entity: ContractDispatchFormModel) => ({
					url: endpointApi,
					method: entity.id > 0 ? 'PUT' : 'POST',
					data: entity,
				}),
				transformResponse: (response) => response,
				invalidatesTags: (result, error, arg) => [
					{ type: eContractDispatchCacheTag, id: arg.id },
					{ type: eContractDispatchCacheTag, id: 'LIST' },
					{ type: eContractDispatchCacheTag, id: 'LIST_BY_CONTRACT' },
				],
			}),
			contractDispatchConfirm: builder.mutation({
				query: (id: number[]) => ({
					url: `${endpointApi}/confirm/${id}`,
					method: 'PUT',
				}),
				transformResponse: (response) => response,
				invalidatesTags: [
					{ type: eContractDispatchCacheTag, id: 'LIST' },
					{ type: eContractDispatchCacheTag, id: 'LIST_BY_CONTRACT' },
				],
			}),
			contractDispatchDelete: builder.mutation({
				query: (id: number) => ({
					url: `${endpointApi}/${id}`,
					method: 'DELETE',
				}),
				transformResponse: (response) => response,
				invalidatesTags: [
					{ type: eContractDispatchCacheTag, id: 'LIST' },
					{ type: eContractDispatchCacheTag, id: 'LIST_BY_CONTRACT' },
				],
			}),
			contractDispatchDeleteMultiple: builder.mutation({
				query: (ids: number[]) => ({
					url: `${endpointApi}/deletes`,
					method: 'DELETE',
					data: { ids },
				}),
				transformResponse: (response) => response,
				invalidatesTags: [
					{ type: eContractDispatchCacheTag, id: 'LIST' },
					{ type: eContractDispatchCacheTag, id: 'LIST_BY_CONTRACT' },
				],
			}),
			contractDispatchChangeStatus: builder.mutation({
				query: (request: ContractDispatchChangeStatusRequest) => ({
					url: `${endpointApi}/change-status`,
					method: 'PUT',
					data: request,
				}),
				transformResponse: (response) => response,
				async onQueryStarted(_args, { queryFulfilled }) {
					try {
						const { data } = await queryFulfilled;
						if (data.is_success)
							NotifyHelper.Success(
								data.message || `Thay đổi trạng thái ${entityName} thành công`
							);
						else
							NotifyHelper.Error(
								data.message || `Thay đổi trạng thái ${entityName} thất bại`
							);
					} catch (ex: any) {
						NotifyHelper.Error(
							ex?.error?.data || `Thay đổi trạng thái ${entityName} có lỗi`
						);
					}
				},
				invalidatesTags: [{ type: eContractDispatchCacheTag, id: 'LIST' }],
			}),
		}),
	});

export const {
	useLazyContractDispatchGetAllQuery,
	useContractDispatchGetAllQuery,
	useContractDispatchDeleteMultipleMutation,
	useContractDispatchDeleteMutation,
	useContractDispatchSaveMutation,
	useLazyContractDispatchGetDetailQuery,
	useContractDispatchGetDetailQuery,
	useContractDispatchConfirmMutation,
	useContractDispatchGetAllByFilterQuery,
	useLazyContractDispatchGetAllByFilterQuery,
	useContractDispatchChangeStatusMutation,
	useContractDispatchGetByGoodsQuery,
	useLazyContractDispatchGetByGoodsQuery,
} = ContractDispatchApi;
