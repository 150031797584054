import { Badge } from '@/components/base/ui/badge';
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from '@/components/base/ui/tooltip';
import { useTransportStatus } from '@/hooks/catalog/use-transport-status';
import { cn } from '@/lib/utils';
import { DotFilledIcon } from '@radix-ui/react-icons';
import { useMemo } from 'react';

interface ITransportStatusBadgeProps {
	value: string;
}

export const TransportStatusBadge = ({ value }: ITransportStatusBadgeProps) => {
	const { all_transport_status } = useTransportStatus();

	const data = useMemo(() => {
		const current_status = value && value.length > 0 ? value : 'NEW';

		return (
			all_transport_status?.find((item) => item.id === current_status) || {
				id: 'NEW',
				name: 'Chưa xử lý',
				class: 'bg-secondary text-secondary',
			}
		);
	}, [all_transport_status, value]);

	return (
		<>
			<TooltipProvider>
				<Tooltip>
					<TooltipTrigger>
						<Badge
							variant='outline'
							className={cn('inline-flex', data.class)}
						>
							<DotFilledIcon className={cn('w-3 h-3')} />{' '}
							<span className='truncate w-14 text-[11px]'>{data.name}</span>
						</Badge>
					</TooltipTrigger>
					<TooltipContent>
						<span className='text-[11px]'>{data.name}</span>
					</TooltipContent>
				</Tooltip>
			</TooltipProvider>
		</>
	);
};
