import { ScrollArea } from '@/components/base/ui/scroll-area';
import { cn } from '@/lib/utils';
import {
	Sheet,
	SheetContent,
	SheetDescription,
	SheetFooter,
	SheetHeader,
	SheetTitle
} from '@/ui/sheet';

import { FieldValues, UseFormReturn } from 'react-hook-form';
import { ButtonSave } from '../button';
import FromContainer from './form-container';
import { IsMobile } from '@/utils/check-mobile';

interface FormSheetDialogPropss<TData extends FieldValues> {
	isOpen?: boolean;
	onOpenChange: (isOpen: boolean) => void;
	form: UseFormReturn<TData, any, undefined>;
	onSubmit: (data: TData) => void;
	sheetTitle?: string;
	sheetDescription?: string;
	children?: React.ReactNode;
	footerBtn?: React.ReactNode;
	isDisabled?: boolean;
	hasScroll?: boolean;
	width?: string;
}

export function FormSheetDialog<TData extends FieldValues>({
	sheetTitle,
	sheetDescription,
	isOpen,
	onOpenChange,
	children,
	isDisabled,
	form,
	onSubmit,
	hasScroll,
	width,
	footerBtn
}: FormSheetDialogPropss<TData>) {
	return (
		<Sheet open={isOpen} onOpenChange={onOpenChange}>
			<SheetContent
				className={cn(
					'w-1/3 xs:max-w-xs sm:max-w-none p-2',
					width,
					IsMobile() && 'w-full'
				)}
			>
				<SheetHeader>
					<SheetTitle>{sheetTitle}</SheetTitle>
					{sheetDescription && (
						<SheetDescription>{sheetDescription}</SheetDescription>
					)}
				</SheetHeader>
				<FromContainer form={form} onSubmit={onSubmit}>
					{hasScroll === true ? (
						<ScrollArea>
							<div className='form-sheet-dialog-scrollable pl-1 pr-3'>
								{children}
							</div>
						</ScrollArea>
					) : (
						<>{children}</>
					)}
					<SheetFooter className='flex-row items-center justify-center mt-2'>
						{footerBtn}
						<ButtonSave
							tabIndex={0}
							disabled={isDisabled}
							isLoading={isDisabled}
							className='rounded-full'
							type='button'
							onClick={form.handleSubmit(onSubmit)}
						/>
					</SheetFooter>
				</FromContainer>
			</SheetContent>
		</Sheet>
	);
}
