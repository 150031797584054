import Combobox from '@/components/common/combobox';
import { useTransportStatus } from '@/hooks/catalog/use-transport-status';
import { cn } from '@/lib/utils';
import { DotFilledIcon } from '@radix-ui/react-icons';

interface TransportStatusComboboxProps {
	value: string;
	onValueChange: (value: string) => void;
	name: string;
	label?: string;
	isDisabled?: boolean;
	helpBlock?: string;
}

const TransportStatusCombobox = ({
	value,
	onValueChange,
	...rest
}: TransportStatusComboboxProps) => {
	const { all_transport_status } = useTransportStatus();
	return (
		<>
			<Combobox
				dataSource={all_transport_status || []}
				valueExpr={'id'}
				displayExpr={'name'}
				value={value}
				onValueChange={(value) => {
					onValueChange(value);
				}}
				placeholder='Chọn trạng thái...'
				{...rest}
				width='w-full w-max-full'
				itemRender={(data) => (
					<div className={cn('inline-flex', data.class)}>
						<DotFilledIcon className={cn('w-3 h-3')} /> {data.name}
					</div>
				)}
			/>
		</>
	);
};

export default TransportStatusCombobox;
