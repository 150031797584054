import { UserApi } from '@/contexts/api/super-admin';
import { UserFormModel, UserModel } from '@/contexts/models/super-admin';
import { createSlice } from '@reduxjs/toolkit';

type UserState = {
	users: UserModel[];
	is_open_dialog: boolean;
	selected_ids: number[];
	is_open_confirm: boolean;
};

const initialState = {
	users: [],
	is_open_confirm: false,
	is_open_dialog: false,
	selected_ids: [],
} as UserState;

const entityEndpointsApi = UserApi.endpoints;

const UserSlice = createSlice({
	name: 'UserSlice',
	initialState: initialState,
	reducers: {
		UserShowConfirm: (state, { payload }) => {
			state.is_open_confirm = payload;
		},
		UserSelectedIdsChange: (state, { payload }) => {
			state.selected_ids = payload;
		},
	},
	extraReducers: (builder) => {
		builder.addMatcher(
			entityEndpointsApi.userGetAll.matchFulfilled,
			(state, { payload }) => {
				state.users = payload || [];
			}
		);
		builder.addMatcher(
			entityEndpointsApi.userDelete.matchFulfilled,
			(state) => {
				state.is_open_confirm = false;
				state.selected_ids = [];
			}
		);
		builder.addMatcher(
			entityEndpointsApi.userDeleteMultiple.matchFulfilled,
			(state) => {
				state.is_open_confirm = false;
				state.selected_ids = [];
			}
		);
	},
});
export const userActions = UserSlice.actions;
export default UserSlice.reducer;
