import { HTMLInputTypeAttribute, useMemo } from 'react';
import {
	FormControl,
	FormDescription,
	FormItem,
	FormLabel,
	FormMessage,
} from '../../base/ui/form';
import { Input } from '../../base/ui/input';
import { cn } from '@/lib/utils';
import {
	MaskEventHandler,
	Modify,
	Replacement,
	Track,
	useMask,
} from '@react-input/mask';

interface FormInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
	onValueChange: (value: string) => void;
	onFilesChange?: (value?: FileList | File[]) => void;
	name: string;
	label?: string;
	type?: HTMLInputTypeAttribute | undefined;
	isDisabled?: boolean;
	placeholder?: string;
	isRequired?: boolean;
	button?: React.ReactNode;
	helpBlock?: string;
	classForm?: string;
	useInputMask?: {
		/** Input mask, `replacement` is used to replace characters. */
		mask: string;
		/** Sets the characters replaced in the mask, where "key" is the replaced character, "value" is the regular expression to which the input character must match (see «[Replacement](https://github.com/GoncharukBro/react-input/tree/main/packages/mask#replacement)»). It is possible to pass the replacement character as a string, then `replacement="_"` will default to `replacement={{ _: /./ }}`. Keys are ignored as you type. */
		replacement: string | Replacement;
		/** Controls the display of the mask, for example, `+0 (123) ___-__-__` instead of `+0 (123`. */
		showMask?: boolean;
		/** Stores the position of the entered characters. By default, input characters are non-breaking, which means that if you remove characters in the middle of the value, the characters are shifted to the left, forming a non-breaking value, which is the behavior of `input`. For example, with `true`, the possible value is `+0 (123) ___-45-__`, with `false` - `+0 (123) 45_-__-__`. */
		separate?: boolean;
		/** The function is activated before masking. Allows you to conditionally change the entered value (see «[Track](https://github.com/GoncharukBro/react-input/tree/main/packages/mask#track)»). */
		track?: Track;
		/** Function triggered before masking. Allows you conditionally change the properties of the component that affect masking. Valid values for modification are `mask`, `replacement`, `showMask` and `separate`. This is useful when you need conditionally tweak the displayed value to improve UX (see «[Modify](https://github.com/GoncharukBro/react-input/tree/main/packages/mask#modify)»). */
		modify?: Modify;
		/** Handler for the custom event `input-mask`. Called asynchronously after the `change` event, accessing the `detail` property containing additional useful information about the value. (see «[Mask event](https://github.com/GoncharukBro/react-input/tree/main/packages/mask#mask-event)»). */
		onMask?: MaskEventHandler;
	};
}

const FormInput = ({
	name,
	value,
	onValueChange,
	label,
	type,
	isDisabled,
	placeholder,
	isRequired,
	button,
	helpBlock,
	classForm,
	useInputMask,
	onFilesChange,
	...rest
}: FormInputProps) => {
	const useInputRef = useMask({
		mask: useInputMask?.mask,
		replacement: useInputMask?.replacement,
		showMask: useInputMask?.showMask || true,
		separate: useInputMask?.separate,
		track: useInputMask?.track,
		modify: useInputMask?.modify,
		onMask: useInputMask?.onMask,
	});
	return (
		<>
			<FormItem className={cn('flex flex-col pb-2', classForm)}>
				{label && (
					<FormLabel htmlFor={name}>
						{label} {isRequired ? <code className='text-red-400'>*</code> : ''}
					</FormLabel>
				)}
				<FormControl>
					<div className='flex w-full'>
						{useInputMask ? (
							<Input
								id={name}
								name={name}
								ref={useInputRef}
								type={type || 'text'}
								value={value}
								onChange={(event) => onValueChange(event.target.value || '')}
								placeholder={placeholder}
								disabled={isDisabled || false}
								{...rest}
							/>
						) : (
							<Input
								id={name}
								name={name}
								type={type || 'text'}
								value={value}
								onChange={(event) => {
									onValueChange(event.target.value || '');
									if (onFilesChange) onFilesChange(event.target.files || []);
								}}
								placeholder={placeholder}
								disabled={isDisabled || false}
								{...rest}
							/>
						)}
						{button && <>{button}</>}
					</div>
				</FormControl>
				{helpBlock && (
					<FormDescription
						className='text-xs italic'
						dangerouslySetInnerHTML={{ __html: helpBlock }}
					></FormDescription>
				)}
				<FormMessage />
			</FormItem>
		</>
	);
};

export default FormInput;
