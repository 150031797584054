import { Button } from '@/components/base/ui/button';
import { LucideHome } from 'lucide-react';
import { useState } from 'react';
import { Link, isRouteErrorResponse, useRouteError } from 'react-router-dom';

export function RootBoundary() {
	const error = useRouteError();
	const [statusCode, setStatusCode] = useState<string>('Opp ...');
	const [message, setMessage] = useState<string>(
		'Nội dung truy cập không tồn tại hoặc đang phát triển !!!'
	);
	if (isRouteErrorResponse(error)) {
		if (error.status === 404) {
			setStatusCode('404');
			setMessage('Nội dung truy cập không tồn tại hoặc đang phát triển !!!');
		}

		if (error.status === 401) {
			setStatusCode('401');
			setMessage('Bạn không có quyền truy cập vào trang này !!!');
		}

		if (error.status === 503) {
			setStatusCode('503');
			setMessage('Dịch vụ đang tạm thời không hoạt động !!!');
		}

		if (error.status === 418) {
			setStatusCode('418');
			setMessage('Trình duyệt không hỗ trợ truy cập vào trang này !!!');
		}
	}

	return (
		<div className='flex items-center justify-center h-full'>
			<div className='flex flex-col items-center'>
				<h1 className='font-bold text-[80px] text-blue-600 lg:text-6xl'>
					{statusCode}
				</h1>
				<p className='mb-4 text-center text-gray-500 md:text-lg'>{message}</p>

				<Link to='/'>
					<Button className='h-7 text-xs'>
						<LucideHome className='mr-2 h-4 w-4' /> Trang chủ
					</Button>
				</Link>
			</div>
		</div>
	);
}
